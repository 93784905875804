const LEVELS = {
  error: 4,
  warn: 3,
  info: 2,
  verbose: 1,
  debug: 0,
};

export class Logger {
  constructor(module) {
    this.module = module;

    // Define aliases for each log level on Logger.
    // eg. `Logger.info(...) = Logger.log('info', ...)`
    Object.keys(LEVELS).forEach((level) => {
      this[level] = function () {
        this.log.call(this, level, ...arguments);
      }.bind(this);
    });
  }

  log() {}
}
