function createProxy<T extends object>(rootElement: Element, type: string) {
  // We need to cast some values to a specific type to make this proxy work, in this case we know more than Typescript.
  const cache = {} as T;

  return new Proxy(cache, {
    // When a property is called on this object then we actually do a querySelector on the given rootElement instead.
    // The queried element is cached so it does not do unnecessary queries to the DOM.
    //
    // Some Typescript casting magic here in this function:
    // 1. cast the property to a key of the given interface T
    // 2. cast the querySelector result to a value of the given interface (using the key from the previous step)
    get(obj, _property) {
      const property = _property as keyof T;

      // If it's in this cache then return it immediately.
      if (property in obj) {
        return obj[property];
      }

      const element = rootElement.querySelector(`[data-${type}=${String(property)}]`) as T[typeof property];

      // Only cache the element within the object if it's not null, so in case it's null the query selector will be
      // run again the next time when this element is requested.
      if (element) {
        obj[property] = element;
      }

      return element;
    },
  });
}

/**
 * @example
 * interface Nodes {
 *   myLittleElement: HTMLDivElement;
 * }
 *
 * this.nodes = NodesProxy<Nodes>(this);
 *
 * this.nodes.myLittleElement // typeof === 'HTMLDivElement'
 */
export function NodesProxy<T extends object>(rootElement: Element) {
  return createProxy<T>(rootElement, 'selector');
}

/**
 * @example
 * interface Actions {
 *   myLittleButton: HTMLButtonElement;
 * }
 *
 * this.actions = ActionsProxy<Actions>(this);
 *
 * this.actions.myLittleButton // typeof === 'HTMLButtonElement'
 */
export function ActionsProxy<T extends object>(rootElement: Element) {
  return createProxy<T>(rootElement, 'action');
}
