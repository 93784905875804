import { loadTemplate } from '@/utils/dom.ts';

loadTemplate('c-count-down').then(({ content }) => {
  window.customElements.define(
    'c-count-down',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['width', 'time'];
      }

      set callback(fn) {
        this._fn = fn;
        this.startIfPossible();
      }

      set width(w) {
        if (isNaN(Number(w))) {
          this.container.style.width = w;
        } else {
          this.container.style.width = `${w}px`;
        }
        this.startIfPossible();
      }

      set time(t = 2) {
        this._time = t;
        this.startIfPossible();
      }

      startIfPossible() {
        if (this.isConnected && this._fn && this._time) {
          this.node.style.transitionDuration = `${this._time}s`;

          setTimeout(() => {
            this.container.style.opacity = '1';
            this.node.style.width = '0%';
          }, 20);
        }
      }

      constructor() {
        super();

        this.counter = 2;
        this.time = undefined;

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(content.cloneNode(true));
        this.container = this.shadowRoot.querySelector('div');
        this.node = this.shadowRoot.querySelector('span');
      }

      handleEvent({ target }) {
        switch (target) {
          case this.node:
            this.container.style.opacity = '0';
            break;

          case this.container:
            if (--this.counter === 0) {
              this._fn && this._fn();
            }
        }
      }

      connectedCallback() {
        this.container.addEventListener('transitionend', this);
        this.node.addEventListener('transitionend', this);
        this.startIfPossible();
      }

      disconnectedCallback() {
        this.container.removeEventListener('transitionend', this);
        this.node.removeEventListener('transitionend', this);
      }

      attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
          case 'time':
            this.time = newValue;
            break;

          case 'width':
            this.width = newValue;
            break;
        }
      }
    },
  );
});
