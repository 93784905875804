import request from '@/lib/request.mjs';
import { getUserFromStorage } from '@/lib/user.mjs';

import sortColltacts from '@/utils/sortColltacts.ts';
import { keysToCamel, keysToSnake } from '@/utils/string.ts';

import Contact from '@/models/Contact.mjs';

export async function one(contactId) {
  const { clientUuid } = await getUserFromStorage();
  const data = await request('contact', { contact_id: contactId, client_uuid: clientUuid });

  return new Contact(keysToCamel(data));
}

export async function all(forceRefresh = false) {
  const { clientUuid } = await getUserFromStorage();

  let data = (await request('contacts', { forceRefresh, client_uuid: clientUuid })).items;

  // FIXME remove this when the groups feature in the webphone has been
  // deployed, or rather when this fake contact is properly handled.
  data = data.filter((obj) => obj.given_name !== '__UNLINKED_VOIP_ACCOUNTS__');

  return data.map((contactData) => new Contact(keysToCamel(contactData))).sort(sortColltacts);
}

export async function create(contactData) {
  const { clientUuid } = await getUserFromStorage();
  const data = await request('contactCreate', {
    body: keysToSnake(contactData),
    client_uuid: clientUuid,
  });
  return new Contact(keysToCamel(data));
}
