import { getUserAvailabilities } from '@/lib/data/getUserAvailabilities.ts';
import { UserAvailabilityModel } from '@/lib/types/UserAvailabilityModel.ts';
import { getUserFromStorage } from '@/lib/user.mjs';

// FIXME we can't get a specific user yet (doesn't work) so get the collection and find the current user.
// We should be able to do: resgate.get(`availability.user.${uuid}`)

/**
 * Get the current logged in user's availability model.
 *
 * This endpoint returns UserAvailability model that holds initial data and can be used to
 * listen for changes for the user.
 */
export async function getAuthenticatedUserAvailability() {
  const user = await getUserFromStorage();

  if (user) {
    const users = await getUserAvailabilities();

    if (users) {
      return (users.toArray() as Array<UserAvailabilityModel>).find(({ user_uuid }) => user_uuid === user.uuid);
    }
  }
}
