import { VALIDATION_INVALID_INDICATORS } from './helpers/constants.mjs';
import createSetupFunction from './helpers/createSetupFunction.mjs';
import getValidationMessage from './helpers/getValidationMessage.mjs';

import { translate } from '@/lib/i18n.mjs';

export async function validate(e) {
  const { target } = e;

  const isValid = target.checkValidity();
  const { validity } = target;

  if (!isValid) {
    const cause = VALIDATION_INVALID_INDICATORS.filter((p) => validity[p])[0];
    target.setCustomValidity(translate(getValidationMessage('companyName', cause)));

    return false;
  }

  return true;
}

export const setup = createSetupFunction(
  [
    ['maxlength', '255'],
    ['required', ''],
  ],
  validate,
);
