import request from '@/lib/request.mjs';
import * as user from '@/lib/user.mjs';

export async function set(userStatus) {
  const { uuid, clientUuid } = await user.getUserFromStorage();

  const body = { status: userStatus };

  await request('setUserStatus', { user_uuid: uuid, client_uuid: clientUuid, body });
}
