export default function (call) {
  let phoneNumber;
  let callerName;
  if (call.voip_account) {
    phoneNumber = call.voip_account.internal_number;
    callerName = call.voip_account.description;
  } else if (call.fixed_destination) {
    phoneNumber = call.fixed_destination.phone_number;
    callerName = call.fixed_destination.description;
  } else {
    phoneNumber = call.phone_number;
  }
  return { phoneNumber, callerName };
}
