export const VALIDATION_SETUP_MARKER = 'validationSetup';
export const VALIDATED_BEFORE_MARKER = 'validatedBefore';
export const IS_VALID_MARKER = 'isValid';
export const IS_BEING_VALIDATED_MARKER = 'isValidating';
export const VALIDATION_INVALID_INDICATORS = [
  'badInput',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
  'typeMismatch',
  'valueMissing',
];

export const VALIDATION_MESSAGES_MAPPING = {
  validation: {
    companyName: {
      tooLong: '',
      valueMissing: '',
    },
    givenName: {
      tooLong: '',
      valueMissing: '',
    },
    familyName: {
      tooLong: '',
      valueMissing: '',
    },
    phoneNumber: {
      tooLong: '',
      tooShort: '',
      patternMismatch: '',
      validating: '',
      validationFailed: '',
    },
  },
};
