import { getPlatformUrl } from '@/lib/platform.mjs';
import * as segment from '@/lib/segment.mjs';

import { loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('p-feedback').then(({ content }) => {
  window.customElements.define(
    'p-feedback',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      async handleEvent() {
        // The autologin token that is retrieved in getPlatformUrl is only valid
        // for a limited amount of time, hence we need to retrieve a new one
        // every time the user clicks on the changelog link to make sure the
        // login is successful
        const featureAnnouncementsUrl = await getPlatformUrl('featureannouncements/?interface=webphone');
        window.open(featureAnnouncementsUrl, '_blank', 'noopener,noreferrer');
        segment.track.changelog();
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        if (import.meta.env.VITE_VENDOR_SUPPORT_EMAIL) {
          show(this.nodes.supportEmail);
        }

        if (import.meta.env.VITE_VENDOR_SUPPORT_PHONE) {
          show(this.nodes.supportPhone);
        }

        if (import.meta.env.VITE_VENDOR_SUPPORT_WEBSITE) {
          show(this.nodes.supportWebsite);
        }

        segment.track.contactUs();

        this.nodes.changelog.addEventListener('click', this);
      }

      disconnectedCallback() {
        this.nodes.changelog.removeEventListener('click', this);
      }
    },
  );
});
