import { userRatingLogger } from '@/lib/loggers.mjs';
import * as segment from '@/lib/segment.mjs';
import * as settings from '@/lib/settings/remote.mjs';

import { hide, loadTemplate, remove, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-user-rating').then(({ content }) => {
  window.customElements.define(
    'c-user-rating',
    class extends HTMLElement {
      get selectedRating() {
        const selectedRating = this.querySelector('input[type="radio"]:checked');
        return selectedRating ? selectedRating.value : false;
      }

      async handleEvent({ currentTarget }) {
        switch (currentTarget) {
          case this.actions.continueButton:
            {
              const selectedRating = this.selectedRating;

              if (selectedRating) {
                segment.track.userRating(selectedRating);

                userRatingLogger.info(`rated: ${selectedRating}`);
                await settings.set('userHasRatedTheWebphone', true);
                userRatingLogger.info(`userHasRatedTheWebphone = ${true}`);

                if (import.meta.env.VITE_VENDOR_NAME === 'Voys' && selectedRating >= 4) {
                  show(this.nodes.referralProgram);
                }

                hide(this.nodes.stepOne);
                show(this.nodes.stepTwo);
              } else {
                show(this.nodes.error);
              }
            }
            break;

          case this.actions.cancelButton:
          case this.actions.closeButton:
          case this.actions.close:
          case this.nodes.feedbackPageLink:
            userRatingLogger.info(`closing dialog`);
            remove(this);
            break;
        }

        if (this.starLabelNodes.includes(currentTarget)) {
          this.sortActiveStar(currentTarget);
        }
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      sortActiveStar(clickedStarNode) {
        hide(this.nodes.error);

        for (const node of this.starLabelNodes) {
          node.classList.remove('selected');
        }

        for (const node of this.starLabelNodes) {
          node.classList.add('selected');

          if (node === clickedStarNode) {
            break;
          }
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        if (typeof this.nodes.dialog.showModal !== 'function') {
          const dialogPolyfill = await import('dialog-polyfill');
          dialogPolyfill.default.registerDialog(this.nodes.dialog);
        }

        this.nodes.dialog.showModal();

        this.starLabelNodes = Array.from(this.nodes.stars.children).filter((node) => node.nodeName === 'LABEL');

        [
          ...this.starLabelNodes,
          this.actions.continueButton,
          this.actions.cancelButton,
          this.actions.closeButton,
          this.actions.close,
          this.nodes.feedbackPageLink,
        ].forEach((n) => n.addEventListener('click', this));
      }

      disconnectedCallback() {
        [
          ...this.starLabelNodes,
          this.actions.continueButton,
          this.actions.cancelButton,
          this.actions.closeButton,
          this.actions.close,
          this.nodes.feedbackPageLink,
        ].forEach((n) => n.removeEventListener('click', this));
      }
    },
  );
});
