import { playSound } from '@/lib/sounds.mjs';

import { disable, enable, loadTemplate } from '@/utils/dom.ts';

loadTemplate('c-keypad').then(({ content }) => {
  window.customElements.define(
    'c-keypad',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['disabled'];
      }

      set isDisabled(isDisabled) {
        for (const button of this.dialerKeypadButtons.children) {
          if (isDisabled) {
            disable(button);
          } else {
            enable(button);
          }
        }
      }

      handleEvent({ target: { dataset } }) {
        if (dataset && dataset.key && this.hasAttribute('dtmf')) {
          playSound(dataset.key, 'keypad');
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));
        this.addEventListener('click', this);
        this.dialerKeypadButtons = this.querySelector('[data-selector=dialer-keypad-buttons]');
      }

      disconnectedCallback() {
        this.removeEventListener('click', this);
      }

      attributeChangedCallback(name, oldValue, newValue) {
        this.isDisabled = newValue === '';
      }
    },
  );
});
