/**
 * This function checks if the list of items (items) contains the exact search word combination (keywords)
 *
 * @param items the list of items to check through
 * @param query  the query you want to find in the items list
 */

export default function doesMatchSearchString(items: Array<string>, query = '') {
  if (query.length === 0) {
    return true;
  }

  // Split the search query on space characters .e.g
  // query = "dik    trom blep   "
  // keywords = ["dik", "trom", "blep"]
  return query
    .toLowerCase()
    .split(/\s+/) // Split by one or more whitespace characters
    .filter((word) => word.length > 0) // Filter out empty strings
    .every(
      (
        keyword, // checks for every keyword if it exists in the items list and returns true, if all keywords exist in the items list
      ) => items.some((value) => value.includes(keyword)), // returns true, if the keyword exists in the items list
    );
}
