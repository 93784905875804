window.showSomethingWentWrongMessage = (function () {
  var somethingWentWrongNode = document.querySelector('[data-selector=something-went-wrong]');

  function show(node) {
    node.removeAttribute('hidden');
  }

  setTimeout(function () {
    var mountedAppNode = document.querySelector('c-router');

    if (mountedAppNode.childNodes && mountedAppNode.childNodes.length === 0) {
      window.showSomethingWentWrongMessage();
    }
  }, 10000);

  var formNode = somethingWentWrongNode.querySelector('[data-selector=feedbackForm]');

  formNode.addEventListener('submit', function (e) {
    e.preventDefault();

    var formData = new FormData(formNode);

    var body = {
      message: formData.get('message'),
      user: {},
      application: {
        version: `${import.meta.env.VITE_APP_NAME}${import.meta.env.VITE_VERSION}`,
      },
    };

    var xhr = new XMLHttpRequest();
    xhr.open('POST', import.meta.env.VITE_FEEDBACK_API_BASE);
    xhr.setRequestHeader('Referer', window.location.href);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(body));
  });

  return function () {
    (function () {
      var lang = document.documentElement.getAttribute('lang');
      if (!lang) {
        return;
      }

      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', function () {
        var content = JSON.parse(this.responseText);
        Array.prototype.slice
          .call(somethingWentWrongNode.querySelectorAll('[data-translation-key]'))
          .forEach(function (node) {
            var key = node.dataset.translationKey;
            if (key in content) {
              node.textContent = content[key];
            }
          });
      });
      xhr.open('GET', '/locales/' + lang + '/index.json');
      xhr.send();
    })();

    [
      ['website', import.meta.env.VITE_VENDOR_SUPPORT_WEBSITE],
      ['email', import.meta.env.VITE_VENDOR_SUPPORT_EMAIL],
      ['phone', import.meta.env.VITE_VENDOR_SUPPORT_PHONE],
    ].forEach(function (op) {
      if (op[1]) {
        Array.prototype.slice.call(somethingWentWrongNode.querySelectorAll('[data-support-' + op[0] + ']')).map(show);
      }
    });

    show(somethingWentWrongNode);
  };
})();
