export let selection;
export let hasSelection;
export let hadSelection = false;
export let isSelecting;

let hadSelectionTimer;

function unsetHadSelection() {
  hadSelection = false;
}

function selectionChange() {
  isSelecting = true;
  selection = document.getSelection().toString();
  hasSelection = !!selection; // https://stackoverflow.com/questions/784929/what-is-the-not-not-operator-in-javascript#answer-784946

  clearTimeout(hadSelectionTimer);

  if (!hasSelection) {
    hadSelectionTimer = setTimeout(unsetHadSelection, 300);
  } else {
    hadSelection = true;
  }
}

function selectstart() {
  isSelecting = true;
}

document.addEventListener('selectionchange', selectionChange, true);
document.addEventListener('selectstart', selectstart, true);
document.addEventListener(
  'mouseup',
  () => {
    isSelecting = false;
  },
  true,
);
