/**
 * Navigate to a new path while pushing the current state to the history stack.
 *
 * We'll also send an event to the window to handle that the view has been updated.
 * This will be used by the router to mark active menu items.
 *
 * @param path - The path to navigate to.
 * @param state - The state to push to the history stack.
 */
export function navigate(path: string, state = {}): void {
  const { pathname } = document.location;

  // Only navigate if the path given is different from the path we're on.
  if (pathname !== path) {
    history.pushState(state, '', path);
    window.dispatchEvent(new CustomEvent('updateview'));
  }
}

/**
 * Replacing state, because every time this function is called it is done
 * from a position where someone was at a place that was not applicable
 * anymore, so it wouldn't make sense to give the user the option to
 * navigate back to that page using the browser history.
 *
 * @param path - The path to navigate to
 */
export function redirect(path: string) {
  history.replaceState(undefined, '', path);
  window.dispatchEvent(new CustomEvent('updateview'));
}
