import debounce from '@/utils/debounce.mjs';

export default function create({ node, callback }) {
  if (!node || !callback) {
    throw new Error('node_or_callback_undefined');
  }

  const debounced = debounce({ fn: callback, delay: 50 });
  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      debounced(entry);
    }
  });

  resizeObserver.observe(node);

  return function deregister() {
    resizeObserver.disconnect();
  };
}
