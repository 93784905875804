import getClickToDialPhoneNumber from '@/lib/calling/getClickToDialPhoneNumber.mjs';

export default function getCallerInfo({ phoneNumber, displayName, colltactName, isClickToDial }) {
  if (colltactName && displayName !== colltactName) {
    displayName = displayName ? `${displayName} ${colltactName}` : colltactName;
  } else if (isClickToDial) {
    phoneNumber = getClickToDialPhoneNumber(displayName);
    displayName = 'click_to_dial';
  } else if (displayName) {
    displayName = displayName === 'Anonymous' ? 'anonymous' : displayName;
  } else {
    displayName = 'unknown';
  }
  return { displayName, phoneNumber };
}
