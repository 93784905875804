import getTemporaryRedirect from '@/lib/data/getTemporaryRedirect.mjs';
import getVoicemail from '@/lib/data/getVoicemail.mjs';
import validateIfUserHasAllPermissions from '@/lib/data/validateIfUserHasAllPermissions.mjs';
import { translateNodes } from '@/lib/i18n.mjs';
import { Logger } from '@/lib/logging.mjs';
import * as user from '@/lib/user.mjs';

import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import { userEvents } from '@/utils/eventTarget.ts';
import { getDateTimeString } from '@/utils/formatDate.ts';

import { temporaryRedirectPermissions } from '@/constants/userPermissions.mjs';

const logger = new Logger('temporary-redirect-banner');

loadTemplate('c-temporary-redirect-banner').then(({ content }) => {
  window.customElements.define(
    'c-temporary-redirect-banner',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
      }

      async handleEvent({ type }) {
        switch (type) {
          case 'loggedIn':
            this.userData = await user.get();
            this.toggleBanner();
            break;

          case 'loggedOut':
            hide(this);
            break;

          case 'tr_redirectStarted':
          case 'tr_redirectChanged':
          case 'tr_redirectEnded':
            this.toggleBanner();
            break;
        }
      }

      async toggleBanner() {
        await getTemporaryRedirect(this.userData.clientUuid)
          .then(async (response) => {
            if (response && !response.available) {
              // We already know the below information, so we don't need to refetch this on every repoll

              const hasPermissions = await validateIfUserHasAllPermissions(temporaryRedirectPermissions);

              const formattedDate = await getDateTimeString(response.end);

              if (hasPermissions) {
                const { data } = await getVoicemail(this.userData.clientId, response.destination.id);
                this.nodes.temporaryRedirectBannerVoicemail.textContent = data.name;
                this.nodes.bannerDate.textContent = formattedDate;

                show(this.nodes.temporaryRedirectBannerLink);
                show(this.nodes.temporaryRedirectBannerWithVoicemail);
              } else {
                show(this.nodes.temporaryRedirectBannerWithoutVoicemail);
              }

              show(this);
            } else {
              hide(this);
            }
          })
          .catch((err) => {
            hide(this);
            logger.error(err);
          });

        // Outside <main> node so translations won't get triggered automatically
        translateNodes(this);
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.userData = await user.get();
        this.toggleInterval = null;
        this.bannerIsActive = false;

        if (this.userData) {
          this.toggleBanner();

          this.toggleInterval = window.setInterval(() => {
            this.toggleBanner();
          }, 120000);
        }

        userEvents.addEventListener('loggedIn', this);
        userEvents.addEventListener('loggedOut', this);
        window.addEventListener('tr_redirectStarted', this);
        window.addEventListener('tr_redirectChanged', this);
        window.addEventListener('tr_redirectEnded', this);
      }

      disconnectedCallback() {
        userEvents.removeEventListener('loggedIn', this);
        userEvents.removeEventListener('loggedOut', this);
        window.removeEventListener('tr_redirectStarted', this);
        window.removeEventListener('tr_redirectChanged', this);
        window.removeEventListener('tr_redirectEnded', this);
        clearInterval(this.toggleInterval);
      }
    },
  );
});
