import request from '@/lib/request.mjs';

import { dataEvents } from '@/utils/eventTarget.ts';

export default async function () {
  const data = await request('getDestination');
  const { id, fixeddestination, phoneaccount } = data.objects[0];
  const destination = {
    id,
    fixeddestination,
    phoneaccount,
  };

  const phoneaccountId = phoneaccount ? phoneaccount.id : null;
  const fixeddestinationId = fixeddestination ? fixeddestination.id : null;

  dataEvents.dispatchEvent(
    new CustomEvent('destinationUpdated', {
      detail: { phoneaccount: phoneaccountId, fixeddestination: fixeddestinationId },
    }),
  );

  return destination;
}
