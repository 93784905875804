// Load necessary components, other components should be dynamically imported when needed.
import '@/components/c-account-picker.mjs';
import '@/components/c-account.mjs';
import '@/components/c-audio-device-selection.mjs';
import '@/components/c-audio-dialog.ts';
import '@/components/c-change-outgoing-number.ts';
import '@/components/c-colleague.mjs';
import '@/components/c-complete-attended-transfer.mjs';
import '@/components/c-contact-create-edit-form.mjs';
import '@/components/c-contact-number.mjs';
import '@/components/c-contact-numbers-popout.mjs';
import '@/components/c-contact.mjs';
import '@/components/c-count-down.mjs';
import '@/components/c-dark-mode-support.mjs';
import '@/components/c-dtmf-display.mjs';
import '@/components/c-feedback-form.mjs';
import '@/components/c-icon.mjs';
import '@/components/c-incoming-call.mjs';
import '@/components/c-install-button.mjs';
import '@/components/c-keypad.mjs';
import '@/components/c-language-switcher.mjs';
import '@/components/c-maintenance-message.mjs';
import '@/components/c-multiple-windows.mjs';
import '@/components/c-nav-link.mjs';
import '@/components/c-onboarding-progress.mjs';
import '@/components/c-outgoing-call.mjs';
import '@/components/c-password-visualiser.mjs';
import '@/components/c-phone-number-input.mjs';
import '@/components/c-platform-link.mjs';
import '@/components/c-prevent-accidental-close.mjs';
import '@/components/c-queue-size.mjs';
import '@/components/c-queue.mjs';
import '@/components/c-quick-links-popup.mjs';
import '@/components/c-recent-call.mjs';
import '@/components/c-router-link.mjs';
import '@/components/c-router.mjs';
import '@/components/c-session-count.mjs';
import '@/components/c-session.mjs';
import '@/components/c-sessions.mjs';
import '@/components/c-setting-error.mjs';
import '@/components/c-setting-saved.mjs';
import '@/components/c-setting-toggle.mjs';
import '@/components/c-short-lived-visual-feedback.mjs';
import '@/components/c-show-online-contacts-toggle.mjs';
import '@/components/c-temporary-redirect-active.mjs';
import '@/components/c-temporary-redirect-banner.mjs';
import '@/components/c-temporary-redirect-initial.mjs';
import '@/components/c-temporary-redirect-setup.mjs';
import '@/components/c-temporary-redirect-wrapper.mjs';
import '@/components/c-toast.mjs';
import '@/components/c-toasts.mjs';
import '@/components/c-toggle.mjs';
import '@/components/c-transfer.mjs';
import '@/components/c-translate.mjs';
import '@/components/c-troubleshoot-notifications.mjs';
import '@/components/c-unsupported-browser.mjs';
import '@/components/c-updater.mjs';
import '@/components/c-user-destinations.mjs';
import '@/components/c-user-rating.mjs';
import '@/components/c-user-statuses.mjs';
import '@/components/c-user.mjs';
import '@/components/c-voip-platform.mjs';
import '@/components/c-volume-selection.mjs';
import '@/components/c-wake-lock.mjs';

// Initialize libraries.
import '@/lib/applicationStatus.mjs';
import { activeSession, getSession } from '@/lib/calling.mjs';
import '@/lib/headset/manager.ts';
import '@/lib/i18n.mjs';
import '@/lib/install.mjs';
import '@/lib/log.mjs';
import '@/lib/media.mjs';
import '@/lib/request.mjs';
import '@/lib/resgate.ts';
import * as segment from '@/lib/segment.mjs';
import '@/lib/sentry.mjs';
import '@/lib/settings/local.mjs';
import '@/lib/settings/remote.mjs';
import { playSound } from '@/lib/sounds.mjs';
import '@/lib/temporary-storage.mjs';
import '@/lib/toasts.mjs';
import * as user from '@/lib/user.mjs';
import '@/lib/wakeLock.mjs';

import { disable, enable } from '@/utils/dom.ts';
import { callingEvents, dtmfEvents } from '@/utils/eventTarget.ts';
import '@/utils/globalDTMFListener.mjs';
import '@/utils/selection.mjs';

import { DONE_TYPING_DTMF, DTMF_OPTIONS } from '@/constants/dtmf.mjs';
import '@/directives/index.mjs';
import '@/sw-base.mjs';
import '@/sw-notifications.mjs';
import '@/sw-registration.mjs';

{
  const { pathname, search } = document.location;
  const linkedFrom = new URLSearchParams(search).get('source');

  user
    .isAuthenticated()
    .then(() => true)
    .catch(() => false)
    .then((isAuthenticated) => {
      if (linkedFrom && linkedFrom === 'plugin') {
        if (!isAuthenticated) {
          // Saving this until user logged in.
          localStorage.setItem('linked_from_click_to_dial', pathname);
          return;
        }

        segment.track.fromClickToDial({ toPage: linkedFrom });
      }
    });
}

let dtmfPressingTimer;
let dtmfString = '';

// global DTMF listener to forward keys that should trigger a DTMF tone to the active call
dtmfEvents.addEventListener('dtmf', ({ detail: key }) => {
  if (activeSession) {
    const session = getSession(activeSession.id);
    dtmfString += key;
    clearTimeout(dtmfPressingTimer);
    dtmfPressingTimer = setTimeout(() => {
      session.dtmf(dtmfString, DTMF_OPTIONS);
      dtmfString = '';
    }, DONE_TYPING_DTMF);
    playSound(key, 'globalDTMF');
  }
});

{
  const logoutNavLink = document.querySelector('[data-selector="logoutNavLink"]');
  const onBeforeUnload = () => {
    localStorage.removeItem('active_session');
  };
  // to have a synchronous way of checking to see if we need to register in possible other tabs
  // we store if we have ongoing sessions in localStorage
  callingEvents.addEventListener('sessionStarted', () => {
    // prevent users form logging out when they are on a call
    disable(logoutNavLink);

    window.addEventListener('beforeunload', onBeforeUnload);
    localStorage.setItem('active_session', 'true');
  });

  callingEvents.addEventListener('sessionEnded', () => {
    // when users are not on a call they probably want to be able to logout again...
    enable(logoutNavLink);

    window.removeEventListener('beforeunload', onBeforeUnload);
    onBeforeUnload();
  });
}
