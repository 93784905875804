import { prepareNode, tearDownNode } from '@/lib/formValidator.mjs';

import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { generalPunctuationCharacterBlockRegExp, removeTheseFromPhoneNumbersRegExp } from '@/utils/regexp.ts';

import PhoneNumber from '@/models/PhoneNumber.mjs';

loadTemplate('c-phone-number-input').then(({ content }) => {
  window.customElements.define(
    'c-phone-number-input',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['name', 'value'];
      }

      get value() {
        return this.nodes.phoneNumberInput.value;
      }

      set removeButtonIsVisible(visible) {
        if (visible) {
          show(this.actions.removeInput);
        } else {
          hide(this.actions.removeInput);
        }
      }

      set onRemoveButtonClick(callback) {
        this._onRemoveButtonClick = callback;
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);

        this.phoneNumberInstance = new PhoneNumber();
      }

      handleEvent(e) {
        switch (e.type) {
          case 'click':
            this._onRemoveButtonClick && this._onRemoveButtonClick();
            break;

          case 'change':
            // we only update the phoneNumber when it passes the validation rules
            // since assinging a value make the model call the validation service
            if (this.nodes.phoneNumberInput.checkValidity()) {
              this.phoneNumberInstance.phoneNumber = this.nodes.phoneNumberInput.value;
            }

            if (this.nodes.phoneNumberInput.value === '') {
              delete this.nodes.phoneNumberInput.dataset.isValid;
            }
            break;

          case 'isValid': // phoneNumber instance event
            this.nodes.phoneNumberInput.value = this.phoneNumberInstance.phoneNumber;
          // deliberatly not breaking here to fall through to the redispatching of the events also...
          // eslint-disable-next-line no-fallthrough
          case 'isInvalid': // phoneNumber instance event
          case 'startedAsyncValidation': // phoneNumber instance event
          case 'finishedAsyncValidation': // phoneNumber instance event
            // make sure the input updates the validation status
            this.nodes.phoneNumberInput.dispatchEvent(new CustomEvent(e.type));

            // give the application some time to go through its execution stack (updating attributes on DOM nodes
            // and such) and only re-validate after that
            window.setTimeout(() => {
              if (this.isConnected) {
                // make sure the input updates the validation status
                this.nodes.phoneNumberInput.dispatchEvent(new CustomEvent('validate'));
                // re-dispatch the event from the phoneNumberInstance to the DOM so we can react to it
                this.nodes.phoneNumberInput.dispatchEvent(new CustomEvent('validated'));
              }
            });
            break;

          case 'paste': {
            e.preventDefault();
            const { target } = e;
            const data = e.clipboardData
              .getData('text/plain')
              .replace(generalPunctuationCharacterBlockRegExp, '')
              .replace(removeTheseFromPhoneNumbersRegExp, '');
            const { selectionStart, selectionEnd } = target;
            const newSelection = selectionStart + data.length;
            target.setRangeText(data, selectionStart, selectionEnd);
            target.setSelectionRange(newSelection, newSelection);

            // to signal the create/edit form to create a new input
            target.dispatchEvent(new InputEvent('input'));

            // since Chrome will not fire a change event when blurring we trigger it ourselves
            target.dispatchEvent(new InputEvent('change'));
          }
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        // this is deliberatly executed before binding the 'change' event listener since we need to set the customError
        // message to empty string to not get false positives in the checkValidity check in the change listener.
        // setting a customError make the validity.valid property be false when checking it and binding the function to
        // reset it needs to be done before calling the checkValidity()
        prepareNode(this.nodes.phoneNumberInput);

        this.actions.removeInput.addEventListener('click', this);
        this.nodes.phoneNumberInput.addEventListener('change', this);
        this.nodes.phoneNumberInput.addEventListener('paste', this);

        // re-dispatch all the events on the phonenumber instance to make the validation work
        this.phoneNumberInstance.addEventListener('isValid', this);
        this.phoneNumberInstance.addEventListener('isInvalid', this);
        this.phoneNumberInstance.addEventListener('startedAsyncValidation', this);
        this.phoneNumberInstance.addEventListener('finishedAsyncValidation', this);
      }

      disconnectedCallback() {
        tearDownNode(this.nodes.phoneNumberInput);

        this.actions.removeInput.removeEventListener('click', this);
        this.nodes.phoneNumberInput.removeEventListener('change', this);
        this.nodes.phoneNumberInput.removeEventListener('paste', this);

        this.phoneNumberInstance.removeEventListener('isValid', this);
        this.phoneNumberInstance.removeEventListener('isInvalid', this);
        this.phoneNumberInstance.removeEventListener('startedAsyncValidation', this);
        this.phoneNumberInstance.removeEventListener('finishedAsyncValidation', this);
      }

      attributeChangedCallback(attribute, oldValue, newValue) {
        switch (attribute) {
          case 'name':
            this.nodes.phoneNumberInput.setAttribute('name', newValue);
            break;

          case 'value':
            this.phoneNumberInstance.phoneNumber = { phone_number: newValue };
            this.nodes.phoneNumberInput.value = newValue;
            break;
        }
      }
    },
  );
});
