import '@/components/c-account-picker.mjs';

import { translateNodes } from '@/lib/i18n.mjs';
import { getPlatformClientUrl } from '@/lib/platform.mjs';

import { loadTemplate } from '@/utils/dom.ts';

loadTemplate('c-no-account').then(({ content }) => {
  window.customElements.define(
    'c-no-account',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['hidden'];
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        translateNodes(this);

        this.purchaseAccountLink = this.querySelector('[data-selector=purchase-account-link]');
      }

      async attributeChangedCallback(name, oldValue, newValue) {
        if (newValue === null) {
          const url = await getPlatformClientUrl('phoneaccount/add/');
          this.purchaseAccountLink.setAttribute('href', url);
        }
      }
    },
  );
});
