import { getSession, getSessions } from '@/lib/calling.mjs';

import { empty } from '@/utils/dom.ts';
import { callingEvents } from '@/utils/eventTarget.ts';

window.customElements.define(
  'c-sessions',
  class extends HTMLElement {
    set sessions(sessions) {
      this._sessions = sessions;
      this.updateDOM();
    }

    constructor() {
      super();
      this._sessions = [];
    }

    handleEvent({ type }) {
      switch (type) {
        case 'lastSessionTerminatedSoundEnded':
          window.history.back();
          break;

        default:
          this.sessions = getSessions();
      }
    }

    async updateDOM() {
      if (this.isConnected) {
        if (0 === this._sessions.length) {
          empty(this);
          const p = document.createElement('c-translate');
          p.setAttribute('key', 'no_active_sessions');
          this.appendChild(p);
          return;
        } else {
          if (this.firstChild && this.firstChild.nodeName === 'C-TRANSLATE') {
            this.removeChild(this.firstChild);
          }
        }

        const idsAlreadyAdded = [];
        // remove the ones that shouldn't be there.
        for (const node of this.querySelectorAll('c-session')) {
          const sessionId = node.getAttribute('session-id');
          if (!getSession(sessionId)) {
            this.removeChild(node);
          } else {
            idsAlreadyAdded.push(sessionId);
          }
        }

        // add the ones that should be there.
        this._sessions.forEach(({ id }) => {
          if (idsAlreadyAdded.includes(id)) {
            return;
          }
          const node = document.createElement('c-session');
          node.setAttribute('session-id', id);
          this.insertAdjacentElement('afterbegin', node);
        });
      }
    }

    connectedCallback() {
      this.sessions = getSessions();
      callingEvents.addEventListener('sessionAdded', this);
      callingEvents.addEventListener('sessionRemoved', this);
      callingEvents.addEventListener('lastSessionTerminatedSoundEnded', this);
    }

    disconnectedCallback() {
      callingEvents.removeEventListener('sessionAdded', this);
      callingEvents.removeEventListener('sessionRemoved', this);
      callingEvents.removeEventListener('lastSessionTerminatedSoundEnded', this);
    }
  },
);
