import pTimeout from 'p-timeout';

import { Logger } from '@/lib/logging.mjs';

import isRunningInTestRuntime from '@/utils/isRunningInTestRuntime.ts';

const logger = new Logger('network');

function hasServerReflexiveCandidates(candidates) {
  return candidates.map((candidate) => candidate.split(' ')[7]).some((type) => type === 'srflx');
}

export function doesNetworkAllowAudioStreams() {
  let peerConnection;
  const candidates = [];

  // When this check is ran in test runtime we can skip this check, as we
  // probably don't need an audio stream but still need to access the rest
  // of the webphone to test other functionalities.
  if (isRunningInTestRuntime) {
    return Promise.resolve(true);
  }

  const promise = new Promise((resolve) => {
    peerConnection = new RTCPeerConnection({
      iceServers: [{ urls: 'stun:stun.voipgrid.nl' }],
    });

    peerConnection.onicecandidate = ({ candidate }) => {
      if (!candidate) {
        return;
      }

      candidates.push(candidate.candidate);
    };

    peerConnection.onicegatheringstatechange = () => {
      if (peerConnection.iceGatheringState !== 'complete') {
        return;
      }

      // Check for server reflexive candidates. If they exists, we can probably
      // open up an audio stream to our server.
      if (hasServerReflexiveCandidates(candidates)) {
        peerConnection.close();
        resolve(true);
      }
    };

    peerConnection.createOffer({ offerToReceiveAudio: 1 }).then((sdp) => peerConnection.setLocalDescription(sdp));
  });

  return pTimeout(promise, {
    milliseconds: 3000,
    fallback: () => {
      peerConnection.close();
      // It could be that gathering ice candidates is not done within 3 seconds.
      // If that is the case, it might be that there is still a suitable ice
      // candidate in the gathered list.
      if (hasServerReflexiveCandidates(candidates)) {
        return Promise.resolve(true);
      }

      logger.error('Network does not allow UDP streams');
      return Promise.resolve(false);
    },
  });
}
