import getUserData from '@/lib/data/getUser.ts';
import * as logging from '@/lib/log.mjs';
import request from '@/lib/request.mjs';
import * as segment from '@/lib/segment.mjs';
import * as sentry from '@/lib/sentry.mjs';
import * as temp from '@/lib/temporary-storage.mjs';

import debounce from '@/utils/debounce.mjs';
import { requestEvents, userEvents } from '@/utils/eventTarget.ts';

// we create a debounced change password dispatcher to create a window for the
// application to react to the change_password error. For example to do stuff
// before we navigate to the /change-password page
const debouncedDispatchChangePassword = debounce({
  fn: function () {
    userEvents.dispatchEvent(new CustomEvent('changePassword'));
  },
  delay: 200,
});

// to prevent the logout function to be called multiple times when multiple
// API requests are initiated but they all result in an unauthorised error
const debouncedLogout = debounce({
  fn: logout,
  delay: 200,
});

requestEvents.addEventListener('changePassword', debouncedDispatchChangePassword);
requestEvents.addEventListener('unauthorised', debouncedLogout);

function setTracking({ email, clientId, uuid } = {}) {
  segment.setUserId(email, clientId);
  sentry.setUserId(uuid);
  logging.setUserId(uuid, clientId);
}

export function login({ email, password, token }) {
  const body = { email, password };
  if (token) {
    body.two_factor_token = token;
  }

  return request('login', { body })
    .then(({ api_token }) => {
      localStorage.setItem('token', `Token ${email}:${api_token}`);
      segment.track.login();
    })
    .then(get)
    .then((user) => {
      setTracking(user);

      userEvents.dispatchEvent(new CustomEvent('userUpdated', { detail: user }));
      userEvents.dispatchEvent(new CustomEvent('loggedIn', { detail: user }));
    });
}

export async function logout() {
  localStorage.removeItem('token');
  segment.track.logout();

  setTracking(undefined);

  userEvents.dispatchEvent(new CustomEvent('userUpdated', { detail: undefined }));
  userEvents.dispatchEvent(new CustomEvent('loggedOut'));
}

export function isAuthenticated() {
  return get()
    .then((user) => {
      // when we get no user at all (meaning not logged in)
      // we force the promise chain into the catch clause
      if ('undefined' === typeof user) {
        return Promise.reject(new Error('unauthorised'));
      }
    })
    .catch(({ message }) => {
      if (message === 'change_password') {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    });
}

export async function get() {
  try {
    const user = await getUserData().catch((err) => {
      // this is important to not get into a loop of checking if the user is
      // logged in since the change_password error does mean the user is
      // logged in but needs to change their password so we create an error
      // sink and rethrow when we find other errors...
      if (err.message === 'change_password') {
        return {};
      } else {
        return Promise.reject(err);
      }
    });

    return user;
  } catch (err) {
    return undefined;
  }
}

export async function getUserFromStorage() {
  const storedUser = temp.get('user');

  if (storedUser) {
    return storedUser;
  }

  // If we don't have a user in temporary storage, we'll default back to the
  // user.get() to retrieve it
  return await get();
}

export async function getEmail() {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('no_token');
  }

  const [, emailAddress] = /Token (.*):/.exec(token);

  return emailAddress;
}

export async function changePassword({ currentPassword, newPassword }) {
  const emailAddress = await getEmail();
  const body = {
    current_password: currentPassword,
    new_password: newPassword,
    email_address: emailAddress,
  };

  return request('changePassword', { body }).then((response) => {
    userEvents.dispatchEvent(new CustomEvent('changedPassword'));
    return response;
  });
}

isAuthenticated()
  .then(get)
  .then((user) => {
    setTracking(user);
  })
  .catch(() => undefined);
