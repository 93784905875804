import { PURGE_ALL_ENDPOINTS } from '@/constants/cache.mjs';

export default {
  setAccount: {
    method: 'PUT',
    useToken: true,
    domain: '',
    path: '/api/webphone/user/selected_account/',
    purgeCache: ['getAccount'],
    mocking: {
      id: 'set_selected_account',
      enabled: true,
      path: '/voipgrid/webphone_user/selected_account/',
      status_code: 200,
    },
  },
  unsetAccount: {
    method: 'DELETE',
    useToken: true,
    domain: '',
    path: '/api/webphone/user/selected_account/',
    purgeCache: ['getAccount'],
    mocking: {
      id: 'unset_selected_account',
      enabled: true,
      path: '/voipgrid/webphone_user/selected_account/',
      status_code: 204,
    },
  },
  getAccount: {
    method: 'GET',
    useToken: true,
    domain: '',
    defaultResponse: {},
    path: '/api/webphone/user/selected_account/',
    mocking: {
      id: 'get_selected_account',
      enabled: true,
      path: '/voipgrid/webphone_user/selected_account/',
      status_code: 200,
      example: 'fixed_account',
    },
  },
  accounts: {
    method: 'GET',
    useToken: true,
    domain: '',
    defaultResponse: [],
    path: '/api/webphone/user/accounts/',
    mocking: {
      id: 'get_accounts',
      enabled: true,
      path: '/voipgrid/webphone_user/accounts/',
      status_code: 200,
      example: 'fixed_accounts_list',
    },
  },
  getPhoneAccount: {
    method: 'GET',
    useToken: true,
    domain: '',
    defaultResponse: {},
    path: '/api/phoneaccount/basic/phoneaccount/{accountId}/',
    mocking: {
      id: 'get_phoneaccount',
      enabled: true,
      path: '/voipgrid/phoneaccount_basic/phoneaccount/basic/phoneaccount/300020010',
      status_code: 200,
    },
  },
  setDestination: {
    method: 'PUT',
    useToken: true,
    domain: '',
    path: '/api/selecteduserdestination/{userDestinationId}/',
    purgeCache: ['getDestination'],
    mocking: {
      id: 'set_selected_user_destination',
      enabled: true,
      dynamic: true,
      path: '/voipgrid/user_destination/selecteduserdestination/111/',
      status_code: 204,
    },
  },
  getDestination: {
    method: 'GET',
    useToken: true,
    domain: '',
    defaultResponse: { objects: [] },
    path: '/api/selecteduserdestination/',
    mocking: {
      id: 'get_selected_user_destination',
      enabled: true,
      dynamic: true,
      path: '/voipgrid/user_destination/selecteduserdestination/',
      status_code: 200,
    },
  },
  destinations: {
    method: 'GET',
    useToken: true,
    domain: '',
    defaultResponse: { objects: [] },
    path: '/api/userdestination/',
    mocking: {
      id: 'get_user_destinations',
      enabled: true,
      dynamic: true,
      path: '/voipgrid/user_destination/userdestination/',
      status_code: 200,
    },
  },
  userDestinations: {
    useBearerToken: true,
    domain: '',
    defaultResponse: { objects: [] },
    path: '/api/v2/user/details',
    mocking: {
      id: 'get_user_details',
      enabled: true,
      path: '/user-details/voipgrid/user/user/{userUuid}/details/',
      status_code: 200,
    },
  },
  voipaccounts: {
    method: 'GET',
    useBearerToken: true,
    domain: '',
    defaultResponse: { data: [], meta: {} },
    path: '/api/v2/clients/{clientId}/voip_accounts',
    mocking: {
      id: 'get_voip_accounts',
      enabled: true,
      path: '/voipgrid/phoneaccount/clients/369779/voip_accounts',
      status_code: 200,
    },
  },
  getVoipAccount: {
    method: 'GET',
    useBearerToken: true,
    domain: '',
    defaultResponse: {},
    path: '/api/v2/clients/{clientId}/voip_accounts/{voipAccountId}',
  },
  updateVoipAccount: {
    method: 'PATCH',
    useBearerToken: true,
    domain: '',
    defaultResponse: { objects: [] },
    path: '/api/v2/clients/{clientId}/voip_accounts/{voipAccountId}',
  },
  colleagues: {
    useBearerToken: true,
    domain: '',
    defaultResponse: { data: [], meta: {} },
    path: '/api/v2/clients/{clientId}/users',
    mocking: {
      id: 'get_users_list',
      enabled: true,
      path: '/voipgrid/user/clients/369779/users',
      status_code: 200,
    },
  },
  allVoipaccounts: {
    method: 'GET',
    useToken: true,
    domain: '',
    defaultResponse: { objects: [] },
    path: '/api/phoneaccount/basic/phoneaccount/?active=true&order_by=description',
    mocking: {
      id: 'get_phoneaccounts',
      enabled: true,
      path: '/voipgrid/phoneaccount_basic/phoneaccount/basic/phoneaccount',
      status_code: 200,
    },
  },
  calls: {
    method: 'GET',
    useBearerToken: true,
    domain: '',
    defaultResponse: [],
    path: '/api/v2/call/personalized',
    mocking: {
      id: 'get_calls',
      enabled: true,
      path: '/voipgrid/call/call/personalized/',
      status_code: 200,
      example: 'incoming_internal_call',
    },
  },
  getCallerIdNumbers: {
    method: 'GET',
    useBearerToken: true,
    domain: '',
    defaultResponse: [],
    path: '/api/v2/clients/{clientUuid}/callerid_numbers?page={page}&per_page=100',
    acceptHeaders: 'application/json;version=2',
  },
  queues: {
    method: 'GET',
    useToken: true,
    domain: '',
    defaultResponse: { objects: [] },
    path: '/api/queuecallgroup/',
  },
  user: {
    method: 'GET',
    useToken: true,
    domain: '',
    defaultResponse: {},
    path: '/api/webphone/user/',
    mocking: {
      id: 'get_user',
      enabled: true,
      path: '/voipgrid/webphone_user/',
      status_code: 200,
    },
  },
  setUserStatus: {
    method: 'POST',
    useBearerToken: true,
    domain: import.meta.env.VITE_HOLODECK_URL,
    defaultResponse: { success: true },
    path: '/user-status/clients/{client_uuid}/users/{user_uuid}/status',
  },
  getUserStatus: {
    method: 'GET',
    useBearerToken: true,
    domain: import.meta.env.VITE_HOLODECK_URL,
    defaultResponse: [],
    path: '/user-status/clients/{client_uuid}/users/{user_uuid}/status',
  },
  login: {
    method: 'POST',
    domain: '',
    path: '/api/permission/apitoken/',
    purgeCache: PURGE_ALL_ENDPOINTS,
    mocking: {
      id: 'login',
      enabled: true,
      path: '/voipgrid/permission/permission/apitoken',
      status_code: 200,
    },
  },
  autologin: {
    method: 'GET',
    useToken: true,
    domain: '',
    path: '/api/autologin/token/',
  },
  changePassword: {
    method: 'PUT',
    domain: '',
    path: '/api/v2/password/',
    purgeCache: ['autologin'],
  },
  isChangePasswordEndpointLive: {
    method: 'OPTIONS',
    domain: '',
    path: '/api/v2/password/',
    purgeCache: ['autologin'],
  },
  contacts: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'GET',
    domain: import.meta.env.VITE_CONTACTS_API_BASE,
    defaultResponse: [],
    path: '/clients/{client_uuid}/contacts',
    mocking: {
      id: 'get_contacts',
      enabled: true,
      path: '/webphone/contacts/contacts/',
      status_code: 200,
      example: 'contacts_list',
    },
    addClientHeaders: true,
  },
  contact: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'GET',
    domain: import.meta.env.VITE_CONTACTS_API_BASE,
    defaultResponse: {},
    path: '/clients/{client_uuid}/contacts/{contact_id}',
    mocking: {
      id: 'get_contact',
      enabled: true,
      path: '/webphone/contacts/contacts/111a',
      status_code: 200,
      example: 'contact1',
    },
    addClientHeaders: true,
  },
  contactUpdate: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'PUT',
    domain: import.meta.env.VITE_CONTACTS_API_BASE,
    path: '/clients/{client_uuid}/contacts/{contact_id}',
    purgeCache: ['contacts', 'contact'],
    addClientHeaders: true,
  },
  contactCreate: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'POST',
    domain: import.meta.env.VITE_CONTACTS_API_BASE,
    path: '/clients/{client_uuid}/contacts',
    purgeCache: ['contacts'],
    addClientHeaders: true,
  },
  contactDelete: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'DELETE',
    domain: import.meta.env.VITE_CONTACTS_API_BASE,
    path: '/clients/{client_uuid}/contacts/{contact_id}',
    purgeCache: ['contacts'],
    addClientHeaders: true,
  },
  phoneNumberValidateAndFormat: {
    useToken: true,
    method: 'GET',
    domain: import.meta.env.VITE_PHONE_NUMBER_API_BASE,
    path: '/validate/{phoneNumber}/{countryCode?}',
  },
  sendFeedback: {
    method: 'POST',
    domain: import.meta.env.VITE_FEEDBACK_API_BASE,
    path: '',
  },
  settingSet: {
    useToken: true,
    method: 'PATCH',
    domain: import.meta.env.VITE_SETTINGS_API_BASE,
    path: '/settings',
  },
  settingGet: {
    useToken: true,
    method: 'GET',
    domain: import.meta.env.VITE_SETTINGS_API_BASE,
    path: '/settings/{setting}',
  },
  settingsGet: {
    useToken: true,
    method: 'GET',
    domain: import.meta.env.VITE_SETTINGS_API_BASE,
    path: '/settings',
  },
  getUserPermissions: {
    useBearerToken: true,
    method: 'GET',
    domain: '',
    path: '/api/v2/users/auth-context',
    mocking: {
      id: 'get_user_permissions',
      enabled: true,
      path: '/voipgrid/user/users/auth-context',
      status_code: 200,
    },
  },
  voicemail: {
    useBearerToken: true,
    method: 'GET',
    domain: '',
    path: '/api/v2/clients/{client_id}/voicemails/{voicemail_id}',
    mocking: {
      id: 'get_voicemail',
      enabled: true,
      path: '/voipgrid/voicemail/clients/1/voicemails/1',
      status_code: 200,
      example: 'sample_voicemail',
    },
  },
  voicemails: {
    useBearerToken: true,
    method: 'GET',
    domain: '',
    path: '/api/v2/clients/{client_id}/voicemails',
    mocking: {
      id: 'list_voicemails',
      enabled: true,
      path: '/voipgrid/voicemail/clients/1/voicemails',
      status_code: 200,
      example: 'list of voicemails',
    },
  },
  getOpeningHoursBasic: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'GET',
    domain: import.meta.env.VITE_HOLODECK_URL,
    path: '/opening-hours-basic/client/{client_uuid}/openinghours',
  },
  getTemporaryRedirect: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'GET',
    domain: import.meta.env.VITE_HOLODECK_URL,
    path: '/business-availability/clients/{client_uuid}/temporary-redirect',
    mocking: {
      id: 'get_redirect',
      enabled: true,
      path: '/holodeck/business_availability/clients/ed0bb8b2-ea1c-4bec-85a1-d0a06b4c46d8/temporary-redirect',
      status_code: 200,
      example: 'available',
    },
  },
  setTemporaryRedirect: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'POST',
    domain: import.meta.env.VITE_HOLODECK_URL,
    path: '/business-availability/clients/{client_uuid}/temporary-redirect',
    mocking: {
      id: 'set_redirect',
      enabled: true,
      path: '/holodeck/business_availability/clients/ed0bb8b2-ea1c-4bec-85a1-d0a06b4c46d8/temporary-redirect',
      status_code: 201,
    },
    segmentHeaders: {
      event: 'started',
      app: 'temporary_redirect',
    },
  },
  updateTemporaryRedirect: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'PUT',
    domain: import.meta.env.VITE_HOLODECK_URL,
    path: '/business-availability/clients/{client_uuid}/temporary-redirect/{temporary_redirect_uuid}',
    mocking: {
      id: 'update_redirect',
      enabled: true,
      path: '/holodeck/business_availability/clients/ed0bb8b2-ea1c-4bec-85a1-d0a06b4c46d8/temporary-redirect/ed0bb8b2-ea1c-4bec-85a1-d0a06b4c46d8',
      status_code: 200,
    },
    segmentHeaders: {
      event: 'changed',
      app: 'temporary_redirect',
    },
  },
  deleteTemporaryRedirect: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'DELETE',
    domain: import.meta.env.VITE_HOLODECK_URL,
    path: '/business-availability/clients/{client_uuid}/temporary-redirect/{temporary_redirect_uuid}',
    mocking: {
      id: 'delete_redirect',
      enabled: true,
      path: '/holodeck/business_availability/clients/ed0bb8b2-ea1c-4bec-85a1-d0a06b4c46d8/temporary-redirect/ed0bb8b2-ea1c-4bec-85a1-d0a06b4c46d8',
      status_code: 204,
    },
    segmentHeaders: {
      event: 'stopped',
      app: 'temporary_redirect',
    },
  },
  getTemporaryRedirectDetail: {
    isHolodeckEndpoint: true,
    useBearerToken: true,
    method: 'GET',
    domain: import.meta.env.VITE_HOLODECK_URL,
    path: '/business-availability/clients/{client_uuid}/temporary-redirect/{temporary_redirect_uuid}',
    mocking: {
      id: 'get_redirect_detail',
      enabled: true,
      path: '/holodeck/business_availability/clients/ed0bb8b2-ea1c-4bec-85a1-d0a06b4c46d8/temporary-redirect/ed0bb8b2-ea1c-4bec-85a1-d0a06b4c46d8',
    },
  },
};
