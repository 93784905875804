import {
  client,
  getSession,
  getSessions,
  isConnected,
  newClient,
  setVoipAccount,
  voipAccount,
  webCalling,
} from '@/lib/calling-base.mjs';
import formatToSipAccount from '@/lib/calling/formatToSipAccount.mjs';
import getCallerInfo from '@/lib/calling/getCallerInfo.mjs';
import isClickToDial from '@/lib/calling/isClickToDial.mjs';
import isInternalCall from '@/lib/calling/isInternalCall.ts';
import getAccount from '@/lib/data/getAccount.mjs';
import { getAuthenticatedUserAvailability } from '@/lib/data/getAuthenticatedUserAvailability.ts';
import { translate } from '@/lib/i18n.mjs';
import { callingLogger, userRatingLogger, webCallingLogger } from '@/lib/loggers.mjs';
import * as media from '@/lib/media.mjs';
import { doesNetworkAllowAudioStreams } from '@/lib/network.mjs';
import * as segment from '@/lib/segment.mjs';
import * as localSettings from '@/lib/settings/local.mjs';
import * as settings from '@/lib/settings/remote.mjs';
import { playSound, stopSound } from '@/lib/sounds.mjs';
import {
  startSubscriptionsFetcher,
  stopSubscriptionsFetcher,
  terminateSubscription,
  updateSubscription,
} from '@/lib/subscriptions.mjs';
import * as temp from '@/lib/temporary-storage.mjs';
import { closeToast, showToast } from '@/lib/toasts.mjs';
import * as user from '@/lib/user.mjs';

import { removeAll } from '@/utils/dom.ts';
import { callingEvents, dataEvents, mediaEvents, userEvents } from '@/utils/eventTarget.ts';
import { navigate } from '@/utils/history.ts';
import * as time from '@/utils/time.ts';

import { TEMPORARILY_UNAVAILABLE } from '@/constants/sessionRejectionCodes.mjs';
import {
  USER_STATUS_AVAILABLE_FOR_COLLEAGUES,
  USER_STATUS_DND,
  USER_STATUS_OFFLINE,
} from '@/constants/userStatusCodes.mjs';
import { closeNotifications, showNotification } from '@/sw-notifications.mjs';

// Re-export base
export { getDefaultMedia, getSession, getSessions, isConnected, voipAccount } from '@/lib/calling-base.mjs';

export let activeSession;
export let networkAllowsAudioStreams;
export let isAbleToMakeCall = false;

// The first thing we show after login is that we're connecting. So act
// like that is the latest status during the initialization of the
// connection.
export let clientStatus = 'connecting';

// Connect webCalling logging to our own Logger.
webCalling.log.level = 'warn';
webCalling.log.connector = ({ level, message, context }) => {
  webCallingLogger.log(level, message, { webphonelib_context: context });
};

const transport = {
  wsServers: `wss://${import.meta.env.VITE_SIP_ENDPOINT}`,
  iceServers: [],
  delegate: {
    onBeforeInvite,
  },
};

const CALL_QUALITY_MOS_THRESHOLD = 4;

// function which plays the correct busytone depending on the amount of sessions
function playBusyTone({ id }) {
  if (getSessions().length === 0) {
    return playSound('busytone', id);
  } else {
    return playSound('busytoneShort', id);
  }
}

// Placeholder until web-calling lib has implemented separate rejected promise
function sessionAccepted(session) {
  return new Promise((resolve, reject) => {
    session
      .accepted()
      .then(({ accepted }) => {
        if (accepted) {
          resolve();
        }
      })
      .catch(() => reject());
  });
}

// Placeholder until web-calling lib has implemented separate rejected promise
function sessionRejected(session) {
  return new Promise((resolve, reject) => {
    session
      .accepted()
      .then(({ accepted, rejectCause }) => {
        if (!accepted) {
          resolve({ rejectCause });
        }
      })
      .catch(() => reject());
  });
}

async function handleCallQualityToast(_, { mos: { average } }) {
  if (average < CALL_QUALITY_MOS_THRESHOLD) {
    showToast({
      id: 'poorAudioQuality',
      title: 'poor_audio_quality_title',
      body: 'poor_audio_quality_message',
      icon: 'warning',
      type: 'danger',
      sticky: true,
    });
  } else {
    closeToast('poorAudioQuality');
  }
}

let callQualityTrackingSent = false;
function handleCallQualityTracking(_, { mos: { average } }) {
  if (average < CALL_QUALITY_MOS_THRESHOLD) {
    if (!callQualityTrackingSent) {
      segment.track.poorAudioQuality();
      callQualityTrackingSent = true;
    }
  } else {
    callQualityTrackingSent = false;
  }
}

function handleCallQualityLogging({ id }, { mos: { average } }) {
  callingLogger.info(`MOS stats for session: ${id} - average:${average}`);
}

function handleCallQuality(session) {
  if (!session) {
    return;
  }

  session.on('callQualityUpdate', handleCallQualityToast);
  session.on('callQualityUpdate', handleCallQualityTracking);
  session.on('callQualityUpdate', handleCallQualityLogging);

  session.terminated().finally(() => {
    session.removeListener('callQualityUpdate', handleCallQualityToast);
    session.removeListener('callQualityUpdate', handleCallQualityTracking);
    session.removeListener('callQualityUpdate', handleCallQualityLogging);

    // Clean up when all the sessions have been terminated.
    if (getSessions().length === 0) {
      closeToast('poorAudioQuality');
      callQualityTrackingSent = false;
    }

    user.get().then(({ clientId }) => {
      segment.track.mosValues(session.id, clientId, session.stats.mos);
    });

    const { average, count, highest, last, lowest, sum } = session.stats.mos;
    callingLogger.info(
      `MOS stats for terminated ${session.id} - average:${average}, highest:${highest}, lowest:${lowest}, last:${last}, count:${count}, sum:${sum}`,
    );
  });
}

function showUserRatingOnNumberOfSuccessfulCalls(session) {
  if (!session) {
    return;
  }

  let timeout;
  let sessionAccepted = false;

  Promise.all([settings.get('userHasRatedTheWebphone'), settings.get('showUserRatingDialog')]).then(
    ([userHasRatedTheWebphone, showUserRatingDialog]) => {
      userRatingLogger.debug(
        `userHasRatedTheWebphone = ${userHasRatedTheWebphone} || showUserRatingDialog = ${showUserRatingDialog}`,
      );

      if (userHasRatedTheWebphone || !showUserRatingDialog) {
        userRatingLogger.debug(
          `Skip because: userHasRatedTheWebphone = ${userHasRatedTheWebphone} || showUserRatingDialog = ${showUserRatingDialog}`,
        );
        return;
      }

      session.accepted().then(({ accepted }) => {
        if (!accepted) {
          userRatingLogger.debug(`Skip because: accepted = ${accepted}`);
          return;
        }

        sessionAccepted = true;

        timeout = window.setTimeout(async () => {
          let count = (await settings.get('amountOfSuccessfulCallsForUserRating')) || 0;
          count += 1;
          await settings.set('amountOfSuccessfulCallsForUserRating', count);

          userRatingLogger.debug(`amountOfSuccessfulCallsForUserRating = ${count}`);

          if (count >= 3) {
            await settings.set('shouldShowUserRatingModalAfterCall', true);
            userRatingLogger.debug(`shouldShowUserRatingModalAfterCall = ${true}`);
          }
        }, time.second * 19);
      });

      session.terminated().finally(async () => {
        window.clearTimeout(timeout);

        if (!sessionAccepted) {
          userRatingLogger.debug(`Skip because: sessionAccepted = ${sessionAccepted}`);
          return;
        }

        const shouldShow = await settings.get('shouldShowUserRatingModalAfterCall');
        userRatingLogger.debug(`shouldShowUserRatingModalAfterCall = ${shouldShow}`);

        if (getSessions().length === 0 && shouldShow) {
          const mainContent = document.querySelector('[data-selector="main-content"]');

          // to prevent screw ups we remove all the user rating modals first
          removeAll('c-user-rating', mainContent);

          userRatingLogger.debug(`Create user rating dialog custom element`);
          mainContent.appendChild(document.createElement('c-user-rating'));
        }
      });
    },
  );
}

async function onInvite(session) {
  callingLogger.info('receiving incoming call invitation', { sessionId: session.id });

  const clickToDialCall = isClickToDial(session.remoteIdentity.displayName);
  if (clickToDialCall) {
    callingLogger.info("incoming call accepted because it's a click to dial call.");
    segment.track.click2dial(session.autoAnswer);
  }

  // localSettings is async but will always resolve!
  const userStatus = await localSettings.get('userStatus');

  // Calls to VoIPAccounts are not being blocked when the user has their status set to "offline"
  // This means we have to explicitly block them client side
  if (!clickToDialCall && userStatus === USER_STATUS_OFFLINE) {
    callingLogger.info('incoming call rejected because the user is offline');
    session.reject({ statusCode: TEMPORARILY_UNAVAILABLE }).catch(callingLogger.error);
    return;
  }

  // We check if we're only available_for_colleagues before we start parsing the incoming call.
  if (userStatus === USER_STATUS_AVAILABLE_FOR_COLLEAGUES) {
    const data = session.media.session.incomingInviteRequest.message.data;
    const internalCall = isInternalCall(data);

    if (!internalCall && !clickToDialCall) {
      callingLogger.info('incoming call rejected because the user has available_for_colleagues set');
      session.reject({ statusCode: TEMPORARILY_UNAVAILABLE }).catch(callingLogger.error);
      return;
    } else if (internalCall) {
      callingLogger.info(
        "incoming call accepted because the user has available_for_colleagues set and it's an internal call",
      );
    }
  }

  if (!media.microphonePermissionGranted) {
    callingLogger.info('incoming call auto rejected because there are no valid audio devices');
    session.reject({ statusCode: TEMPORARILY_UNAVAILABLE }).catch(callingLogger.error);
    return;
  }

  handleCallQuality(session);
  showUserRatingOnNumberOfSuccessfulCalls(session);

  if (session.autoAnswer) {
    callingLogger.info('auto answer is enabled by the user, accepting this session', session.id);
    await session.accept().catch(callingLogger.error);

    session.terminated().then(() => playBusyTone(session));

    segment.track.incomingCall();
    callingLogger.info('incoming session is accepted', { sessionId: session.id });
    callingEvents.dispatchEvent(new CustomEvent('sessionAccepted', { detail: session }));
    navigate('/ongoing-calls');
    return;
  }

  playSound('ringtone', session.id, { loop: true, timeout: 60 * time.second });
  Promise.race([session.accepted(), session.terminated()]).finally(() => {
    stopSound('ringtone', session.id);
  });

  session.terminated().finally(() => {
    playBusyTone(session).then(() => {
      callingEvents.dispatchEvent(new CustomEvent('sessionTerminatedSoundEnded', { detail: session }));
    });
  });

  sessionAccepted(session).then(() => {
    segment.track.incomingCall();
    callingLogger.info('incoming session is accepted', { sessionId: session.id });
    callingEvents.dispatchEvent(new CustomEvent('sessionAccepted', { detail: session }));
    navigate('/ongoing-calls');
    closeNotifications();
  });

  sessionRejected(session).then(async ({ rejectCause }) => {
    callingLogger.info('incoming session was rejected', { sessionId: session.id, rejectCause });
    closeNotifications();
  });

  session.terminated().then(async (terminationCause) => {
    callingEvents.dispatchEvent(new CustomEvent('sessionTerminated', { detail: session }));
    if (terminationCause === 'call_completed_elsewhere') {
      const { displayName, phoneNumber } = await getCallerInfo(session);
      const title = translate('call_completed_elsewhere');
      const body = `${displayName} (${phoneNumber})`;

      showNotification({
        title,
        body,
        requireInteraction: false,
        tag: 'callCompletedElsewhere',
      });
    }
  });

  session.terminated().finally(() => {
    closeNotifications();
    callingLogger.info('incoming call was terminated', { sessionId: session.id });
  });

  callingEvents.dispatchEvent(new CustomEvent('inviteReceived', { detail: session }));

  const { displayName, phoneNumber } = await getCallerInfo(session);

  showNotification({
    title: translate('receiving_call'),
    body: `${displayName} (${phoneNumber})`,
    actions: [
      { action: 'reject', title: translate('reject_call') },
      { action: 'accept', title: translate('accept_call') },
    ],
    data: { sessionId: session.id },
    requireInteraction: true,
    tag: 'invite',
  });
}

callingEvents.addEventListener('sessionStatusUpdated', ({ detail: { session } }) => {
  if (activeSession && activeSession.id === session.id && (session.status !== 'active' || session.holdState)) {
    activeSession = undefined;
    callingEvents.dispatchEvent(new CustomEvent('activeSessionUpdated', { detail: undefined }));
  } else if (session && session.status === 'active' && !session.holdState) {
    activeSession = session;
    callingEvents.dispatchEvent(new CustomEvent('activeSessionUpdated', { detail: session }));
  }
});

callingEvents.addEventListener('sessionsUpdated', ({ detail: { sessions } }) => {
  if (activeSession && !sessions.includes(activeSession.id)) {
    activeSession = undefined;
    callingEvents.dispatchEvent(new CustomEvent('activeSessionUpdated', { detail: undefined }));
  }
});

function updateIsAbleToMakeCall() {
  isAbleToMakeCall =
    !isConnecting() && isConnected() && media.microphonePermissionGranted && typeof voipAccount !== 'undefined';
}

callingEvents.addEventListener('clientStatusUpdated', updateIsAbleToMakeCall);
mediaEvents.addEventListener('microphonePermissionUpdated', updateIsAbleToMakeCall);
dataEvents.addEventListener('getAccount', updateIsAbleToMakeCall);

// trigger a specific event when the busytone of the last session has ended so we do not have to
// create logic in other places for this
// be aware that this event is not guaranteed to fire since it checks if there are no sessions after
// the sound ends. So if another session is added in the meantime this will not trigger...
let alreadyDispatchedLastSessionTerminatedSoundEnded;
function resetAlreadyDispatchedLastSessionTerminatedSoundEnded() {
  alreadyDispatchedLastSessionTerminatedSoundEnded = undefined;
}
callingEvents.addEventListener('sessionTerminatedSoundEnded', () => {
  if (0 === getSessions().length && !alreadyDispatchedLastSessionTerminatedSoundEnded) {
    alreadyDispatchedLastSessionTerminatedSoundEnded = window.setTimeout(
      resetAlreadyDispatchedLastSessionTerminatedSoundEnded,
      200,
    );
    callingEvents.dispatchEvent(new CustomEvent('lastSessionTerminatedSoundEnded'));
  }
});

const sessionsMap = new Map();
function sessionStatusUpdated(session) {
  callingEvents.dispatchEvent(new CustomEvent('sessionStatusUpdated', { detail: { session } }));
}

function sessionsUpdated() {
  const sessions = getSessions();
  callingEvents.dispatchEvent(new CustomEvent('sessionsUpdated', { detail: { sessions } }));

  sessions.forEach((session) => {
    const { id } = session;
    if (!sessionsMap.has(id)) {
      sessionsMap.set(id, true);
      session.on('statusUpdate', sessionStatusUpdated);
    }
  });

  // cleanup old session ids which are not in the active sessions list anymore
  for (const id of sessionsMap.keys()) {
    if (!(id in sessions)) {
      sessionsMap.delete(id);
    }
  }
}

function onSessionAdded() {
  callingEvents.dispatchEvent(new CustomEvent('sessionAdded'));
  sessionsUpdated();

  if (1 === getSessions().length) {
    callingEvents.dispatchEvent(new CustomEvent('sessionStarted'));
  }
}

function onSessionRemoved() {
  callingEvents.dispatchEvent(new CustomEvent('sessionRemoved'));
  sessionsUpdated();

  if (0 === getSessions().length) {
    callingEvents.dispatchEvent(new CustomEvent('sessionEnded'));
  }
}

let isDyingCounterActive = false;
async function onClientStatusUpdate(status) {
  const tag = 'clientStatusUpdated';
  clientStatus = status;

  if (document.visibilityState !== 'visible') {
    switch (status) {
      case 'connected':
        showNotification({ title: translate('connected'), tag });
        break;
      case 'dying':
        showNotification({
          title: translate('trying_to_reconnect'),
          tag,
        });
        break;
      case 'disconnected':
        showNotification({ title: translate('disconnected'), tag });
        break;
    }
  }

  if (['dying', 'recovering'].includes(status) && !isDyingCounterActive) {
    isDyingCounterActive = true;

    const disconnectIfNotReconnected = () => {
      isDyingCounterActive = false;
      // Maybe we have reconnected at this time, if not, set the status to
      // disconnected.
      if (isConnecting()) {
        callingEvents.dispatchEvent(
          new CustomEvent('clientStatusUpdated', {
            detail: { status: 'disconnected' },
          }),
        );
      }
    };

    setTimeout(disconnectIfNotReconnected, 60000);
  }

  if (networkAllowsAudioStreams) {
    closeToast('noAudioStreamsAllowed');
  } else if (await settings.get('showNoAudioStreamsAllowedToast')) {
    showToast({
      id: 'noAudioStreamsAllowed',
      title: 'incorrect_firewall_settings',
      body: 'incorrect_firewall_settings_toast',
      type: 'danger',
      icon: 'shield',
      sticky: true,
      showOptOut: 'showNoAudioStreamsAllowedToast',
      dismissable: true,
    });
  }

  if (voipAccount) {
    closeToast('noAccountToast');
  } else if (await settings.get('showNoVoipAccountSelectedToast')) {
    showToast({
      id: 'noAccountToast',
      title: 'no_account_toast_title',
      body: 'no_account_toast_body',
      routerLink: { path: '/settings', text: 'no_account_toast_link' },
      type: 'danger',
      icon: 'missed-call',
      sticky: true,
      showOptOut: 'showNoVoipAccountSelectedToast',
      dismissable: true,
    });
  }

  callingLogger.info(`client status update: ${status}`);
  callingEvents.dispatchEvent(new CustomEvent('clientStatusUpdated', { detail: { status } }));
}

export function isConnecting() {
  return ['dying', 'recovering', 'connecting'].includes(clientStatus);
}

async function onBeforeInvite(session) {
  const click2dial = isClickToDial(session.remoteIdentity.displayName);
  const userAvailabilityModel = await getAuthenticatedUserAvailability();

  if (localStorage.getItem('purpose') === 'wizard' || userAvailabilityModel?.user_status === USER_STATUS_DND) {
    if (click2dial) {
      callingLogger.info('incoming click to dial call while dnd is enabled, let the call through');
    } else {
      callingLogger.info('incoming call auto rejected because dnd is enabled');
      // Use code 480 here because 480 should be used when it's invisible for the
      // user. On other places such as the decline button 486 is used because that
      // is a user action.
      session.reject({ statusCode: TEMPORARILY_UNAVAILABLE }).catch(callingLogger.error);
      return true;
    }
  }

  if (getSessions().length > 0) {
    callingLogger.info('incoming call auto rejected because a session is already in progress');
    session.reject({ statusCode: TEMPORARILY_UNAVAILABLE }).catch(callingLogger.error);
    return true;
  }

  return false;
}

let account;
export async function connect() {
  // The first time a connection is created `client` does not exist yet while
  // the clientStatus in the app is effectively 'connecting', because that
  // is the first thing we show the user.
  if (isConnected() || (isConnecting() && client)) {
    return;
  }

  setVoipAccount(await getAccount());

  try {
    networkAllowsAudioStreams = await doesNetworkAllowAudioStreams();

    if (voipAccount) {
      const initialMedia = await media.getInitialMedia();
      account = formatToSipAccount(voipAccount);
      const userAgentString = `${import.meta.env.VITE_VENDOR_NAME} Webphone ${import.meta.env.VITE_VERSION}`;
      const options = { transport, account, media: initialMedia, userAgentString };

      // Status could have changed while we were awaiting.
      if (isConnected() || (isConnecting() && client)) {
        return;
      }

      callingLogger.info('connecting to voip account');
      if (client) {
        await client.reconfigure(options);
      } else {
        newClient(options);
        client.on('invite', onInvite);
        client.on('subscriptionNotify', updateSubscription);
        client.on('subscriptionTerminated', terminateSubscription);
        client.on('statusUpdate', onClientStatusUpdate);
        client.on('sessionAdded', onSessionAdded);
        client.on('sessionRemoved', onSessionRemoved);
        await client.connect();
      }

      startSubscriptionsFetcher(client);
    } else {
      callingLogger.info('cannot connect, no webphone account set in the portal');
      // Emit this event manually because the client is prevented from emitting it.
      onClientStatusUpdate('disconnected');
    }
  } catch (err) {
    callingLogger.error(err);
  }
}

export async function disconnect() {
  if (isConnected()) {
    setVoipAccount(undefined);
    stopSubscriptionsFetcher();
    await client.disconnect();
  } else {
    callingLogger.info('client is not connected, cannot disconnect');
  }
}

let invitePromise;
export function invite(phoneNumber, options = {}) {
  if (isConnected()) {
    if (invitePromise) {
      callingLogger.warn('cannot send an invite, there is already an invite pending');
      return;
    }

    segment.track.outgoingCall();

    invitePromise = client.invite(`sip:${encodeURIComponent(phoneNumber)}@voipgrid.nl`).catch(callingLogger.error);

    // If an error occurs during an invite we allow another invite attempt.
    invitePromise.catch(() => (invitePromise = undefined));

    invitePromise.then(async (session) => {
      await holdAllOtherSessions(session);

      handleCallQuality(session);
      showUserRatingOnNumberOfSuccessfulCalls(session);

      playSound('ringback', session.id, {
        loop: true,
        timeout: 60 * time.second,
      });

      Promise.race([session.accepted(), session.terminated()]).finally(() => {
        stopSound('ringback', session.id);
      });

      session.accepted().then(({ rejectCause }) => {
        if (rejectCause && rejectCause === 'address_incomplete') {
          session.terminated().then(() => {
            playSound('addressIncomplete', session.id).then(() => {
              callingEvents.dispatchEvent(new CustomEvent('sessionTerminatedSoundEnded', { detail: session }));
            });
          });
        } else {
          session.terminated().finally(() => {
            playBusyTone(session).then(() => {
              callingEvents.dispatchEvent(new CustomEvent('sessionTerminatedSoundEnded', { detail: session }));
            });
          });
        }
      });

      sessionAccepted(session).then(() => {
        callingEvents.dispatchEvent(new CustomEvent('sessionAccepted', { detail: session }));
        callingLogger.info('outgoing session is accepted', { sessionId: session.id });
      });

      sessionRejected(session).then(({ rejectCause }) => {
        callingLogger.info('outgoing session was rejected', { sessionId: session.id, rejectCause });
      });

      session.terminated().finally(() => {
        callingEvents.dispatchEvent(new CustomEvent('sessionTerminated'));
        callingLogger.info('outgoing call was terminated', { sessionId: session.id });
      });

      // When a pending invite has been accepted, rejected or terminated
      // we allow another invite attempt.
      Promise.race([session.accepted(), session.terminated()]).finally(() => (invitePromise = undefined));

      callingEvents.dispatchEvent(new CustomEvent('inviteSent', { detail: session }));

      if (options.isAttendedTransfer && options.session) {
        const sessionA = options.session;
        const sessionB = session;

        // when we setup a new attended transfer and we have an old linked
        // session still open we want to terminate that automatically
        const oldSessionObject = temp.get(sessionA.id);
        if (oldSessionObject) {
          const { b } = oldSessionObject;
          const oldSessionB = getSession(b);
          oldSessionB && oldSessionB.terminate();
        }

        temp.set(sessionA.id, { side: 'a', b: sessionB.id });
        temp.set(sessionB.id, { side: 'b', a: sessionA.id });

        sessionA.terminated().finally(() => {
          sessionA.id && temp.remove(sessionA.id);
          sessionB.id && temp.remove(sessionB.id);
        });

        sessionB.accepted().then(({ accepted }) => {
          if (accepted) {
            callingEvents.dispatchEvent(
              new CustomEvent('attendedTransferStatusUpdated', {
                // Make sure to get a new session because old session is not
                // updated after accepted (because it is frozen).
                detail: {
                  a: getSession(sessionA.id),
                  b: getSession(sessionB.id),
                },
              }),
            );
          }
        });

        sessionB.terminated().finally(async () => {
          if (sessionB.id) {
            temp.remove(sessionB.id);
          }

          callingEvents.dispatchEvent(
            new CustomEvent('attendedTransferStatusUpdated', {
              detail: { a: getSession(sessionA.id), b: undefined },
            }),
          );
        });
      } else {
        navigate('/ongoing-calls');
      }

      return invitePromise;
    });
  } else {
    callingLogger.warn('client is not connected, cannot send invite');
  }
}

export function blindTransfer(session, phoneNumber) {
  if (isConnected()) {
    session
      .blindTransfer(`sip:${phoneNumber}@voipgrid.nl`)
      .then(async () => {
        showToast({
          title: 'blind_transfer_toast_success_title',
          body: 'blind_transfer_toast_success_message',
          icon: 'transfer',
          type: 'success',
          sticky: false,
        });
      })
      .catch(callingLogger.error);
  } else {
    callingLogger.warn('client is not connected, cannot transfer');
  }
}

export function holdAllOtherSessions(session) {
  return Promise.all(
    getSessions()
      .filter((s) => s !== session && !s.holdState && !['ringing', 'terminated'].includes(s.status))
      .map((s) => s.hold()),
  );
}

export function attendedTransfer(a, b) {
  client
    .attendedTransfer(a, b)
    .then(async () => {
      showToast({
        title: 'attended_transfer_toast_success_title',
        body: 'attended_transfer_toast_success_message',
        icon: 'transfer',
        type: 'success',
        sticky: false,
      });
    })
    .catch(callingLogger.error);
}

user
  .isAuthenticated()
  .then(connect)
  .catch(() => callingLogger.info('not logged in, cannot connect'));

userEvents.addEventListener('loggedIn', connect);
userEvents.addEventListener('loggedOut', disconnect);
