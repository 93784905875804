import { remove } from '@/utils/dom.ts';

window.customElements.define(
  'c-short-lived-visual-feedback',
  class extends HTMLElement {
    static get observedAttributes() {
      return ['icon', 'key'];
    }

    handleEvent() {
      remove(this);
    }

    position(x, y) {
      this.style.left = `${x}px`;
      this.style.top = `${y}px`;
    }

    connectedCallback() {
      this.addEventListener('animationend', this);
    }

    disconnectedCallback() {
      this.removeEventListener('animationend', this);
    }

    attributeChangedCallback(name, oldValue, newValue) {
      switch (name) {
        case 'icon':
          {
            Array.from(this.querySelectorAll('c-icon')).map(remove);
            const icon = document.createElement('c-icon');
            icon.setAttribute('icon', newValue);
            this.insertAdjacentElement('afterbegin', icon);
          }
          break;

        case 'key':
          {
            Array.from(this.querySelectorAll('c-translate')).map(remove);
            const node = document.createElement('c-translate');
            node.setAttribute('key', newValue);
            this.append(node);
          }
          break;
      }
    }
  },
);
