import * as localSettings from '@/lib/settings/local.mjs';

import { empty, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-quick-links-popup').then(({ content }) => {
  window.customElements.define(
    'c-quick-links-popup',
    class extends HTMLElement {
      set settingsKey(key) {
        this._settingsKey = key;
        this.sortVisible();
      }
      get settingsKey() {
        return this._settingsKey;
      }

      set links(links) {
        empty(this.nodes.list);

        links.forEach(({ text, link }) => {
          const div = document.createElement('div');

          const a = document.createElement('a');
          a.setAttribute('href', link);
          a.setAttribute('target', '_blank');
          a.textContent = text;
          div.appendChild(a);

          this.nodes.list.appendChild(div);
        });
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      async handleEvent() {
        await localSettings.toggle(this.settingsKey);
        await this.sortVisible();
      }

      async sortVisible() {
        const isVisible = await localSettings.get(this.settingsKey);
        if (isVisible) {
          show(this.nodes.popup);
          this.classList.add('active');
        } else {
          hide(this.nodes.popup);
          this.classList.remove('active');
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.actions.openPopup.addEventListener('click', this);
      }

      disconnectedCallback() {
        this.actions.openPopup.removeEventListener('click', this);
      }
    },
  );
});
