import { Logger } from '@/lib/logging.mjs';

import { userEvents } from '@/utils/eventTarget.ts';

const logger = new Logger('toasts');

const toastsNode = document.querySelector('c-toasts');
const toasts = {};

let uniqueId = 0;

export function showToast(data) {
  const id = data.id ? data.id : ++uniqueId;

  if (id in toasts) {
    logger.info(`already showing toast with id: ${id}`);
    return id;
  }

  const toastNode = document.createElement('c-toast');
  toastsNode.insertBefore(toastNode, toastNode.firstChild);
  toastNode.id = id;
  toastNode.data = data;
  toasts[id] = toastNode;

  logger.info(`showing toast with id: ${id} and title: ${toastNode.data.title}`);
  return id;
}

export function closeToast(id) {
  const toastNode = toasts[id];
  if (toastNode && toastsNode.contains(toastNode)) {
    toastsNode.removeChild(toastNode);
    delete toasts[id];
    logger.info(`closing toast with id: ${id} and title: ${toastNode.data.title}`);
  }
}

export function closeAllToasts() {
  Object.keys(toasts).forEach((id) => closeToast(id));
}

userEvents.addEventListener('loggedOut', () => {
  closeAllToasts();
});
