import * as Sentry from '@sentry/browser';

// All HTML templates will be stored in the templateCache
const templateCache: Map<string, HTMLTemplateElement> = new Map();

// Iterate over all HTML templates and add them to the templateCache
Array.from(document.querySelectorAll('template')).forEach((templateNode) => {
  const { component } = templateNode.dataset;
  if (component) {
    templateCache.set(component, templateNode);
  }
});

/**
 * load a HTML template from the cache by its component name.
 *
 * @param componentName - The name of the component whose template is to be retrieved.
 * @returns - A promise which resolves with the template element if found.
 */
export function loadTemplate(componentName: string): Promise<HTMLTemplateElement> {
  const template = templateCache.get(componentName);

  if (template === undefined) {
    return Promise.reject(new Error(`Template for component '${componentName}' not found`));
  }

  return Promise.resolve(template);
}

/**
 * removes a DOM node from its parentNode.
 *
 * @param node - the node that needs to be removed.
 */
export function remove(node: Element) {
  node.parentNode?.removeChild(node);
}

/**
 * removes all occurrences of nodes matching 'selector' from the DOM node that is passed as inNode.
 *
 * @param selector - selector that matches the nodes to be removed
 * @param inNode - the node in which to remove all occurrences of selector
 */
export function removeAll(selector: string, inNode = document.body) {
  try {
    Array.from(inNode.querySelectorAll(selector)).map(remove);
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * empties a DOM node.
 *
 * @param node - the node that needs to be emptied.
 */
export function empty(node: Element) {
  try {
    while (node.firstChild) {
      node.removeChild(node.firstChild);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * hides a DOM node.
 *
 * @param node - the node that needs to be hidden
 */
export function hide(node: Element) {
  try {
    node.setAttribute('hidden', '');
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * returns true of the DOM node is hidden.
 *
 * @param node - the node that needs to be checked.
 */
export function isHidden(node: Element) {
  try {
    return node.hasAttribute('hidden');
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * shows a DOM node.
 *
 * @param node - the node that needs to be shown.
 */
export function show(node: Element) {
  try {
    node.removeAttribute('hidden');
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * disables a DOM node.
 *
 * @param node - the node that needs to be disabled.
 */
export function disable(node: Element) {
  try {
    node.setAttribute('disabled', '');
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * returns true of the DOM node is disabled.
 *
 * @param node - the node that needs to be checked.
 */
export function isDisabled(node: Element) {
  try {
    return node.hasAttribute('disabled');
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * enable a DOM node.
 *
 * @param node - the node that needs to be enabled.
 */
export function enable(node: Element) {
  try {
    node.removeAttribute('disabled');
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * select an option DOM node.
 *
 * @param node - the node that needs to be selected.
 */
export function select(node: HTMLOptionElement) {
  try {
    node.setAttribute('selected', '');
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * deselect an option DOM node.
 *
 * @param node - the node that needs to be deselected.
 */
export function deselect(node: HTMLOptionElement) {
  try {
    node.removeAttribute('selected');
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * Convert a form element to an object. Each form input's name (the key) and value are stored in the returned object.
 *
 * @param form - the form element.
 * @returns - the object containing the form values.
 */
export function getFormValues(form: HTMLFormElement): object {
  return Array.from(form).reduce((prev, element) => {
    const input = element as HTMLInputElement;

    if (input.name) {
      return Object.assign(prev, {
        [input.name]: input.type === 'checkbox' ? input.checked : input.value,
      });
    } else {
      return prev;
    }
  }, {});
}

/**
 * Get all relevant form elements (input, select, textarea) from a given form, excluding submit buttons.
 *
 * @param form - An HTMLFormElement from which to extract the elements.
 * @returns - An array of form elements
 */
export function getFormElements(form: HTMLFormElement): Array<Element> {
  return Array.from(form).filter((node) => {
    const input = node as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

    switch (input.nodeName) {
      case 'INPUT':
        // Exclude submit buttons.
        if (input.type === 'submit') {
          return false;
        }
        return true;

      case 'TEXTAREA':
      case 'SELECT':
        return true;

      // Exclude anything else that is not an input, textarea or select.
      default:
        return false;
    }
  });
}

// A cache to store loaded SVG icons. The key is the icon's ID and the value is the Promise that resolves to the SVG icon.
const iconCache: Map<string, Promise<HTMLTemplateElement>> = new Map();

/**
 * Load an SVG icon by its ID.
 *
 * @param id - The identifier of the SVG icon.
 * @returns - A Promise that resolves to an HTML template containing the SVG icon.
 */
export function loadIcon(id: string): Promise<HTMLTemplateElement> {
  // Check if the icon is already cached. If it is, return the cached Promise.
  if (iconCache.has(id)) {
    return Promise.resolve(iconCache.get(id)!); // The "!" postfixed here asserts that the returned value is non-null/non-undefined.
  }

  // Fetch the SVG icon from the server using its ID.
  const iconTemplate = fetch(`${id}.svg`).then((response: Response) => {
    // If the fetch was unsuccessful, reject the Promise.
    if (response.status !== 200) {
      return Promise.reject(new Error(`icon ${id} ${response.statusText}`));
    }

    // Convert the fetched response to text.
    return response.text().then((svg: string) => {
      // Create an HTML template and set its innerHTML to the fetched SVG content.
      const template: HTMLTemplateElement = document.createElement('template');
      template.innerHTML = svg;

      // Return the created template.
      return template;
    });
  });

  // Cache the Promise for future requests.
  iconCache.set(id, iconTemplate);

  return iconTemplate;
}
