import { translate } from '@/lib/i18n.mjs';
import * as segment from '@/lib/segment.mjs';

import { disable, empty, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { second } from '@/utils/time.ts';

const isSupported = 'Notification' in window;

const statusText = {
  granted: 'notification_permission_granted',
  denied: 'notification_permission_denied',
  default: 'notification_permission_default',
};

loadTemplate('c-troubleshoot-notifications').then(({ content }) => {
  window.customElements.define(
    'c-troubleshoot-notifications',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);

        this.previousPermission = '';
      }

      handleEvent(e) {
        switch (e.currentTarget) {
          case this.actions.fireNotification: {
            this.tryToNotify();
            this.updateStatus();
            {
              const node = document.createElement('c-short-lived-visual-feedback');
              node.setAttribute('key', 'troubleshoot_notification_button_clicked');
              node.setAttribute('icon', 'check');
              node.position(e);
              document.body.append(node);
            }
            break;
          }
          case this.nodes.linkToSupportSite: {
            segment.track.visitedNotificationsSupportPage();
            break;
          }
        }
      }

      tryToNotify() {
        hide(this.nodes.notificationWasFired);

        segment.track.triedToTroubleshoot('notifications');
        if (Notification.permission === 'granted') {
          this.handleGrantedPermission();
        } else if (Notification.permission !== 'denied') {
          Notification.requestPermission().then((permission) => {
            // to not have the checkPermissionChanges remove the message we update the previousPermission ourselves here
            this.previousPermission = permission;

            if (permission === 'granted') {
              this.handleGrantedPermission();
            } else {
              this.handleDeniedPermission();
            }
            this.updateStatus();
          });
        }
      }

      async handleGrantedPermission() {
        hide(this.nodes.notificationPermissionDenied);
        const text = translate('notification_test_message');
        new Notification(text);
        show(this.nodes.notificationWasFired);
        if (import.meta.env.VITE_VENDOR_SUPPORT_WEBSITE_NOTIFICATIONS) {
          show(this.nodes.supportSiteText);
        }
      }

      handleDeniedPermission() {
        show(this.nodes.notificationPermissionDenied);
      }

      updateStatus() {
        const { permission } = Notification;
        empty(this.nodes.currentStatus);

        const node = document.createElement('c-translate');
        node.setAttribute('key', statusText[permission]);
        this.nodes.currentStatus.append(node);

        if ('denied' === permission) {
          disable(this.actions.fireNotification);
        } else {
          enable(this.actions.fireNotification);
        }
      }

      checkPermissionChanges() {
        const { permission } = Notification;

        if (this.previousPermission !== permission) {
          this.previousPermission = permission;
          this.updateStatus();

          hide(this.nodes.notificationWasFired);

          if (permission === 'denied') {
            show(this.nodes.notificationPermissionDenied);
          } else {
            hide(this.nodes.notificationPermissionDenied);
          }
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));
        this.actions.fireNotification.addEventListener('click', this);
        this.nodes.linkToSupportSite.addEventListener('click', this);
        this.checkPermissionChanges();

        if (!isSupported) {
          show(this.nodes.notificationsNotSupported);
          disable(this.actions.fireNotification);
        }

        this.checkinterval = window.setInterval(this.checkPermissionChanges.bind(this), second * 2);
      }

      disconnectedCallback() {
        this.actions.fireNotification.removeEventListener('click', this);
        this.nodes.linkToSupportSite.removeEventListener('click', this);
        this.clearInterval(this.checkinterval);
      }
    },
  );
});
