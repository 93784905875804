import * as log from '@/lib/log.mjs';
import * as segment from '@/lib/segment.mjs';

import { disable, enable, loadTemplate } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import showSettingSaved from '@/utils/settingSaved.ts';

loadTemplate('c-remote-logging-toggle').then(({ content }) => {
  window.customElements.define(
    'c-remote-logging-toggle',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      handleEvent({ type, currentTarget: { checked } }) {
        if ('change' === type) {
          this.updateRemoteLogging(checked);
          segment.track.toggleRemoteLogging();
          showSettingSaved(this.nodes.remoteLoggingSaved);
        }
      }

      updateRemoteLogging(value) {
        if (value) {
          log.enable();
        } else {
          log.disable();
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.nodes.remoteLoggingCheckbox.addEventListener('change', this);

        if (!log.isSupported()) {
          disable(this.nodes.remoteLoggingCheckbox);
        } else {
          if (log.isEnabled()) {
            this.nodes.remoteLoggingCheckbox.checked = true;
          }
          enable(this.nodes.remoteLoggingCheckbox);
        }
      }

      disconnectedCallback() {
        this.nodes.remoteLoggingCheckbox.removeEventListener('change', this);
      }
    },
  );
});
