import '@/components/c-no-account.mjs';
import '@/components/c-no-connection.mjs';
import '@/components/c-recent-call.mjs';

import { voipAccount } from '@/lib/calling.mjs';
import getCalls from '@/lib/data/getCalls.mjs';
import { Logger } from '@/lib/logging.mjs';
import * as segment from '@/lib/segment.mjs';
import * as localSettings from '@/lib/settings/local.mjs';
import * as settings from '@/lib/settings/remote.mjs';

import { empty, hide, loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents } from '@/utils/eventTarget.ts';
import * as time from '@/utils/time.ts';

const logger = new Logger('recents');

const RECENTS_INTERVAL_MIN = 20 * time.second;

loadTemplate('p-recents').then(({ content }) => {
  window.customElements.define(
    'p-recents',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      async handleEvent({ type, currentTarget }) {
        switch (type) {
          case 'change':
            {
              switch (currentTarget) {
                case this.nodes.showMissedCalls:
                  {
                    const { checked } = this.nodes.showMissedCalls;
                    await settings.set('showMissedCalls', checked);
                    await this.fetchCalls(true);
                  }
                  break;

                case this.nodes.showOnlyWebphoneCalls:
                  {
                    const { checked } = this.nodes.showOnlyWebphoneCalls;
                    await settings.set('showWebphoneCalls', checked);
                    await this.applyCallsFilters();
                    segment.track.toggleShowOnlyWebphoneCalls();
                  }
                  break;
              }
            }
            break;

          case 'clientStatusUpdated':
            this.blockPageOrShowCalls();
            break;
        }
      }

      async applyCallsFilters() {
        const onlyShowWebphoneCalls = await settings.get('showWebphoneCalls');

        this.nodes.showOnlyWebphoneCalls.checked = onlyShowWebphoneCalls;

        const { children } = this.nodes.callsList;

        for (const node of children) {
          let match = true;

          if (onlyShowWebphoneCalls) {
            match &= node.data.isWebphoneCall;
          }

          match ? show(node) : hide(node);
        }
      }

      async fetchCalls(refreshFromApi) {
        this.nodes.callsList.classList.add('loading');
        empty(this.nodes.callsList);

        const calls = await getCalls(refreshFromApi);
        this.populate(calls);

        if (refreshFromApi) {
          localSettings.set('timeOfLastRecentsUpdate', Date.now());
        }

        await this.applyCallsFilters();

        this.nodes.callsList.classList.remove('loading');
      }

      async fetchCallsIfPossible() {
        let refreshFromApi = true;

        // See if enough time has passed to do an API call.
        const timeOfLastUpdate = await localSettings.get('timeOfLastRecentsUpdate');
        if (timeOfLastUpdate) {
          const delta = Date.now() - timeOfLastUpdate;
          const remainingDelta = RECENTS_INTERVAL_MIN - delta;

          if (delta < RECENTS_INTERVAL_MIN) {
            logger.warn(`Too soon with recents update, updating recents in ${remainingDelta / time.second} seconds`);
            refreshFromApi = false;
            this.fetcher = setTimeout(() => this.fetchCalls(true), remainingDelta);
          }
        }

        this.fetchCalls(refreshFromApi);
      }

      blockPageOrShowCalls() {
        if (typeof voipAccount === 'undefined' || voipAccount === 'unknown') {
          show(this.nodes.noAccount);
          hide(this.nodes.recentsFilters);
        } else {
          hide(this.nodes.noAccount);
          show(this.nodes.recentsFilters);
          this.fetchCallsIfPossible();
        }
      }

      populate(calls) {
        empty(this.nodes.callsList);

        for (const call of calls) {
          const callNode = document.createElement('c-recent-call');
          callNode.data = call;
          callNode.setAttribute('tabindex', '0');
          hide(callNode);
          this.nodes.callsList.appendChild(callNode);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.nodes.showMissedCalls.addEventListener('change', this);
        this.nodes.showOnlyWebphoneCalls.addEventListener('change', this);

        const onlyShowMissedCalls = await settings.get('showMissedCalls');
        this.nodes.showMissedCalls.checked = onlyShowMissedCalls;

        this.blockPageOrShowCalls();

        callingEvents.addEventListener('clientStatusUpdated', this);
      }

      disconnectedCallback() {
        clearTimeout(this.fetcher);

        this.nodes.showMissedCalls.removeEventListener('change', this);
        this.nodes.showOnlyWebphoneCalls.removeEventListener('change', this);

        callingEvents.removeEventListener('clientStatusUpdated', this);
      }
    },
  );
});
