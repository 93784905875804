export default function (mocking) {
  // Dynamic mode gives us less predictable results so it can be useful
  // for rendering large lists of data or when you don't care about
  // predictability
  if (mocking.dynamic) {
    return `dynamic=true`;
  }

  // On a 200 request we shouldn't provide the code, since the mocking server
  // returns 200 by default and can't handle this scenario
  if (mocking.status_code === 200 && mocking.example) {
    return `example=${mocking.example}`;
  }

  // If an example is given we should handle it
  if (mocking.example) {
    return `code=${mocking.status_code}&example=${mocking.example}`;
  }

  // Finally, if nothing is given just mock a response based on the status_code
  return `code=${mocking.status_code}`;
}
