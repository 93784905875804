import HeadsetService from 'softphone-vendor-headsets';
import { ImplementationConfig } from 'softphone-vendor-headsets/dist/es/src/library/services/vendor-implementations/vendor-implementation';

import { EPOSHeadset } from '@/lib/headset/epos-headset.ts';
import { Headset } from '@/lib/headset/headset.ts';
import { headsetLogger } from '@/lib/loggers.mjs';
import * as media from '@/lib/media.mjs';

import { mediaEvents } from '@/utils/eventTarget.ts';

export enum HeadsetStatus {
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  CHECKING = 'CHECKING',
  ENABLED = 'ENABLED',
  PERMISSIONS_REQUIRED = 'PERMISSIONS_REQUIRED',
}

/*
 * This class is responsible for initialising the headset and choosing
 * the correct implementation based on the headset input.
 * It either initialises the EPOS headset or the headset from the softphone library.
 * It also communicates the status of the headset to the audio-selector component.
 */
class HeadsetManager {
  headsetService: HeadsetService;
  eposHeadset: EPOSHeadset | null;
  headset: Headset | null;
  headsetStatus: HeadsetStatus;
  headsetVendorNameChecking: string;

  constructor() {
    this.headsetService = HeadsetService.getInstance({} as ImplementationConfig);
    this.eposHeadset = null;
    this.headset = null;
    this.headsetStatus = HeadsetStatus.NOT_SUPPORTED;
    this.headsetVendorNameChecking = '';
    this.init();
  }

  init() {
    this.initialiseHeadset();
    this.setupMicrophoneInputListeners();
  }

  async initialiseHeadset(headsetName = '') {
    const headsetInput = await media.getHeadsetInput();
    const headsetInputName = headsetInput.name?.toLowerCase();
    // Since this function is being called from init() we have to
    // find out the headsetName from the devices list if we already have a matching
    // webHID device selected.
    if (!headsetName) {
      const devices = await navigator.hid.getDevices();
      if (devices.length > 0) {
        headsetLogger.info(`Headset: Found devices`, {
          devices,
        });
        const headsetMatch = devices.find((device) => {
          return headsetInputName.includes(device.productName.toLowerCase());
        });
        if (headsetMatch) {
          headsetName = headsetMatch.productName.toLowerCase();
        } else {
          headsetLogger.error(
            `Headset: Couldn't find a matching device in the devices list for ${headsetInputName}`,
            {},
          );
          headsetName = headsetInputName;
        }
      } else {
        headsetName = headsetInputName;
      }
    }

    const headsetIsSupported = this.headsetService.deviceIsSupported({ micLabel: headsetName });
    if (headsetIsSupported) {
      this.updateStatus(HeadsetStatus.CHECKING);
      const headsetImplementation = this.headsetService.implementations.find((implementation) => {
        return implementation.deviceLabelMatchesVendor(headsetName);
      });

      this.headsetVendorNameChecking = headsetImplementation.vendorName;

      if (this.headsetVendorNameChecking === 'Sennheiser') {
        this.eposHeadset = new EPOSHeadset();
        this.eposHeadset.initialSetup();
      } else {
        this.headset = new Headset();
        this.headset.initialSetup(headsetName);
      }
      headsetLogger.info('Headset is supported', { deviceName: headsetName });
    } else {
      this.updateStatus(HeadsetStatus.NOT_SUPPORTED);
      headsetLogger.info('Headset is not supported', { deviceName: headsetName });
    }
  }

  updateStatus(status: HeadsetStatus) {
    this.headsetStatus = status;
    mediaEvents.dispatchEvent(new CustomEvent('headsetStatusUpdate'));
  }

  setupMicrophoneInputListeners() {
    mediaEvents.addEventListener('headsetInputChanged', () => {
      this.destroyHeadsets();
      this.initialiseHeadset();
    });
  }

  destroyHeadsets() {
    if (this.eposHeadset) {
      this.eposHeadset.destroy();
      this.eposHeadset = null;
    }

    if (this.headset) {
      this.headset.destroy();
      this.headset = null;
    }
  }

  getVendorName() {
    if (this.headsetStatus === HeadsetStatus.CHECKING) {
      if (this.headsetVendorNameChecking === 'Sennheiser') {
        return 'EPOS / Sennheiser';
      }

      return this.headsetVendorNameChecking;
    }
    if (this.eposHeadset) {
      return 'EPOS / Sennheiser';
    }
    if (this.headset) {
      return this.headsetService.selectedImplementation.vendorName;
    }
    return;
  }

  getStatus() {
    return this.headsetStatus;
  }
}

export const headsetManagerInstance = new HeadsetManager();
