import { getSession, invite, isAbleToMakeCall } from '@/lib/calling.mjs';
import * as favorites from '@/lib/favorites.mjs';
import { translate } from '@/lib/i18n.mjs';
import * as segment from '@/lib/segment.mjs';

import doesMatchSearchString from '@/utils/doesMatchSearchString.ts';
import { disable, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents, mediaEvents, popoutEvents } from '@/utils/eventTarget.ts';
import { navigate } from '@/utils/history.ts';

loadTemplate('c-contact').then(({ content }) => {
  window.customElements.define(
    'c-contact',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['session-id'];
      }

      set data(data) {
        this._data = data;
        this.populate();
        this.searchValues = this.data.getSearchValues();
      }
      get data() {
        return this._data;
      }

      set phoneNumber(phoneNumberInstance) {
        this._phoneNumber = phoneNumberInstance.phoneNumber;
      }
      get phoneNumber() {
        if (this._phoneNumber) {
          return this._phoneNumber;
        } else if (this.data.firstPhoneNumber && this.data.firstPhoneNumber.phoneNumber) {
          return this.data.firstPhoneNumber.phoneNumber;
        }
        return undefined;
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      async handleEvent({ type, currentTarget, detail }) {
        switch (type) {
          case 'clientStatusUpdated':
            this.sortCallButtonVisiblity();
            break;

          case 'microphonePermissionUpdated':
            this.sortCallButtonVisiblity();
            break;

          case 'closePopouts':
            {
              const popoutThatShouldBeOpen = detail;
              if (this.contactPopoutNode && popoutThatShouldBeOpen !== this.contactPopoutNode) {
                this.actions.popout.close();
              }
            }
            break;

          case 'toggleOpen':
            popoutEvents.dispatchEvent(new CustomEvent('closePopouts', { detail: this.contactPopoutNode }));
            this.classList.add('open');
            show(this.contactPopoutNode);
            break;

          case 'toggleClose':
            if (this.contactPopoutNode) {
              hide(this.contactPopoutNode);
              this.classList.remove('open');
            }
            break;

          case 'click':
            switch (currentTarget) {
              case this.actions.edit:
                navigate(`/contact/${this.data.id}`);
                break;

              case this.actions.call:
                {
                  const phoneNumber = this.phoneNumber;
                  if (phoneNumber) {
                    segment.track.callContact();
                    const path = document.location.pathname.split('/')[1];
                    history.pushState(undefined, '', `/${path}`);
                    invite(phoneNumber);
                  }
                }
                break;

              case this.actions.favorite:
                segment.track.favoriteToggle();
                await favorites.toggle(this.data.id);
                this.sortIsFavorite();
                break;
            }
        }
      }

      async getOneOrMultiplePhoneNumberDescription() {
        if (typeof this._phoneNumber !== 'undefined') {
          return this._phoneNumber;
        }

        let description = this.data.firstPhoneNumber.phoneNumber;

        if (this.data.phoneNumbers.length > 1) {
          description = translate('multiple_phone_numbers_description', {
            phoneNumber: this.data.firstPhoneNumber.phoneNumber,
            phoneNumbersMore: this.data.phoneNumbers.length - 1,
          });
        }

        return description;
      }

      doesMatchSearchString(query = '') {
        return doesMatchSearchString(this.searchValues, query);
      }

      sortCallButtonVisiblity() {
        if (this.hasAttribute('popoutable')) {
          hide(this.actions.call);
        }
        if (!isAbleToMakeCall || this.data.phoneNumbers.length < 1) {
          disable(this.actions.call);
        } else {
          enable(this.actions.call);
        }
      }

      sortIsFavorite() {
        if (this.hasAttribute('favoritable') && favorites.contains(this.data.id)) {
          show(this.nodes.favoriteIndicator);
        } else {
          hide(this.nodes.favoriteIndicator);
        }

        if (this.hasAttribute('favoritable')) {
          show(this.actions.favorite);
        } else {
          hide(this.actions.favorite);
        }
      }

      sortIsEditable() {
        if (this.hasAttribute('editable')) {
          show(this.actions.edit);
        } else {
          hide(this.actions.edit);
        }
      }

      // to be able to call this function from every colltact node in c-dialer we need to do this function
      shouldShow() {
        return true;
      }

      sortShouldPopout() {
        if (this.hasAttribute('popoutable')) {
          show(this.actions.popout); // toggle

          this.contactPopoutNode = document.createElement('c-contact-numbers-popout');
          this.nodes.contactNode.appendChild(this.contactPopoutNode);
          this.contactPopoutNode.data = this.data.phoneNumbers;
          hide(this.contactPopoutNode);
        } else {
          hide(this.actions.popout);
        }
      }

      sortPopoutButtonVisibility() {
        if (isAbleToMakeCall && this.data.phoneNumbers.length > 1 && this.hasAttribute('popoutable')) {
          show(this.actions.popout);
        } else {
          hide(this.actions.popout);
          this.actions.popout.close();
        }
      }

      async populate() {
        if (this.data && this.isConnected) {
          this.nodes.contactName.textContent = this.data.fullName;
          if (this.data.hasPhoneNumber) {
            this.nodes.contactNumber.textContent = await this.getOneOrMultiplePhoneNumberDescription();
            show(this.nodes.contactNumber);
            hide(this.nodes.contactNoNumbers);
          } else {
            hide(this.nodes.contactNumber);
            show(this.nodes.contactNoNumbers);
          }
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        [this.actions.call, this.actions.favorite, this.actions.edit].forEach((n) => {
          n.addEventListener('click', this);
        });

        this.actions.popout.addEventListener('toggleOpen', this);
        this.actions.popout.addEventListener('toggleClose', this);
        popoutEvents.addEventListener('closePopouts', this);

        this.populate();
        this.sortIsFavorite();
        this.sortIsEditable();
        this.sortShouldPopout();
        this.sortCallButtonVisiblity();
        // this.sortPopoutButtonVisibility();

        if (this.session) {
          this.nodes.callButtonIcon.setAttribute('icon', 'transfer');
        } else {
          this.nodes.callButtonIcon.setAttribute('icon', 'phone');
        }

        callingEvents.addEventListener('clientStatusUpdated', this);
        mediaEvents.addEventListener('microphonePermissionUpdated', this);
      }

      disconnectedCallback() {
        [this.actions.call, this.actions.favorite, this.actions.edit].forEach((n) => {
          n.removeEventListener('click', this);
        });

        this.actions.popout.removeEventListener('toggleOpen', this);
        this.actions.popout.removeEventListener('toggleClose', this);
        popoutEvents.removeEventListener('closePopouts', this);

        callingEvents.removeEventListener('clientStatusUpdated', this);
        mediaEvents.removeEventListener('microphonePermissionUpdated', this);
      }

      attributeChangedCallback(name, oldValue, newValue) {
        // If the contact is part of a session it means we are
        // in transfer mode.
        this.session = getSession(newValue);
      }
    },
  );
});
