/**
 * This function shows the 'Saved' message (the settingSaved component)
 *
 * @param target the HTML element where we attach the settingSaved component to
 */

export default function settingSaved(target: HTMLElement) {
  if (target) {
    const existingSavedNode = target.querySelector('c-setting-saved');
    // By default c-setting-saved will remove this node in two seconds.
    // If a user toggles before this timeout, we remove this node.
    if (existingSavedNode) {
      target.removeChild(existingSavedNode);
    }

    const node = document.createElement('c-setting-saved');
    target.appendChild(node);
  }
}
