import * as segment from '@/lib/segment.mjs';

import { loadTemplate } from '@/utils/dom.ts';

loadTemplate('p-choose-account').then(({ content }) => {
  window.customElements.define(
    'p-choose-account',
    class extends HTMLElement {
      connectedCallback() {
        this.appendChild(content.cloneNode(true));
      }

      disconnectedCallback() {
        segment.track.wizardSoftphoneAccountStepCompleted();
      }
    },
  );
});
