import request from '@/lib/request.mjs';
import * as temp from '@/lib/temporary-storage.mjs';
import { User } from '@/lib/types/User.ts';

export default async function () {
  const { user_id, client_id, client_uuid, email, first_name, last_name, preposition, token, uuid } =
    await request('user');

  const user: User = {
    id: user_id,
    email,
    token,
    uuid,
    preposition,
    clientUuid: client_uuid,
    clientId: client_id,
    firstName: first_name,
    lastName: last_name,
  };

  // We store the user in temporary storage
  // because we periodically do API requests based on certain id's
  // We don't want to do an API request for these values every time
  // since they won't change.
  temp.set('user', user);

  return user;
}
