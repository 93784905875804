export default function getInstanceName(obj, models) {
  for (const model of models) {
    if (obj instanceof model) {
      return obj.constructor.name.toLowerCase();
    }
  }

  if (Array.isArray(obj)) {
    return 'array';
  }

  return typeof obj;
}
