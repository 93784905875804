// Convert a camelCase string to a kebab-case string.
export const camelToKebab = (str: string) => str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

// Check if the given object is an array.
const isArray = function (a: unknown): a is Array<unknown> {
  return Array.isArray(a);
};

// Check if the given object is an object but not an array or function.
const isObject = function (o: unknown): o is Record<string, unknown> {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

// Convert a camelCase string to a snake_case string.
export const camelToSnake = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

// Convert a snake_case string to a camelCase string.
export const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('_', ''));

// Recursively convert the keys of an object (or array of objects) from snake_case to camelCase.
export const keysToCamel = function (o: unknown): unknown {
  if (isObject(o)) {
    const n: Record<string, unknown> = {};

    Object.keys(o).forEach((k) => {
      n[snakeToCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

/**
 * Recursively convert the keys of an object, or array of objects, from camelCase to snake_case.
 *
 * @param o - The object or array of objects whose keys should be converted.
 * @returns An object or array of objects with keys converted to snake_case.
 */

export const keysToSnake = function (o: unknown): unknown {
  if (isObject(o)) {
    const n: Record<string, unknown> = {};

    Object.keys(o).forEach((k) => {
      n[camelToSnake(k)] = keysToSnake(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToSnake(i);
    });
  }

  return o;
};

/**
 * Generate a unique base64 aka binary-to-text encoded string. Optionally ensures that the
 * generated string has not been generated before.
 *
 * @param forceUniqueness - Conditionally ensure the generated string is unique.
 * @returns A base64 encoded string.
 */

// A Map to keep track of previously generated strings for uniqueness
const previousMumboJumbo = new Map<string, null>();

export function generateUniqueString(forceUniqueness: boolean): string {
  // Generate a base64 string based on the current time in milliseconds
  const str = btoa(String(new Date().getTime()));

  // If we need to ensure the string is unique
  if (forceUniqueness) {
    // Check if this string has been generated before
    if (previousMumboJumbo.has(str)) {
      // Generate a new unique string recursively
      return generateUniqueString(forceUniqueness);
    }
    // Add the generated string to the Map to prevent future duplicates
    previousMumboJumbo.set(str, null);
  }

  // Return the generated string
  return str;
}
