import { NO_ACCOUNT, NO_AUDIO_DEVICES, NO_CONNECTION } from '@/constants/dialerBlockingInterfaceKeys.mjs';

export default function getDialerBlockingInterfaceKey({
  microphonePermissionGranted,
  isConnecting,
  isConnected,
  voipAccount,
}) {
  if (isConnecting) {
    return NO_CONNECTION;
  } else if (typeof voipAccount === 'undefined') {
    return NO_ACCOUNT;
  } else if (!isConnected) {
    return NO_CONNECTION;
  } else if (!microphonePermissionGranted) {
    return NO_AUDIO_DEVICES;
  } else {
    return null;
  }
}
