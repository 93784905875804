import request from '@/lib/request.mjs';

export interface UpdateVoipAccountBody {
  internal_number?: string;
  description?: string;
  outgoing_caller_identification: {
    phone_number: string;
    name?: string;
  };
}

export default function (clientId: string, voipAccountId: string, body: UpdateVoipAccountBody) {
  return request('updateVoipAccount', { clientId, voipAccountId, body, forceRefresh: true });
}
