import { getSessions } from '@/lib/calling.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { callingEvents } from '@/utils/eventTarget.ts';

loadTemplate('c-session-count').then(({ content }) => {
  window.customElements.define(
    'c-session-count',
    class extends HTMLElement {
      handleEvent() {
        const length = getSessions().length;
        if (0 === length) {
          this.badge.classList.add('no-sessions');
        } else {
          this.badge.classList.remove('no-sessions');
        }

        this.badge.textContent = length;
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.badge = this.querySelector('[data-selector=badge]');

        this.handleEvent();
        callingEvents.addEventListener('sessionAdded', this);
        callingEvents.addEventListener('sessionRemoved', this);
      }

      disconnectedCallback() {
        callingEvents.removeEventListener('sessionAdded', this);
        callingEvents.removeEventListener('sessionRemoved', this);
      }
    },
  );
});
