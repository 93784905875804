function check(key, a, b) {
  if (key in a && a[key] === b[key]) {
    return true;
  }
}

export default function shallowEqual(a, b) {
  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) {
    return false;
  }
  let key;

  for (key of keysA) {
    if (!check(key, a, b)) {
      return false;
    }
  }

  for (key of keysB) {
    if (!check(key, b, a)) {
      return false;
    }
  }

  return true;
}
