import * as log from '@/lib/log.mjs';
import request from '@/lib/request.mjs';
import * as user from '@/lib/user.mjs';

import { disable, enable, getFormValues, hide, loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-feedback-form').then(({ content }) => {
  window.customElements.define(
    'c-feedback-form',
    class extends HTMLElement {
      set isDisabled(isDisabled) {
        Array.from(this.nodes.feedbackForm).forEach((node) => {
          isDisabled ? disable(node) : enable(node);
        });
      }

      constructor() {
        super();
        this.nodes = NodesProxy(this);
      }

      async handleEvent(e) {
        e.preventDefault();
        const { target } = e;

        this.isDisabled = true;

        const { uuid, email, firstName, lastName } = await user.get();
        const { message, enableRemoteLogging } = getFormValues(target);

        const headers = {
          Referer: window.location.href,
        };

        const body = {
          message,
          user: {
            id: uuid,
            email_address: email,
            given_name: firstName,
            family_name: lastName,
          },
          application: {
            version: `${import.meta.env.VITE_APP_NAME}${import.meta.env.VITE_VERSION}`,
          },
        };

        try {
          await request('sendFeedback', { headers, body });
          if (enableRemoteLogging) {
            log.enable();
          }
          hide(this.nodes.feedback);
          show(this.nodes.feedbackSent);
        } catch (err) {
          console.error(err);
        }

        this.isDisabled = false;
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        if (!log.isSupported()) {
          disable(this.nodes.remoteLoggingCheckbox);
        }

        if (!log.isEnabled()) {
          show(this.nodes.remoteLoggingSection);
        }

        this.nodes.feedbackForm.addEventListener('submit', this);
      }

      disconnectedCallback() {
        this.nodes.feedbackForm.removeEventListener('submit', this);
      }
    },
  );
});
