import * as settings from '@/lib/settings/remote.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import showSettingSaved from '@/utils/settingSaved.ts';
import shouldPreventUnload from '@/utils/shouldPreventUnload.mjs';

async function getSetting() {
  return settings.get('accidentalClosingIsPrevented');
}

let isPrevented;
getSetting().then((_isPrevented) => {
  isPrevented = _isPrevented;
});

window.addEventListener('beforeunload', (e) => shouldPreventUnload(e, isPrevented));

loadTemplate('c-prevent-accidental-close').then(({ content }) => {
  window.customElements.define(
    'c-prevent-accidental-close',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      async handleEvent({ type, currentTarget: { checked } }) {
        if ('change' === type) {
          isPrevented = checked;
          await settings.set('accidentalClosingIsPrevented', checked);
          showSettingSaved(this.nodes.preventAccidentalCloseSaved);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.nodes.preventAccidentalClose.addEventListener('change', this);

        this.nodes.preventAccidentalClose.checked = await getSetting();
      }

      disconnectedCallback() {
        this.nodes.preventAccidentalClose.removeEventListener('change', this);
      }
    },
  );
});
