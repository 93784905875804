import { serviceWorkerLogger } from '@/lib/loggers.mjs';

import { remove } from '@/utils/dom.ts';

export let canBeInstalledAsPWA = false;
export let beforeInstallPromptEvent = null;

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();

  serviceWorkerLogger.info('not installed yet as PWA so assuming it runs in a tab');

  canBeInstalledAsPWA = true;
  beforeInstallPromptEvent = e;

  const isAlreadyOnTheSettingsPage = localStorage.getItem('purpose') === 'settings';
  const showSettingsDot = !localStorage.getItem('hideSettingsInstallPWANotificationDot');

  // Add a notification dot to the settings nav item,
  // it gets removed when the nav item is clicked.
  if (!isAlreadyOnTheSettingsPage && showSettingsDot) {
    const settingsNavLink = document.querySelector('[data-selector=settingsNavLink]');
    const dotNode = document.createElement('div');
    dotNode.classList.add('notification-indicator');
    settingsNavLink.appendChild(dotNode);
    settingsNavLink.addEventListener(
      'click',
      () => {
        localStorage.setItem('hideSettingsInstallPWANotificationDot', true);
        remove(dotNode);
      },
      { once: true },
    );
  }
});

window.addEventListener('appinstalled', () => {
  canBeInstalledAsPWA = false;
  beforeInstallPromptEvent = null;

  serviceWorkerLogger.info('installed and using as PWA');
});
