import * as settings from '@/lib/settings/remote.mjs';
import * as wakeLock from '@/lib/wakeLock.mjs';

import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import showSettingSaved from '@/utils/settingSaved.ts';

loadTemplate('c-wake-lock').then(({ content }) => {
  window.customElements.define(
    'c-wake-lock',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      async handleEvent(e) {
        switch (e.type) {
          case 'change':
            {
              const {
                currentTarget: { checked },
              } = e;
              await settings.set('wakeLock', checked);
              showSettingSaved(this.nodes.wakeLockText);
              if (checked) {
                wakeLock.request();
              } else {
                wakeLock.release();
              }
            }
            break;
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.nodes.wakeLock.addEventListener('change', this);

        this.nodes.wakeLock.checked = await settings.get('wakeLock');

        if (wakeLock.isSupported) {
          show(this);
        } else {
          hide(this);
        }
      }

      disconnectedCallback() {
        this.nodes.wakeLock.removeEventListener('change', this);
      }
    },
  );
});
