import request from '@/lib/request.mjs';

export default async function ({ resourceName, requestOptions }) {
  let allData = [];

  const { data, meta } = await request(resourceName, requestOptions);

  let metaInformation = meta;
  allData.push(...data);

  while (metaInformation?.links?.next) {
    requestOptions.params.page = metaInformation?.links?.next.params.page;
    const { data, meta } = await request(resourceName, requestOptions);
    metaInformation = meta;
    allData.push(...data);
  }
  return allData;
}
