import { translateNodes } from '@/lib/i18n.mjs';
import * as segment from '@/lib/segment.mjs';
import * as settings from '@/lib/settings/remote.mjs';

import { loadTemplate, remove, show } from '@/utils/dom.ts';
import { ActionsProxy } from '@/utils/elementProxies.mjs';

const MESSAGE_NAME_LOCAL_STORAGE = 'noMoreNetlifyMessage2022';
const linkToInfo = import.meta.env.VENDOR_NETLIFY_BLOG_POST;

loadTemplate('c-maintenance-message').then(({ content }) => {
  window.customElements.define(
    'c-maintenance-message',
    class extends HTMLElement {
      constructor() {
        super();
        this.actions = new ActionsProxy(this);
      }

      handleEvent(e) {
        switch (e.type) {
          case 'click': {
            segment.track.maintenanceMessageClosed();
            this.close();
            break;
          }
        }
      }

      close() {
        remove(this);
        localStorage.setItem(MESSAGE_NAME_LOCAL_STORAGE, 'true');
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        const today = new Date();
        const showUntilDate = new Date('May 27, 2022 23:59:00');
        if (today.getTime() <= showUntilDate.getTime() && !localStorage.getItem(MESSAGE_NAME_LOCAL_STORAGE)) {
          const chosenLanguage = await settings.get('language');
          // This is the way to only make the link show for Dutch speaking Voys clients :(
          if (!!linkToInfo && chosenLanguage === 'nl-NL') {
            this.actions.linkToMoreInformation.setAttribute('href', linkToInfo);
            this.actions.linkToMoreInformation.addEventListener(
              'click',
              () => {
                segment.track.maintenanceMessageLinkClicked();
              },
              { once: true },
            );
            show(this.actions.linkToMoreInformation);
          }
          show(this);
        }

        this.actions.closeMaintenanceMessage.addEventListener('click', this);

        // Since this component is not child node of main, we need to call translate nodes ourselves
        // Since the directives are instantiated on main
        translateNodes(this);
      }

      disconnectedCallback() {
        this.actions.closeMaintenanceMessage.removeEventListener('click', this);
      }
    },
  );
});
