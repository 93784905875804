import * as Sentry from '@sentry/browser';

import { sentryLogger } from './loggers.mjs';

const { VITE_SENTRY_DSN, VITE_BRAND, VITE_VERSION } = import.meta.env;

const user = {
  id: null,
};

/**
 * Initialize Sentry using the Sentry DSN provided in the config.
 * This will happen only once and immediately when this file is imported
 * to ensure that errors are logged as soon as possible.
 */
if (VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    release: VITE_VERSION,
    sampleRate: 0.5,
    integrations: [new Sentry.BrowserTracing()],
    initialScope: {
      tags: {
        brand: VITE_BRAND,
      },
    },
  });
  sentryLogger.info('sentry initialized');
} else {
  sentryLogger.warn('VITE_SENTRY_DSN environment variable is not set, errors will not be send to sentry');
}

/**
 * Set the user UUID for Sentry so that when an exception occurs it is also
 * logged.
 * @param {String} id - The UUID of the user, can be null.
 */
export function setUserId(id) {
  if (!VITE_SENTRY_DSN) {
    return;
  }

  user.id = id;
  Sentry.configureScope((scope) => scope.setUser(user));
  sentryLogger.info('sentry scope updated', { user });
}
