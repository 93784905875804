import { invite, isAbleToMakeCall } from '@/lib/calling.mjs';

import { disable, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents, mediaEvents } from '@/utils/eventTarget.ts';

loadTemplate('c-contact-number').then(({ content }) => {
  window.customElements.define(
    'c-contact-number',
    class extends HTMLElement {
      set data(phoneNumber) {
        this._phoneNumber = phoneNumber;
        this.populate();
      }

      constructor() {
        super();
        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      handleEvent(e) {
        e.preventDefault();
        const { currentTarget, type } = e;

        if (type === 'clientStatusUpdated' || type === 'microphonePermissionUpdated') {
          this.sortCallButtonVisiblity();
        } else {
          switch (currentTarget) {
            case this.actions.call:
              invite(this._phoneNumber);
              break;

            case this.actions.copy:
              if ('click' === type) {
                hide(this.nodes.copyLabel);
                show(this.nodes.copiedLabel);
                navigator.clipboard.writeText(this._phoneNumber);
              }
              if ('mouseout' === type) {
                show(this.nodes.copyLabel);
                hide(this.nodes.copiedLabel);
              }
              break;
          }
        }
      }

      populate() {
        if (this._phoneNumber) {
          this.nodes.contactNumber.textContent = this._phoneNumber;
        }
      }

      sortCallButtonVisiblity() {
        if (!isAbleToMakeCall) {
          disable(this.actions.call);
        } else {
          enable(this.actions.call);
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.actions.call.addEventListener('click', this);
        this.actions.copy.addEventListener('click', this);
        this.actions.copy.addEventListener('mouseout', this);

        this.sortCallButtonVisiblity();

        callingEvents.addEventListener('clientStatusUpdated', this);
        mediaEvents.addEventListener('microphonePermissionUpdated', this);
      }

      disconnectedCallback() {
        this.actions.call.removeEventListener('click', this);
        this.actions.copy.removeEventListener('click', this);
        this.actions.copy.removeEventListener('mouseout', this);

        callingEvents.removeEventListener('clientStatusUpdated', this);
        mediaEvents.removeEventListener('microphonePermissionUpdated', this);
      }
    },
  );
});
