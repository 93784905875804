import { logout } from '@/lib/user.mjs';

import { loadTemplate } from '@/utils/dom.ts';

loadTemplate('p-logout').then(({ content }) => {
  window.customElements.define(
    'p-logout',
    class extends HTMLElement {
      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        await logout();
      }
    },
  );
});
