import { translateNodes } from '@/lib/i18n.mjs';
import * as user from '@/lib/user.mjs';

import { loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.ts';

interface Nodes {
  dialog: HTMLDialogElement;
  close: HTMLButtonElement;
  supportLink: HTMLAnchorElement;
  textPreventWithLink: HTMLElement;
  textPreventWithoutLink: HTMLElement;
}

/*
 * This component solves the problem of audio not autoplaying in certain browsers.
 * It solves this by trying to create an audio context and show a dialog in case this audio context is not allowed.
 * Users should only see this dialog when they haven't installed the app and haven't allowed autoplaying sounds in their browsers.
 */
loadTemplate('c-audio-dialog').then(({ content }) => {
  window.customElements.define(
    'c-audio-dialog',
    class extends HTMLElement {
      nodes: Nodes;

      constructor() {
        super();
        this.nodes = NodesProxy<Nodes>(this);
      }

      async handleEvent(event: Event) {
        // The cancel event is being called when user presses Esc.
        // But pressing Esc doesn't count as a user interaction and doesn't enable the audio context.
        // This is why we don't allow closing the dialog with Esc.
        if (event.type === 'cancel') {
          event.preventDefault();
        }

        if (event.type === 'click') {
          this.nodes.dialog.close();
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        translateNodes(this);

        // Check if the user is logged in before showing the dialog.
        const userData = await user.getUserFromStorage();
        if (!userData) return;

        // If audio context is running fine we don't want to show the dialog.
        const audioContext = new AudioContext();
        if (audioContext.state !== 'suspended') return;

        this.nodes.dialog.addEventListener('cancel', this);
        this.nodes.close.addEventListener('click', this);

        // We are showing different text with/without the link,
        // depending on if the certain brand has a support link.
        if (import.meta.env.VITE_VENDOR_SUPPORT_WEBSITE_AUDIO_DIALOG) {
          show(this.nodes.supportLink);
          show(this.nodes.textPreventWithLink);
        } else {
          show(this.nodes.textPreventWithoutLink);
        }

        this.nodes.dialog.showModal();
        // By default the link would be focused in the dialog, drawing unwanted attention.
        // To prevent this we explicitly move focus to the dialog itself.
        this.nodes.dialog.focus();
      }

      disconnectedCallback() {
        this.nodes.close.removeEventListener('click', this);
        this.nodes.dialog.removeEventListener('cancel', this);
      }
    },
  );
});
