const parsers = new Map();

// Define a regular expression to match certain parts of a URI.
// Specifically, this regex will match everything before a '?' and after it, effectively removing the 'base' part of a URL.
const cleanRegexp = /^.*\?|^.*$/;

// A URI (Uniform Resource Identifier) is a string that identifies a particular resource, like a url, web page, file...
// Initialize the default uri to the current document's location (i.e., the current url)

/**
 * takes a URI, isolates its query parameters, parses them, and returns those parameters as an object.
 *
 * @cleanRegexp - cleans the input uri by matching and removing everything before the '?' and after it.
 * @param {uri} string - the uri to get the searchparams properties and values form
 * @returns {object} - an object containing all properties that could be found in the searchParams part of the URI.
 *
 */

export function searchParamsParse(uri = document.location.toString()) {
  try {
    // Create a URLSearchParams object from the URI after replacing matched portions of the URI using `cleanRegexp`.
    // This step is meant to isolate the searchParams (i.e., query parameters) from the URI for parsing.
    const searchParams = new URLSearchParams(uri.replace(cleanRegexp, ''));

    // Initialize an empty object to hold the parsed search parameters.
    const queryParams: { [key: string]: string } = {};

    for (const [name, value] of searchParams.entries()) {
      queryParams[name] = value;
    }

    // Return the object containing all parsed search parameters.
    return queryParams;
  } catch (e) {
    // If any exception occurs during the process, return an empty object.
    return {};
  }
}

/**
 * takes an object and converts it into a URL query string.
 *
 * @param {queryParams} Object - The object whose properties will be converted into a URL query string.
 * @returns {string} -  URL query string that represents the given object.
 */

export function searchParamsStringify(queryParams: { [key: string]: string }) {
  const searchParams = new URLSearchParams();

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];

    // Skip null values
    if (value === null) {
      return;
    }

    // If the value is an object, loop through its properties.
    // Then, add each property as a new query parameter under the same main key
    if ('object' === typeof value) {
      Object.keys(value).forEach((_key) => {
        searchParams.append(key, _key);
      });
    } else {
      // Otherwise, If the value is not an object, add it directly to the URL query.
      // Convert the value to a string first since URLSearchParams requires string values.
      searchParams.append(key, String(value));
    }
  });

  // Convert the URLSearchParams object to a query string and return it.
  return searchParams.toString();
}

// regexp to match property names with in templateStrings
// this needs to match optional and non optional placeholders...
const placeholderRegexp = /{(.*?)}/g;

/**
 * Takes a template string and finds all the placeholders.
 *
 * @param {string} templateString The string with placeholders, e.g. "/user/{name}/{age}?".
 * @returns {Array} A list of found placeholders with extra info.
 */

export function getPlaceholdersFromTemplate(templateString: string) {
  // 'currentMatch' holds the currently matched placeholder;
  let currentMatch;

  //'foundPlaceholders' stores all the discovered placeholders
  const foundPlaceholders = [];

  // Loop to find all placeholders in the template string
  while ((currentMatch = placeholderRegexp.exec(templateString)) !== null) {
    // Extract the placeholder name from the match (it's the second item in the array)
    let [, placeholder] = currentMatch;

    // Initialize a variable to check if the placeholder is optional
    let isOptional = false;

    // If the placeholder ends with '?', it's optional. Remove the '?'.
    if (placeholder.endsWith('?')) {
      isOptional = true;
      placeholder = placeholder.substring(0, placeholder.length - 1);
    }

    // Add the placeholder and additional information to the list.
    // This information helps us understand if the placeholder is optional and how to remove it from the string later.
    foundPlaceholders.push({
      // a regExp to easily remove this property until the end of the string
      // so we do not have to recreate it every time we check if the property is passed into getUrl
      // if any optional property isn't set, we assume it's alright to just skip the rest of the path
      removePlaceholderAndRest: new RegExp(`/?{${placeholder}\\?}.*`),
      isOptional,
      placeholder,
    });
  }

  return foundPlaceholders;
}

// regeExp used to match templateStrings to when parsing url's in the parser
const optionalPropertyNamesMatchRegExp = /\/?{[^?}]+\?}\/?/g;
const notOptionalPropertyNamesMatchRegExp = /{[^?}]+}/g;
/**
 * Creates a parser for getting values from URI or (path)
 * or creating URI (or path) from properties
 *
 * @param {templateId} string - Number of milliseconds to sleep.
 * @param {templateString} string - Number of milliseconds to sleep.
 */
export function createParser(templateId: string, templateString: string) {
  const placeholders = getPlaceholdersFromTemplate(templateString);

  const regExp = new RegExp(
    `^${templateString
      .replace(notOptionalPropertyNamesMatchRegExp, '([^/?]+)')
      .replace(optionalPropertyNamesMatchRegExp, '/?([^/?]+)?')}/?$`,
  );

  parsers.set(templateId, {
    templateString,
    placeholders,
    parser: function (path: string) {
      if (!regExp.test(path)) {
        throw new Error(`path ${path} does not test positive on ${regExp}`);
      }

      const regexpMatches = regExp.exec(path);
      if (!regexpMatches) throw new Error(`path ${path} does not match ${regExp}`);

      const values = regexpMatches.splice(1, placeholders.length);
      const properties = {};
      placeholders.forEach(({ placeholder }, i) => {
        Object.assign(properties, { [placeholder]: values[i] });
      });

      return properties;
    },
  });
}

/**
 * gets the properties from a URI based on a templateId
 *
 * @param {templateId} string - the templateId used to find the parser
 * @param {urlOrPath} string - the string from which to get the properties
 * @returns {object} - all propeties that could be found in the urlOrPath
 */
export function getProperties(templateId: string, urlOrPath: string) {
  if (!parsers.has(templateId)) {
    throw new Error(`missing parser for ${templateId}`);
  }

  const { parser } = parsers.get(templateId);

  return parser(urlOrPath);
}

/**
 * create and get a URI (or path) from an object of properties
 *
 * @param {templateId} string - templateId to use when creating the URI (or path).
 * @returns {String} - the URI (or path) with the values placed in the right spot.
 */

interface PlaceholderInfo {
  placeholder: string;
  isOptional: boolean;
  removePlaceholderAndRest: RegExp;
}

export function getUrl(templateId: string, properties: Record<string, unknown> = {}) {
  if (!parsers.has(templateId)) {
    throw new Error(`missing parser for ${templateId}`);
  }
  const { templateString, placeholders } = parsers.get(templateId);

  return placeholders.reduce((str: string, { placeholder, isOptional, removePlaceholderAndRest }: PlaceholderInfo) => {
    if (isOptional) {
      if (!(placeholder in properties) || 'undefined' === typeof properties[placeholder]) {
        return str.replace(removePlaceholderAndRest, '');
      } else {
        return str.replace(`{${placeholder}?}`, String(properties[placeholder]));
      }
    }

    if (!(placeholder in properties)) {
      throw new Error(`missing property ${placeholder}`);
    }

    return str.replace(`{${placeholder}}`, String(properties[placeholder]));
  }, templateString);
}

let params: Record<string, unknown> = {};
export function storeUrlParams(verifiedParams: Record<string, unknown>) {
  params = { ...verifiedParams };
}

export function getUrlParams(): Record<string, unknown> {
  // Just to make sure we don't accidentally change it we return a copy of the
  // object.
  return { ...params };
}

let lastPushedUrl: string;
export function updateUrl(url: string) {
  if (lastPushedUrl !== url) {
    history.pushState({}, '', url);
  }

  lastPushedUrl = url;
}
