const flatProperties = [
  '_id',
  '_givenName',
  '_familyName',
  '_companyName',
  'description',
  'isAppAccount',
  'phoneNumber',
  'accountId',
];

const nonFlatProperties = {
  _phoneNumbers: phoneNumbersReducer,
  _groups: groupsReducer,
};

function phoneNumbersReducer(acc, value) {
  return acc + value['phoneNumber'];
}

function groupsReducer(acc, value) {
  return acc + value['name'];
}

export default function flattenColltact(colltact) {
  const flattenedColltact = Object.create(null);

  for (const key of flatProperties) {
    if (key in colltact) {
      flattenedColltact[key] = colltact[key];
    }
  }

  for (const key in nonFlatProperties) {
    const reducer = nonFlatProperties[key];

    if (key in colltact) {
      flattenedColltact[key] = colltact[key].reduce(reducer, '');
    }
  }

  return flattenedColltact;
}
