import { beforeInstallPromptEvent } from '@/lib/install.mjs';
import { serviceWorkerLogger } from '@/lib/loggers.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { ActionsProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-install-button').then(({ content }) => {
  window.customElements.define(
    'c-install-button',
    class extends HTMLElement {
      constructor() {
        super();

        this.actions = new ActionsProxy(this);
      }

      handleEvent() {
        serviceWorkerLogger.info('clicked install button');
        beforeInstallPromptEvent.prompt();
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.actions.install.addEventListener('click', this);
      }

      disconnectedCallback() {
        this.actions.install.removeEventListener('click', this);
      }
    },
  );
});
