export default function formatToSipAccount(account) {
  const { id, accountId, password, description } = account;

  if (!password) {
    throw new Error('the account requires a password when used as a sip account');
  }

  return {
    id,
    password,
    user: accountId,
    uri: `sip:${accountId}@voipgrid.nl`,
    name: description,
  };
}
