import buildMockingPreferHeader from '@/utils/buildMockingPreferHeader.mjs';
import getOS from '@/utils/getOS.mjs';
import getVersion from '@/utils/getVersion.mjs';
import { getUrl } from '@/utils/url.ts';

import { PARSER_NAME_SUFFIX } from '@/constants/request.mjs';

export default function makeRequestObject(name, options, endpoints, requestParserMap = {}) {
  let config;
  let cacheKey;

  if (name in endpoints) {
    config = Object.assign({}, endpoints[name]);
  } else {
    throw new Error(`api config '${name}' not found`);
  }

  const requestOptions = Object.assign({ headers: {} }, config, options);

  if (requestOptions.useToken) {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('unauthorised');
    }
    requestOptions.headers['authorization'] = token;
  }

  if (requestOptions.useBearerToken) {
    const localToken = localStorage.getItem('token');
    if (!localToken) {
      throw new Error('unauthorised');
    }
    const strippedToken = localToken.split(':')[1];
    const token = `Bearer ${strippedToken}`;
    requestOptions.headers['Authorization'] = token;
  }

  if (name in requestParserMap) {
    requestOptions.path = getUrl(`${name}${PARSER_NAME_SUFFIX}`, options);
  }

  if (requestOptions.params) {
    const queryString = Object.keys(requestOptions.params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(requestOptions.params[k]))
      .join('&');

    requestOptions.path += `?${queryString}`;
  }

  if (requestOptions.body && typeof requestOptions.body === 'object') {
    requestOptions.body = JSON.stringify(requestOptions.body);
    requestOptions.headers['Content-Type'] = 'application/json';
  }

  let url = `${requestOptions.domain}${requestOptions.path}`;

  if (import.meta.env.VITE_MOCKING_ENABLED && requestOptions.mocking?.enabled) {
    requestOptions.headers['Prefer'] = buildMockingPreferHeader(requestOptions.mocking);
    requestOptions.headers['mockingId'] = requestOptions.mocking.id;
    url = `${import.meta.env.VITE_MOCKING_URL}${requestOptions.mocking.path}`;
  }

  if (requestOptions.segmentHeaders) {
    requestOptions.headers['X-SEGMENT-EVENT'] = requestOptions.segmentHeaders.event;
    requestOptions.headers['X-SEGMENT-CONTEXT-APP-NAME'] = requestOptions.segmentHeaders.app;
    requestOptions.headers['X-SEGMENT-CONTEXT-APPLICATION'] = 'webphone';
  }

  if (requestOptions.acceptHeaders) {
    requestOptions.headers['Accept'] = requestOptions.acceptHeaders;
  }

  if (requestOptions.addClientHeaders) {
    requestOptions.headers['X-Client-Type'] = 'web';
    requestOptions.headers['X-Client-Product'] = 'webphone';
    requestOptions.headers['X-Client-Version'] = getVersion();
    requestOptions.headers['X-Client-OS'] = getOS(navigator.userAgent);
    requestOptions.headers['X-Client-Brand'] = import.meta.env.VITE_BRAND;
  }

  if ('GET' === requestOptions.method && requestOptions.cache !== false) {
    cacheKey = url;
  }

  const defaultResponse = requestOptions.defaultResponse;
  const removeCache = requestOptions.forceRefresh;

  delete requestOptions.cache;
  delete requestOptions.forceRefresh;
  delete requestOptions.defaultResponse;
  delete requestOptions.domain;
  delete requestOptions.params;
  delete requestOptions.path;
  delete requestOptions.purgeCache;
  delete requestOptions.useToken;
  delete requestOptions.useBearerToken;

  return { url, requestOptions, cacheKey, removeCache, defaultResponse };
}
