import * as media from '@/lib/media.mjs';
import * as segment from '@/lib/segment.mjs';

import { loadTemplate } from '@/utils/dom.ts';

loadTemplate('c-volume-selection').then(({ content }) => {
  window.customElements.define(
    'c-volume-selection',
    class extends HTMLElement {
      handleEvent({ target }) {
        switch (target) {
          case this.masterSlider:
            media.setMasterVolume(target.value);
            segment.track.adjustMasterVolume();
            break;

          case this.ringtoneSlider:
            media.setRingtoneVolume(target.value);
            segment.track.adjustRingtoneVolume();
            break;

          case this.systemSlider:
            media.setSystemVolume(target.value);
            segment.track.adjustSystemVolume();
            break;

          case this.callingSlider:
            media.setCallingVolume(target.value);
            segment.track.adjustCallingVolume();
            break;
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.masterSlider = this.querySelector('[data-selector=master-volume-slider]');
        this.ringtoneSlider = this.querySelector('[data-selector=ringtone-volume-slider]');
        this.systemSlider = this.querySelector('[data-selector=system-volume-slider]');
        this.callingSlider = this.querySelector('[data-selector=calling-volume-slider]');

        this.masterSlider.addEventListener('input', this);
        this.ringtoneSlider.addEventListener('input', this);
        this.systemSlider.addEventListener('input', this);
        this.callingSlider.addEventListener('input', this);

        this.init();
      }

      async init() {
        this.masterSlider.value = await media.getMasterVolume();
        this.ringtoneSlider.value = await media.getRingtoneVolume();
        this.systemSlider.value = await media.getSystemVolume();
        this.callingSlider.value = await media.getCallingVolume();
      }

      disconnectedCallback() {
        this.masterSlider = this.removeEventListener('input', this);
        this.ringtoneSlider = this.removeEventListener('input', this);
        this.systemSlider = this.removeEventListener('input', this);
        this.callingSlider = this.removeEventListener('input', this);
      }
    },
  );
});
