import { dtmfEvents } from '@/utils/eventTarget.ts';

const numerics = new Map();
const specials = new Map();
let intercept = false;

// create a map to hold all standard characters we would like to capture
// this way we can distinguish between shortcuts like alt-4 and not trigger on those occurrences
// ;, should be implemented to support wait/pause instructions
'0123456789'.split('').forEach((key) => {
  numerics.set(key, true);
});

// create a map to hold all characters we would like to capture that need a modifier key
'#*'.split('').forEach((key) => {
  specials.set(key, true);
});

document.addEventListener(
  'focus',
  ({ target }) => {
    if ('INPUT' === target.nodeName) {
      intercept = true;
    }
  },
  true,
);
document.addEventListener(
  'blur',
  () => {
    intercept = false;
  },
  true,
);

document.addEventListener('keypress', (e) => {
  if (intercept) {
    return;
  }

  const { metaKey, ctrlKey, shiftKey, altKey, key } = e;

  if (specials.has(`${key}`)) {
    dtmfEvents.dispatchEvent(new CustomEvent('dtmf', { detail: key }));
    return;
  }

  if (metaKey || ctrlKey || shiftKey || altKey) {
    return;
  }

  if (numerics.has(`${key}`)) {
    dtmfEvents.dispatchEvent(new CustomEvent('dtmf', { detail: key }));
  }
});
