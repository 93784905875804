import getTemporaryRedirect from '@/lib/data/getTemporaryRedirect.mjs';
import getVoicemails from '@/lib/data/getVoicemails.mjs';
import validateIfUserHasAllPermissions from '@/lib/data/validateIfUserHasAllPermissions.mjs';
import { translate } from '@/lib/i18n.mjs';
import { getPlatformClientUrl } from '@/lib/platform.mjs';
import * as user from '@/lib/user.mjs';

import { deselect, disable, enable, hide, loadTemplate, select, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { getDateTimeString, getTimeString } from '@/utils/formatDate.ts';

import { temporaryRedirectPermissions } from '@/constants/userPermissions.mjs';

loadTemplate('c-temporary-redirect-setup').then(({ content }) => {
  window.customElements.define(
    'c-temporary-redirect-setup',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
        this.userData = null;
        this.selectedDate = null;
        this.selectedTime = null;
        this.combinedDateTime = new Date();
      }

      get selectedDate() {
        return this._selectedDate;
      }

      set selectedDate(value) {
        this._selectedDate = value;
        this.updateDateTime();
      }

      get selectedTime() {
        return this._selectedTime;
      }

      set selectedTime(value) {
        this._selectedTime = value;
        this.updateDateTime();
      }

      get combinedDateTime() {
        return this._combinedDateTime;
      }

      set combinedDateTime(value) {
        const oldValue = this._combinedDateTime;
        this._combinedDateTime = value;
        this.dispatchEvent(
          new CustomEvent('combined-date-time-changed', {
            detail: { oldValue, newValue: value },
            bubbles: true,
            composed: true,
          }),
        );
      }

      updateDateTime() {
        if (this.selectedDate && this.selectedTime) {
          const dateParts = this.selectedDate.split('-');
          const timeParts = this.selectedTime.split(':');
          const year = parseInt(dateParts[0]);
          const month = parseInt(dateParts[1]) - 1;
          const day = parseInt(dateParts[2]);
          const hours = parseInt(timeParts[0]);
          const minutes = parseInt(timeParts[1]);

          // Create a local date object
          const localDate = new Date(year, month, day, hours, minutes);

          this.combinedDateTime = localDate.toISOString(); // Save combined date and time as ISO string
        } else {
          this.combinedDateTime = null;
        }
      }

      async fetchAndUpdateDateTime() {
        const userData = await user.get();
        if (userData) {
          const response = await getTemporaryRedirect(userData.clientUuid);
          if (response && !response.available) {
            const combinedDateTime = new Date(response.end);

            // Update the component's state with the fetched date and time
            this.selectedDate = combinedDateTime.toISOString().substring(0, 10);
            this.selectedTime = getTimeString(combinedDateTime);

            // Update the input fields
            this.nodes.dateInput.value = this.selectedDate;
            this.nodes.timeInput.value = this.selectedTime;

            // Update the description
            this.updateDateDescription(this.selectedDate, this.selectedTime);
          }
        }
      }

      handleEvent({ currentTarget }) {
        const selectedVoicemail = this.nodes.voicemailSelect.options[this.nodes.voicemailSelect.selectedIndex];

        if (currentTarget === this.nodes.voicemailSelect) {
          this.setVoicemailDescriptionText(selectedVoicemail.text);
        } else if (currentTarget === this.nodes.dateInput) {
          const isValid = this.validateDate();
          if (isValid) {
            this.updateDateDescription(this.nodes.dateInput.value, this.nodes.timeInput.value);
          }
        } else if (currentTarget === this.nodes.timeInput) {
          this.updateDateDescription(this.nodes.dateInput.value, this.nodes.timeInput.value);
        }

        this.updateEnableTemporaryRedirectStatus();
      }

      async setInitialDateTime() {
        const userData = await user.get();
        if (userData) {
          const response = await getTemporaryRedirect(userData.clientUuid);
          if (response && !response.available) {
            const combinedDateTime = new Date(response.end);
            this.selectedDate = combinedDateTime.toISOString().substring(0, 10);
            this.selectedTime = getTimeString(combinedDateTime);
            this.updateDateDescription(this.selectedDate, this.selectedTime);
          } else {
            const localTime = new Date();
            localTime.setHours(localTime.getHours() + 1); // Add 1 hour to the local time
            const formattedTime = getTimeString(localTime);

            // Set the default selected date and time to the current date and time
            this.selectedDate = localTime.toISOString().substring(0, 10);
            this.selectedTime = formattedTime;

            // Format the date value properly for the input field and set the minimum selectable date
            this.nodes.dateInput.value = this.selectedDate;
            this.nodes.dateInput.min = this.selectedDate;

            this.nodes.timeInput.value = this.selectedTime;
            this.updateDateDescription(this.nodes.dateInput.value, this.nodes.timeInput.value);

            this.validateDate();
          }
        }
      }

      async updateDateDescription(date, time) {
        if (date && time) {
          const combinedDateTime = new Date(`${date}T${time}`);
          this.combinedDateTime = combinedDateTime;
          const formattedDateTime = await getDateTimeString(combinedDateTime);

          this.nodes.dateSelected.textContent = formattedDateTime;
        }

        this.selectedDate = date;
        this.selectedTime = time;
      }

      validateDate() {
        const currentDate = new Date();
        const selectedDate = this.nodes.dateInput.value;
        const selectedTime = this.nodes.timeInput.value;
        const selectedDateTime = new Date(`${selectedDate}T${selectedTime}`);

        const isDateInvalid = selectedDateTime < currentDate;

        if (isDateInvalid) {
          if (selectedDate < currentDate.toISOString().substring(0, 10)) {
            show(this.nodes.datePickerError);
            this.nodes.dateInput.classList.add('date-picker-error');
            hide(this.nodes.timePickerError);
          } else {
            hide(this.nodes.datePickerError);
            this.nodes.dateInput.classList.remove('date-picker-error');
          }

          if (selectedTime < currentDate.toTimeString().substring(0, 5)) {
            show(this.nodes.timePickerError);
            this.nodes.timeInput.classList.add('time-picker-error');
            hide(this.nodes.datePickerError);
          } else {
            hide(this.nodes.timePickerError);
            this.nodes.timeInput.classList.remove('time-picker-error');
          }

          if (
            selectedDate === currentDate.toISOString().substring(0, 10) &&
            selectedTime === currentDate.toTimeString().substring(0, 5)
          ) {
            enable(this.actions.enableTemporaryRedirect);
          } else {
            disable(this.actions.enableTemporaryRedirect);
          }

          return false;
        } else {
          hide(this.nodes.datePickerError);
          hide(this.nodes.timePickerError);
          enable(this.actions.enableTemporaryRedirect);
          this.nodes.dateInput.classList.remove('date-picker-error');
          this.nodes.timeInput.classList.remove('time-picker-error');
          return true;
        }
      }

      updateEnableTemporaryRedirectStatus() {
        if (this.selectedDate && this.selectedTime && this.validateDate()) {
          enable(this.actions.enableTemporaryRedirect);
        } else {
          disable(this.actions.enableTemporaryRedirect);
        }
      }

      async setVoicemailDescriptionText(selectedVoicemailName) {
        this.nodes.temporaryRedirectVoicemail.textContent = selectedVoicemailName;
      }

      selectVoicemail(id) {
        // Deselect all and select the right option after
        Array.from(this.nodes.voicemailSelect.options).forEach((option) => {
          deselect(option);
        });

        Array.from(this.nodes.voicemailSelect.options).forEach((option) => {
          if (parseInt(option.value) === id) {
            select(option);
          }
        });
      }

      async populateSelect() {
        this.userData = await user.get();
        const { data } = await getVoicemails(this.userData.clientId);
        data.items.forEach((voicemail) => {
          const option = document.createElement('option');
          option.value = voicemail.id;
          option.textContent = voicemail.name;
          this.nodes.voicemailSelect.appendChild(option);
        });
        const initialSelectedNode = this.nodes.voicemailSelect.options[1];
        if (initialSelectedNode) {
          select(initialSelectedNode);
          this.setVoicemailDescriptionText(initialSelectedNode.text);
          this.updateEnableTemporaryRedirectStatus();
        } else {
          const option = document.createElement('option');
          option.textContent = translate('no_voicemails_found');
          show(this.nodes.addVoicemailLink);
          this.actions.redirectToVoicemailPage.href = await getPlatformClientUrl('voicemail/');
          this.nodes.voicemailSelect.appendChild(option);
          select(this.nodes.voicemailSelect.options[1]);
        }
      }

      async setupLoader() {
        const loadingOption = document.createElement('option');
        loadingOption.textContent = translate('loading');
        disable(loadingOption);
        select(loadingOption);
        hide(loadingOption);
        this.nodes.voicemailSelect.appendChild(loadingOption);
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));
        this.setInitialDateTime();
        this.updateDateDescription(this.nodes.dateInput.value, this.nodes.timeInput.value);
        disable(this.actions.enableTemporaryRedirect);
        const hasPermissions = await validateIfUserHasAllPermissions(temporaryRedirectPermissions);
        if (hasPermissions) {
          await this.setupLoader();
          await this.populateSelect();
        }
        this.nodes.voicemailSelect.addEventListener('change', this);
        this.nodes.dateInput.addEventListener('change', this);
        this.nodes.timeInput.addEventListener('change', this);
        this.setInitialDateTime();
        this.fetchAndUpdateDateTime();
      }

      disconnectedCallback() {
        this.nodes.voicemailSelect.removeEventListener('change', this);
        this.nodes.dateInput.removeEventListener('change', this);
        this.nodes.timeInput.removeEventListener('change', this);
      }
    },
  );
});
