import * as contacts from '@/lib/contacts.mjs';
import * as favorites from '@/lib/favorites.mjs';
import { Logger } from '@/lib/logging.mjs';

import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { navigate } from '@/utils/history.ts';
import { getUrlParams } from '@/utils/url.ts';

const logger = new Logger('contact-view');

loadTemplate('p-contact').then(({ content }) => {
  window.customElements.define(
    'p-contact',
    class extends HTMLElement {
      set contactInstance(contact) {
        this._contactInstance = contact;
        this.populate(contact);
      }

      get contactInstance() {
        return this._contactInstance;
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      handleEvent({ currentTarget }) {
        switch (currentTarget) {
          case this.actions.contactDelete:
            show(this.nodes.contactDeleteConfirmationModal);
            break;

          case this.actions.abortDelete:
            hide(this.nodes.contactDeleteConfirmationModal);
            break;

          case this.actions.confirmDelete:
            if (this.contactInstance) {
              this.contactInstance
                .delete()
                .then(() => {
                  const isFavorite = favorites.contains(this.contactInstance.id);
                  // When we update a favorite contact we should update the
                  // hydrated favorites too.
                  isFavorite && favorites.hydrateFavorites();
                })
                .then(() => {
                  navigate('/contacts');
                })
                .catch(logger.error);
            }
            break;

          case this.actions.back:
            window.history.back();
            break;

          default:
            break;
        }
      }

      populate({ id, name, fullName, companyName, phoneNumbers }) {
        this.nodes.contactName.textContent = name;
        this.nodes.contactCompany.textContent = companyName;
        this.nodes.contactNamePopup.textContent = `${fullName} `;
        this.nodes.contactEditLink.setAttribute('path', `/contact/${id}/edit`);

        for (let { phoneNumber } of phoneNumbers) {
          const numberNode = document.createElement('c-contact-number');
          this.nodes.contactNumbers.appendChild(numberNode);

          numberNode.data = phoneNumber;
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.classList.add('loading');

        contacts
          .one(getUrlParams().id)
          .then((contact) => {
            this.contactInstance = contact;
            this.classList.remove('loading');
          })
          .catch(logger.error);

        [this.actions.contactDelete, this.actions.confirmDelete, this.actions.abortDelete, this.actions.back].forEach(
          (n) => n.addEventListener('click', this),
        );
      }
      disconnectedCallback() {
        [this.actions.contactDelete, this.actions.confirmDelete, this.actions.abortDelete, this.actions.back].forEach(
          (n) => n.removeEventListener('click', this),
        );
      }
    },
  );
});
