import * as cache from '@/lib/request/cache.mjs';
import makeRequestObject from '@/lib/request/makeRequestObject.mjs';
import responseHandler from '@/lib/request/responseHandler.mjs';

import { requestEvents } from '@/utils/eventTarget.ts';
import { createParser } from '@/utils/url.ts';

import { CACHE_TTL, PURGE_ALL_ENDPOINTS } from '@/constants/cache.mjs';
import endpoints from '@/constants/endpoints.mjs';
import { DEFAULT_REQUEST_TIMEOUT, PARSER_NAME_SUFFIX } from '@/constants/request.mjs';

const endpointsToUrlMap = {};
{
  const pathWithoutVarsRegexp = /\{.*$/;
  for (const [key, config] of Object.entries(endpoints)) {
    if ('GET' === config.method && ('undefined' === typeof config.cache || config.cache)) {
      const pathWithoutVars = config.path.replace(pathWithoutVarsRegexp, '');
      endpointsToUrlMap[key] = `${config.domain}${pathWithoutVars}`;
    }
  }
}

// keep track of the endpoints that have dynamically created URI's
// so we can easily determine for which calls we need to call the getUrl function
// to create a specific url for that single request
const hasParser = {};

Object.entries(endpoints).forEach(([name, { path }]) => {
  if (path.includes('{')) {
    createParser(`${name}${PARSER_NAME_SUFFIX}`, path);

    // since parsers are cached in the url module we minimise
    // the chances for naming collisions by adding a suffix
    hasParser[name] = undefined;
  }
});

export default function request(name, options) {
  let resolve, reject;
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });

  const endpoint = endpoints[name];

  if ('purgeCache' in endpoint) {
    if (endpoint.purgeCache === PURGE_ALL_ENDPOINTS) {
      cache.purge();
    } else {
      endpoint.purgeCache.forEach((purgeEndpoint) => {
        if (purgeEndpoint in endpointsToUrlMap) {
          cache.remove(endpointsToUrlMap[purgeEndpoint]);
        }
      });
    }
  }

  let requestObject;
  try {
    requestObject = makeRequestObject(name, options, endpoints, hasParser);
  } catch (err) {
    // In case when the endpoint requires a token but the token is not found in localStorage then
    // short circuit this function by returning unauthorized to prevent doing the request and getting
    // a 401 - unauthorized response.
    if (err.message === 'unauthorised') {
      requestEvents.dispatchEvent(new CustomEvent('unauthorised'));
    }
    reject(err);
    return promise;
  }

  const { cacheKey, removeCache, defaultResponse, url, requestOptions } = requestObject;

  if (cacheKey) {
    if (removeCache) {
      cache.remove(cacheKey);
    }

    const cachedItem = cache.get(cacheKey);

    if (cachedItem) {
      resolve(cachedItem);
      return promise;
    }

    cache.set(cacheKey, { devalidateAt: new Date().getTime() + CACHE_TTL, promise });
  }

  // make our own timeout and return the default response when hitting that timeout
  const timeout = window.setTimeout(() => resolve(defaultResponse), DEFAULT_REQUEST_TIMEOUT);

  fetch(url, requestOptions)
    .then((response) => responseHandler(response, requestOptions))
    .then((data) => {
      window.clearTimeout(timeout);
      resolve(data);
    })
    .catch((err) => {
      if (typeof defaultResponse !== 'undefined') {
        resolve(defaultResponse);
        return;
      }

      reject(err);
    });

  return promise;
}
