import { clientStatus, getSession, invite, isAbleToMakeCall } from '@/lib/calling.mjs';
import * as favorites from '@/lib/favorites.mjs';
import { blfLogger } from '@/lib/loggers.mjs';
import * as segment from '@/lib/segment.mjs';
import { getSubscription, hasSubscription } from '@/lib/subscriptions.mjs';

import doesMatchSearchString from '@/utils/doesMatchSearchString.ts';
import { disable, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents, mediaEvents, subscriptionEvents } from '@/utils/eventTarget.ts';
import getSearchValuesFromModel from '@/utils/getSearchValuesFromModel.mjs';
import { camelToKebab } from '@/utils/string.ts';

const SEARCH_THESE_PROPERTIES = ['description', 'phoneNumber', 'accountId'];

// since we do not know the actual status of other accounts if we have no voipaccount or are connecting or recovering
// (without SIP subscription) we show other accounts as unknown, and only show them with a specific status when we're
// sure we know what status they have based on subscription data from SIP. So until we have subscription data we just
// indicate we do not know the actual status of accounts
const UNKNOWN_CLIENT_STATUSES = ['connecting', 'recovering', 'disconnecting', 'disconnected', undefined];

const indicatorAttributesMap = {
  available: {
    icon: 'phone-office',
  },
  offline: {
    icon: 'phone-office',
  },
  busy: {
    icon: 'phone-volume',
  },
  ringing: {
    icon: 'bell',
  },
  appAccount: {
    icon: 'smartphone',
  },
  dnd: {
    icon: 'bell-alt',
  },
  unknown: {
    icon: 'none',
  },
};

loadTemplate('c-account').then(({ content }) => {
  window.customElements.define(
    'c-account',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['session-id'];
      }

      set showOnlyOnlineContacts(showOnlyOnlineContacts) {
        this._showOnlyOnlineContacts = showOnlyOnlineContacts;
      }

      get showOnlyOnlineContacts() {
        return this._showOnlyOnlineContacts;
      }

      set data(data) {
        this._data = data;
        this.populate();
        this.searchValues = getSearchValuesFromModel(data, SEARCH_THESE_PROPERTIES);
      }

      get data() {
        return this._data;
      }

      get phoneNumber() {
        return this.data.phoneNumber;
      }

      set presenceIndicator(presence) {
        this._presenceIndicator = presence;
      }

      get presenceIndicator() {
        return camelToKebab(this._presenceIndicator);
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);

        this._presenceIndicator = '';
      }

      async handleEvent({ type, detail: { status }, currentTarget }) {
        switch (type) {
          case `notify-${this.data.accountId}`:
            this._data.status = status;
            this.updateVoipaccountPresence(true);

            break;

          case 'clientStatusUpdated':
            this.updateVoipaccountPresence();
            this.sortCallButtonVisibility();
            break;

          case 'microphonePermissionUpdated':
            this.sortCallButtonVisibility();
            break;

          case 'click':
            switch (currentTarget) {
              case this.actions.call:
                {
                  segment.track.callContact();
                  const path = document.location.pathname.split('/')[1];
                  history.pushState(undefined, '', `/${path}`);
                  invite(this.data.phoneNumber);
                }
                break;

              case this.actions.favorite:
                segment.track.favoriteToggle();
                await favorites.toggle(this.data.accountId);
                this.sortIsFavorite();
                break;
            }
        }
      }

      doesMatchSearchString(query = '') {
        return doesMatchSearchString(this.searchValues, query);
      }

      populate() {
        if (this.data && this.isConnected) {
          const { description, phoneNumber, accountId } = this.data;
          // If there is a subscription for this accountId, the api status
          // can be overridden by the sip status.
          if (hasSubscription(accountId)) {
            const subscription = getSubscription(accountId);
            if (subscription) {
              this._data.status = subscription;
            }
          }

          this.nodes.contactName.textContent = description;
          this.nodes.contactNumber.textContent = phoneNumber;

          this.updateVoipaccountPresence();
        }
      }

      sortCallButtonVisibility() {
        if (!isAbleToMakeCall) {
          disable(this.actions.call);
        } else {
          enable(this.actions.call);
        }
      }

      sortIsFavorite() {
        if (this.hasAttribute('favoritable') && favorites.contains(this.data.accountId)) {
          show(this.nodes.favoriteIndicator);
        } else {
          hide(this.nodes.favoriteIndicator);
        }

        if (this.hasAttribute('favoritable')) {
          show(this.actions.favorite);
        } else {
          hide(this.actions.favorite);
        }
      }

      shouldShow() {
        if (this.showOnlyOnlineContacts && this.data.status === 'offline') {
          return false;
        } else {
          return true;
        }
      }

      getPresenceIndicator() {
        if ('offline' === this.data.status) {
          return 'offline';
        }
        if (!this.data.status && UNKNOWN_CLIENT_STATUSES.includes(clientStatus)) {
          if (this.data.isAppAccount) {
            return 'appAccount';
          }
          return 'unknown';
        }
        if (this.data.isAppAccount && this.data.status !== 'ringing' && this.data.status !== 'busy') {
          return 'appAccount';
        } else {
          return this.data.status || 'unknown';
        }
      }

      updateVoipaccountPresence() {
        const presenceIndicator = this.getPresenceIndicator();

        // If the indicator is the same, no need to change it.
        if (presenceIndicator === this._presenceIndicator) {
          return;
        }

        this.presenceIndicator = presenceIndicator;

        if (this.nodes.presenceIndicator) {
          this.nodes.presenceIndicator.className = `${this.initialPresenceIndicatorClassName} ${this.presenceIndicator}`;
        }
        if (this.nodes.presenceLabel) {
          this.nodes.presenceLabel.className = `${this.initialPresenceLabelClassName} ${this.presenceIndicator}`;
        }
        // icon cannot be looked up using kebab notation.
        const { icon } = indicatorAttributesMap[this._presenceIndicator] || {};

        if (icon) {
          this.nodes.presenceIcon.setAttribute('icon', icon);
          blfLogger.info(`c-account card icon changed to ${icon}`, { accountId: this.data.accountId });
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.initialPresenceIndicatorClassName = this.nodes.presenceIndicator.className;
        this.initialPresenceLabelClassName = this.nodes.presenceLabel.className;

        this.actions.call.addEventListener('click', this);
        this.actions.favorite.addEventListener('click', this);

        this.populate();
        this.sortCallButtonVisibility();
        this.sortIsFavorite();

        if (this.session) {
          this.nodes.callButtonIcon.setAttribute('icon', 'transfer');
        } else {
          this.nodes.callButtonIcon.setAttribute('icon', 'phone');
        }

        subscriptionEvents.addEventListener(`notify-${this.data.accountId}`, this);
        callingEvents.addEventListener('clientStatusUpdated', this);
        mediaEvents.addEventListener('microphonePermissionUpdated', this);
      }

      disconnectedCallback() {
        this.actions.call.removeEventListener('click', this);
        this.actions.favorite.removeEventListener('click', this);

        subscriptionEvents.removeEventListener(`notify-${this.data.accountId}`, this);
        callingEvents.removeEventListener('clientStatusUpdated', this);
        mediaEvents.removeEventListener('microphonePermissionUpdated', this);
      }

      attributeChangedCallback(name, oldValue, newValue) {
        // If the contact is part of a session it means we are
        // in transfer mode.
        this.session = getSession(newValue);
      }
    },
  );
});
