import { getUserAvailabilities } from '@/lib/data/getUserAvailabilities.ts';
import { UserAvailabilityModel } from '@/lib/types/UserAvailabilityModel.ts';

/**
 * Get the availability of a specific user.
 *
 * This endpoint returns UserAvailability model that holds initial data and can be used to
 * listen for changes for that specific user.
 */
export async function getUserAvailability(uuid: string) {
  const users = await getUserAvailabilities();

  if (users) {
    return (users.toArray() as Array<UserAvailabilityModel>).find(({ user_uuid }) => user_uuid === uuid);
  }
}
