import * as colltacts from '@/lib/colltacts.mjs';
import * as contactBook from '@/lib/contacts.mjs';

import { getDisplayDateAndTime } from '@/utils/formatDate.ts';
import getNameAndNumberFromCall from '@/utils/getNameAndNumberFromCall.mjs';

export default async function ({ data }) {
  const contacts = await contactBook.all();

  return data.map(({ id, from, to, direction, start_time, duration_in_seconds, answered, is_answered_elsewhere }) => {
    let callerInfo;

    if (direction === 'incoming') {
      callerInfo = getNameAndNumberFromCall(from);
    } else {
      callerInfo = getNameAndNumberFromCall(to);
    }

    const colltact = colltacts.find(callerInfo.phoneNumber, contacts);
    if (typeof colltact !== 'undefined') {
      callerInfo.callerName = colltact.fullName;
    }

    let status;
    let answeredElsewhereName;
    if (is_answered_elsewhere) {
      status = 'answered-elsewhere';
      const answeredBy = getNameAndNumberFromCall(to);
      if (answeredBy.callerName) {
        answeredElsewhereName = answeredBy.callerName;
      }
    } else if (!answered) {
      status = 'missed';
    } else {
      status = direction;
    }

    const isWebphoneCall = from.type === 'webphone' || to.type === 'webphone';

    return {
      id,
      ...callerInfo,
      status,
      duration: duration_in_seconds,
      isWebphoneCall,
      callDate: getDisplayDateAndTime(new Date(start_time)),
      answeredElsewhereName,
    };
  });
}
