import request from '@/lib/request.mjs';
import { getUserFromStorage } from '@/lib/user.mjs';

import getSearchValuesFromModel from '@/utils/getSearchValuesFromModel.mjs';
import { removeCountryCodeRegExp } from '@/utils/regexp.ts';
import replaceSpecialCharacters from '@/utils/replaceSpecialCharacters.mjs';
import { keysToSnake } from '@/utils/string.ts';

import Group from '@/models/Group.mjs';
import PhoneNumber from '@/models/PhoneNumber.mjs';

export default class Contact {
  get id() {
    return this._id;
  }

  get name() {
    if (!this.givenName) {
      return `${this.familyName}`;
    } else {
      return `${this.givenName} ${this.familyName}`;
    }
  }

  get fullName() {
    if (!this.name && this.companyName) {
      return this.companyName;
    }
    if (this.name && this.companyName) {
      return `${this.name} (${this.companyName})`;
    }
    return this.name;
  }

  get givenName() {
    return this._givenName;
  }
  set givenName(v) {
    this.internalDirtyState.givenName = v;
  }

  get familyName() {
    return this._familyName;
  }
  set familyName(v) {
    this.internalDirtyState.familyName = v;
  }

  get companyName() {
    return this._companyName;
  }
  set companyName(v) {
    this.internalDirtyState.companyName = v;
  }

  get phoneNumbers() {
    return this._phoneNumbers;
  }
  set phoneNumbers(v) {
    this.internalDirtyState.phoneNumbers = v;
  }

  get firstPhoneNumber() {
    return this.phoneNumbers[0];
  }

  get hasPhoneNumber() {
    return typeof this.firstPhoneNumber !== 'undefined';
  }

  get groups() {
    return this._groups;
  }
  set groups(v) {
    this.internalDirtyState.groups = v;
  }

  constructor({ id, givenName = '', familyName = '', companyName = '', phoneNumbers = [], groups = [] } = {}) {
    this._givenName = givenName;
    this._familyName = familyName;
    this._companyName = companyName;
    this._phoneNumbers = phoneNumbers.map((data) => new PhoneNumber(data));
    this._groups = groups.map((data) => new Group(data));

    Object.defineProperties(this, {
      _id: {
        enumerable: true,
        configurable: false,
        writable: false,
        value: id,
      },
      internalDirtyState: {
        enumerable: false,
        configurable: false,
        writable: false,
        value: Object.create(null),
      },
    });

    this._deleted = false;
  }

  serialize() {
    return {
      id: this.id,
      givenName: this.givenName,
      familyName: this.familyName,
      companyName: this.companyName,
      phoneNumbers: this.phoneNumbers.map((p) => p.serialize()),
      groups: this.groups.map((g) => g.serialize()),
    };
  }

  async save() {
    if (this._deleted) {
      throw new Error(`This contact with id ${this.id} is already deleted`);
    }

    const { clientUuid } = await getUserFromStorage();
    await request('contactUpdate', {
      contact_id: this.id,
      client_uuid: clientUuid,
      body: keysToSnake(this.internalDirtyState),
    });

    Object.keys(this.internalDirtyState).forEach((key) => {
      this[key] = this.internalDirtyState[key];
    });

    Object.keys(this.internalDirtyState).forEach((key) => {
      delete this.internalDirtyState[key];
    });
  }

  async delete() {
    const { clientUuid } = await getUserFromStorage();
    await request('contactDelete', { contact_id: this.id, client_uuid: clientUuid });
    this._deleted = true;
  }

  getSearchValues() {
    return [
      ...getSearchValuesFromModel(this, ['givenName', 'familyName', 'companyName', 'name', 'fullName']),

      ...this.phoneNumbers.reduce((prev, phoneNumberInstance) => {
        if (typeof phoneNumberInstance.phoneNumber !== 'undefined') {
          prev.push(phoneNumberInstance.phoneNumber);
          prev.push(phoneNumberInstance.phoneNumber.replace(removeCountryCodeRegExp, '0'));
        }
        return prev;
      }, []),

      ...this.groups.reduce((prev, groupInstance) => {
        prev.push(groupInstance.name);
        prev.push(replaceSpecialCharacters(groupInstance.name));
        return prev;
      }, []),
    ];
  }
}
