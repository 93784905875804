import { getSessions } from '@/lib/calling-base.mjs';
import { serviceWorkerLogger } from '@/lib/loggers.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { serviceWorkerEvents } from '@/utils/eventTarget.ts';

loadTemplate('c-updater').then(({ content }) => {
  window.customElements.define(
    'c-updater',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
        this.timerId = null;
      }

      handleEvent({ type }) {
        switch (type) {
          case 'updateAvailable':
            serviceWorkerLogger.info('Update available. Waiting for the right time to update.');
            this.trackWhenUserIsInactive();
            break;
        }
      }

      // This function tracks if the user is active
      // by adding listeners for any mouse, keyboard, scroll, and click events.
      trackWhenUserIsInactive() {
        document.addEventListener('mousemove', this.handleUserEvent.bind(this));
        document.addEventListener('keydown', this.handleUserEvent.bind(this));
        document.addEventListener('scroll', this.handleUserEvent.bind(this));
        document.addEventListener('click', this.handleUserEvent.bind(this));
      }

      // This code handles user events
      // It is used to reload the page if the user has been inactive for a while
      // timerId is a variable used to keep track of the timer
      // when the timer expires, the page is reloaded
      handleUserEvent() {
        const minimumTimeAUserShouldBeInactive = 1000 * 60; // 1 minute
        clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          const hasSessions = getSessions().length !== 0;

          if (hasSessions) {
            serviceWorkerLogger.info(`User has sessions, not reloading: ${this.timerId}`);

            // If we have sessions, we reset the timer to 60 seconds

            // Possible optimisation for the following scenario:
            // A user calls the user with the update
            // The user with the update takes the call and calls for a few minutes
            // meanwhile this user has their updates stopped because of this call, but doesn't perform
            // any user actions. If the other user hangs up the call, and this user doesn't do anything
            // it could be that the timer was about to expire again and the user sees a reload between
            // 1 - 60 seconds.

            // A small optimisation could be that we listen to when a session ends to always reset
            // the timer to 60 seconds
            this.handleUserEvent();
          } else {
            serviceWorkerLogger.info(`User does not have sessions, reloading : ${this.timerId}`);
            // We set DO_NOT_PREVENT_UNLOAD to true so that the page can be reloaded
            // with this variable we bypass the check in shouldPreventUnload.mjs
            window.DO_NOT_PREVENT_UNLOAD = true;
            window.location.reload();
          }
        }, minimumTimeAUserShouldBeInactive);
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        serviceWorkerEvents.addEventListener('updateAvailable', this);
      }

      disconnectedCallback() {
        serviceWorkerEvents.removeEventListener('updateAvailable', this);
      }
    },
  );
});
