import { hide, loadTemplate, remove, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';

import { windowChosen } from '@/sw-base.mjs';

loadTemplate('c-multiple-windows').then(({ content }) => {
  window.customElements.define(
    'c-multiple-windows',
    class extends HTMLElement {
      handleEvent({ currentTarget }) {
        if (currentTarget === this.actions.continueButton) {
          windowChosen();
          remove(this);
        }
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        if (localStorage.getItem('active_session')) {
          show(this.nodes.activeSessionInOtherTab);
          hide(this.nodes.defaultSituation);
        }

        this.actions.continueButton.addEventListener('click', this);
      }

      disconnectedCallback() {
        this.actions.continueButton.removeEventListener('click', this);
      }
    },
  );
});
