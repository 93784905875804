import getOpeningHoursBasic from '@/lib/data/getOpeningHoursBasic.mjs';
import validateWhichPermissionsUserHas from '@/lib/data/validateWhichPermissionsUserHas.mjs';
import { getPlatformClientUrl } from '@/lib/platform.mjs';

import { hide, loadTemplate } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';

import {
  callsPermission,
  dialPlanPermission,
  openingHoursBasicPermission,
  statisticsPermission,
} from '@/constants/userPermissions.mjs';

const PLATFORM_LINKS = {
  calls: {
    translation: 'calls',
    path: 'call',
    permission: callsPermission,
  },
  dialPlan: {
    translation: 'dial_plan',
    path: 'routing',
    permission: dialPlanPermission,
  },
  openingHoursBasic: {
    translation: 'opening_hours_basic',
    path: 'openinghoursbasic',
    permission: openingHoursBasicPermission,
    useUuid: true,
  },
  statistics: {
    translation: 'statistics',
    path: 'stats/dashboard',
    permission: statisticsPermission,
  },
};

loadTemplate('c-voip-platform').then(({ content }) => {
  window.customElements.define(
    'c-voip-platform',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      async populate(whichPermissionsUserHas) {
        const hasOpeningHoursBasicPermission = whichPermissionsUserHas.allowed.includes(
          'openinghours.view_openinghoursgroup',
        );

        let openingHoursBasicList = [];
        const openingHoursBasicLink = { ...PLATFORM_LINKS['openingHoursBasic'] };

        if (hasOpeningHoursBasicPermission) {
          openingHoursBasicList = await getOpeningHoursBasic();
          if (openingHoursBasicList.length === 1) {
            const id = openingHoursBasicList[0].id;
            openingHoursBasicLink.path = `openinghoursbasic/${id}/change`;
          }
        }

        Object.keys(PLATFORM_LINKS).forEach(async (platformLink) => {
          let { path, useUuid, translation } = PLATFORM_LINKS[platformLink];

          if (platformLink === 'openingHoursBasic' && openingHoursBasicList.length >= 1) {
            path = openingHoursBasicLink.path;
          }

          const href = await getPlatformClientUrl(path, useUuid);

          const platformLinkNode = document.createElement('c-platform-link');
          this.nodes.platformList.appendChild(platformLinkNode);
          hide(platformLinkNode);

          platformLinkNode.data = { href, translation };

          const allowed = whichPermissionsUserHas.allowed.includes(PLATFORM_LINKS[platformLink].permission);
          platformLinkNode.setAttribute('user-permission', allowed);
        });
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        const whichPermissionsUserHas = await validateWhichPermissionsUserHas([
          callsPermission,
          dialPlanPermission,
          openingHoursBasicPermission,
          statisticsPermission,
        ]);

        this.populate(whichPermissionsUserHas);
      }
    },
  );
});
