import * as segment from '@/lib/segment.mjs';
import * as temp from '@/lib/temporary-storage.mjs';
import * as user from '@/lib/user.mjs';

import { getFormValues, hide, loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('p-login').then(({ content }) => {
  window.customElements.define(
    'p-login',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
      }

      handleEvent(e) {
        e.preventDefault();
        const { target } = e;

        switch (e.type) {
          case 'submit': {
            const { email, password, token } = getFormValues(target);

            [
              this.nodes.authenticationError,
              this.nodes.twoFactorContainer,
              this.nodes.twoFactorAuthenticationError,
            ].forEach(hide);

            if (email && password) {
              const payload = { email, password };
              if (token) {
                payload.token = token;
              }

              user
                .login(payload)
                // if you're looking for the place the application navigates
                // after logging in... the loggedIn event dipatched on
                // userEvent will make the router navigate to the /dialer
                .then(user.get)
                .then(({ email: hasUser }) => {
                  if (!hasUser) {
                    temp.set('ZZZZZ', btoa(password));
                  }
                })
                .then(() => {
                  const fromClickToDial = localStorage.getItem('linked_from_click_to_dial');
                  if (fromClickToDial) {
                    segment.track.fromClickToDial({ toPage: fromClickToDial });
                    localStorage.removeItem('linked_from_click_to_dial');
                  }
                })
                .catch(({ status, body }) => {
                  if (status === 400 && 'apitoken' in body && 'two_factor_token' in body.apitoken) {
                    show(this.nodes.twoFactorContainer);
                    this.nodes.twoFactorInput.setAttribute('required', '');

                    if (token) {
                      show(this.nodes.twoFactorAuthenticationError);
                    } else {
                      this.nodes.twoFactorInput.focus();
                    }
                  } else {
                    show(this.nodes.authenticationError);
                  }
                });
            }
          }
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));
        this.nodes.loginForm.addEventListener('submit', this);
      }

      disconnectedCallback() {
        this.nodes.loginForm.removeEventListener('submit', this);
      }
    },
  );
});
