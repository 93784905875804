import { audioContext, createAudioMeter } from '@/lib/audio-meter.mjs';
import { Logger } from '@/lib/logging.mjs';
import * as media from '@/lib/media.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { mediaEvents } from '@/utils/eventTarget.ts';

const logger = new Logger('sound-meter');

loadTemplate('c-sound-meter').then(({ content }) => {
  window.customElements.define(
    'c-sound-meter',
    class extends HTMLElement {
      set deviceId(value) {
        if (value !== this._deviceId) {
          this._deviceId = value;
          this.startIfPossible();
        }
      }

      get inputDevice() {
        return {
          id: this._deviceId,
          audioProcessing: false,
          volume: 1.0,
          muted: false,
        };
      }

      startIfPossible() {
        if (this._deviceId) {
          this.releaseMeter();
          this.createMeter().catch(logger.error);
        }
      }

      handleEvent({ type }) {
        if (type === 'devicesChanged') {
          const found = media.getInputs().some(({ id }) => id === this._deviceId);
          if (!found) {
            logger.debug('device was removed');
            this.releaseMeter();
            this._deviceId = undefined;
          }
        }
      }

      async createMeter() {
        this.inputStream = await media.openInputStream(this.inputDevice);
        this.streamSource = audioContext.createMediaStreamSource(this.inputStream);
        this.audioMeter = await createAudioMeter();
        this.streamSource.connect(this.audioMeter);
      }

      releaseMeter() {
        if (this.audioMeter) {
          this.audioMeter.shutdown();
          delete this.audioMeter;
        }
        if (this.streamSource) {
          this.streamSource.disconnect();
          delete this.streamSource;
        }
        if (this.inputStream) {
          media.closeStream(this.inputStream);
          delete this.inputStream;
        }
      }

      drawLoop() {
        // Clear the background
        this.drawCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        if (this.audioMeter) {
          if (this.audioMeter.checkClipping()) {
            this.drawCtx.fillStyle = '#d9534f';
          } else {
            this.drawCtx.fillStyle = '#28ca42';
          }

          const barWidth = this.audioMeter.volume * this.canvas.width * 2.4;
          this.drawCtx.fillRect(0, 0, barWidth, this.canvas.height);
        }
        this.rafId = requestAnimationFrame(() => this.drawLoop());
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.canvas = this.querySelector('canvas');
        this.drawCtx = this.canvas.getContext('2d');

        mediaEvents.addEventListener('devicesChanged', this);

        this.drawLoop();
      }

      disconnectedCallback() {
        if (this.rafId) {
          cancelAnimationFrame(this.rafId);
        }
        this.releaseMeter();
        mediaEvents.removeEventListener('devicesChanged', this);
      }
    },
  );
});
