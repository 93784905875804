import {
  routerLinkAttributeChanged,
  routerLinkConnect,
  routerLinkDisconnect,
  routerLinkHandleEvent,
  routerLinkSetActive,
} from '@/components/shared.mjs';

window.customElements.define(
  'c-nav-link',
  class extends HTMLElement {
    static get observedAttributes() {
      return ['path', 'disabled'];
    }

    get active() {
      return this._active;
    }
    set active(value) {
      routerLinkSetActive.call(this, value);
    }

    handleEvent(e) {
      routerLinkHandleEvent.call(this, e);
    }

    connectedCallback() {
      const a = this.querySelector('a');
      const href = a ? a.getAttribute('href') : undefined;
      if (href) {
        this._path = href;
      }

      routerLinkConnect.call(this);
      routerLinkSetActive.call(this);
    }
    disconnectedCallback() {
      routerLinkDisconnect.call(this);
    }

    attributeChangedCallback(...properties) {
      routerLinkAttributeChanged.apply(this, properties);
    }
  },
);
