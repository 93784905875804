import { VALIDATION_INVALID_INDICATORS } from './helpers/constants.mjs';
import createSetupFunction from './helpers/createSetupFunction.mjs';
import getValidationMessage from './helpers/getValidationMessage.mjs';

import { translate } from '@/lib/i18n.mjs';

export async function validate(e) {
  const { target } = e;

  if (!target.checkValidity()) {
    const { validity } = target;
    const cause = VALIDATION_INVALID_INDICATORS.filter((p) => validity[p])[0];

    // since checkValidity also returns false when we have a customErrorMessage set we only return false when a
    // cause is found otherwise we just move on
    if (cause) {
      target.setCustomValidity(translate(getValidationMessage('phoneNumber', cause)));
      return false;
    }
  }

  if ('isValidating' in target.dataset) {
    target.setCustomValidity(translate(getValidationMessage('phoneNumber', 'validating')));
    return false;
  }

  if ('isValid' in target.dataset) {
    if (target.dataset.isValid === 'true') {
      target.setCustomValidity('');
      return true;
    }

    target.setCustomValidity(translate(getValidationMessage('phoneNumber', 'validationFailed')));
    return false;
  }

  return true;
}

export const setup = createSetupFunction(
  [
    ['minlength', '1'],
    ['maxlength', '255'],
    ['pattern', '\\s*?\\+?[0-9()\\-=#.,|{}[\\] ]*'],
  ],
  validate,
);
