import request from '@/lib/request.mjs';

export default function (clientUuid: string, nextUrl: string | null) {
  let page = '1';

  // If we retrieved a nextUrl, then we need to parse the page number from the URL
  // and use that as the page number for the request.
  if (nextUrl) {
    const parsedNextUrl = new URL(nextUrl);
    const searchParams = new URLSearchParams(parsedNextUrl.search);
    page = searchParams.get('page');
  }

  return request('getCallerIdNumbers', { clientUuid, page, forceRefresh: true });
}
