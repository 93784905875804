import '@/components/c-no-audio-devices.mjs';
import '@/components/c-user-destination-popout.mjs';

import { getAuthenticatedUserAvailability } from '@/lib/data/getAuthenticatedUserAvailability.ts';
import * as user from '@/lib/user.mjs';

import { hide, isHidden, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { userEvents } from '@/utils/eventTarget.ts';

const userStatusAttributeMap = {
  offline: {
    icon: 'minus-circle',
    translationKey: 'disconnected',
  },
  do_not_disturb: {
    icon: 'bell-alt',
    translationKey: 'do_not_disturb_en',
  },
  available: {
    icon: 'check',
    translationKey: 'available',
  },
  available_for_colleagues: {
    icon: 'available-internal',
    translationKey: 'available',
  },
  device_not_found: {
    icon: 'exclamation',
  },
  busy: {
    icon: 'phone-volume',
  },
  ringing: {
    icon: 'bell',
  },
};

loadTemplate('c-user').then(({ content }) => {
  window.customElements.define(
    'c-user',
    class extends HTMLElement {
      set user(_user) {
        this._user = _user;
        this.populate();
      }
      get user() {
        return this._user;
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);

        // We're using a bound function here to be able to remove the event listener later.
        this.boundUpdateAvailability = this.updateAvailability.bind(this);
      }

      handleEvent(event) {
        const { type, detail, target, key } = event;
        switch (type) {
          case 'click':
            // Opens the user destination popout, and closes when clicked on the x, or outside of the popout.
            if (
              !this.actions.toggleDestinationsPopout.contains(target) &&
              !this.nodes.destinationsPopout.contains(target)
            ) {
              // If the click is outside, close the destinationsPopout and mark the toggle button as 'closed'
              hide(this.nodes.destinationsPopout);
              this.actions.toggle.classList.add('closed');
              // Update aria-expanded to false because it's now closed
              this.actions.toggle.setAttribute('aria-expanded', 'false');
            } else if (!this.nodes.destinationsPopout.contains(target) || target === this.actions.close) {
              // If the click is on the toggle button or the close button, toggle the destinationsPopout
              if (isHidden(this.nodes.destinationsPopout)) {
                show(this.nodes.destinationsPopout);
                this.actions.toggle.classList.remove('closed');
                // Update aria-expanded to true because it's now open
                this.actions.toggle.setAttribute('aria-expanded', 'true');
              } else {
                hide(this.nodes.destinationsPopout);
                this.actions.toggle.classList.add('closed');
                // Update aria-expanded to false because it's now closed
                this.actions.toggle.setAttribute('aria-expanded', 'false');
              }
            }
            break;

          case 'keydown':
            if (target === this.actions.toggle) {
              if (key === ' ' || key === 'Enter') {
                event.preventDefault();
                target.click();

                // Use setTimeout to delay the focus
                setTimeout(() => {
                  // Set focus back to the toggle element
                  target.focus();
                }, 0);
              }
            }
            break;

          case 'userUpdated':
            this.user = detail;
            break;

          case 'loggedIn':
            this.setUserAvailability();
            break;
        }
      }

      updateStatus() {
        let { icon } = userStatusAttributeMap[this.status];

        this.nodes.userPresenceIcon.setAttribute('icon', icon);
        this.nodes.statusIndicator.classList.remove(this.oldStatus);
        this.nodes.statusIndicator.classList.add(this.status);

        this.oldStatus = this.status;
      }

      populate() {
        if (!this.isConnected) {
          return;
        }

        if (this.user) {
          const { firstName, lastName, preposition, email } = this.user;
          this.nodes.name.textContent = `${firstName} ${preposition ? `${preposition} ` : ''}${lastName}`;
          this.nodes.email.textContent = email;
        } else {
          this.nodes.name.textContent = '';
          this.nodes.email.textContent = '';
        }
      }

      updateAvailability(_, model) {
        const { availability, context, internal_number } = model;
        this.nodes.internalNumber.textContent = `${internal_number} - `;
        const availabilityContext = context.length ? context[0].type : undefined;

        if (userStatusAttributeMap[availabilityContext]) {
          this.status = availabilityContext;
        } else {
          this.status = availability;
        }

        this.updateStatus();
      }

      async setUserAvailability() {
        if (this.userModel) {
          this.userModel.off('change', this.boundUpdateAvailability);
        }

        this.userModel = await getAuthenticatedUserAvailability();

        if (this.userModel) {
          this.updateAvailability(null, this.userModel);
          this.userModel.on('change', this.boundUpdateAvailability);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        // Adding click event listener to the document to be able to click outside to close.
        document.addEventListener('click', this);

        this.populate();

        // async lookups to be able to show the data when we have it available and carry on
        // without blocking and waiting
        user.get().then((_user) => {
          this.user = _user;
        });

        this.oldStatus = 'offline';

        this.setUserAvailability();

        this.actions.toggle.addEventListener('keydown', this);
        userEvents.addEventListener('loggedIn', this);
        userEvents.addEventListener('userUpdated', this);
      }

      disconnectedCallback() {
        document.removeEventListener('click', this);
        this.actions.toggle.removeEventListener('keydown', this);

        userEvents.removeEventListener('loggedIn', this);
        userEvents.removeEventListener('userUpdated', this);

        if (this.userModel) {
          this.userModel.off('change', this.boundUpdateAvailability);
        }
      }
    },
  );
});
