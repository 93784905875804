import { searchParamsParse } from '@/utils/url.ts';

const linkRegexp = /<(.*)>/;
const relRegexp = /rel="(.*?)"/;
const methodRegexp = /method="(.*?)"/;

export function parse(str) {
  const uri = linkRegexp.exec(str)[1];
  const rel = relRegexp.exec(str)[1];
  let method;

  if (methodRegexp.test(str)) {
    method = methodRegexp.exec(str)[1];
  }

  return {
    [rel]: {
      params: searchParamsParse(uri),
      method,
      uri,
    },
  };
}

export function getLinks(links) {
  return links.split(',').reduce((prev, cur) => Object.assign(prev, parse(cur)), {});
}
