import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter.mjs';

/**
 * Formats a date to a string representation of that date.
 * EN: "WEEKDAY, MONTH DAY(st/nd/rd/th) YYYY at TIME AM/PM" (e.g. Monday, December 31st 2023 at 2:30 PM).
 * NL: "TIME op WEEKDAY DAY MONTH YEAR" (e.g. 14:30 op maandag 31 december 2023).
 *
 * @param dateToFormat - the date (object or string or null) to format.
 * @returns the formatted string of the given date.
 */
export async function getDateTimeString(
  dateToFormat: Date,
  chosenLanguage: string,
  translateFn: (key: string, variables: object) => Promise<string>,
) {
  if (typeof dateToFormat === 'string') {
    dateToFormat = new Date(dateToFormat);
  }

  if (dateToFormat) {
    const day = dateToFormat.getDate();
    const time = getTimeString(dateToFormat, chosenLanguage);
    const weekDay = getWeekDay(dateToFormat, false, false, chosenLanguage);
    const monthString = dateToFormat.toLocaleString(chosenLanguage, { month: 'long' });
    const year = dateToFormat.getFullYear();
    let dateString = `${weekDay}, ${monthString} ${day} ${year}`;

    if (chosenLanguage === 'nl-NL') {
      dateString = `${weekDay} ${day} ${monthString} ${year}`;
    }

    return translateFn('date_time_string', { date: dateString, time });
  }

  return '';
}

/**
 * Formats a date to a 24 hour HH:MM format (e.g. 08:00).
 *
 * @param date - the date to get a time string from.
 * @returns the formatted time string.
 */
export function getTimeString(date: Date, language: string): string {
  return date.toLocaleTimeString(language, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}

/**
 * Returns a formatted string representing the given date and time.
 *
 * @param date - The date object to format.
 * @returns A string in the format (based on the language): "DD/MM/YYYY, HH:MM".
 */
export function getDisplayDateAndTime(date: Date, language: string): string {
  return date.toLocaleString(language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}

/**
 * Formats a date to a weekday string (e.g. "Monday"/"Tuesday")
 * The weekday will be formatted to the local format (e.g. NL will be "Maandag"/"Dinsdag")
 *
 * @param date - the date to get the weekday from.
 * @param shouldCapitalize - if the weekday which is being returned should be capitalized or not.
 * @param isShortWeekDay - if the weekday should be either 'long' (false) or 'short' (true) (e.g. "wednesday" vs "wed")
 * @returns the weekday.
 */
export function getWeekDay(date: Date, shouldCapitalize: boolean, isShortWeekDay: boolean, language: string): string {
  const weekdayLength = isShortWeekDay ? 'short' : 'long';

  const weekDay = date.toLocaleString(language, { weekday: weekdayLength });

  if (shouldCapitalize) {
    return capitalizeFirstLetter(weekDay);
  }

  return weekDay.charAt(0).toLowerCase() + weekDay.slice(1);
}
