import { Logger } from '@/lib/logging.mjs';
import * as settings from '@/lib/settings/remote.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import showSettingSaved from '@/utils/settingSaved.ts';

const logger = new Logger('c-setting-toggle');

loadTemplate('c-setting-toggle').then(({ content }) => {
  window.customElements.define(
    'c-setting-toggle',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['inverted'];
      }

      async handleEvent({ currentTarget, target: { checked } }) {
        const value = this.inverted ? !checked : checked;
        await settings.set(this.dataset.settingsKey, value);
        logger.info(`Setting ${this.dataset.settingsKey} to ${value}`);
        const labelToAttachedTo = currentTarget.parentElement.nextElementSibling.firstElementChild;
        showSettingSaved(labelToAttachedTo);
      }

      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        const value = await settings.get(this.dataset.settingsKey);
        this.nodes.settingCheckbox.checked = this.inverted ? !value : value;

        this.nodes.settingCheckbox.addEventListener('change', this);
      }

      disconnectedCallback() {
        this.nodes.settingCheckbox.removeEventListener('change', this);
      }

      attributeChangedCallback(name, oldValue, newValue) {
        this.inverted = newValue === '';
      }
    },
  );
});
