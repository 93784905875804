import * as contactBook from '@/lib/contacts.mjs';
import getColleagues from '@/lib/data/getColleagues.mjs';
import getLinkedAndUnlinkedVoipAccounts from '@/lib/data/getLinkedAndUnlinkedVoipAccounts.mjs';
import getUnlinkedVoipaccounts from '@/lib/data/getUnlinkedVoipaccounts.mjs';

import getInstanceName from '@/utils/getInstanceName.mjs';
import isPhoneNumberEqualDespiteCountryCode from '@/utils/isPhoneNumberEqualDespiteCountryCode.ts';
import sortColltacts from '@/utils/sortColltacts.ts';

import Contact from '@/models/Contact.mjs';

export async function all(forceRefresh = false) {
  const [voipaccounts, colleagues, contacts] = await Promise.all([
    getUnlinkedVoipaccounts(forceRefresh),
    getColleagues(forceRefresh),
    contactBook.all(),
  ]);
  return voipaccounts.concat(colleagues, contacts).sort(sortColltacts);
}

export async function allPlusLinkedVoipaccounts(forceRefresh = false) {
  const [voipaccounts, colleagues, contacts] = await Promise.all([
    getLinkedAndUnlinkedVoipAccounts(forceRefresh),
    getColleagues(forceRefresh),
    contactBook.all(),
  ]);
  return voipaccounts.concat(colleagues, contacts).sort(sortColltacts);
}

export function find(phoneNumber, colltacts) {
  if (!phoneNumber) {
    return;
  }

  return colltacts.find((colltact) => {
    if (getInstanceName(colltact) === Contact.name.toLowerCase()) {
      return colltact.phoneNumbers.some((phoneNumberInstance) =>
        isPhoneNumberEqualDespiteCountryCode(phoneNumberInstance.phoneNumber, phoneNumber),
      );
    } else {
      return colltact.phoneNumber === phoneNumber;
    }
  });
}
