import getCallerInfo from '@/lib/calling/getCallerInfo.mjs';
import { translate } from '@/lib/i18n.mjs';
import { Logger } from '@/lib/logging.mjs';

import debounce from '@/utils/debounce.mjs';
import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents } from '@/utils/eventTarget.ts';

import { BUSY_HERE } from '@/constants/sessionRejectionCodes.mjs';

const logger = new Logger('incoming-call');

loadTemplate('c-incoming-call').then(({ content }) => {
  window.customElements.define(
    'c-incoming-call',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);

        this.debouncedScaleSVG = debounce({ fn: this.scaleSVG, delay: 100, context: this });
      }

      async handleEvent({ detail, type, currentTarget }) {
        switch (type) {
          case 'inviteReceived':
            this.setupIncomingCall(detail);
            break;

          case 'click':
            if (currentTarget === this.actions.reject) {
              this.session.reject({ statusCode: BUSY_HERE }).catch(logger.error);
            } else if (currentTarget === this.actions.accept) {
              this.session.accept().catch(logger.error);
            }
            break;
        }
      }

      scaleSVG() {
        const { clientWidth, clientHeight } = this.nodes.svg.previousElementSibling;
        this.nodes.svg.setAttribute('width', clientWidth + 32);
        this.nodes.svg.setAttribute('height', clientHeight + 32);
        this.nodes.svg.setAttribute('viewBox', `0 0 ${clientWidth + 32} ${clientHeight + 32}`);

        const rect = this.nodes.svg.querySelector('rect');
        rect.setAttribute('width', clientWidth);
        rect.setAttribute('height', clientHeight);
        rect.setAttribute('x', 16);
        rect.setAttribute('y', 16);
        rect.setAttribute('ry', 8);
        rect.setAttribute('ry', 8);
      }

      async setupIncomingCall(session) {
        this.reset();

        this.session = session;

        this.nodes.displayName.textContent = translate('loading');
        this.nodes.phoneNumber.textContent = this.session.phoneNumber;

        show(this);

        getCallerInfo(this.session).then(({ displayName, phoneNumber }) => {
          this.nodes.displayName.textContent = displayName;
          this.nodes.phoneNumber.textContent = phoneNumber;
        });

        this.scaleSVG();

        if (typeof this.session === 'undefined') {
          this.reset();
          return;
        }

        Promise.race([this.session.accepted(), this.session.terminated()]).finally(() => this.reset());
      }

      reset() {
        delete this.session;
        hide(this);
        this.nodes.displayName.textContent = '';
        this.nodes.phoneNumber.textContent = '';
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        callingEvents.addEventListener('inviteReceived', this);
        this.actions.accept.addEventListener('click', this);
        this.actions.reject.addEventListener('click', this);

        window.addEventListener('resize', this.debouncedScaleSVG);
      }

      disconnectedCallback() {
        callingEvents.removeEventListener('inviteReceived', this);
        this.actions.accept.removeEventListener('click', this);
        this.actions.reject.removeEventListener('click', this);

        window.removeEventListener('resize', this.debouncedScaleSVG);
      }
    },
  );
});
