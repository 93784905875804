import parseCalls from '@/lib/data/parseCalls.mjs';
import request from '@/lib/request.mjs';
import * as settings from '@/lib/settings/remote.mjs';

export default async function (forceRefresh = false) {
  const onlyShowMissedCalls = await settings.get('showMissedCalls');

  const apiRequestOptions = {
    forceRefresh,
    params: {
      page: 1,
      per_page: 50,
    },
  };

  if (onlyShowMissedCalls) {
    apiRequestOptions.params.answered = false;
  }

  return request('calls', apiRequestOptions).then(parseCalls);
}
