import * as webCalling from 'webphone-lib';

import { getDefaultMedia, getSessions } from '@/lib/calling-base.mjs';
import { translate } from '@/lib/i18n.mjs';
import { mediaLogger } from '@/lib/loggers.mjs';
import * as localSettings from '@/lib/settings/local.mjs';
import * as settings from '@/lib/settings/remote.mjs';
import { setSink, setVolume } from '@/lib/sounds.mjs';
import { closeToast, showToast } from '@/lib/toasts.mjs';

import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter.mjs';
import { mediaEvents } from '@/utils/eventTarget.ts';

import { showNotification } from '@/sw-notifications.mjs';

export let microphonePermissionGranted = false;

//
// Re-export parts of WebCalling
// ------------------------------

export function getInputs() {
  return webCalling.Media.inputs;
}

export function getOutputs() {
  return webCalling.Media.outputs;
}

export function requestPermission() {
  return webCalling.Media.requestPermission();
}

export function openInputStream(input) {
  return webCalling.Media.openInputStream(input);
}

export function closeStream(stream) {
  webCalling.Media.closeStream(stream);
}

//
// Helpers
// --------

export function updateMicrophonePermission() {
  return requestPermission()
    .then(() => {
      microphonePermissionGranted = true;
      mediaLogger.info('microphone permission granted');
      mediaEvents.dispatchEvent(
        new CustomEvent('microphonePermissionUpdated', { detail: { permissionGranted: true } }),
      );
      closeToast('noMicToast');
    })
    .catch(async () => {
      microphonePermissionGranted = false;
      mediaLogger.warn('microphone permission blocked');
      mediaEvents.dispatchEvent(
        new CustomEvent('microphonePermissionUpdated', { detail: { permissionGranted: false } }),
      );
      if (await settings.get('showDeclinedMicrophonePermissionToast')) {
        showToast({
          id: 'noMicToast',
          title: 'no_audio_devices',
          icon: 'mute',
          type: 'danger',
          sticky: true,
          showOptOut: 'showDeclinedMicrophonePermissionToast',
          dismissable: true,
        });
      }
    });
}

function absoluteVolume(relativeVolume, masterVolume) {
  const volume = Number(relativeVolume) * Number(masterVolume);

  if (Number.isNaN(volume)) {
    mediaLogger.error(`one of the volume values is not-a-number, returning 1 instead`, {
      relativeVolume,
      masterVolume,
    });
    return 1;
  }

  return volume;
}

async function addPreferredDevice(deviceKey, device) {
  const settingsKey = `preferred${capitalizeFirstLetter(deviceKey)}Devices`;
  let devices = (await localSettings.get(settingsKey)) || [];

  // Remove the device from the list if it is already present.
  // This way it is re-added at the end of the list.
  devices = devices.filter((other) => device.id !== other.id);

  // If the maximum length of the list is reached; remove the first device.
  if (devices.length === 10) {
    devices.shift();
  }
  devices.push(device);

  mediaLogger.info(`${deviceKey} device added to preferred devices: ${device.name}`, { device });

  await localSettings.set(settingsKey, devices);
}

//
// Volumes
// --------

export function getMasterVolume() {
  return localSettings.get('masterVolume');
}

export async function setMasterVolume(volume) {
  await localSettings.set('masterVolume', volume);
  mediaLogger.info(`setting master volume to: ${volume} and updating related volumes as well`);
  await Promise.all([updateCallingVolume(), updateRingtoneVolume(), updateSystemVolume()]);
}

export function getCallingVolume() {
  return localSettings.get('callingVolume');
}

export async function setCallingVolume(volume) {
  await localSettings.set('callingVolume', volume);
  await updateCallingVolume();
}

export function getRingtoneVolume() {
  return localSettings.get('ringtoneVolume');
}

export async function setRingtoneVolume(volume) {
  await localSettings.set('ringtoneVolume', volume);
  await updateRingtoneVolume();
}

export function getSystemVolume() {
  return localSettings.get('systemVolume');
}

export async function setSystemVolume(volume) {
  await localSettings.set('systemVolume', volume);
  await updateSystemVolume();
}

async function getAbsoluteCallingVolume() {
  const masterVolume = await getMasterVolume();
  const callingVolume = await getCallingVolume();
  return absoluteVolume(callingVolume, masterVolume);
}

async function updateCallingVolume() {
  const masterVolume = await getMasterVolume();
  const callingVolume = await getCallingVolume();
  const volume = absoluteVolume(callingVolume, masterVolume);

  const media = getDefaultMedia();
  if (media) {
    media.output.volume = volume;
  }

  mediaLogger.info(`setting calling volume for future sessions to: ${volume}`, { callingVolume, masterVolume });

  getSessions().forEach((session) => {
    session.media.output.volume = volume;
    mediaLogger.info(`setting calling volume for current session to: ${volume}`, {
      callingVolume,
      masterVolume,
      sessionId: session.id,
    });
  });
}

async function updateRingtoneVolume() {
  const masterVolume = await getMasterVolume();
  const ringtoneVolume = await getRingtoneVolume();
  const volume = absoluteVolume(ringtoneVolume, masterVolume);

  mediaLogger.info(`setting ringtone volume to: ${volume}`, { ringtoneVolume, masterVolume });

  setVolume(volume, 'ringtone');
}

async function updateSystemVolume() {
  const masterVolume = await getMasterVolume();
  const systemVolume = await getSystemVolume();
  const volume = absoluteVolume(systemVolume, masterVolume);

  mediaLogger.info(`setting system volume to: ${volume}`, { systemVolume, masterVolume });

  setVolume(volume, 'system');
}

//
// Device selection
// -----------------

export async function getHeadsetInput() {
  return (await localSettings.get('headsetInput')) || { id: null };
}

export async function setHeadsetInput(device, isPreferred = true) {
  await localSettings.set('headsetInput', device);
  isPreferred && addPreferredDevice('headsetInput', device);
  await updateHeadsetInput();
}

export async function getHeadsetOutput() {
  return (await localSettings.get('headsetOutput')) || { id: null };
}

export async function setHeadsetOutput(device, isPreferred = true) {
  await localSettings.set('headsetOutput', device);
  isPreferred && addPreferredDevice('headsetOutput', device);
  await updateHeadsetOutput();
}

export async function getRingtoneOutput() {
  return (await localSettings.get('ringtoneOutput')) || { id: null };
}

export async function setRingtoneOutput(device, isPreferred = true) {
  await localSettings.set('ringtoneOutput', device);
  isPreferred && addPreferredDevice('ringtoneOutput', device);
  await updateRingtoneOutput();
}

async function updateHeadsetInput() {
  const device = await getHeadsetInput();

  const defaultMedia = getDefaultMedia();
  if (defaultMedia) {
    defaultMedia.input.id = device.id;
    mediaLogger.info(`setting headsetInput for future sessions to: ${device.name}`, { device });
  }

  // When there is an incoming or outgoing call, it could be that the call
  // is not answered yet. In those cases we should avoid changing the audio device
  // on those sessions, because there is no audio stream active yet.
  getSessions().forEach((session) => {
    if (['active', 'on_hold'].includes(session.status)) {
      session.media.input.id = device.id;
      mediaLogger.info(`setting headsetInput for current session to: ${device.name}`, {
        device,
        sessionId: session.id,
      });
    }
  });
}

async function updateHeadsetOutput() {
  const device = await getHeadsetOutput();

  const defaultMedia = getDefaultMedia();
  if (defaultMedia) {
    defaultMedia.output.id = device.id;
    mediaLogger.info(`setting headsetOutput for future sessions to: ${device.name}`, { device });
  }

  // When there is an incoming or outgoing call, it could be that the call
  // is not answered yet. In those cases we should avoid changing the audio device
  // on those sessions, because there is no audio stream active yet.
  getSessions().forEach((session) => {
    if (['active', 'on_hold'].includes(session.status)) {
      session.media.output.id = device.id;
      mediaLogger.info(`setting headsetOutput for current session to: ${device.name}`, {
        device,
        sessionId: session.id,
      });
    }
  });

  setSink(device, 'headset');
}

async function updateRingtoneOutput() {
  const device = await getRingtoneOutput();
  mediaLogger.info(`setting ringtoneOutput to: ${device.name}`, { device });
  setSink(device, 'ringtone');
}

//
// Post audio processing
// ----------------------

export async function getAudioProcessing() {
  return await localSettings.get('audioProcessing');
}

export async function setAudioProcessing(enabled) {
  await localSettings.set('audioProcessing', enabled);
  await updateAudioProcessing();
}

async function updateAudioProcessing() {
  const audioProcessing = await getAudioProcessing();

  const defaultMedia = getDefaultMedia();
  if (defaultMedia) {
    defaultMedia.input.audioProcessing = audioProcessing;
  }

  getSessions().forEach((session) => (session.media.input.audioProcessing = audioProcessing));
}

//
// Misc
// -----

export async function getInitialMedia() {
  return {
    input: {
      id: (await getHeadsetInput()).id,
      audioProcessing: await getAudioProcessing(),
      volume: 1.0,
      muted: false,
    },
    output: {
      id: (await getHeadsetOutput()).id,
      volume: await getAbsoluteCallingVolume(),
      muted: false,
    },
  };
}

async function getChangedDevice(deviceKey, deviceList) {
  const device = await localSettings.get(deviceKey);
  const preferredDevices = (await localSettings.get(`preferred${capitalizeFirstLetter(deviceKey)}Devices`)) || [];

  if (preferredDevices.length > 0) {
    let found = preferredDevices
      .reverse() // reverse the list because the last one in the list has the highest priority
      .find((preferredDevice) =>
        deviceList.some((device) => device.id === preferredDevice.id && device.name === preferredDevice.name),
      );

    if (found) {
      mediaLogger.info(`${deviceKey} preferred device found: ${found.name}`, { device: found });
      return { device: found, missingDevice: null };
    }
  }

  if (device) {
    const found = deviceList.find(({ id, name }) => id === device.id && name === device.name);
    if (found) {
      mediaLogger.info(`${deviceKey} device found: ${found.name}`, { device: found });
      return { device: found, missingDevice: null };
    }
  }

  mediaLogger.info(`missing ${deviceKey} device (probably disconnected)`, { device });
  return { device: null, missingDevice: device };
}

async function onDevicesChanged() {
  mediaLogger.info('audio input and/or output device(s) have been connected/disconnected');

  const { device: headsetInput, missingDevice: missingHeadsetInput } = await getChangedDevice(
    'headsetInput',
    getInputs(),
  );
  const { device: headsetOutput, missingDevice: missingHeadsetOutput } = await getChangedDevice(
    'headsetOutput',
    getOutputs(),
  );
  const { device: ringtoneOutput, missingDevice: missingRingtoneOutput } = await getChangedDevice(
    'ringtoneOutput',
    getOutputs(),
  );

  if (headsetInput) {
    await setHeadsetInput(headsetInput, false);
  } else {
    mediaLogger.info('(preferred) headsetInput not found, reverting back to default');
    await setHeadsetInput((await getInputs())[0], false);
  }

  if (headsetOutput) {
    await setHeadsetOutput(headsetOutput, false);
  } else {
    mediaLogger.info('(preferred) headsetOutput not found, reverting back to default');
    await setHeadsetOutput((await getOutputs())[0], false);
  }

  if (ringtoneOutput) {
    await setRingtoneOutput(ringtoneOutput, false);
  } else {
    mediaLogger.info('(preferred) ringtoneOutput not found, reverting back to default');
    await setRingtoneOutput((await getOutputs())[0], false);
  }

  // Make a list of the missing devices, removing `undefined` and `null` devices and
  // removing duplicates (could be that a headset is used for
  // output and input).
  if (await settings.get('showAudioDeviceNotifications')) {
    const missingDevices = new Set(
      [missingHeadsetInput, missingHeadsetOutput, missingRingtoneOutput].filter((device) => !!device),
    );

    missingDevices.forEach(async (device) => {
      if (device.kind === 'audioinput') {
        showToast({
          title: 'audio_input_device_disconnected',
          body: device.name,
          translateBody: false,
          icon: 'mute',
          type: 'danger',
          sticky: false,
        });
      } else if (device.kind === 'audiooutput') {
        showToast({
          title: 'audio_device_disconnected',
          body: device.name,
          translateBody: false,
          icon: 'headset-off',
          type: 'danger',
          sticky: false,
        });
      }

      if (document.visibilityState !== 'visible') {
        if (device.kind === 'audioinput') {
          showNotification({
            title: translate('audio_input_device_disconnected'),
            body: device.name,
            requireInteraction: false,
            tag: 'audioDeviceDisconnected',
          });
        } else if (device.kind === 'audiooutput') {
          showNotification({
            title: translate('audio_device_disconnected'),
            body: device.name,
            requireInteraction: false,
            tag: 'audioDeviceDisconnected',
          });
        }
      }
    });

    // Also show toasts for devices that were (re)connected.
    const connectedDevices = new Set([headsetInput, headsetOutput, ringtoneOutput].filter((device) => !!device));

    connectedDevices.forEach(async (device) => {
      if (device.kind === 'audioinput') {
        showToast({
          title: 'audio_input_device_connected',
          body: device.name,
          translateBody: false,
          icon: 'microphone',
          type: 'success',
          sticky: false,
        });
      } else if (device.kind === 'audiooutput') {
        showToast({
          title: 'audio_device_connected',
          body: device.name,
          translateBody: false,
          icon: 'headset',
          type: 'success',
          sticky: false,
        });
      }

      if (document.visibilityState !== 'visible') {
        if (device.kind === 'audioinput') {
          showNotification({
            title: translate('audio_input_device_connected'),
            body: device.name,
            requireInteraction: false,
            tag: 'audioDeviceConnected',
          });
        } else if (device.kind === 'audiooutput') {
          showNotification({
            title: translate('audio_device_connected'),
            body: device.name,
            requireInteraction: false,
            tag: 'audioDeviceConnected',
          });
        }
      }
    });
  }

  mediaEvents.dispatchEvent(new CustomEvent('devicesChanged'));
}

async function init() {
  await localSettings.initialized;

  await Promise.all(
    [
      updateCallingVolume(),
      updateRingtoneVolume(),
      updateSystemVolume(),
      updateHeadsetInput(),
      updateHeadsetOutput(),
      updateRingtoneOutput(),
    ].map((p) => p.catch(mediaLogger.error)),
  );

  // Don't show the mic permission popup at the login page on startup.
  if (localStorage.getItem('token')) {
    updateMicrophonePermission();
  }

  webCalling.Media.on('devicesChanged', onDevicesChanged);
  webCalling.Media.on('permissionGranted', updateMicrophonePermission);
  webCalling.Media.on('permissionRevoked', updateMicrophonePermission);
  webCalling.Media.init();
}

init();
