import { Sound } from 'webphone-lib';

import { soundLogger } from '@/lib/loggers.mjs';

import soundCheck from '@/utils/soundCheck.mjs';

function makeDtmfConfigs() {
  const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '#', '*'];
  const configs = {};

  keys.forEach((key) => {
    let name;

    switch (key) {
      case '#':
        name = 'hash';
        break;
      case '*':
        name = 'star';
        break;
      default:
        name = key;
    }

    configs[key] = {
      file: `/sounds/dtmf-${name}.mp3`,
      sinkType: 'headset',
      volumeType: 'system',
      options: { overlap: true },
    };
  });

  return configs;
}

// Keep a library of all available sounds.
const soundConfigs = Object.assign(
  {
    ringtone: {
      file: '/brand/default.ogg',
      sinkType: 'ringtone',
      volumeType: 'ringtone',
      options: { overlap: false },
    },
    ringback: {
      file: '/sounds/ringback.ogg',
      sinkType: 'headset',
      volumeType: 'system',
      options: { overlap: false },
    },
    addressIncomplete: {
      file: '/sounds/address-incomplete.ogg',
      sinkType: 'headset',
      volumeType: 'system',
      options: { overlap: false },
    },
    busytone: {
      file: '/sounds/busytone.ogg',
      sinkType: 'headset',
      volumeType: 'system',
      options: { overlap: false },
    },
    busytoneShort: {
      file: '/sounds/busytone_short.ogg',
      sinkType: 'headset',
      volumeType: 'system',
      options: { overlap: false },
    },
  },
  makeDtmfConfigs(),
);

const soundSinks = {
  headset: { id: undefined },
  ringtone: { id: undefined },
};

const soundVolumes = {
  system: 1,
  ringtone: 1,
};

// Keep track of all sounds that are currently playing.
// Keys in this object may look like: 'settings-ringtone' this means that
// a ringtone is playing in the context: 'settings'.
const soundContext = {};

// A simple wrapper for the web-calling lib's Sound class that keeps track
// of the sink it's playing on and at what volume.
class SoundManager {
  set sink(sink) {
    this.sound.sinkId = sink.id;
    this._sink = sink;
  }
  get sink() {
    return this._sink;
  }

  set volume(volume) {
    this.sound.volume = volume;
  }
  get volume() {
    return this.sound.volume;
  }

  constructor({ file, sinkType, volumeType, options }) {
    this.sound = new Sound(file, options);
    this.sinkType = sinkType;
    this.volumeType = volumeType;
    this.updateSinkAndVolume();
  }

  play(options) {
    this.updateSinkAndVolume();
    return this.sound.play(options);
  }

  stop() {
    this.sound.stop();
  }

  updateSinkAndVolume() {
    this.sink = soundSinks[this.sinkType];
    this.volume = soundVolumes[this.volumeType];
  }
}

export function playSound(name, key, options) {
  if (!key) {
    soundLogger.error('cannot look for sound, no context key given');
    return;
  }

  const contextKey = `${key}-${name}`;
  const config = soundConfigs[name];

  if (!config) {
    soundLogger.error(`cannot play sound, unknown sound ${name}`);
    return;
  }

  let sound;
  if (soundContext[contextKey]) {
    sound = soundContext[contextKey];
  } else {
    sound = new SoundManager(config);
    soundContext[contextKey] = sound;
  }

  const logDetails = {
    sink: sound.sink.name,
    volume: sound.volume,
    contextKey,
  };

  // Play the sound and delete it from the context when it is finished.
  // Do NOT delete it when the sound is perhaps already playing, in that case
  // it will be resolved by another instance of this promise chain.
  soundLogger.info(`sound ${name} trying to play`, logDetails);

  return soundCheck()
    .then(() =>
      sound
        .play(options)
        .then(() => {
          soundLogger.info(`sound ${name} finished`, logDetails);
        })
        .catch((e) => {
          stopSound(name, key);
          soundLogger.error(`sound ${name} cannot be played because ${e}`, logDetails);
        }),
    )
    .catch(() => {
      soundLogger.error(`sound ${name} cannot be played because the sound check did not pass.`, logDetails);
    });
}

export function stopSound(name, key) {
  if (!key) {
    soundLogger.error('cannot stop sound, no context key given');
    return;
  }

  const contextKey = `${key}-${name}`;
  const sound = soundContext[contextKey];

  if (!sound) {
    soundLogger.warn(`cannot stop sound, ${name} is not currently playing`, { contextKey });
    return;
  }

  sound.stop();

  soundLogger.info(`sound ${name} stopped`, {
    sink: sound.sink.name,
    volume: sound.volume,
    contextKey,
  });

  delete soundContext[contextKey];
}

export function setSink(sink, sinkType) {
  // Update the sink for new sounds that want to be played.
  soundSinks[sinkType] = sink;

  soundLogger.info(`changed ${sinkType} sink to: ${sink.name}`);

  // Update the sink for currently playing sounds.
  Object.entries(soundContext)
    .filter(([, sound]) => sound.sinkType === sinkType)
    .forEach(([key, sound]) => {
      sound.sink = sink;
      soundLogger.info(`changed sink of currently playing sound: ${key} to: ${sink.name}`);
    });
}

export function setVolume(volume, volumeType) {
  // Update the volume for new sounds that want to be played.
  soundVolumes[volumeType] = volume;

  soundLogger.info(`changed ${volumeType} sound volume to: ${volume}`);

  // Update the volume for currently playing sounds.
  Object.entries(soundContext)
    .filter(([, sound]) => sound.volumeType === volumeType)
    .forEach(([key, sound]) => {
      sound.volume = volume;
      soundLogger.info(`changed volume of currently playing sound: ${key} to: ${volume}`);
    });
}
