import { loadTemplate } from '@/utils/dom.ts';

const steps = ['/welcome', '/choose-account', '/audio-settings'];

loadTemplate('c-onboarding-progress').then(({ content }) => {
  window.customElements.define(
    'c-onboarding-progress',
    class extends HTMLElement {
      constructor() {
        super();

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(content.cloneNode(true));

        const { pathname } = document.location;

        steps.forEach((step) => {
          const node = document.createElement('c-router-link');
          node.setAttribute('path', step);
          if (step === pathname) {
            node.classList.add('currentStep');
          }
          this.shadowRoot.appendChild(node);
        });
      }
    },
  );
});
