import '@/components/c-contact-number.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-contact-numbers-popout').then(({ content }) => {
  window.customElements.define(
    'c-contact-numbers-popout',
    class extends HTMLElement {
      set data(phoneNumberInstances) {
        this._phoneNumberInstances = phoneNumberInstances;
        this.populate();
      }

      constructor() {
        super();
        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      populate() {
        for (let phoneNumberInstance of this._phoneNumberInstances) {
          const numberNode = document.createElement('c-contact-number');
          this.nodes.contactNumbers.appendChild(numberNode);
          numberNode.data = phoneNumberInstance.phoneNumber;
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));
      }
    },
  );
});
