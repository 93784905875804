import { Subscription } from 'rxjs';
import HeadsetService from 'softphone-vendor-headsets';
import { ImplementationConfig } from 'softphone-vendor-headsets/dist/es/src/library/services/vendor-implementations/vendor-implementation';
import { HeadsetEvents } from 'softphone-vendor-headsets/dist/es/src/library/types/consumed-headset-events';
import { type ISession } from 'webphone-lib/dist/session';

import { setVisualStateOfButton } from '@/components/shared.mjs';

import { holdAllOtherSessions } from '@/lib/calling.mjs';
import { HeadsetStatus, headsetManagerInstance } from '@/lib/headset/manager.ts';
import { headsetLogger } from '@/lib/loggers.mjs';
import * as segment from '@/lib/segment.mjs';

import { uuidv4 } from '@/utils/crypto.mjs';
import { callingEvents } from '@/utils/eventTarget.ts';

import { BUSY_HERE } from '@/constants/sessionRejectionCodes.mjs';

export class Headset {
  headset: HeadsetService;
  session: ISession | null;
  currentCallUuid: string;
  headsetSubscription: Subscription | null;

  constructor() {
    this.headset = HeadsetService.getInstance({} as ImplementationConfig);
    this.session = null;
    this.headsetSubscription = null;
    this.currentCallUuid = uuidv4();
  }

  /**
   * This method subscribes to the headset events from
   * the softphone library and handles them accordingly.
   */
  subscribeToHeadsetEvents() {
    this.headsetSubscription = this.headset.headsetEvents$.subscribe({
      next: (event) => {
        switch (event.event) {
          case HeadsetEvents.deviceAnsweredCall:
            headsetLogger.info('Headset: Answer call', { payload: event.payload });
            this.session?.accept();
            segment.track.callControlAction('answer', headsetManagerInstance.getVendorName());
            break;

          case HeadsetEvents.deviceRejectedCall:
            headsetLogger.info('Headset: Reject call', { payload: event.payload });
            this.session?.reject({ statusCode: BUSY_HERE });
            segment.track.callControlAction('reject', headsetManagerInstance.getVendorName());
            break;

          case HeadsetEvents.deviceEndedCall:
            headsetLogger.info('Headset: End call', { payload: event.payload });
            this.session?.terminate();
            segment.track.callControlAction('end', headsetManagerInstance.getVendorName());
            break;

          case HeadsetEvents.deviceMuteStatusChanged:
            headsetLogger.info('Headset: Change mute status', { payload: event.payload });
            if (this.session) {
              // Updating the mute status in the session
              this.session.media.input.muted = event.payload.isMuted;
              this.updateMuteButtonState(event.payload.isMuted);
            }
            segment.track.callControlAction('mute', headsetManagerInstance.getVendorName());
            break;

          case HeadsetEvents.deviceHoldStatusChanged:
            if (this.session) {
              headsetLogger.info('Headset: Change hold status', { payload: event.payload });
              if (event.payload.holdRequested) {
                headsetLogger.info('Headset: Change to hold', { payload: event.payload });
                // If hold is requested, mute and hold the session
                this.session.media.input.muted = true;
                this.session.hold();
                segment.track.callControlAction('hold', headsetManagerInstance.getVendorName());
              } else {
                headsetLogger.info('Headset: Resume from hold', { payload: event.payload });
                // If unhold is requested, unhold and unmute the session, holding all other sessions
                holdAllOtherSessions(this.session);
                this.session.media.input.muted = false;
                this.session.unhold();
                segment.track.callControlAction('unhold', headsetManagerInstance.getVendorName());
              }
            }
            break;

          // Based on the updateStatus call the UI will be updated
          case HeadsetEvents.deviceConnectionStatusChanged:
            if (event.payload === 'checking') {
              headsetManagerInstance.updateStatus(HeadsetStatus.CHECKING);
            }

            if (event.payload === 'running') {
              headsetManagerInstance.updateStatus(HeadsetStatus.ENABLED);
              segment.track.callControlAction('connected', headsetManagerInstance.getVendorName());
            }

            if (event.payload === 'notRunning') {
              headsetManagerInstance.updateStatus(HeadsetStatus.PERMISSIONS_REQUIRED);
            }

            headsetLogger.info('Headset: Connection status changed', { payload: event.payload });
            break;

          case HeadsetEvents.implementationChanged:
            headsetLogger.info('Headset: Implementation changed', { payload: event.payload });
            break;

          case HeadsetEvents.webHidPermissionRequested:
            headsetManagerInstance.updateStatus(HeadsetStatus.PERMISSIONS_REQUIRED);
            break;

          default:
            headsetLogger.info('Headset: Unhandled event', { payload: event });
        }
      },
      error: (error) => {
        headsetLogger.error('Headset: Error handling an event', { error });
      },
    });
  }

  updateMuteButtonState(isMuted: boolean) {
    const toggleMuteNode = document.querySelector('button[data-action="toggleMute"]');
    const state = isMuted ? 'active' : 'inactive';
    setVisualStateOfButton({ buttonKey: 'mute', state, buttonNode: toggleMuteNode });
    toggleMuteNode?.classList.toggle('active', isMuted);
  }

  /**
   * This method performs the initial setup for the headset instance.
   * It obtains the headset input and sets it as the active microphone.
   * It also sets up event listeners for various calling events and headset events.
   */
  async initialSetup(headsetName: string) {
    const headsetImplementation = this.headset.implementations.find((implementation) => {
      return implementation.deviceLabelMatchesVendor(headsetName);
    });

    const status = this.headset.connectionStatus();

    // Status won't be updated if they are already in a certain state: running or notRunning
    // We can expect a checking status to always return either one of these states.
    // Thus when we're not checking, we can show our status based on the current status.
    if (status === 'notRunning') {
      headsetManagerInstance.updateStatus(HeadsetStatus.PERMISSIONS_REQUIRED);
    }

    if (status === 'running') {
      headsetManagerInstance.updateStatus(HeadsetStatus.ENABLED);
    }

    this.setupEventListeners();
    this.subscribeToHeadsetEvents();

    this.headset.changeImplementation(headsetImplementation, headsetName);
  }

  destroy() {
    this.headsetSubscription?.unsubscribe();
    this.removeEventListeners();
  }

  handleEvent(event: Event) {
    switch (event.type) {
      // We're filling the session based on inviteSent and inviteReceived events
      // Within the session events we're using this session to perform the actions
      case 'inviteSent':
        this.session = (event as CustomEvent).detail;
        this.headset.outgoingCall({ conversationId: this.session.id });
        break;

      case 'inviteReceived':
        this.session = (event as CustomEvent).detail;
        this.headset.incomingCall({ conversationId: this.session.id });
        break;

      case 'sessionAccepted':
        this.session = (event as CustomEvent).detail;
        this.headset.answerCall(this.session.id);
        break;

      case 'sessionTerminated':
        this.headset.endCall(this.session.id);
        break;

      case 'sessionEnded':
        this.headset.rejectCall(this.session.id);
        break;

      case 'muteActive':
        this.headset.setMute(true);
        break;

      case 'muteInactive':
        this.headset.setMute(false);
        break;
    }
  }

  setupEventListeners() {
    callingEvents.addEventListener('inviteSent', this);
    callingEvents.addEventListener('inviteReceived', this);
    callingEvents.addEventListener('sessionAccepted', this);
    callingEvents.addEventListener('sessionEnded', this);
    callingEvents.addEventListener('sessionTerminated', this);
    callingEvents.addEventListener('muteActive', this);
    callingEvents.addEventListener('muteInactive', this);
  }

  removeEventListeners() {
    callingEvents.removeEventListener('inviteSent', this);
    callingEvents.removeEventListener('inviteReceived', this);
    callingEvents.removeEventListener('sessionAccepted', this);
    callingEvents.removeEventListener('sessionEnded', this);
    callingEvents.removeEventListener('sessionTerminated', this);
    callingEvents.removeEventListener('muteActive', this);
    callingEvents.removeEventListener('muteInactive', this);
  }
}
