export default function (items) {
  return items.map(({ id, account_id, description: desc, internal_number }) => {
    const description = `${internal_number} - ${desc}`;

    return {
      id,
      description,
      internal_number,
      accountId: account_id,
    };
  });
}
