import '@/components/c-contact-api-error.mjs';
import '@/components/c-phone-number-input.mjs';

import * as contacts from '@/lib/contacts.mjs';
import * as favorites from '@/lib/favorites.mjs';
import { prepareForm, tearDownForm, validateForm } from '@/lib/formValidator.mjs';
import { Logger } from '@/lib/logging.mjs';
import * as segment from '@/lib/segment.mjs';

import { empty, getFormElements, getFormValues, hide, loadTemplate, remove, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { navigate, redirect } from '@/utils/history.ts';
import { snakeToCamel } from '@/utils/string.ts';
import { getUrlParams } from '@/utils/url.ts';

const logger = new Logger('contact-form');

loadTemplate('c-contact-create-edit-form').then(({ content }) => {
  window.customElements.define(
    'c-contact-create-edit-form',
    class extends HTMLElement {
      set contactInstance(contact) {
        this._contactInstance = contact;
        this.populate(contact);
      }
      get contactInstance() {
        return this._contactInstance;
      }

      set triedToSaveEarlier(value) {
        if (value) {
          this._triedToSaveEarlier = true;
          window.setTimeout(() => {
            this.triedToSaveEarlier = false;
          }, 2000);
        } else {
          this._triedToSaveEarlier = false;
        }
      }
      get triedToSaveEarlier() {
        return this._triedToSaveEarlier;
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      async handleEvent(e) {
        const { currentTarget, type } = e;

        switch (type) {
          case 'validated':
            if (this.triedToSaveEarlier && (await this.isValid())) {
              this.saveContact();
            }
            break;

          case 'submit':
            e.preventDefault();
            if (await this.isValid()) {
              this.saveContact();
            } else {
              this.triedToSaveEarlier = true;
            }
            break;

          case 'click':
            switch (currentTarget) {
              case this.actions.cancel:
                this.cancel();
                break;

              case this.actions.deleteContact:
                show(this.nodes.contactDeleteConfirmationModal);
                break;

              case this.actions.abortDelete:
                hide(this.nodes.contactDeleteConfirmationModal);
                break;

              case this.actions.confirmDelete:
                this.deleteContact();
                break;

              case this.actions.saveContact:
                e.preventDefault();
                if (await this.isValid()) {
                  this.saveContact();
                } else {
                  this.triedToSaveEarlier = true;
                }
                break;
            }
            break;

          case 'change':
            this.triedToSaveEarlier = false;
            this.sortRequiredNameFields();
            break;

          case 'input':
            this.sortPhoneNumberRows();
            break;
        }
      }

      async isValid() {
        this.sortRequiredNameFields();

        return !(await validateForm(this.nodes.contactForm)).includes(false);
      }

      sortRequiredNameFields() {
        // we can't check n.validity.valueMissing here since that is only true when the required attribute is set
        const filledFields = this.oneOfTheseIsRequired.filter((n) => n.value.length !== 0);

        if (filledFields.length === 0) {
          this.oneOfTheseIsRequired.forEach((n) => n.setAttribute('required', ''));
        } else {
          this.oneOfTheseIsRequired.forEach((n) => {
            n.removeAttribute('required');
            n.checkValidity();
          });
        }

        this.oneOfTheseIsRequired.forEach((n) => n.dispatchEvent(new CustomEvent('validate')));
      }

      saveContact() {
        this.triedToSaveEarlier = false;
        const data = getFormValues(this.nodes.contactForm);

        // Convert all separate phoneNumber inputs, e.g. phoneNumber0,
        // phoneNumber1, etc. to one array of these phoneNumbers.
        const phoneNumbers = [];
        Object.entries(data).forEach(([key, phoneNumberFlat]) => {
          if (key.startsWith('phoneNumber')) {
            delete data[key];

            if (!phoneNumberFlat) {
              return;
            }

            phoneNumbers.push({ phoneNumberFlat });
          }
        });

        data.phoneNumbers = phoneNumbers;

        this.querySelectorAll('c-contact-api-error').forEach((e) => remove(e));

        if (this.contactInstance) {
          Object.assign(this.contactInstance, data);
          this.contactInstance
            .save()
            .then(() => {
              const isFavorite = favorites.contains(this.contactInstance.id);
              // When we update a favorite contact we should update the
              // hydrated favorites too.
              isFavorite && favorites.hydrateFavorites();
              navigate('/contacts');
              segment.track.editContact();
            })
            .catch((err) => {
              logger.error(JSON.stringify(err));
              this.handleFormApiError(err);
            });
        } else {
          contacts
            .create(data)
            .then(() => {
              navigate('/contacts');
              segment.track.createContact();
            })
            .catch((err) => {
              logger.error(JSON.stringify(err));
              this.handleFormApiError(err);
            });
        }
      }

      deleteContact() {
        if (this.contactInstance) {
          this.contactInstance
            .delete()
            .then(() => {
              const isFavorite = favorites.contains(this.contactInstance.id);
              // When we update a favorite contact we should update the
              // hydrated favorites too.
              isFavorite && favorites.hydrateFavorites();
            })
            .then(() => {
              navigate('/contacts');
              segment.track.deleteContact();
            })
            .catch((err) => logger.error(JSON.stringify(err)));
        }
      }

      createPhoneNumberRow(data = false) {
        const phoneNumberComponent = document.createElement('c-phone-number-input');
        this.nodes.phoneNumbers.appendChild(phoneNumberComponent);

        if (data) {
          phoneNumberComponent.setAttribute('value', data);
        }

        const input = phoneNumberComponent.querySelector('input');
        input.addEventListener('change', this);
        input.addEventListener('input', this);

        phoneNumberComponent.onRemoveButtonClick = () => {
          this.removePhoneNumberRow(phoneNumberComponent);
        };
      }

      removePhoneNumberRow(phoneNumberComponent) {
        const input = phoneNumberComponent.querySelector('input');
        input.removeEventListener('change', this);
        input.removeEventListener('input', this);
        this.nodes.phoneNumbers.removeChild(phoneNumberComponent);
        this.sortPhoneNumberRows();
      }

      sortPhoneNumberRows() {
        const showbuttons = this.nodes.phoneNumbers.children.length > 1;
        const phoneNumberComponents = Array.from(this.nodes.phoneNumbers.children);

        phoneNumberComponents.forEach((phoneNumberComponent, index) => {
          phoneNumberComponent.setAttribute('name', `phoneNumber${index}`);
          if (phoneNumberComponent.value !== '') {
            phoneNumberComponent.removeButtonIsVisible = showbuttons;
          } else {
            phoneNumberComponent.removeButtonIsVisible = false;
          }
        });

        const allPhoneNumbersHaveInput = phoneNumberComponents.every(
          (phoneNumberComponent) => phoneNumberComponent.value !== '',
        );

        if (allPhoneNumbersHaveInput && this.nodes.phoneNumbers.children.length < 10) {
          this.createPhoneNumberRow();
          this.sortPhoneNumberRows();
        }

        const listOfEmptyInputs = phoneNumberComponents.filter(
          (phoneNumberComponent) => phoneNumberComponent.value === '',
        );

        if (listOfEmptyInputs.length > 1) {
          this.removePhoneNumberRow(listOfEmptyInputs[0]);
        }
      }

      cancel() {
        window.history.back();
      }

      populate({ id, givenName, familyName, companyName, fullName, phoneNumbers }) {
        this.nodes.givenNameInput.value = givenName;
        this.nodes.familyNameInput.value = familyName;
        this.nodes.companyNameInput.value = companyName;
        this.nodes.contactNamePopup.textContent = `${fullName} `;

        empty(this.nodes.phoneNumbers);
        phoneNumbers.forEach(({ phoneNumber }) => this.createPhoneNumberRow(phoneNumber));

        if (this.nodes.phoneNumbers.children.length < 1) {
          this.createPhoneNumberRow();
        }

        this.sortPhoneNumberRows();

        id ? show(this.actions.deleteContact) : hide(this.actions.deleteContact);
      }

      handleFormApiError(errors) {
        const formElements = getFormElements(this.nodes.contactForm);

        for (const errorKey in errors.body) {
          const error = errors.body[errorKey];
          if (errorKey === 'phone_numbers') {
            for (const phoneNumberIndex in error) {
              const phoneNumberError = error[phoneNumberIndex];
              //if there is an error in one of the phone numbers, the API returns an array with responses for all the numbers.
              //Thats why if there is everything fine with the number, the object would be empty.
              if (!Object.keys(phoneNumberError).length) {
                continue;
              }
              const nodeThatCausedError = formElements.find(
                (formElement) => formElement.name === `phoneNumber${phoneNumberIndex}`,
              );
              const errorComponent = document.createElement('c-contact-api-error');
              errorComponent.error = phoneNumberError.phone_number_flat;
              nodeThatCausedError.parentNode.insertAdjacentElement('beforebegin', errorComponent);
            }
          } else {
            const nodeThatCausedError = formElements.find((formElement) => formElement.name === snakeToCamel(errorKey));
            const errorComponent = document.createElement('c-contact-api-error');
            errorComponent.error = error;
            nodeThatCausedError.insertAdjacentElement('beforebegin', errorComponent);
          }
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        prepareForm(this.nodes.contactForm);

        this.oneOfTheseIsRequired = [
          this.nodes.givenNameInput,
          this.nodes.familyNameInput,
          this.nodes.companyNameInput,
        ];

        this.oneOfTheseIsRequired.forEach((n) => n.addEventListener('change', this));

        [
          this.actions.saveContact,
          this.actions.deleteContact,
          this.actions.cancel,
          this.actions.confirmDelete,
          this.actions.abortDelete,
        ].forEach((n) => n.addEventListener('click', this));

        this.addEventListener('validated', this, true);

        this.nodes.contactForm.addEventListener('submit', this);

        const urlParams = getUrlParams();

        if (urlParams.phoneNumber) {
          this.createPhoneNumberRow(urlParams.phoneNumber);
          redirect('/contact/create');
        } else {
          this.createPhoneNumberRow();
        }

        this.sortPhoneNumberRows();

        this.sortRequiredNameFields();
      }

      disconnectedCallback() {
        this.oneOfTheseIsRequired.forEach((n) => n.removeEventListener('change', this));

        [
          this.actions.saveContact,
          this.actions.deleteContact,
          this.actions.cancel,
          this.actions.confirmDelete,
          this.actions.abortDelete,
        ].forEach((n) => n.removeEventListener('click', this));

        this.removeEventListener('validated', this, true);

        this.nodes.contactForm.addEventListener('submit', this);

        tearDownForm(this.nodes.contactForm);
      }
    },
  );
});
