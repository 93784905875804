import parseVoipaccounts from '@/lib/data/parseVoipaccounts.mjs';
import paginateUsingNext from '@/lib/paginateUsingNext.mjs';
import * as user from '@/lib/user.mjs';

import { dataEvents } from '@/utils/eventTarget.ts';

export default async function (forceRefresh = false) {
  const { clientId } = await user.getUserFromStorage();

  const requestOptions = {
    forceRefresh,
    clientId,
    params: {
      filter_accounts: 'without_connected_users',
      status: 'active',
      per_page: 100,
    },
  };

  const data = await paginateUsingNext({ resourceName: 'voipaccounts', requestOptions });

  const voipaccounts = parseVoipaccounts(data);

  if (forceRefresh) {
    dataEvents.dispatchEvent(new CustomEvent('voipAccountsUpdated', { detail: { voipaccounts } }));
  }

  return voipaccounts;
}
