import * as t from '@/lib/i18n.mjs';

window.customElements.define(
  'c-translate',
  class extends HTMLElement {
    static get observedAttributes() {
      return ['key'];
    }

    constructor() {
      super();

      this.chosenLanguage = t.chosenLanguage;
    }

    connectedCallback() {
      if (this.key) {
        this.update();
      }
    }

    async update() {
      if (
        !this.key ||
        (this.previousLanguage === t.chosenLanguage && this.previousKey && this.previousKey === this.key)
      ) {
        return;
      }
      this.previousKey = this.key;
      this.chosenLanguage = t.chosenLanguage;
      this.textContent = t.translate(this.key);
    }

    attributeChangedCallback(name, oldValue, newValue) {
      this.key = newValue;
      this.update();
    }
  },
);
