import { attendedTransfer, getSession } from '@/lib/calling.mjs';
import * as temp from '@/lib/temporary-storage.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { ActionsProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-complete-attended-transfer').then(({ content }) => {
  window.customElements.define(
    'c-complete-attended-transfer',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['a-session-id', 'b-session-id'];
      }

      constructor() {
        super();
        this.actions = new ActionsProxy(this);
      }

      handleEvent({ type, target: { dataset } }) {
        if (type === 'click' && dataset.action) {
          switch (dataset.action) {
            case 'complete':
              if (this.a && this.b) {
                attendedTransfer(this.a, this.b);
                temp.remove(this.a.id);
                temp.remove(this.b.id);
              }
              break;

            case 'reset':
              // let the event bubble up so that c-session can capture it
              break;
          }
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.actions.complete.addEventListener('click', this);
      }

      disconnectedCallback() {
        this.actions.complete.removeEventListener('click', this);
      }

      attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
          case 'a-session-id':
            this.a = getSession(newValue);
            break;

          case 'b-session-id':
            this.b = getSession(newValue);
            break;
        }
      }
    },
  );
});
