import { voipAccount } from '@/lib/calling-base.mjs';
import request from '@/lib/request.mjs';

import debounce from '@/utils/debounce.mjs';
import { removeTheseFromPhoneNumbersRegExp } from '@/utils/regexp.ts';

let iterator = 0;

const propertyMappingEntries = Object.entries({
  phone_number: 'phoneNumber',
  phone_number_flat: 'phoneNumber',
  phoneNumberFlat: 'phoneNumber',
  number_type: 'phoneNumberType',
  numberType: 'phoneNumberType',
  phone_number_type: 'phoneNumberType',
  phone_number_pretty: 'phoneNumberPretty',
  calling_code: 'callingCode',
  country_code: 'countryCode',
  location: 'location',
});

// temporary function to mitigate differences in the outpuf of different API endpoint
// this can be removed when or if the contacts API returns phoneNumbers which are the same as the validate API returns
function setInternalValues(data = {}) {
  propertyMappingEntries.forEach(([from, to]) => {
    if (from in data) {
      this[`_${to}`] = data[from];
    }
  });
}
function validateAndFormat(phoneNumber, countryCode) {
  iterator++;

  this._promise = {
    iterator,
  };

  if (typeof voipAccount !== 'undefined' && typeof countryCode === 'undefined') {
    countryCode = voipAccount.countryCode.toUpperCase();
  }
  const sanitisedPhoneNumber = phoneNumber.replace(removeTheseFromPhoneNumbersRegExp, '');

  request('phoneNumberValidateAndFormat', { phoneNumber: sanitisedPhoneNumber, countryCode })
    .then((response) => {
      if (this._promise.iterator === iterator) {
        if (!response) {
          throw new Error('invalid');
        }
        delete this._intermediateNumber;

        setInternalValues.call(this, response);

        this.dispatchEvent(new CustomEvent('finishedAsyncValidation'));
        this.dispatchEvent(new CustomEvent('isValid'));
        this._promise.resolve(response);
      } else {
        console.log(this._promise.iterator, iterator, "don't match");
      }
    })
    .catch((err) => {
      this.reset();
      this._promise.resolve(err);
    });

  return new Promise((resolve, reject) => {
    this._promise.resolve = resolve;
    this._promise.reject = reject;
  });
}

export default class PhoneNumber extends EventTarget {
  get phoneNumber() {
    return this._phoneNumber;
  }
  set phoneNumber(args) {
    // in different situations this function gets different input
    switch (typeof args) {
      // user input is usually a string, like '0612345678'
      // in this case we also need to validate and format the input
      case 'string':
        if ('' === args) {
          this.reset();
          return;
        }
        // dispatch as fast as possible to counter the 250ms delay the debounced function has
        this.dispatchEvent(new CustomEvent('startedAsyncValidation'));

        this._intermediateNumber = args;
        this.validateAndFormat(this._intermediateNumber);
        break;

      // if we receive an object we assume this was already sanitised by the validateAndFormat or was received from
      // another API endpoint (like contacts)
      case 'object':
        setInternalValues.call(this, args);
        this.dispatchEvent(new CustomEvent('isValid'));
        break;

      // if you'd want to clear the phoneNumber, you trigger the setter with undefined
      case 'undefined':
        this.reset();
        break;
    }
  }

  get phoneNumberType() {
    return this._phoneNumberType;
  }

  get phoneNumberPretty() {
    return this._phoneNumberPretty;
  }

  get callingCode() {
    return this.callingCode;
  }

  get countryCode() {
    return this._countryCode;
  }

  get location() {
    return this._location;
  }

  constructor(args = {}) {
    super(args);

    this.reset();
    this.phoneNumber = args;

    this.validateAndFormat = debounce({ fn: validateAndFormat, delay: 250, context: this });
  }

  reset() {
    delete this._intermediateNumber;
    this.dispatchEvent(new CustomEvent('finishedAsyncValidation'));
    this.dispatchEvent(new CustomEvent('isInvalid'));
    setInternalValues.call(this, undefined);
  }

  serialize() {
    return {
      phoneNumberFlat: this.phoneNumber,
    };
  }
}
