import parseUserDestinations from '@/lib/data/parseUserDestinations.mjs';
import request from '@/lib/request.mjs';
import { getUserFromStorage } from '@/lib/user.mjs';

export default async function (forceRefresh = false) {
  const user = await getUserFromStorage();
  return await request('userDestinations', { userUuid: user.uuid, forceRefresh }).then((items) =>
    parseUserDestinations(items),
  );
}
