import register from '@/lib/directives.mjs';

import { generateUniqueString } from '@/utils/string.ts';

register((node) => {
  if (node.nodeName !== 'svg') {
    Array.from(node.querySelectorAll('[data-prevent-autofill]')).forEach((node) => {
      node.setAttribute('autocomplete', generateUniqueString());
      delete node.dataset.preventAutofill;
    });
  }
});
