import { loadTemplate } from '@/utils/dom.ts';
import createResizeObserver from '@/utils/resizeObserver.mjs';

const alternativeIcon = 'eye-slash';

loadTemplate('c-password-visualiser').then(({ content }) => {
  window.customElements.define(
    'c-password-visualiser',
    class extends HTMLElement {
      flip() {
        if (this.inputNode.hasAttribute('type')) {
          this.inputNode.removeAttribute('type');
          this.iconNode.setAttribute('icon', alternativeIcon);
        } else {
          this.inputNode.setAttribute('type', 'password');
          this.iconNode.setAttribute('icon', this.defaultIcon);
        }
      }

      position() {
        this.style.top = `${this.inputNode.offsetTop + (this.inputNode.clientHeight - this.clientHeight) / 2}px`;
      }

      handleEvent(e) {
        e.preventDefault();

        switch (e.type) {
          case 'click':
            this.flip();
            break;

          case 'translated':
            this.position();
        }
      }

      register() {
        // this all seems a bit superfluous but it is sometimes needed to
        // give the surounding interface some time to initialise before accesing its DOM properties
        if (this.deregisterResizeObserver) {
          window.clearTimeout(this.interval);
          return;
        }

        if (this.inputNode && !this.inputNode.offsetParent) {
          this.timer = window.setTimeout(this.register.bind(this), 20);
          return;
        }

        if (this.inputNode && this.inputNode.offsetParent) {
          this.deregisterResizeObserver = createResizeObserver({
            node: this.inputNode.offsetParent,
            callback: () => {
              this.position();
            },
          });
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.inputNode = this.previousElementSibling;
        this.iconNode = this.querySelector('c-icon');
        this.defaultIcon = this.iconNode.getAttribute('icon');

        this.addEventListener('click', this);

        this.register();

        this.position();
      }

      disconnectedCallback() {
        this.deregisterResizeObserver && this.deregisterResizeObserver();
        this.removeEventListener('click', this);
      }
    },
  );
});
