import { loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-no-connection').then(({ content }) => {
  window.customElements.define(
    'c-no-connection',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.loadingTimeout = window.setTimeout(() => {
          show(this.nodes.additionalText);
          show(this.nodes.loadingText);
        }, 2000);
      }

      disconnectedCallback() {
        window.clearTimeout(this.loadingTimeout);
      }
    },
  );
});
