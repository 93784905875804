import parseVoipaccounts from '@/lib/data/parseVoipaccounts.mjs';
import request from '@/lib/request.mjs';

export default async function (forceRefresh = false) {
  const data = await request('allVoipaccounts', {
    forceRefresh,
  });

  const voipaccounts = parseVoipaccounts(data.objects);

  return voipaccounts;
}
