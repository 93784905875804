import * as applicationStatus from '@/lib/applicationStatus.mjs';
import * as colltacts from '@/lib/colltacts.mjs';
import * as settings from '@/lib/settings/remote.mjs';

import { userEvents } from '@/utils/eventTarget.ts';
import getInstanceName from '@/utils/getInstanceName.mjs';
import sortColltacts from '@/utils/sortColltacts.ts';

import Contact from '@/models/Contact.mjs';

// Cache the list of IDs to not spam the API unnecessarily.
let cachedFavorites = [];
// This array will hold actual colltact data based on the list of IDs.
let hydratedFavorites = [];

let hydrated = hydrate();

async function hydrate() {
  await applicationStatus.isReadyToRock();
  await hydrateFavorites();
}

userEvents.addEventListener('loggedIn', () => {
  // Make sure favorites are hydrated again after logging in. This way
  // favorites.all() always waits until the favorites are hydrated again.
  cachedFavorites = [];
  hydrated = hydrate();
});

userEvents.addEventListener('loggedOut', () => {
  // Clear the existing array so references to it will also be empty.
  cachedFavorites = [];
  hydratedFavorites = [];
});

async function set(favorites) {
  cachedFavorites = favorites;
  return settings.set('favorites', favorites);
}

export function all() {
  return hydrated.then(() => hydratedFavorites);
}

export function contains(id) {
  return cachedFavorites.includes(id);
}

export async function toggle(id) {
  let favorites = Array.from(cachedFavorites);

  if (favorites.includes(id)) {
    favorites = favorites.filter((value) => value !== id);
  } else {
    favorites.push(id);
  }

  await set(favorites);
  await hydrateFavorites();
}

export async function hydrateFavorites() {
  const [favorites, allColltacts] = await Promise.all([settings.get('favorites'), colltacts.all()]);
  const colltactFavorites = [];

  for (const id of favorites) {
    for (const colltact of allColltacts) {
      if (getInstanceName(colltact) === Contact.name.toLowerCase() && colltact.id.toString() === id) {
        colltactFavorites.push(colltact);
      } else if (colltact.accountId === id) {
        colltactFavorites.push(colltact);
      } else if (colltact.colleagueUuid === id) {
        colltactFavorites.push(colltact);
      }
    }
  }

  cachedFavorites = favorites;
  hydratedFavorites = colltactFavorites.sort(sortColltacts);
}
