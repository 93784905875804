import { isConnected } from '@/lib/calling-base.mjs';
import getUnlinkedVoipaccounts from '@/lib/data/getUnlinkedVoipaccounts.mjs';
import { blfLogger } from '@/lib/loggers.mjs';
import * as localSettings from '@/lib/settings/local.mjs';

import { subscriptionEvents } from '@/utils/eventTarget.ts';
import jitter from '@/utils/jitter.mjs';
import * as time from '@/utils/time.ts';

const accountIdRegExp = /:(.*?)@/;

const SUBSCRIPTIONS_INTERVAL_MIN = 30 * time.second;
const SUBSCRIPTIONS_INTERVAL_MAX = 40 * time.second;
const SUBSCRIBE_INTERVAL = 10 * time.millisecond;

// Using an object as searching through those is faster than searching through
// an array with objects. Values need to be updated regularly on notify events.
let subscriptions = {};

// Keep a reference of the subscriptions fetcher.
let fetcher;

export function hasSubscription(accountId) {
  return accountId in subscriptions;
}

export function getSubscription(accountId) {
  return subscriptions[accountId];
}

export function clearSubscriptions() {
  Object.keys(subscriptions).forEach((accountId) => delete subscriptions[accountId]);
}

async function subscribeContact(client, voipaccount) {
  await time.sleep(SUBSCRIBE_INTERVAL);

  if (!voipaccount) {
    return;
  }

  if (!isConnected()) {
    return;
  }

  // If offline according to the api, that status takes precedence over the
  // sip status.
  if (voipaccount.status === 'offline') {
    return;
  }

  // If already subscribed, do nothing.
  if (hasSubscription(voipaccount.accountId)) {
    return;
  }

  await client
    .subscribe(`sip:${voipaccount.accountId}@voipgrid.nl`)
    .then(() =>
      blfLogger.info('subscribed', {
        accountId: voipaccount.accountId,
      }),
    )
    .catch((error) =>
      blfLogger.error('subscribe failed', {
        accountId: voipaccount.accountId,
        error,
      }),
    );

  subscriptions[voipaccount.accountId] = voipaccount.status;
}

async function unsubscribeContact(client, voipaccount) {
  await time.sleep(SUBSCRIBE_INTERVAL);

  if (!voipaccount) {
    return;
  }

  if (!isConnected()) {
    return;
  }

  if (voipaccount.status !== 'offline') {
    return;
  }

  client.unsubscribe(`sip:${voipaccount.accountId}@voipgrid.nl`);

  // Be sure to emit an event sharing the new status before deleting.
  updateSubscription(`sip:${voipaccount.accountId}@voipgrid.nl`, 'offline');

  // Remove from subscriptions object so resubscribing is possible.
  delete subscriptions[voipaccount.accountId];

  blfLogger.info('unsubscribed', { accountId: voipaccount.accountId });
}

async function updateSubscriptions(client) {
  // The server only allows 2 (un)subscriptions for each colleague per 60 seconds.
  // Use the time since the last subscriptions update to prevent the client
  // to land into the rate limit.
  const timeOfLastUpdate = await localSettings.get('timeOfLastSubscriptionsUpdate');
  if (timeOfLastUpdate) {
    const delta = Date.now() - timeOfLastUpdate;
    const remainingDelta = SUBSCRIPTIONS_INTERVAL_MIN - delta;

    if (delta < SUBSCRIPTIONS_INTERVAL_MIN) {
      blfLogger.warn(
        `Too soon with subscriptions update, updating subscriptions in ${remainingDelta / time.second} seconds`,
        { reason: 'rate-limit' },
      );
      stopSubscriptionsFetcher();
      setTimeout(() => startSubscriptionsFetcher(client), remainingDelta);
      return;
    }
  }

  blfLogger.info('updating subscriptions...');

  const voipaccounts = await getUnlinkedVoipaccounts().catch((error) => {
    blfLogger.error('error while retrieving colleagues for subscriptions', { error });
  });

  // When there are no voipaccounts, assume that we have no internet connection,
  // and do not unsubscribe from any possible existing subscriptions.
  if (!voipaccounts || voipaccounts.length === 0) {
    blfLogger.warn(
      'subscriptions update not possible, colleagues array is empty, assume that there is no internet connection',
    );
    return;
  }

  // Save the current time which is used to make sure that the client does
  // not hit the rate limit when spamming.
  localSettings.set('timeOfLastSubscriptionsUpdate', Date.now());

  for (const accountId of Object.keys(subscriptions)) {
    const voipaccount = voipaccounts.find((voipaccount) => voipaccount.accountId === Number(accountId));

    if (voipaccount) {
      await unsubscribeContact(client, voipaccount);
    }
  }

  for (const voipaccount of voipaccounts) {
    await subscribeContact(client, voipaccount);
  }

  blfLogger.info('subscriptions updated');
}

export async function updateSubscription(uri, status) {
  const accountId = uri.match(accountIdRegExp)[1];

  subscriptions[accountId] = status;
  subscriptionEvents.dispatchEvent(new CustomEvent(`notify-${accountId}`, { detail: { status } }));

  blfLogger.info(`subscriptions dispatched event: notify-${accountId}`, { accountId, status });
}

export function terminateSubscription(uri) {
  const accountId = uri.match(accountIdRegExp)[1];

  if (!hasSubscription(accountId)) {
    return;
  }

  // Remove from subscriptions object so resubscribing is possible.
  delete subscriptions[accountId];

  blfLogger.info('subscription has been terminated', { accountId });
}

function subscriptionsFetcher(client) {
  fetcher = setTimeout(
    async () => {
      await updateSubscriptions(client);
      subscriptionsFetcher(client);
    },
    jitter(SUBSCRIPTIONS_INTERVAL_MIN, SUBSCRIPTIONS_INTERVAL_MAX),
  );
}

export async function startSubscriptionsFetcher(client) {
  stopSubscriptionsFetcher();
  subscriptionsFetcher(client);
  blfLogger.info('subscription fetcher started');
  await updateSubscriptions(client);
}

export function stopSubscriptionsFetcher() {
  if (fetcher) {
    clearTimeout(fetcher);
    fetcher = undefined;

    // When the client disconnects the subscriptions are also unsubscribed.
    clearSubscriptions();

    blfLogger.info('subscription fetcher stopped');
  }
}
