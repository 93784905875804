import { localSettingsLogger } from '@/lib/loggers.mjs';

const localSettingsDefaults = {
  accountsQuickLinksVisible: undefined,
  audioProcessing: true,
  callingVolume: 1,
  headsetInput: undefined,
  headsetOutput: undefined,
  masterVolume: 1,
  outgoingNumberQuickLinksVisible: undefined,
  preferredHeadsetInputDevices: [],
  preferredHeadsetOutputDevices: [],
  preferredRingtoneOutputDevices: [],
  ringtoneOutput: undefined,
  ringtoneVolume: 1,
  selectedQueues: [],
  systemVolume: 1,
  timeOfLastQueuesUpdate: undefined,
  timeOfLastRecentsUpdate: undefined,
  timeOfLastSubscriptionsUpdate: undefined,
  userDestinationQuickLinksVisible: undefined,
  userStatus: 'available',
};

export let initialized = new Promise((resolve) => {
  for (const [key, value] of Object.entries(localSettingsDefaults)) {
    if (window.localStorage.getItem(key) === null && typeof value !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }

  resolve();
});

export function set(key, value) {
  return initialized.then(() => {
    if (typeof value !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(key);
    }
    localSettingsLogger.info(`changed local setting "${key}" to: ${JSON.stringify(value)}`);
  });
}

export function get(key) {
  return initialized.then(() => {
    const value = window.localStorage.getItem(key);

    if (typeof value !== 'undefined') {
      return JSON.parse(value);
    }

    return null;
  });
}

export function getAll() {
  return initialized.then(() => {
    const settings = {};

    for (const key in localSettingsDefaults) {
      settings[key] = window.localStorage.getItem(key);
    }

    return settings;
  });
}

export async function toggle(key) {
  await set(key, !(await get(key)));
}

getAll().then((settings) => {
  localSettingsLogger.info('local settings initialized', { settings });
});
