import '@/components/c-account.mjs';
import '@/components/c-colleague.mjs';
import '@/components/c-contact.mjs';
import { determineElementName } from '@/components/shared.mjs';

import * as colltacts from '@/lib/colltacts.mjs';
import * as settings from '@/lib/settings/remote.mjs';

import { empty, hide, loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import { dataEvents, navigationEvents } from '@/utils/eventTarget.ts';
import flattenColltact from '@/utils/flattenColltact.mjs';
import shallowEqual from '@/utils/shallowEqual.mjs';
import { getUrlParams, updateUrl } from '@/utils/url.ts';

const CONTACT_CARD_NODE_NAMES = ['C-ACCOUNT', 'C-CONTACT', 'C-COLLEAGUE'];

loadTemplate('p-contacts').then(({ content }) => {
  window.customElements.define(
    'p-contacts',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      async handleEvent({ type, detail }) {
        switch (type) {
          case 'voipAccountsUpdated':
            this.updateColleaguesAndContactsData();
            break;

          case 'input':
            {
              this.applyContactsFilters();
            }
            break;

          case 'change':
            {
              const { value } = this.nodes.searchInput;
              updateUrl(`/contacts/${value}`);

              this.applyContactsFilters();
            }
            break;

          case 'updateInterface':
            this.fillSearchInput(detail);
            this.updateColleaguesAndContactsData();
            break;
        }
      }

      fillSearchInput({ query = '' }) {
        this.nodes.searchInput.value = query;
      }

      async updateColleaguesAndContactsData() {
        const lastScrollPosition = this.nodes.colltactsListContainer.scrollTop;
        if (!this.colltacts) {
          this.nodes.colltactsListContainer.classList.add('loading');
        }

        const updatedColltacts = await colltacts.all();

        // check if the collections of colltacts are the same
        if (this.colltacts) {
          if (this.colltacts === updatedColltacts) {
            return;
          }

          let allAreTheSame = false;
          if (this.colltacts.length === updatedColltacts.length) {
            for (let i = 0, len = updatedColltacts.length; i < len; i++) {
              if (!shallowEqual(flattenColltact(this.colltacts[i]), flattenColltact(updatedColltacts[i]))) {
                break;
              }

              if (i === len - 1) {
                allAreTheSame = true;
              }
            }
          }

          if (allAreTheSame) {
            return;
          }
        }

        this.colltacts = updatedColltacts;

        empty(this.nodes.colltactsList);

        const showOnlyOnlineContacts = await settings.get('showOnlineContacts');

        let characterBlock;
        for (const colltact of this.colltacts) {
          if (showOnlyOnlineContacts && colltact.status === 'offline') {
            continue;
          }

          let colltactNode, firstCharacter;

          const elementName = determineElementName(colltact);
          colltactNode = document.createElement(elementName);

          if (elementName === 'C-CONTACT') {
            [firstCharacter] = colltact.fullName;
            if (colltact.phoneNumbers.length > 1) {
              colltactNode.setAttribute('popoutable', '');
            }
          } else {
            [firstCharacter] = colltact.description;
          }

          if (firstCharacter && characterBlock !== firstCharacter.toLowerCase()) {
            const h5 = document.createElement('h5');
            h5.textContent = firstCharacter.toLowerCase();
            this.nodes.colltactsList.appendChild(h5);
            characterBlock = firstCharacter.toLowerCase();
          }

          hide(colltactNode);
          colltactNode.data = colltact;
          colltactNode.showOnlyOnlineContacts = showOnlyOnlineContacts;
          colltactNode.setAttribute('editable', '');
          colltactNode.setAttribute('favoritable', '');
          colltactNode.setAttribute('tabindex', '0');
          this.nodes.colltactsList.appendChild(colltactNode);
        }

        this.nodes.colltactsListContainer.classList.remove('loading');

        this.alphabeticCharacters = Array.from(this.nodes.colltactsList.querySelectorAll('h5'));
        this.applyContactsFilters();

        this.nodes.colltactsListContainer.scrollTop = lastScrollPosition;
      }

      applyContactsFilters() {
        const { children } = this.nodes.colltactsList;
        const { value } = this.nodes.searchInput;

        for (const node of children) {
          if (!CONTACT_CARD_NODE_NAMES.includes(node.nodeName)) {
            continue;
          }

          node.doesMatchSearchString(value) ? show(node) : hide(node);
        }

        this.sortAlphabeticCharacterVisibility();
      }

      sortAlphabeticCharacterVisibility() {
        this.alphabeticCharacters.forEach((node) => {
          let shouldBeVisible = false;
          let current = node.nextElementSibling;

          while (current && CONTACT_CARD_NODE_NAMES.includes(current.nodeName)) {
            if (!current.hasAttribute('hidden')) {
              shouldBeVisible = true;
              break;
            }
            current = current.nextElementSibling;
          }

          shouldBeVisible ? show(node) : hide(node);
        });
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.alphabeticCharacters = [];

        this.nodes.searchInput.addEventListener('input', this);
        this.nodes.searchInput.addEventListener('change', this);
        this.nodes.colltactsList.addEventListener('click', this);

        dataEvents.addEventListener('voipAccountsUpdated', this);
        navigationEvents.addEventListener('updateInterface', this);

        const urlParams = getUrlParams();
        if ('query' in urlParams) {
          this.fillSearchInput(urlParams);
        }

        this.nodes.searchInput.focus();
        this.updateColleaguesAndContactsData();
      }

      disconnectedCallback() {
        this.nodes.searchInput.removeEventListener('input', this);
        this.nodes.searchInput.removeEventListener('change', this);
        this.nodes.colltactsList.removeEventListener('click', this);

        dataEvents.removeEventListener('voipAccountsUpdated', this);
        navigationEvents.removeEventListener('updateInterface', this);
      }
    },
  );
});
