import { type ISession } from 'webphone-lib/dist/session.js';

import { setVisualStateOfButton } from '@/components/shared.mjs';

import { holdAllOtherSessions } from '@/lib/calling.mjs';
import { HeadsetStatus, headsetManagerInstance } from '@/lib/headset/manager.ts';
import { headsetLogger } from '@/lib/loggers.mjs';
import * as segment from '@/lib/segment.mjs';

import { callingEvents } from '@/utils/eventTarget.ts';

import { BUSY_HERE } from '@/constants/sessionRejectionCodes.mjs';

export class EPOSHeadset {
  headset: unknown;
  session: ISession | null;

  constructor() {
    this.headset = null;
    this.session = null;
  }

  // This method is called from the manager if it is determined that the EPOS headset is supported
  // It sets up two event listeners, one for the SDK (headset) events and one for the calling events
  async initialSetup() {
    this.headset = new webhid();
    await this.headset.open();
    this.setupCallingEventListeners();
    this.setupSDKEventListeners();
  }

  async destroy() {
    // Close the connection to the SDK
    await this.headset.close();

    // Remove any event listeners which have been created in initialSetup
    this.removeCallingEventListeners();
    this.removeSDKEventListeners();
  }

  removeSDKEventListeners() {
    document.removeEventListener('AcceptCall', () => {});
    document.removeEventListener('RejectCall', () => {});
    document.removeEventListener('EndCall', () => {});
    document.removeEventListener('HoldCall', () => {});
    document.removeEventListener('ResumeCall', () => {});
    document.removeEventListener('MuteMicrophone', () => {});
    document.removeEventListener('EPOSSDK_WebhidPermissionsRequired', () => {});
    document.removeEventListener('HeadsetConnected', () => {});
  }

  // The SDK events are emitted from epos.sdk.js
  setupSDKEventListeners() {
    document.addEventListener('AcceptCall', (event) => {
      headsetLogger.info('Headset: Answer call', { payload: event });
      this.session?.accept();
      segment.track.callControlAction('answer', headsetManagerInstance.getVendorName());
    });

    document.addEventListener('RejectCall', (event) => {
      headsetLogger.info('Headset: Reject call', { payload: event });
      this.session?.reject({ statusCode: BUSY_HERE });
      segment.track.callControlAction('reject', headsetManagerInstance.getVendorName());
    });

    document.addEventListener('EndCall', (event) => {
      headsetLogger.info('Headset: End call', { payload: event });
      this.session?.terminate();
      this.session = null;
      segment.track.callControlAction('end', headsetManagerInstance.getVendorName());
    });

    document.addEventListener('HoldCall', (event) => {
      if (this.session) {
        headsetLogger.info('Headset: Hold call', { payload: event });
        // If hold is requested, mute and hold the session
        // Maybe need to communicate to headset that the call is on hold
        this.session.media.input.muted = true;
        this.session.hold();
        this.headset.hold(this.session.id);
      }
      segment.track.callControlAction('hold', headsetManagerInstance.getVendorName());
    });

    document.addEventListener('ResumeCall', (event) => {
      headsetLogger.info('Headset: Resume call', { payload: event });
      // If unhold is requested, unhold and unmute the session, holding all other sessions
      holdAllOtherSessions(this.session);
      this.session.media.input.muted = false;
      this.session.unhold(this.session.id);
      this.headset.active(this.session.id);
      segment.track.callControlAction('unhold', headsetManagerInstance.getVendorName());
    });

    document.addEventListener('MuteMicrophone', (event) => {
      headsetLogger.info('Headset: Change mute status', { payload: event });
      if (this.session) {
        // Updating the mute status in the session
        this.session.media.input.muted = event.detail;
        this.updateMuteButtonState(event.detail);
      }
      segment.track.callControlAction('mute', headsetManagerInstance.getVendorName());
    });

    document.addEventListener('EPOSSDK_WebhidPermissionsRequired', () => {
      headsetManagerInstance.updateStatus(HeadsetStatus.PERMISSIONS_REQUIRED);
    });

    document.addEventListener('HeadsetConnected', () => {
      headsetManagerInstance.updateStatus(HeadsetStatus.ENABLED);
      segment.track.callControlAction('connected', headsetManagerInstance.getVendorName());
    });
  }

  updateMuteButtonState(isMuted: boolean) {
    const toggleMuteNode = document.querySelector('button[data-action="toggleMute"]');
    const state = isMuted ? 'active' : 'inactive';
    setVisualStateOfButton({ buttonKey: 'mute', state, buttonNode: toggleMuteNode });
    toggleMuteNode?.classList.toggle('active', isMuted);
  }

  async getConnectionStatus() {
    const vendorId = 0x1395;
    const devices = await navigator.hid.getDevices();
    if (devices && devices.length > 0) {
      const match = devices.find((device) => device.vendorId === vendorId);

      if (match) {
        return 'running';
      }
    }

    if (devices.length === 0) {
      return 'noDevices';
    }
  }

  handleEvent(event: Event) {
    switch (event.type) {
      case 'inviteSent':
        this.session = (event as CustomEvent).detail;
        this.headset.outgoing(this.session.id);
        break;

      case 'inviteReceived':
        this.session = (event as CustomEvent).detail;
        this.headset.incoming(this.session.id);
        break;

      case 'sessionAccepted':
        this.session = (event as CustomEvent).detail;
        this.headset.active(this.session.id);
        break;

      case 'sessionEnded':
        this.headset.end(this.session.id);
        this.session = null;
        break;

      case 'muteActive':
        this.headset.mute(true);
        break;

      case 'muteInactive':
        this.headset.mute(false);
        break;
    }
  }

  setupCallingEventListeners() {
    callingEvents.addEventListener('sessionAccepted', this);
    callingEvents.addEventListener('inviteSent', this);
    callingEvents.addEventListener('inviteReceived', this);
    callingEvents.addEventListener('sessionEnded', this);
    callingEvents.addEventListener('muteActive', this);
    callingEvents.addEventListener('muteInactive', this);
  }

  removeCallingEventListeners() {
    callingEvents.removeEventListener('sessionAccepted', this);
    callingEvents.removeEventListener('inviteSent', this);
    callingEvents.removeEventListener('inviteReceived', this);
    callingEvents.removeEventListener('sessionEnded', this);
    callingEvents.removeEventListener('muteActive', this);
    callingEvents.removeEventListener('muteInactive', this);
  }
}
