import { type ResCollection } from '@spindle/resclient';

import { initialized } from '@/lib/resgate.ts';
import { getUserFromStorage } from '@/lib/user.mjs';

/**
 * Get the user availabilities.
 *
 * This endpoint returns UserAvailability model that holds initial data and can be used to
 * listen for changes per user.
 */
export async function getUserAvailabilities() {
  const user = await getUserFromStorage();

  if (user) {
    const resgate = await initialized();
    const response = await resgate.get(`availability.client.${user.clientUuid}`);
    const collection = response as ResCollection;

    // We need at least one callback to prevent ResClient from unsubscribing.
    // See: https://resgate.io/docs/writing-clients/resclient/ (Section Listen to events).
    //
    // FIXME this can be removed when this collection is properly used after CI-340 has been implemented.
    //
    collection.on('add', null);
    collection.on('remove', null);

    // Remove the callback from the collection before the page is reloaded to prevent
    // ResClient from trying to reuse the old websocket after the page has reloaded.
    //
    // FIXME this can be removed when this collection is properly used after CI-340 has been implemented.
    //
    window.addEventListener('beforeunload', () => {
      collection.off('add', null);
      collection.off('remove', null);
    });

    return collection;
  }

  return null;
}
