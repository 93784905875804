import { getAuthenticatedUserAvailability } from '@/lib/data/getAuthenticatedUserAvailability.ts';

import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { userEvents } from '@/utils/eventTarget.ts';

const IGNORE_AVAILABILITY = ['busy'];

loadTemplate('c-user-destination-popout').then(({ content }) => {
  window.customElements.define(
    'c-user-destination-popout',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      showOfflineDeviceWarning() {
        this.nodes.statuses.classList.add('show-warning');
        this.nodes.destinations.classList.add('show-warning');
        show(document.querySelector('[data-selector="deviceOfflineBanner"]'));
      }

      hideOfflineDeviceWarning() {
        this.nodes.statuses.classList.remove('show-warning');
        this.nodes.destinations.classList.remove('show-warning');
        hide(document.querySelector('[data-selector="deviceOfflineBanner"]'));
      }

      handleEvent() {
        this.setUserAvailability();
      }

      // The below event handle function is an arrow functions so we don't have to bind it.
      // If not we would need to create a bound version of this function before using it as an event handler.
      handleAvailabilityChanged = () => {
        const userAvailability = this.userModel.availability;
        const userStatus = this.userModel.user_status;

        if (IGNORE_AVAILABILITY.includes(userAvailability)) {
          return;
        }

        // The userStatus is derived from also the availability of the selected device + userAvailability.
        // The userAvailability is what the user wants to be. If these don't match you cannot receive a call.
        if (userStatus !== userAvailability) {
          this.showOfflineDeviceWarning();
          this.nodes.statuses.selectedDeviceIsOffline = true;
        } else {
          this.hideOfflineDeviceWarning();
          this.nodes.statuses.selectedDeviceIsOffline = false;
        }
      };

      async setUserAvailability() {
        // In case we had to login make sure to remove any handlers from previous-logged-in users.
        if (this.userModel) {
          this.userModel.off('change', this.handleAvailabilityChanged);
        }

        this.userModel = await getAuthenticatedUserAvailability();

        if (this.userModel) {
          // Also trigger this to use initial data.
          this.handleAvailabilityChanged();
          this.userModel.on('change', this.handleAvailabilityChanged);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.setUserAvailability();

        userEvents.addEventListener('loggedIn', this);
      }

      disconnectedCallback() {
        if (this.userModel) {
          this.userModel.off('change', this.handleAvailabilityChanged);
        }

        userEvents.removeEventListener('loggedIn', this);
      }
    },
  );
});
