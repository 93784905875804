import request from '@/lib/request.mjs';
import * as user from '@/lib/user.mjs';

const { VITE_VENDOR_PORTAL_URL } = import.meta.env;

export async function getPlatformUrl(path) {
  return request('autologin', { forceRefresh: true })
    .then(async (apiToken) => {
      const userData = await user.getUserFromStorage();
      if (userData) {
        const { token } = apiToken;
        const { email } = userData;
        path = `user/autologin/?token=${token}&username=${email}&next=/${path}`;
        path = `${VITE_VENDOR_PORTAL_URL}${path}`;
        return path;
      } else {
        return `${VITE_VENDOR_PORTAL_URL}${path}`;
      }
    })
    .catch(() => `${VITE_VENDOR_PORTAL_URL}${path}`);
}

export async function getPlatformClientUrl(path, useUuid = false) {
  const userData = await user.get();
  if (userData) {
    return await getPlatformUrl(`client/${useUuid ? userData.clientUuid : userData.clientId}/${path}`);
  } else {
    return VITE_VENDOR_PORTAL_URL;
  }
}
