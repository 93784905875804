import { loadTemplate } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-temporary-redirect-initial').then(({ content }) => {
  window.customElements.define(
    'c-temporary-redirect-initial',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));
      }
    },
  );
});
