import '@/components/c-dialer.mjs';
import '@/components/c-keypad.mjs';

import { loadTemplate } from '@/utils/dom.ts';

loadTemplate('p-ongoing-calls').then(({ content }) => {
  window.customElements.define(
    'p-ongoing-calls',
    class extends HTMLElement {
      connectedCallback() {
        this.appendChild(content.cloneNode(true));
      }
    },
  );
});
