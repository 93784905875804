import request from '@/lib/request.mjs';

import { settingsEvents, userEvents } from '@/utils/eventTarget.ts';

const settingsFromAPIDefaults = {
  accidentalClosingIsPrevented: true,
  amountOfSuccessfulCallsForUserRating: 0,
  dnd: false,
  favorites: [],
  isAdheringToColorScheme: window.matchMedia('(prefers-color-scheme: dark)').matches,
  language: navigator.language || navigator.userLanguage,
  latestDialedNumber: undefined,
  latestTransferMethod: 'attended',
  remoteLoggingEnabled: false,
  shouldShowUserRatingModalAfterCall: false,
  showAudioDeviceNotifications: true,
  showVoipaccounts: true,
  showColleagues: true,
  showContacts: true,
  showDeclinedMicrophonePermissionToast: true,
  showDialpad: true,
  showFavorites: true,
  showMissedCalls: undefined,
  showNoAudioStreamsAllowedToast: true,
  showNoVoipAccountSelectedToast: true,
  showOnlineContacts: false,
  showUnsupportedBrowserModal: true,
  showUserRatingDialog: true,
  showWebphoneCalls: undefined,
  userHasRatedTheWebphone: false,
  wakeLock: false,
};

let settingsFromAPICache = {};
let initialized;

function init() {
  const settingsFromAPI = request('settingsGet').catch(() => {
    return {};
  });

  initialized = settingsFromAPI
    .then((externalSettings) => {
      // dump all the settings retrieved from the API and the defaults into the localSettingsCache
      Object.assign(settingsFromAPICache, settingsFromAPIDefaults, externalSettings);
    })
    .then(() => {
      // We are using remote settings in the log.mjs lib so to prevent this circular dependency which
      // causes the build to fail, we throw an event where the loggers.mjs lib will listen to.
      settingsEvents.dispatchEvent(new CustomEvent('remoteSettingsInitialized', { detail: { settingsFromAPICache } }));
    });
}

// This map is used to generate the notification settings on the settings page.
// See p-settings.mjs.
export const notificationSettings = [
  {
    labelTranslationKey: 'toggle_device_notifications',
    helpTextTranslationKey: 'toggle_device_notifications_help_text',
    settingsKey: 'showAudioDeviceNotifications',
  },
  {
    labelTranslationKey: 'toggle_unsupported_browser_modal',
    helpTextTranslationKey: 'toggle_unsupported_browser_modal_help_text',
    settingsKey: 'showUnsupportedBrowserModal',
  },
  {
    labelTranslationKey: 'toggle_no_voip_account',
    helpTextTranslationKey: 'toggle_no_voip_account_help_text',
    settingsKey: 'showNoVoipAccountSelectedToast',
  },
  {
    labelTranslationKey: 'toggle_decline_microphone_permission',
    helpTextTranslationKey: 'toggle_decline_microphone_permission_help_text',
    settingsKey: 'showDeclinedMicrophonePermissionToast',
  },
  {
    labelTranslationKey: 'toggle_incorrect_firewall_settings',
    helpTextTranslationKey: 'toggle_incorrect_firewall_settings_help_text',
    settingsKey: 'showNoAudioStreamsAllowedToast',
  },
];

export function set(key, value) {
  return initialized.then(() => {
    if (key in settingsFromAPIDefaults) {
      const oldSetting = settingsFromAPICache[key];

      settingsFromAPICache[key] = value;
      settingsEvents.dispatchEvent(new CustomEvent(key, { detail: value }));

      // to keep this async we just send the request and not wait for it to return
      request('settingSet', { body: { [key]: value } }).catch(() => {
        settingsEvents.dispatchEvent(new CustomEvent(key, { detail: oldSetting }));
      });

      // We are using remote settings in the log.mjs lib so to prevent this circular dependency which
      // causes the build to fail, we throw an event where the loggers.mjs lib will listen to.
      settingsEvents.dispatchEvent(
        new CustomEvent('remoteSettingChange', { detail: { key, value: JSON.stringify(value) } }),
      );
    }
  });
}

export function get(key) {
  return initialized.then(() => settingsFromAPICache[key]);
}

export async function toggle(key) {
  await set(key, !(await get(key)));
}

init();

userEvents.addEventListener('loggedIn', () => {
  init();
});

userEvents.addEventListener('loggedOut', () => {
  settingsFromAPICache = {};
});
