import _getCallerInfo from '@/lib/calling/getCallerInfo/index.mjs';
import isClickToDial from '@/lib/calling/isClickToDial.mjs';
import shouldDisplayNameBeTranslated from '@/lib/calling/shouldDisplayNameBeTranslated.mjs';
import * as colltacts from '@/lib/colltacts.mjs';
import { translate } from '@/lib/i18n.mjs';

import getInstanceName from '@/utils/getInstanceName.mjs';

import Contact from '@/models/Contact.mjs';

export default async function getCallerInfo(session) {
  let {
    remoteIdentity: { displayName: sessionDisplayName },
    phoneNumber: sessionPhoneNumber,
  } = session;
  const colltact = colltacts.find(sessionPhoneNumber, await colltacts.allPlusLinkedVoipaccounts());

  let colltactName;

  if (colltact) {
    colltactName = getInstanceName(colltact) === Contact.name.toLowerCase() ? colltact.fullName : colltact.description;
  }

  let { displayName, phoneNumber } = _getCallerInfo({
    phoneNumber: sessionPhoneNumber,
    displayName: sessionDisplayName,
    colltactName,
    isClickToDial: isClickToDial(sessionDisplayName),
  });

  if (shouldDisplayNameBeTranslated(displayName)) {
    displayName = translate(displayName);
  }

  return { displayName, phoneNumber };
}
