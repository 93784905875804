import { loadTemplate, remove } from '@/utils/dom.ts';

loadTemplate('c-setting-saved').then(({ content }) => {
  window.customElements.define(
    'c-setting-saved',
    class extends HTMLElement {
      handleEvent() {
        remove(this);
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.addEventListener('animationend', this);
      }

      disconnectedCallback() {
        this.removeEventListener('animationend', this);
      }
    },
  );
});
