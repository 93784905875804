import { setVisualStateOfButton } from '@/components/shared.mjs';

import { getSession, holdAllOtherSessions } from '@/lib/calling.mjs';
import getCallerInfo from '@/lib/calling/getCallerInfo.mjs';
import { translateNodes } from '@/lib/i18n.mjs';
import { Logger } from '@/lib/logging.mjs';
import * as temp from '@/lib/temporary-storage.mjs';

import { disable, empty, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents, dtmfEvents } from '@/utils/eventTarget.ts';
import { redirect } from '@/utils/history.ts';
import leftPad from '@/utils/leftPad.mjs';
import { minute, second } from '@/utils/time.ts';
import { searchParamsParse, searchParamsStringify } from '@/utils/url.ts';

import { DONE_TYPING_DTMF, DTMF_OPTIONS } from '@/constants/dtmf.mjs';
import { BUSY_HERE } from '@/constants/sessionRejectionCodes.mjs';

const logger = new Logger('session');
const showTheseStatuses = ['active', 'on_hold'];

function handleSessionStatusUpdate({ status }) {
  this.status = status;
}

function handleRemoteIdentityUpdate() {
  this.updateDOM();
}

loadTemplate('c-session').then(({ content }) => {
  window.customElements.define(
    'c-session',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['session-id'];
      }
      set status(_status) {
        this._status = _status;
        this.className = `status-${_status}`;

        this.hideAllButtons();
        this.showHoldOrActiveLabel();

        if (showTheseStatuses.includes(_status)) {
          show(this);
        } else {
          hide(this);
        }

        {
          const { startTime } = this.session;
          if (_status !== 'ringing' && startTime) {
            this.updateTimer(startTime);
            if (!this.sessionTimerInterval) {
              this.startTimer(startTime);
            }
          }
        }

        switch (_status) {
          case 'active':
            {
              [this.actions.toggleMute, this.actions.toggleKeypad, this.actions.hold, this.actions.hangup].forEach(
                show,
              );

              this.showKeyPadIfNeeded();
              this.showTransferButton();
            }
            break;

          case 'ringing':
            // c-session is not visible for ringing calls...
            break;

          case 'on_hold':
            this.showOnHoldButtons();
            break;
        }
      }
      get status() {
        return this._status;
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);

        this.handleSessionStatusUpdate = handleSessionStatusUpdate.bind(this);
        this.handleRemoteIdentityUpdate = handleRemoteIdentityUpdate.bind(this);

        this.dtmfString = '';
      }

      hideAllButtons() {
        hide(this.actions.activate);

        enable(this.actions.toggleMute);
        enable(this.actions.toggleKeypad);
      }

      showTransferButton() {
        const { side, a } = temp.get(this.session.id) || {};

        if (!side) {
          show(this.actions.toggleTransfer);
        } else {
          if (side === 'a') {
            show(this.actions.toggleTransfer);
          }

          if (side === 'b') {
            const value = temp.get(a);
            if (value) {
              hide(this.actions.toggleTransfer);
            } else {
              show(this.actions.toggleTransfer);
            }
          }
        }
      }

      async showOnHoldButtons() {
        hide(this.actions.hold);
        show(this.actions.activate);

        this.actions.activate.classList.add('active');
        disable(this.actions.toggleMute);
        disable(this.actions.toggleKeypad);

        this.showTransferButton();
      }

      showHoldOrActiveLabel() {
        const { isIncoming } = this.session;

        if (this.status === 'ringing') {
          this.nodes.sessionTimer.textContent = `00:00`;
        }

        [
          this.nodes.onHoldCallLabel,
          this.nodes.activeCallLabel,
          this.nodes.outgoingCallLabel,
          this.nodes.incomingCallLabel,
        ].forEach(hide);

        switch (this.status) {
          case 'active':
            show(this.nodes.activeCallLabel);
            break;

          case 'on_hold':
            show(this.nodes.onHoldCallLabel);
            break;

          case 'ringing':
            if (isIncoming) {
              show(this.nodes.incomingCallLabel);
            } else {
              show(this.nodes.outgoingCallLabel);
            }
            break;
        }
      }

      async handleEvent(e) {
        switch (e.type) {
          case 'attendedTransferStatusUpdated':
            {
              const {
                detail: { a, b },
              } = e;

              let isA = false;
              let isB = false;

              if (!a) {
                delete this.aId;
              } else {
                isA = this.session.id === a.id;
              }
              if (!b) {
                delete this.bId;
              } else {
                isB = this.session.id === b.id;
              }

              if (isB && a) {
                this.aId = a.id;
              }
              if (isA && b) {
                this.bId = b.id;
              }

              if (isA) {
                if (b) {
                  switch (b.status) {
                    case 'active':
                      disable(this.actions.toggleTransfer);
                      this.showCompleteTransferInterface(a.id, b.id);
                      break;
                  }
                } else {
                  enable(this.actions.toggleTransfer);
                  if (
                    !this.nodes.additionalInterface.firstChild ||
                    this.nodes.additionalInterface.firstChild.nodeName !== 'C-TRANSFER'
                  ) {
                    this.setAdditionalInterface('c-transfer', e.target);
                    this.actions.toggleTransfer.classList.add('active');
                  }
                }

                if (isB) {
                  if (!a) {
                    this.showTransferButton();
                  }
                }
              }
            }
            break;

          case 'click':
            {
              const { target } = e;
              const { dataset } = target;

              if (dataset.action) {
                switch (dataset.action) {
                  case 'accept':
                    this.session && this.session.accept().catch(logger.error);
                    break;

                  case 'reject':
                    this.session && this.session.reject({ statusCode: BUSY_HERE }).catch(logger.error);
                    break;

                  case 'activate':
                    holdAllOtherSessions(this.session);
                    this.setMute(false);
                    this.session && this.session.unhold();
                    break;

                  case 'hangup':
                    this.session && (await this.session.terminate());
                    break;

                  case 'toggleMute':
                    this.toggleMute();
                    break;

                  case 'resetTransfer':
                    if (this.bId) {
                      temp.remove(this.bId);
                      delete this.bId;
                      enable(this.actions.toggleTransfer);
                      empty(this.nodes.additionalInterface);
                      this.setAdditionalInterface('c-transfer', this.actions.toggleTransfer);
                      this.setMute(true);
                      this.session.hold();
                    }
                    break;

                  case 'toggleTransfer':
                    if (this.bId) {
                      this.showCompleteTransferInterface(this.session.id, this.bId);
                    } else {
                      this.setAdditionalInterface('c-transfer', target);
                      this.setMute(true);
                      this.session.hold();
                    }
                    break;

                  case 'toggleKeypad':
                    this.setAdditionalInterface('c-keypad', target);
                    break;

                  case 'hold':
                    this.setMute(true);
                    this.session.hold();
                    break;

                  case 'copy':
                    navigator.clipboard.writeText(this.phoneNumber);
                    hide(this.nodes.copyLabel);
                    show(this.nodes.copiedLabel);
                    break;
                }
              } else if (dataset.key) {
                const isDTMFKeypad = target.parentElement.parentElement.hasAttribute('dtmf');
                if (isDTMFKeypad) {
                  this.dtmfString += dataset.key;
                  clearTimeout(this.dtmfTypingTimer);
                  this.dtmfTypingTimer = setTimeout(() => this.doneTypingDTMF(), DONE_TYPING_DTMF);
                  this.addDtmfToHistory(dataset.key);
                }
              }
            }
            break;

          case 'dtmf':
            if (this._status === 'active') {
              if (!this.nodes.additionalInterface.firstChild) {
                this.setAdditionalInterface('c-keypad', this.actions.toggleKeypad);
              }
              this.addDtmfToHistory(e.detail);
            }
            break;
        }
      }

      doneTypingDTMF() {
        this.session.dtmf(this.dtmfString, DTMF_OPTIONS);
        this.dtmfString = '';
      }

      setAdditionalInterface(type, target) {
        this.actions.toggleKeypad.classList.remove('active');
        this.actions.toggleTransfer.classList.remove('active');

        setVisualStateOfButton({
          buttonKey: 'transfer',
          state: 'inactive',
          buttonNode: this.actions.toggleTransfer,
        });

        setVisualStateOfButton({
          buttonKey: 'keypad',
          state: 'inactive',
          buttonNode: this.actions.toggleKeypad,
        });

        if (!type) {
          empty(this.nodes.additionalInterface);
          return;
        }

        const currentlyVisibleOne = this.nodes.additionalInterface.firstChild;
        let shouldAddNode = true;

        if (currentlyVisibleOne) {
          empty(this.nodes.additionalInterface);
          if (currentlyVisibleOne.nodeName === type.toUpperCase()) {
            shouldAddNode = false;
          }
          if (currentlyVisibleOne.nodeName === 'C-DTMF-DISPLAY' && type === 'c-keypad') {
            shouldAddNode = false;
          }
        }

        if (!shouldAddNode) {
          if (this.bId) {
            this.showCompleteTransferInterface(this.session.id, this.bId);
          }
        } else {
          const node = document.createElement(type);

          if (type === 'c-transfer') {
            node.setAttribute('session-id', this.session.id);

            setVisualStateOfButton({
              buttonKey: 'transfer',
              state: 'active',
              buttonNode: this.actions.toggleTransfer,
            });
          }

          if (type === 'c-keypad') {
            node.setAttribute('dtmf', '');
            const dtmfDisplay = document.createElement('c-dtmf-display');
            dtmfDisplay.updateDisplay(this.session.id);

            setVisualStateOfButton({
              buttonKey: 'keypad',
              state: 'active',
              buttonNode: this.actions.toggleKeypad,
            });
            this.nodes.additionalInterface.appendChild(dtmfDisplay);
          }

          if (target && target.classList) {
            target.classList.add('active');
          }
          this.nodes.additionalInterface.appendChild(node);
          translateNodes(this.nodes.additionalInterface);

          this.showTransferButton();
        }
      }

      showCompleteTransferInterface(aId, bId) {
        empty(this.nodes.additionalInterface);
        disable(this.actions.toggleTransfer);

        this.setAdditionalInterface('c-complete-attended-transfer');
        this.showTransferButton();

        const node = this.nodes.additionalInterface.querySelector('c-complete-attended-transfer');
        node.setAttribute('a-session-id', aId);
        node.setAttribute('b-session-id', bId);
      }

      startTimer(d) {
        this.sessionTimerInterval = setInterval(() => this.updateTimer(d), 1000);
      }

      updateTimer(d) {
        const elapsed = new Date() - d;
        const elapsedMinutes = leftPad(Math.floor(elapsed / minute));
        const elapsedSeconds = leftPad(Math.round((elapsed % minute) / second));

        this.nodes.sessionTimer.textContent = `${elapsedMinutes}:${elapsedSeconds}`;
      }

      addDtmfToHistory(key) {
        const dtmfId = `dtmf-${this.session.id}`;
        const history = temp.get(dtmfId);

        if (history) {
          const newHistory = history + key;
          temp.set(dtmfId, newHistory);
        } else {
          temp.set(dtmfId, key);
        }

        const display = document.querySelector('c-dtmf-display');
        display && display.updateDisplay(this.session.id);
      }

      async updateDOM() {
        if (this.isConnected && this.session) {
          this.showHoldOrActiveLabel();
          this.sortMuteButton();

          const { displayName, phoneNumber } = await getCallerInfo(this.session);

          this.phoneNumber = phoneNumber;

          this.nodes.name.textContent = displayName;
          this.nodes.phoneNumber.textContent = phoneNumber;
        }
      }

      sortMuteButton() {
        if (this.session.media.input.muted) {
          callingEvents.dispatchEvent(new CustomEvent('muteActive', { detail: this.session }));
          setVisualStateOfButton({ buttonKey: 'mute', state: 'active', buttonNode: this.actions.toggleMute });
          this.actions.toggleMute.classList.add('active');
        } else {
          callingEvents.dispatchEvent(new CustomEvent('muteInactive', { detail: this.session }));
          setVisualStateOfButton({ buttonKey: 'mute', state: 'inactive', buttonNode: this.actions.toggleMute });
          this.actions.toggleMute.classList.remove('active');
        }
      }

      toggleMute() {
        if (this.session) {
          this.session.media.input.muted = !this.session.media.input.muted;
          this.sortMuteButton();
        }
      }

      setMute(mute) {
        this.session.media.input.muted = mute;
        this.sortMuteButton();
      }

      showKeyPadIfNeeded() {
        const parsedSearchParams = searchParamsParse();
        // make sure we open the keypad when intructions are in the url to do so...
        if ('open-keypad' in parsedSearchParams && !this.nodes.additionalInterface.firstChild) {
          delete parsedSearchParams['open-keypad'];
          const cleanedSearchParams = searchParamsStringify(parsedSearchParams);
          redirect(`${document.location.pathname}${cleanedSearchParams ? `?${cleanedSearchParams}` : ''}`);
          this.setAdditionalInterface('c-keypad', this.actions.toggleKeypad);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        [
          this.actions.activate,
          this.actions.toggleMute,
          this.actions.toggleTransfer,
          this.actions.toggleKeypad,
          this.actions.hold,
          this.actions.hangup,
          this.actions.copy,
          this.nodes.additionalInterface,
        ].forEach((n) => n.addEventListener('click', this));

        dtmfEvents.addEventListener('dtmf', this);

        this.updateDOM();
        if (this.session) {
          this.handleSessionStatusUpdate(this.session);
        }

        callingEvents.addEventListener('attendedTransferStatusUpdated', this);
        this.addEventListener('resetAttendedTransfer', this);

        if ('active' === this.session.status) {
          this.showKeyPadIfNeeded();
        }
      }

      disconnectedCallback() {
        if (this.session) {
          this.session.removeListener('statusUpdate', this.handleSessionStatusUpdate);
        }

        [
          this.actions.activate,
          this.actions.toggleMute,
          this.actions.toggleTransfer,
          this.actions.toggleKeypad,
          this.actions.hold,
          this.actions.hangup,
          this.actions.copy,
          this.nodes.additionalInterface,
        ].forEach((n) => n.removeEventListener('click', this));

        dtmfEvents.removeEventListener('dtmf', this);

        clearInterval(this.sessionTimerInterval);

        callingEvents.removeEventListener('attendedTransferStatusUpdated', this);
        this.removeEventListener('resetAttendedTransfer', this);
      }

      attributeChangedCallback(name, oldValue, newValue) {
        if (this.session) {
          this.session.removeListener('statusUpdate', this.handleSessionStatusUpdate);
          this.session.removeListener('remoteIdentityUpdate', this.handleRemoteIdentityUpdate);
        }
        this.session = getSession(newValue);
        this.session.on('statusUpdate', this.handleSessionStatusUpdate);
        this.session.on('remoteIdentityUpdate', this.handleRemoteIdentityUpdate);

        // prime the interface to allow to complete a transfer after a navigation action
        const { side, b } = temp.get(this.session.id) || {};

        if (side === 'a') {
          // only show the complete transfer when we also have a bId in the temp storage
          const value = temp.get(b);
          if (value) {
            const sessionB = getSession(b);
            this.bId = b;

            if (sessionB) {
              this.showCompleteTransferInterface(this.session.id, b);
            }
          }
        }

        this.updateDOM();
      }
    },
  );
});
