import { Logger } from '@/lib/logging.mjs';
import { Analytics } from '@/lib/segment-api.mjs';

import { digestMessage, hexString } from '@/utils/crypto.mjs';

const logger = new Logger('segment');

let segmentApi;
let segmentUserId;

export function isEnabled() {
  return !!import.meta.env.VITE_SEGMENT_WRITE_KEY;
}

let launchedAs;
function updateLaunchedAs() {
  const isInstalled = navigator.standalone || matchMedia('(display-mode: standalone)').matches;
  const isFullscreen = matchMedia('(display-mode: fullscreen)').matches;

  if (isInstalled) {
    logger.info('Launched: Installed');
    launchedAs = 'installed';
    // When the display-mode is fullscreen we don't know if the user is running
    // the installed or non-installed webphone.
  } else if (isFullscreen) {
    logger.info('Launched: Unknown');
    launchedAs = 'unknown';
  } else {
    logger.info('Launched: Browser-tab');
    launchedAs = 'browser-tab';
  }
}

function init() {
  if (!isEnabled()) {
    logger.warn('Telemetry disabled, API key missing');
    return;
  }

  try {
    segmentApi = new Analytics(import.meta.env.VITE_SEGMENT_WRITE_KEY, { host: '/segment' });
  } catch (e) {
    logger.error('init failed', e);
  }

  updateLaunchedAs();
  window.addEventListener('appinstalled', () => {
    logger.info('Launched: Installed');
    launchedAs = 'installed';
  });
}

async function _setUserId(email, clientId) {
  if (!email) {
    segmentUserId = undefined;
    return;
  }

  const userHash = hexString(await digestMessage(email));
  // Set user id only if it has changed. It's volatile on purpose. On every page
  // load the `identify` message is sent to Segment.
  // https://segment.com/docs/spec/identify/
  if (userHash === segmentUserId) {
    return;
  }

  logger.verbose(`setting user to ${userHash}`);
  segmentUserId = userHash;

  const clientHash = hexString(await digestMessage(clientId));
  const { language, platform, userAgent } = navigator;

  if (segmentApi) {
    segmentApi.identify({
      userId: userHash,
      traits: {
        brand: import.meta.env.VITE_BRAND,
        clientId: clientHash,
        language,
        launchedAs,
        platform,
        userAgent,
        version: import.meta.env.VITE_VERSION,
      },
    });
  }
}

export function setUserId(email) {
  _setUserId(email).catch(logger.error);
}

function trackEvent(event, properties) {
  try {
    if (!segmentUserId) {
      logger.debug('trying to track event without user!');
      return;
    }

    logger.debug(`tracking event ${event} with properties: ${JSON.stringify(properties)}`);
    if (segmentApi) {
      segmentApi.track({
        userId: segmentUserId,
        event,
        properties,
      });
    }
  } catch (e) {
    logger.error(e);
  }
}

function tr(event, properties) {
  return () => trackEvent(event, properties);
}

export const track = {
  adjustCallingVolume: tr('adjust_volume', { name: 'calling' }),
  adjustMasterVolume: tr('adjust_volume', { name: 'master' }),
  adjustRingtoneVolume: tr('adjust_volume', { name: 'ringtone' }),
  adjustSystemVolume: tr('adjust_volume', { name: 'system' }),
  attendedTransfer: tr('call_transfer', { mode: 'attended' }),
  audioDeviceUpdate: tr('audio_device_update', { deviceType: 'audio' }),
  audioProcessingUpdate: tr('audio_processing_update'),
  blindTransfer: tr('call_transfer', { mode: 'blind' }),
  callControlAction: (action, vendor) => {
    trackEvent('call_control', { action, vendor });
  },
  callContact: tr('call_contact'),
  callRecent: tr('call_recent'),
  changeTemporaryRedirect: tr('change_temporary_redirect'),
  changelog: tr('open_feature_announcements'),
  contactUs: tr('contact_us'),
  copyRecentPhoneNumber: tr('copy_recent_phone_number'),
  createContact: tr('create_contact'),
  darkModeToggle: tr('dark_mode_toggle'),
  deleteContact: tr('delete_contact'),
  editContact: tr('edit_contact'),
  favoriteToggle: tr('favorite_toggle'),
  incomingCall: tr('call', { direction: 'incoming' }),
  installButton: tr('install_button'), // todo
  installed: tr('installed'), // todo
  login: tr('login'),
  logout: tr('logout'),
  maintenanceMessageClosed: tr('maintenance_message_closed'),
  maintenanceMessageLinkClicked: tr('maintenance_message_link_clicked'),
  microphoneDeviceUpdate: tr('audio_device_update', { deviceType: 'microphone' }),
  outgoingCall: tr('call', { direction: 'outgoing' }),
  passwordChangeRequired: tr('password_change_required'),
  poorAudioQuality: tr('poor_audio_quality'),
  queueActiveToggle: tr('queue_active_toggle'),
  redial: tr('redial'),
  ringtoneDeviceUpdate: tr('audio_device_update', { deviceType: 'ringtone' }),
  setupTemporaryRedirect: tr('setup_temporary_redirect'),
  softphoneAccountUpdate: tr('softphone_account_update'),
  setStatus: (status) => trackEvent('set_user_status', { status }),
  toggleRemoteLogging: tr('remote_logging_toggle'),
  toggleShowOnlyWebphoneCalls: tr('toggle_show_only_webphone_calls'),
  triedToTroubleshoot: (fromPart) => {
    tr('user_tried_troubleshooting', { fromPart });
  },
  visitedTroubleshootPage: tr('visited_troubleshooting_page'),
  visitedNotificationsSupportPage: tr('visited_notifications_support_page'),
  updateAvailability: tr('availability_update'),
  userRating: (rating) => {
    trackEvent('user_rating', { rating });
  },
  wizardCompleted: tr('wizard_completed'),
  wizardDevicesStepCompleted: tr('wizard_step_completed', { stepName: 'devices' }),
  wizardInstallStepCompleted: tr('wizard_step_completed', { stepName: 'install' }), // todo
  wizardSoftphoneAccountStepCompleted: tr('wizard_step_completed', { stepName: 'account' }),
  wizardWelcomeStepCompleted: tr('wizard_step_completed', { stepName: 'welcome' }),
  click2dial: (autoAnswer) => {
    const auto_answer = autoAnswer ? 'yes' : 'no';
    trackEvent('call_click2dial', { auto_answer });
  },
  fromClickToDial: (toPage) => {
    trackEvent('linked_from_click2dial_to', { toPage });
  },
  openPlatform: (path) => {
    if (!path) {
      return;
    }
    const module = path.split('/')[0];
    trackEvent('open_platform', { module });
  },
  mosValues: (sessionId, clientId, { average, count, highest, last, lowest, sum }) => {
    trackEvent('mos_values', { sessionId, clientId, average, count, highest, last, lowest, sum, type: 'webphone' });
  },
  selectedUserDestinationWasOffline: tr('selected_user_destiantion_was_offline'),
};

init();
