export default function (userAgent) {
  userAgent = userAgent.toLowerCase();

  if (userAgent.includes('windows')) {
    return 'Windows';
  } else if (userAgent.includes('mac os') || userAgent.includes('macintosh')) {
    return 'macOS';
  } else if (userAgent.includes('linux')) {
    return 'Linux';
  } else {
    return 'Other';
  }
}
