import { translateNodes } from '@/lib/i18n.mjs';
import { getPlatformUrl } from '@/lib/platform.mjs';
import request from '@/lib/request.mjs';
import * as segment from '@/lib/segment.mjs';
import * as temp from '@/lib/temporary-storage.mjs';
import * as user from '@/lib/user.mjs';

import { getFormValues, hide, loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import { navigate } from '@/utils/history.ts';

loadTemplate('p-change-password').then(({ content }) => {
  window.customElements.define(
    'p-change-password',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
      }

      handleEvent(e) {
        e.preventDefault();

        const { target } = e;

        switch (e.type) {
          case 'click':
            break;

          case 'submit': {
            e.preventDefault();
            const { oldPass, newPass } = getFormValues(target);

            hide(this.nodes.changePasswordError);

            user
              .changePassword({
                currentPassword: oldPass || this.password,
                newPassword: newPass,
              })
              .then(user.getEmail)
              .then((email) =>
                user.login({
                  email,
                  password: newPass,
                }),
              )
              .catch(async ({ body }) => {
                if (body && body.apitoken && body.apitoken.two_factor_token) {
                  await user.logout();
                  navigate('/login');
                } else {
                  const errorCode = body['__all__'][0]['code'];
                  this.showChangePasswordError(errorCode);
                }
              });
          }
        }
      }

      async showChangePasswordMessage() {
        hide(this.nodes.changePasswordForm);
        show(this.nodes.changePasswordMessage);

        const path = await getPlatformUrl('user/personal_settings');
        this.nodes.changePasswordLink.setAttribute('href', path);
      }

      showPasswordChangeInput() {
        this.nodes.newPasswordInput.setAttribute('required', '');
        hide(this.nodes.changePasswordMessage);
        show(this.nodes.changePasswordForm);
      }

      showAuthenticationError() {
        show(this.nodes.authenticationError);
      }

      showChangePasswordError(errorCode) {
        show(this.nodes.changePasswordError);
        // Set the data-translation-key to make sure when the language is
        // changed it also translates the error message.
        this.nodes.changePasswordErrorMessage.setAttribute(
          'data-translation-key',
          `change_password_error_${errorCode}`,
        );
        translateNodes(this.nodes.changePasswordError);
      }

      connectedCallback() {
        this.classList.add('loading');

        this.appendChild(content.cloneNode(true));

        translateNodes(this);

        this.nodes.changePasswordForm.addEventListener('submit', this);

        segment.track.passwordChangeRequired();

        request('isChangePasswordEndpointLive')
          .then(() => {
            this.showPasswordChangeInput();
          })
          .catch(() => {
            this.showChangePasswordMessage();
          })
          .then(() => {
            const persisted = temp.get('ZZZZZ');

            if (!persisted) {
              show(this.nodes.oldPasswordInputContainer);
            }

            let password = persisted ? atob(persisted) : undefined;
            if (password) {
              this.password = password;
              hide(this.nodes.oldPasswordInputContainer);
              hide(this.nodes.changePasswordBody);
              show(this.nodes.changePasswordBodyPrefilled);
              temp.remove('ZZZZZ');
            } else {
              show(this.nodes.oldPasswordInputContainer);
            }
          })
          .finally(() => {
            this.classList.remove('loading');
          });
      }

      disconnectedCallback() {
        this.nodes.changePasswordForm.removeEventListener('submit', this);
      }
    },
  );
});
