import getPhoneAccount from '@/lib/data/getPhoneAccount.mjs';
import request from '@/lib/request.mjs';

import { dataEvents } from '@/utils/eventTarget.ts';

export default async function () {
  let account;

  try {
    account = await request('getAccount');
  } catch (e) {
    return undefined;
  }

  dataEvents.dispatchEvent(new CustomEvent('getAccount'));

  if (account) {
    const { account_id, id, description, internal_number, password } = account;

    let country;
    try {
      const phoneAccount = await getPhoneAccount({ accountId: id });
      country = phoneAccount.country;
    } catch {
      country = 'nl';
    }

    return {
      id,
      description,
      password,
      accountId: account_id,
      phoneNumber: internal_number,
      countryCode: country,
    };
  }

  return undefined;
}
