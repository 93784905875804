import request from '@/lib/request.mjs';
import * as user from '@/lib/user.mjs';

export default async function (forceRefresh = false) {
  const { clientUuid } = await user.getUserFromStorage();

  const openingHoursBasicList = await request('getOpeningHoursBasic', { client_uuid: clientUuid, forceRefresh });

  return openingHoursBasicList.items;
}
