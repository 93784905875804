import '@/components/c-account-picker.mjs';
import '@/components/c-audio-device-selection.mjs';
import '@/components/c-audio-processing-toggle.mjs';
import '@/components/c-remote-logging-toggle.mjs';
import '@/components/c-setting-toggle.mjs';
import '@/components/c-show-online-contacts-toggle.mjs';
import '@/components/c-temporary-redirect-wrapper.mjs';
import '@/components/c-volume-selection.mjs';

import validateIfUserHasAllPermissions from '@/lib/data/validateIfUserHasAllPermissions.mjs';
import { translateNodes } from '@/lib/i18n.mjs';
import { canBeInstalledAsPWA } from '@/lib/install.mjs';
import { notificationSettings } from '@/lib/settings/remote.mjs';
import * as user from '@/lib/user.mjs';

import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';

import { temporaryRedirectPermissions } from '@/constants/userPermissions.mjs';

loadTemplate('p-settings').then(({ content }) => {
  window.customElements.define(
    'p-settings',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      handleEvent(e) {
        switch (e.type) {
          case 'beforeinstallprompt':
            e.preventDefault();
            this.sortAppSectionVisibility();
            break;

          case 'appinstalled':
            this.sortAppSectionVisibility();
            break;
        }
      }

      sortAppSectionVisibility() {
        canBeInstalledAsPWA ? show(this.nodes.appSection) : hide(this.nodes.appSection);
      }

      async sortTemporaryRedirectVisibility() {
        const hasPermissions = await validateIfUserHasAllPermissions(temporaryRedirectPermissions);
        if (hasPermissions) {
          show(this.nodes.organisationSection);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.sortAppSectionVisibility();
        this.sortTemporaryRedirectVisibility();

        window.addEventListener('beforeinstallprompt', this);
        window.addEventListener('appinstalled', this);

        const { firstName, lastName } = await user.getUserFromStorage();

        this.nodes.userName.textContent = `${firstName} ${lastName}`;

        notificationSettings.forEach(({ settingsKey, labelTranslationKey, helpTextTranslationKey }, index) => {
          const labelNode = document.createElement('label');
          labelNode.classList.add('with-help-text');
          labelNode.setAttribute('for', settingsKey);
          labelNode.setAttribute('data-translation-key', labelTranslationKey);
          labelNode.setAttribute('data-selector', settingsKey);

          const toggleNode = document.createElement('c-setting-toggle');
          toggleNode.setAttribute('data-settings-key', settingsKey);

          const helpTextNode = document.createElement('p');
          helpTextNode.classList.add('help-text');
          helpTextNode.setAttribute('data-translation-key', helpTextTranslationKey);

          const divNode = document.createElement('div');
          divNode.appendChild(labelNode);
          divNode.appendChild(helpTextNode);

          const divContainerNode = document.createElement('div');
          divContainerNode.appendChild(toggleNode);
          divContainerNode.appendChild(divNode);

          const lastChild = index === notificationSettings.length - 1;
          if (lastChild) {
            divContainerNode.classList.add('checkbox-helptext', 'mb-0');
          } else {
            divContainerNode.classList.add('checkbox-helptext', 'mb-1');
          }

          this.nodes.notificationsSection.appendChild(divContainerNode);
          toggleNode.querySelector('input').setAttribute('id', settingsKey);
          toggleNode.querySelector('label').setAttribute('for', settingsKey);
        });

        translateNodes(this);
      }

      disconnectedCallback() {
        window.removeEventListener('beforeinstallprompt', this);
        window.removeEventListener('appinstalled', this);
      }
    },
  );
});
