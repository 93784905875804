import { requestEvents } from '@/utils/eventTarget.ts';
import { getLinks } from '@/utils/links.mjs';

export default async function responseHandler(response, requestOptions) {
  const { status, headers } = response;
  let data;

  if (status === 400) {
    try {
      data = await response.json();
      return Promise.reject({
        status,
        url: response.url,
        statusText: response.statusText,
        body: data,
      });
    } catch (err) {
      throw new Error('bad_request');
    }
  }

  if (status === 401) {
    // If the endpoint is served from Holodeck, the user can still stay logged in
    // There could be an error with the authorisation service, but your token from VG can still be valid
    // So we'll make an exception for Holodeck 401's
    if (!requestOptions?.isHolodeckEndpoint) {
      requestEvents.dispatchEvent(new CustomEvent('unauthorised'));
    }
    throw new Error('unauthorised');
  }

  if (status === 403) {
    const message = await response.text();
    if (message === 'You need to change your password in the portal') {
      requestEvents.dispatchEvent(new CustomEvent('changePassword'));
      throw new Error('change_password');
    }
    throw new Error(message);
  }

  if (status === 404) {
    return undefined;
  }

  if (status === 422) {
    throw new Error('validation_error');
  }

  if (status === 429) {
    throw new Error('too_many_requests');
  }

  const meta = {};
  if (headers?.has('X-total-count')) {
    meta.totalCount = parseInt(headers.get('X-total-count'), 10);
  }
  if (headers?.has('Link')) {
    meta.links = getLinks(headers.get('Link'));
  }

  if (status === 200 || status === 201) {
    try {
      data = await response.json();
      if (meta.links) {
        return { data, meta };
      } else {
        return data;
      }
    } catch (err) {
      return {};
    }
  }
}
