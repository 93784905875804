/*
TAKEN FROM https://github.com/aaronblohowiak/routes.js
and reformatted to use native ESM
*/

/**
 * Convert path to route object
 *
 * A string or RegExp should be passed,
 * will return { re, src, keys} obj
 *
 * @param  {String / RegExp} path
 * @return {Object}
 */

export function Route(path) {
  //using 'new' is optional

  var re,
    keys = [];

  if (path instanceof RegExp) {
    re = path;
  } else {
    re = pathToRegExp(path, keys);
  }

  return {
    re: re,
    src: path.toString(),
    keys: keys,
  };
}

/**
 * Normalize the given path string,
 * returning a regular expression.
 *
 * An empty array should be passed,
 * which will contain the placeholder
 * key names. For example "/user/:id" will
 * then contain ["id"].
 *
 * @param  {String} path
 * @param  {Array} keys
 * @return {RegExp}
 */
export function pathToRegExp(path, keys) {
  path = path
    .concat('/?')
    .replace(/\/\(/g, '(?:/')
    .replace(/(\/)?(\.)?:(\w+)(?:(\(.*?\)))?(\?)?|\*/g, function (_, slash, format, key, capture, optional) {
      if (_ === '*') {
        keys.push(undefined);
        return _;
      }

      keys.push(key);
      slash = slash || '';
      return (
        '' +
        (optional ? '' : slash) +
        '(?:' +
        (optional ? slash : '') +
        (format || '') +
        (capture || '([^/]+?)') +
        ')' +
        (optional || '')
      );
    })
    .replace(/([/.])/g, '\\$1')
    .replace(/\*/g, '(.*)');
  return new RegExp('^' + path + '$', 'i');
}

/**
 * Attempt to match the given request to
 * one of the routes. When successful
 * a  {fn, params, splats} obj is returned
 *
 * @param  {Array} routes
 * @param  {String} uri
 * @return {Object}
 */
export function match(routes, uri, startAt) {
  var captures,
    i = startAt || 0;

  for (var len = routes.length; i < len; ++i) {
    var route = routes[i],
      re = route.re,
      keys = route.keys,
      splats = [],
      params = {};

    if ((captures = uri.match(re))) {
      for (var j = 1, capturesLength = captures.length; j < capturesLength; ++j) {
        var key = keys[j - 1],
          val = typeof captures[j] === 'string' ? unescape(captures[j]) : captures[j];
        if (key) {
          params[key] = val;
        } else {
          splats.push(val);
        }
      }
      return {
        params: params,
        splats: splats,
        route: route.src,
        next: i + 1,
      };
    }
  }
}

/**
 * Default "normal" router constructor.
 * accepts path, fn tuples via addRoute
 * returns {fn, params, splats, route}
 *  via match
 *
 * @return {Object}
 */

function Router() {
  //using 'new' is optional
  return {
    routes: [],
    routeMap: {},
    addRoute: function (path, fn) {
      if (!path) {
        throw new Error(' route requires a path');
      }
      if (!fn) {
        throw new Error(' route ' + path.toString() + ' requires a callback');
      }

      if (this.routeMap[path]) {
        throw new Error('path is already defined: ' + path);
      }

      var route = Route(path);
      route.fn = fn;

      this.routes.push(route);
      this.routeMap[path] = fn;
    },

    removeRoute: function (path) {
      if (!path) {
        throw new Error(' route requires a path');
      }
      if (!this.routeMap[path]) {
        throw new Error('path does not exist: ' + path);
      }

      var newRoutes = [];

      // copy the routes excluding the route being removed
      for (var i = 0; i < this.routes.length; i++) {
        var route = this.routes[i];
        if (route.src !== path) {
          newRoutes.push(route);
        }
      }
      this.routes = newRoutes;
      delete this.routeMap[path];
    },

    match: function (pathname, startAt) {
      var route = match(this.routes, pathname, startAt);
      if (route) {
        route.fn = this.routeMap[route.route];
        route.next = this.match.bind(this, pathname, route.next);
      }
      return route;
    },
  };
}

export default new Router();
