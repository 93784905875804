import '@/components/c-sound-meter.mjs';

import { headsetManagerInstance } from '@/lib/headset/manager.ts';
import { translate } from '@/lib/i18n.mjs';
import { Logger } from '@/lib/logging.mjs';
import * as media from '@/lib/media.mjs';
import * as segment from '@/lib/segment.mjs';
import { playSound } from '@/lib/sounds.mjs';

import { disable, empty, enable, hide, loadTemplate, select, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { mediaEvents } from '@/utils/eventTarget.ts';
import showSettingSaved from '@/utils/settingSaved.ts';

const logger = new Logger('audio-device-selection');

loadTemplate('c-audio-device-selection').then(({ content }) => {
  window.customElements.define(
    'c-audio-device-selection',
    class extends HTMLElement {
      async setHeadsetInput(id) {
        const device = media.getInputs().find((input) => input.id === id);
        await media.setHeadsetInput(device);
        mediaEvents.dispatchEvent(new CustomEvent('headsetInputChanged'));
        this.soundMeter.deviceId = id;
        segment.track.microphoneDeviceUpdate();
        showSettingSaved(this.nodes.headsetInputSaved);
      }

      async setHeadsetOutput(id) {
        const device = media.getOutputs().find((output) => output.id === id);
        await media.setHeadsetOutput(device);
        segment.track.audioDeviceUpdate();
        showSettingSaved(this.nodes.headsetOutputSaved);
      }

      async setRingtoneOutput(id) {
        const device = media.getOutputs().find((output) => output.id === id);
        await media.setRingtoneOutput(device);
        segment.track.ringtoneDeviceUpdate();
        showSettingSaved(this.nodes.ringtoneOutputSaved);
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      async handleEvent({ type, currentTarget, target: { value }, x, y }) {
        if ('change' === type) {
          switch (currentTarget) {
            case this.nodes.headsetInputs:
              this.setHeadsetInput(value);
              break;

            case this.nodes.headsetOutputs:
              this.setHeadsetOutput(value);
              break;

            case this.nodes.ringtoneOutputs:
              this.setRingtoneOutput(value);
              break;
          }
        }
        if ('click' === type) {
          switch (currentTarget) {
            case this.actions.callControlReload:
              // Since the user is already clicking on a button to reload the Webphone
              // We don't have to show the accidental close dialog
              window.DO_NOT_PREVENT_UNLOAD = true;
              window.location.reload();
              break;

            case this.actions.testHeadsetOutput:
              {
                this.createPlayingSoundNode(x, y);
                disable(this.actions.testHeadsetOutput);
                playSound('busytone', 'settings').finally(() => {
                  enable(this.actions.testHeadsetOutput);
                });
              }
              break;

            case this.actions.testRingtoneOutput:
              {
                this.createPlayingSoundNode(x, y);
                disable(this.actions.testRingtoneOutput);
                playSound('ringtone', 'settings').finally(() => {
                  enable(this.actions.testRingtoneOutput);
                });
              }
              break;

            case this.actions.connectWebHIDCallControl:
              {
                // We either connect EPOS, Jabra or Yealink
                try {
                  // Check if WebHID is available
                  if (!('hid' in navigator)) return;

                  // We're not adding specific filters, since we want to catch alot of different devices
                  const devices = await navigator.hid.requestDevice({ filters: [] });

                  // When devices have been connected, show the dialog
                  // so users can reload the page
                  if (devices && devices.length > 0) {
                    this.nodes.callControlReloadTitle.textContent = translate('call_control_reload_title', {
                      deviceName: devices[0].productName,
                    });
                    logger.info('WebHID device connected:', devices[0].productName);
                    this.nodes.callControlReloadDialog.showModal();
                  }
                } catch (error) {
                  logger.error('Failed to connect WebHID device:', error);
                }
              }
              break;
          }
        }
        if ('devicesChanged' === type) {
          this.populateSelects();
        }

        if ('headsetStatusUpdate' === type) {
          this.handleHeadsetStatusUpdate();
        }
      }

      // Shows the play icon on the location where the sound is played from (e.g. after pressing a button)
      createPlayingSoundNode(x, y) {
        const node = document.createElement('c-short-lived-visual-feedback');
        node.setAttribute('key', 'audio-device-test-feedback');
        node.setAttribute('icon', 'speaker');
        node.position(x, y);
        document.body.append(node);
      }

      async populateSelect(selectNode, devices, storedDevicePromise) {
        const storedDevice = await storedDevicePromise;

        empty(selectNode);

        if (devices.length === 0) {
          disable(selectNode);

          const option = document.createElement('option');
          option.textContent = translate('no_devices_available');
          disable(option);
          select(option);
          hide(option);
          selectNode.appendChild(option);
        } else {
          enable(selectNode);
        }

        devices.forEach((device) => {
          const option = document.createElement('option');
          option.value = device.id;
          option.textContent = device.name;

          if (storedDevice && storedDevice.id === device.id) {
            select(option);
          }

          selectNode.appendChild(option);
        });

        this.soundMeter.deviceId = (await media.getHeadsetInput()).id;
      }

      async populateSelects() {
        await this.populateSelect(this.nodes.headsetInputs, media.getInputs(), media.getHeadsetInput());
        await this.populateSelect(this.nodes.headsetOutputs, media.getOutputs(), media.getHeadsetOutput());
        await this.populateSelect(this.nodes.ringtoneOutputs, media.getOutputs(), media.getRingtoneOutput());
      }

      /*
       * This function gets triggered if the headset retrieves a new status
       * Based on the status it wil show the correct UI
       */
      async handleHeadsetStatusUpdate() {
        const vendorName = await headsetManagerInstance.getVendorName();
        this.nodes.callControlVendorName.textContent = vendorName;
        const status = await headsetManagerInstance.getStatus();

        // To avoid eleborate if/else statements, we hide all elements
        // and show the correct one based on the status
        show(this.nodes.callControlSetup);
        hide(this.nodes.callControlStatusChecking);
        hide(this.nodes.callControlStatusEnabled);
        hide(this.nodes.callControlStatusPermissionsRequired);
        this.nodes.callControlStatusIndicator.classList = 'call-control-status-indicator';

        switch (status) {
          case 'NOT_SUPPORTED':
            hide(this.nodes.callControlSetup);
            break;
          case 'CHECKING':
            show(this.nodes.callControlStatusChecking);
            this.nodes.callControlStatusIndicator.classList.add('is--checking');
            break;
          case 'ENABLED':
            show(this.nodes.callControlStatusEnabled);
            this.nodes.callControlStatusIndicator.classList.add('is--enabled');
            break;
          case 'PERMISSIONS_REQUIRED':
            show(this.nodes.callControlStatusPermissionsRequired);
            this.nodes.callControlStatusIndicator.classList.add('is--permissions-required');
            break;
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.soundMeter = this.querySelector('c-sound-meter');

        this.nodes.headsetInputs.addEventListener('change', this);
        this.nodes.headsetOutputs.addEventListener('change', this);
        this.nodes.ringtoneOutputs.addEventListener('change', this);
        this.actions.testHeadsetOutput.addEventListener('click', this);
        this.actions.testRingtoneOutput.addEventListener('click', this);
        this.actions.connectWebHIDCallControl.addEventListener('click', this);
        this.actions.callControlReload.addEventListener('click', this);

        mediaEvents.addEventListener('devicesChanged', this);
        mediaEvents.addEventListener('headsetStatusUpdate', this);

        media
          .requestPermission()
          .then(() => this.populateSelects())
          .catch(() => {
            logger.info('microphone permission denied, cannot fill select with audio devices');
          });

        this.populateSelects();

        this.soundMeter.deviceId = (await media.getHeadsetInput()).id;

        // Fetch initial headset status
        this.handleHeadsetStatusUpdate();
      }

      disconnectedCallback() {
        this.nodes.headsetInputs.removeEventListener('change', this);
        this.nodes.headsetOutputs.removeEventListener('change', this);
        this.nodes.ringtoneOutputs.removeEventListener('change', this);
        this.actions.testHeadsetOutput.removeEventListener('click', this);
        this.actions.testRingtoneOutput.removeEventListener('click', this);
        this.actions.connectWebHIDCallControl.removeEventListener('click', this);
        this.actions.callControlReload.removeEventListener('click', this);

        mediaEvents.removeEventListener('devicesChanged', this);
        mediaEvents.removeEventListener('headsetStatusUpdate', this);
      }
    },
  );
});
