import '@/components/c-contact-create-edit-form.mjs';

import * as contacts from '@/lib/contacts.mjs';
import { Logger } from '@/lib/logging.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import { getUrlParams } from '@/utils/url.ts';

const logger = new Logger('contact-edit');

loadTemplate('p-contact-edit').then(({ content }) => {
  window.customElements.define(
    'p-contact-edit',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        contacts
          .one(getUrlParams().id)
          .then((contact) => (this.nodes.contactForm.contactInstance = contact))
          .catch(logger.error);
      }
    },
  );
});
