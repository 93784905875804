import { loadTemplate } from '@/utils/dom.ts';

loadTemplate('c-no-audio-devices').then(({ content }) => {
  window.customElements.define(
    'c-no-audio-devices',
    class extends HTMLElement {
      connectedCallback() {
        this.appendChild(content.cloneNode(true));
      }
    },
  );
});
