import { chosenLanguage, translate } from '@/lib/i18n.mjs';

import {
  getDateTimeString as _getDateTimeString,
  getDisplayDateAndTime as _getDisplayDateAndTime,
  getTimeString as _getTimeString,
  getWeekDay as _getWeekDay,
} from '@/utils/formatDate/index.ts';

export function getDateTimeString(dateToFormat: Date, language = chosenLanguage) {
  return _getDateTimeString(dateToFormat, language, translate);
}

export function getTimeString(date: Date, language = chosenLanguage) {
  return _getTimeString(date, language);
}

export function getDisplayDateAndTime(date: Date, language = chosenLanguage) {
  return _getDisplayDateAndTime(date, language);
}

export function getWeekDay(date: Date, shouldCapitalize: boolean, isShortWeekDay: boolean, language = chosenLanguage) {
  return _getWeekDay(date, shouldCapitalize, isShortWeekDay, language);
}
