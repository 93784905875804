import getDestination from '@/lib/data/getDestination.mjs';
import request from '@/lib/request.mjs';

import { dataEvents } from '@/utils/eventTarget.ts';

export default async function (destination) {
  const { id } = await getDestination();
  const body = {
    fixeddestination: destination && destination.phone_number ? destination.id : null,
    phoneaccount: destination && destination.internal_number ? destination.id : null,
  };

  // this event is used to update the status pill when a user destination is set.
  dataEvents.dispatchEvent(new CustomEvent('setDestination', { detail: body }));

  return await request('setDestination', { userDestinationId: id, body });
}
