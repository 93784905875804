import { chosenLanguage } from '@/lib/i18n.mjs';

import _sortColltacts from '@/utils/sortColltacts/index.ts';

export default function sortColltacts(
  _a: { fullName?: string; description?: string },
  _b: { fullName?: string; description?: string },
): number {
  return _sortColltacts(_a, _b, chosenLanguage);
}
