import markAsValidatedBefore from './formValidators/helpers/markAsValidatedBefore.mjs';
import reportValidityIfNeeded from './formValidators/helpers/reportValidityIfNeeded.mjs';
import * as validators from './formValidators/index.mjs';

import { getFormElements } from '@/utils/dom.ts';

const validatorsRegistry = new Map();

export async function validateForm(form) {
  const elements = getFormElements(form);
  elements.forEach((target) => markAsValidatedBefore({ target }));
  return Promise.all(getFormElements(form).map(validateNode))
    .then((outcome) => {
      const index = outcome.findIndex((boolean) => boolean === false);
      if (index !== -1) {
        reportValidityIfNeeded({ target: elements[index] });
      }

      return outcome;
    })
    .catch(console.error);
}

export function validateNode(node) {
  const { validator } = node.dataset;
  return validators[validator].validate({ target: node });
}

export function prepareNode(node, type = node.dataset.validator) {
  if (type in validators) {
    validatorsRegistry.set(node, validators[type].setup(node));
  }
}
export function tearDownNode(node) {
  if (validatorsRegistry.has(node)) {
    validatorsRegistry.get(node);
    validatorsRegistry.delete(node);
  }
}

export function prepareForm(form) {
  getFormElements(form).forEach((n) => prepareNode(n));
}

export function tearDownForm(form) {
  getFormElements(form).forEach(tearDownNode);
}
