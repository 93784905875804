import { invite, isAbleToMakeCall } from '@/lib/calling.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { callingEvents } from '@/utils/eventTarget.ts';

const currentPathnameRegExp = /\/call\/?/;

loadTemplate('p-call').then(({ content }) => {
  window.customElements.define(
    'p-call',
    class extends HTMLElement {
      handleEvent() {
        if (isAbleToMakeCall) {
          invite(this.number);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        const { pathname } = document.location;
        this.number = pathname.replace(currentPathnameRegExp, '');

        callingEvents.addEventListener('clientStatusUpdated', this);
      }

      disconnectedCallback() {
        callingEvents.removeEventListener('clientStatusUpdated', this);
      }
    },
  );
});
