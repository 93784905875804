export default function (items) {
  const webphone = items.webphone.voip_account;

  const app = items.app.voip_account;

  const deskphones = items.destinations.voip_accounts.filter((account) => {
    if (account.account_id === webphone?.account_id || account.account_id === app?.account_id) return false;
    return true;
  });

  const fixedDestinations = items.destinations.fixed_destinations;

  const selectedDestination = items.selected_destination.voip_account
    ? items.selected_destination.voip_account
    : items.selected_destination.fixed_destination;

  let selectedDestinationType;
  if (selectedDestination === null) {
    selectedDestinationType = 'noDestination';
  } else if (selectedDestination?.phone_number) {
    selectedDestinationType = 'fixedDestinations';
  } else if (selectedDestination?.id === webphone?.id) {
    selectedDestinationType = 'webphone';
  } else if (selectedDestination?.id === app?.id) {
    selectedDestinationType = 'app';
  } else {
    selectedDestinationType = 'deskphones';
  }

  return {
    webphone,
    app,
    fixedDestinations,
    deskphones,
    selectedDestination,
    selectedDestinationType,
    allVoipAccounts: items.destinations.voip_accounts,
  };
}
