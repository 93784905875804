import * as settings from '@/lib/settings/remote.mjs';

import { userEvents } from '@/utils/eventTarget.ts';

const {
  VITE_LOKI_API_URL,
  VITE_LOKI_API_TOKEN,
  VITE_LOKI_SEND_INTERVAL_IN_MS,
  VITE_LOGS_TO_CONSOLE,
  VITE_LOG_LEVEL,
  VITE_BRAND,
  VITE_VERSION,
} = import.meta.env;

const LEVELS = {
  error: 4,
  warn: 3,
  info: 2,
  verbose: 1,
  debug: 0,
};

/*
 * Library to send logs to Loki, our on-premise logging service.
 * Sending logs to a specialized service is also known as 'remote logging'.
 * This lib exposes the Logger class which is used by the loggers.mjs lib.
 */

// Keep track of some user meta information to send in each log message
// so we can search on these values in Loki. Also known as a 'trace'.
let userUuid;
let clientId;

// Keep a buffer of logs that gets send to Loki on the set interval (see bottom of this file).
// The buffer is flushed after sending it to Loki.
let logs = [];

// Users need to enable remote logging in the settings themselves.
// This state is saved here and also in the remote (user) settings.
let enabled = false;

/**
 * Start this library, also executed when the user logs in (see bottom of this file).
 */
function init() {
  if (!isSupported()) {
    logToConsole('warn', 'VITE_LOKI_API_TOKEN environment variable is not set, logs will not be send to loki');
    return;
  }

  settings.get('remoteLoggingEnabled').then((_enabled) => {
    enabled = _enabled;
  });
}

/**
 * Enable or disable remote logging.
 * @param {boolean} _enabled - Whether to enable remote logging or not.
 */
async function setEnabled(_enabled) {
  enabled = _enabled;
  await settings.set('remoteLoggingEnabled', enabled);
}

/**
 * Whether remote logging is enabled or not.
 */
export function isEnabled() {
  return enabled;
}

/**
 * Quickly set remote logging to enabled.
 */
export async function enable() {
  if (!isSupported()) {
    throw new Error('remote logging disabled, API key missing');
  }

  await setEnabled(true);
}

/**
 * Quickly set remote logging to disabled.
 */
export async function disable() {
  await setEnabled(false);
}

/**
 * Returns true if an API token for Loki is set and thus we can use remote logging.
 */
export function isSupported() {
  return !!VITE_LOKI_API_TOKEN;
}

/**
 * Set the trace for logging.
 * @param {string} _userUuid - The user's UUID.
 * @param {string} _clientId - The client's ID (we are not using UUID for the client here to make it easier to use the logs while debugging).
 */
export function setUserId(_userUuid, _clientId) {
  userUuid = _userUuid;
  clientId = _clientId;
  _log('info', 'set trace for logging', { userUuid, clientId });
}

/**
 * Add a log to the log buffer and show it in the console.
 * @param {'info' | 'warn' | 'error'} level - The urgency of the log, should match the console API.
 * @param {string} message - The log message.
 * @param {object} details - An object that you can put in anything to add more context to the log.
 */
function _log(level, message, details) {
  // Surround the log command in a try catch to not disrupt the app if an error occurs and to prevent infinite cycle of the window 'error' and 'unhandledrejection' listeners.
  try {
    const unixTimestampInNanoseconds = Date.now() * 1000000;
    const log = JSON.stringify({
      level,
      message,
      ...details,
      userUuid,
      clientId,
      version: VITE_VERSION,
    });

    if (LEVELS[level] >= LEVELS[VITE_LOG_LEVEL]) {
      // Push a log on the logs buffer in the format that Loki wants.
      logs.push([unixTimestampInNanoseconds.toString(), log]);
      // Output to the console as well in a more dev-console-readable format.
      logToConsole(level, message);
    }
  } catch (e) {
    console.error('error during logging', e);
  }
}

/**
 * Send the logs buffer to Loki and empty it.
 */
function sendToLoki() {
  if (logs.length === 0) {
    return;
  }

  // Make a shallow copy of the logs and then clear the logs buffer to not infinitely add logs to this array when logging is not enabled.
  const logsToSend = [...logs];

  logs = [];

  if (!enabled) {
    return;
  }

  if (!isSupported()) {
    return;
  }

  fetch(VITE_LOKI_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: VITE_LOKI_API_TOKEN,
      product: 'webphone',
      app_id: VITE_BRAND,
      logs: logsToSend,
    }),
  });
}

/**
 * Log a message to the console with a timestamp.
 * @param {'info' | 'warn' | 'error' | 'debug' } level - The urgency of the log, should match the console API.
 * @param {string} message - The log message.
 */
function logToConsole(level, message) {
  if (VITE_LOGS_TO_CONSOLE) {
    const date = new Date().toLocaleString();
    console[level](`[${date}] ${message}`);
  }
}

export class Logger {
  /**
   * A logger class that can be instantiated to group logs to a certain context. See /lib/loggers.mjs for Logger instances.
   * @param {string} context - The context (name) of this logger, e.g. 'blf'.
   */
  constructor(context) {
    this.context = context;
  }

  /**
   * Add an log to the log buffer and show it in the console. This is a generic log function to work with the Webphonelib logging.
   * @param {string} level - The urgency of the log, should match the console API
   * @param {string} message - The log message.
   * @param {object} details - An object that you can put in anything to add more context to the log.
   */
  log(level, message, details) {
    _log(level, message, this.extendDetailsWithContext(details));
  }

  /**
   * Add an debug log to the log buffer and show it in the console.
   * @param {string} message - The log message.
   * @param {object} details - An object that you can put in anything to add more context to the log.
   */
  debug(message, details) {
    _log('debug', message, this.extendDetailsWithContext(details));
  }

  /**
   * Add an info log to the log buffer and show it in the console.
   * @param {string} message - The log message.
   * @param {object} details - An object that you can put in anything to add more context to the log.
   */
  info(message, details) {
    _log('info', message, this.extendDetailsWithContext(details));
  }

  /**
   * Add a warn log to the log buffer and show it in the console.
   * @param {string} message - The log message.
   * @param {object} details - An object that you can put in anything to add more context to the log.
   */
  warn(message, details) {
    _log('warn', message, this.extendDetailsWithContext(details));
  }

  /**
   * Add an error log to the log buffer and show it in the console.
   * @param {string} message - The log message.
   * @param {object} details - An object that you can put in anything to add more context to the log.
   */
  error(message, details) {
    _log('error', message, this.extendDetailsWithContext(details));
  }

  /**
   * Get the complete details object for a log message.
   * @param {object} details - An object that you can put in anything to add more context to the log.
   */
  extendDetailsWithContext(details = {}) {
    return { context: this.context, ...details };
  }
}

window.setInterval(sendToLoki, Number(VITE_LOKI_SEND_INTERVAL_IN_MS));

// Log errors that are thrown inside promises.
window.addEventListener('unhandledrejection', (e) =>
  _log('error', e.reason ? e.reason.stack : 'unknown promise error'),
);

// Log all other thrown errors.
window.addEventListener('error', (e) => _log('error', e.error ? e.error.stack : 'unknown error'));

init();

userEvents.addEventListener('loggedIn', () => {
  init();
});
