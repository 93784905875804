import * as settings from '@/lib/settings/remote.mjs';

import { userEvents } from '@/utils/eventTarget.ts';

export function setDarkMode(on) {
  if (on) {
    document.documentElement.classList.add('adhere-to-dark-mode');
  } else {
    document.documentElement.classList.remove('adhere-to-dark-mode');
  }
}

export async function updateColorScheme() {
  setDarkMode(await settings.get('isAdheringToColorScheme'));
}

export function resetColorScheme() {
  setDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
}

userEvents.addEventListener('loggedIn', () => {
  updateColorScheme();
});

userEvents.addEventListener('loggedOut', () => {
  resetColorScheme();
});
