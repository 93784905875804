export default function ({ items, allowedItems }) {
  const categorizedItems = { allowed: [], doesNotContain: [] };

  items.forEach((value) => {
    if (allowedItems.includes(value)) {
      categorizedItems.allowed.push(value);
    } else {
      categorizedItems.doesNotContain.push(value);
    }
  });

  return categorizedItems;
}
