/**
 * Retrieves the version number from the environment variable VITE_VERSION.
 *
 * Examples:
 * - 'v1.2.3 (4c1b2d3)' -> '1.2.3'
 * - 'development' -> 'development'
 *
 * @returns {string} The extracted version number in the format 'X.X.X', or the original version string if the format does not match.
 */
export default function () {
  const version = import.meta.env.VITE_VERSION;
  const match = version.match(/v(\d+\.\d+\.\d+)/);
  return match ? match[1] : version;
}
