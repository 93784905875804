export const millisecond = 1;
export const milliseconds = millisecond;
export const second = 1000 * millisecond;
export const seconds = second;
export const minute = 60 * second;
export const minutes = minute;
export const hour = 60 * minute;
export const hours = hour;
export const day = 24 * hour;
export const days = day;

/**
 * Sleep for a number of milliseconds. This will not block the thread, but
 * instead it returns a Promise which will resolve after a number of
 * milliseconds.
 *
 * @param milliseconds - Number of milliseconds to sleep.
 * @returns A Promise which resolves after a number of milliseconds.
 */
export function sleep(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Converts a given number of milliseconds to an object containing hours,
 * minutes, and seconds.
 *
 * @param milliseconds - Duration in milliseconds.
 * @returns Object with properties `hours`, `minutes`, and `seconds`.
 */
export function getHoursMinutesAndSeconds(milliseconds: number): { hours: number; minutes: number; seconds: number } {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = totalSeconds % 60;

  return { hours, minutes, seconds };
}
