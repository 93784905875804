import * as temp from '@/lib/temporary-storage.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-dtmf-display').then(({ content }) => {
  window.customElements.define(
    'c-dtmf-display',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
      }

      updateDisplay(id) {
        const dtmfId = `dtmf-${id}`;
        const history = temp.get(dtmfId);

        if (history) {
          if (history.length > 12) {
            const shortHistory = `...${history.slice(history.length - 12)}`;
            this.innerText = shortHistory;
          } else {
            this.innerText = history;
          }
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));
      }
    },
  );
});
