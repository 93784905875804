const { ELEMENT_NODE } = Node;

let checks = [];

new MutationObserver((mutations) => {
  mutations.forEach(({ addedNodes }) => {
    if (addedNodes && checks.length) {
      addedNodes.forEach((node) => {
        const { nodeType } = node;
        if (nodeType === ELEMENT_NODE) {
          checks.forEach((fn) => {
            fn(node);
          });
        }
      });
    }
  });
}).observe(document.querySelector('main'), {
  childList: true,
  attributes: false,
  subtree: true,
});

export default function register(fn) {
  checks.push(fn);

  return function deregister() {
    checks = checks.filter((_fn) => fn !== _fn);
  };
}
