import '@/pages/p-audio-settings.mjs';
import '@/pages/p-call.mjs';
import '@/pages/p-change-password.mjs';
import '@/pages/p-choose-account.mjs';
import '@/pages/p-contact-create.mjs';
import '@/pages/p-contact-edit.mjs';
import '@/pages/p-contact.mjs';
import '@/pages/p-contacts.mjs';
import '@/pages/p-dialer.mjs';
import '@/pages/p-feedback.mjs';
import '@/pages/p-login.mjs';
import '@/pages/p-logout.mjs';
import '@/pages/p-not-found.mjs';
import '@/pages/p-ongoing-calls.mjs';
import '@/pages/p-queues.mjs';
import '@/pages/p-recents.mjs';
import '@/pages/p-settings.mjs';
import '@/pages/p-welcome.mjs';

import { routerLogger } from '@/lib/loggers.mjs';
import router from '@/lib/router.mjs';
import * as user from '@/lib/user.mjs';

import { empty } from '@/utils/dom.ts';
import { navigationEvents, userEvents } from '@/utils/eventTarget.ts';
import { navigate } from '@/utils/history.ts';
import { getUrlParams, storeUrlParams } from '@/utils/url.ts';

const allPurposes = {};
Object.entries({
  '/': { component: 'p-dialer', purpose: 'root' },
  '/dialer': { component: 'p-dialer', purpose: 'default' },
  '/dialer/:query?': { component: 'p-dialer' },
  '/call/:id?': { component: 'p-call' },
  '/recents': { component: 'p-recents' },
  '/queues': { component: 'p-queues' },
  '/feedback': { component: 'p-feedback' },
  '/ongoing-calls': { component: 'p-ongoing-calls' },
  '/settings': { component: 'p-settings', purpose: 'settings' },
  '/login': { component: 'p-login', type: 'fakeModal', purpose: 'login' },
  '/change-password': { component: 'p-change-password', type: 'fakeModal', purpose: 'changePassword' },
  '/logout': { component: 'p-logout', type: 'fakeModal', purpose: 'logout' },
  '/welcome': { component: 'p-welcome', type: 'fakeModal', purpose: 'wizard' },
  '/choose-account': { component: 'p-choose-account', type: 'fakeModal', purpose: 'wizard' },
  '/audio-settings': { component: 'p-audio-settings', type: 'fakeModal', purpose: 'wizard' },
  '/contacts/:query?': { component: 'p-contacts' },
  '/contacts': { component: 'p-contacts' },
  '/contact/create/:phoneNumber?': { component: 'p-contact-create' },
  '/contact/create': { component: 'p-contact-create' },
  '/contact/:id([0-9a-z-]{36})/edit': { component: 'p-contact-edit' },
  '/contact/:id([0-9a-z-]{36})': { component: 'p-contact' },
}).forEach(([route, ob]) => {
  router.addRoute(route, () => ob);
  const { purpose } = ob;
  if (purpose) {
    allPurposes[purpose] = route;
  }
});

window.customElements.define(
  'c-router',
  class extends HTMLElement {
    async showView() {
      this.classList.add('loading');

      const { pathname } = document.location;

      let isAuthenticated;
      try {
        await user.isAuthenticated();
        isAuthenticated = true;
      } catch (err) {
        isAuthenticated = false;
      }

      const options = {};
      {
        const { fn, params } = router.match(pathname) || {};
        if (fn) {
          Object.assign(options, fn());
          Object.assign(options, params);
          storeUrlParams(params);
        }
      }
      let { component } = options;
      const { purpose, type } = options;

      if (component) {
        if (isAuthenticated) {
          if (['root', 'login'].includes(purpose)) {
            const wizardCompleted = localStorage.getItem('wizard_completed');
            if (!wizardCompleted) {
              navigate('/welcome');
            } else {
              navigate(allPurposes.default);
            }
            return;
          }
        } else {
          if (purpose !== 'login') {
            navigate(allPurposes.login);
            return;
          }
        }
      } else {
        component = 'p-not-found';
      }

      routerLogger.info(`showing page: ${component}`, { options, pathname, isAuthenticated });

      // The fake-modal class is also added by the index.html (inline script)
      // if you are not logged in (there is no token in localStorage).
      if (type && 'fakeModal' === type) {
        document.documentElement.classList.add('fake-modal');
      } else {
        document.documentElement.classList.remove('fake-modal');
      }

      localStorage.setItem('purpose', purpose);

      if (!this.children.length || (this.children.length && this.children[0].nodeName.toLowerCase() !== component)) {
        empty(this);

        this.appendChild(document.createElement(component));
      }

      this.classList.remove('loading');
    }

    handleEvent({ type }) {
      switch (type) {
        case 'loggedOut':
          {
            const { pathname } = document.location;
            if (pathname !== allPurposes.logout) {
              navigate(allPurposes.login);
            }
          }
          break;

        case 'loggedIn':
          navigate(allPurposes.root);
          break;

        case 'changePassword':
          {
            const { pathname } = document.location;
            if (pathname !== allPurposes.logout) {
              navigate(allPurposes.changePassword);
            }
          }
          break;
      }
    }

    connectedCallback() {
      // when we refresh on the logout page we do not want to end up in an infinite logging in and logging out again loop...
      const { pathname } = document.location;
      if (pathname === allPurposes.logout) {
        history.replaceState(undefined, '', '/');
      }

      // Fired when the user manually navigates e.g. clicking the back and forwards buttons.
      // This event is not fired when a router link is clicked.
      window.addEventListener('popstate', async ({ state }) => {
        await this.showView({ state });
        navigationEvents.dispatchEvent(new CustomEvent('updateInterface', { detail: getUrlParams() }));
      });

      // Update the view according to the url in the browser.
      // This event is fired by router links.
      window.addEventListener('updateview', () => {
        this.showView();
      });

      userEvents.addEventListener('loggedOut', this);
      userEvents.addEventListener('loggedIn', this);
      userEvents.addEventListener('changePassword', this);

      this.showView();
    }

    disconnectedCallback() {
      userEvents.removeEventListener('loggedOut', this);
      userEvents.removeEventListener('loggedIn', this);
      userEvents.removeEventListener('changePassword', this);
    }
  },
);
