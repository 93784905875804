/**
 * Compares two contacts based on their 'fullName' or 'description' properties.
 * The comparison is made case-insensitive.
 *
 * @param _a - The first contact object to compare.
 * @param _b - The second contact object to compare.
 * @returns a negative integer if _a is less than _b, a positive integer if
 * _a is greater than _b, and 0 if they are equal.
 */
export default function sortColltacts(
  _a: { fullName?: string; description?: string },
  _b: { fullName?: string; description?: string },
  chosenLanguage: string,
): number {
  // Prioritize extracting full names, then descriptions, and finally fallback to empty strings.
  const a = _a.fullName || _a.description || '';
  const b = _b.fullName || _b.description || '';

  // We'll add sensitivity: 'base' to the localeCompare options to ignore accents and case.
  return a.localeCompare(b, chosenLanguage, { sensitivity: 'base' });
}
