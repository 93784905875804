import parseColleagues from '@/lib/data/parseColleagues.mjs';
import paginateUsingNext from '@/lib/paginateUsingNext.mjs';
import * as user from '@/lib/user.mjs';

import { dataEvents } from '@/utils/eventTarget.ts';

export default async function (forceRefresh = false) {
  const { clientId } = await user.getUserFromStorage();

  const requestOptions = {
    clientId,
    forceRefresh,
    params: {
      per_page: 100,
    },
  };

  const data = await paginateUsingNext({ resourceName: 'colleagues', requestOptions });

  const colleagues = parseColleagues(data);

  if (forceRefresh) {
    dataEvents.dispatchEvent(new CustomEvent('colleaguesUpdated', { detail: { colleagues } }));
  }

  return colleagues;
}
