import '@/components/c-translate.mjs';

import { closeToast } from '@/lib/toasts.mjs';

import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-toast').then(({ content }) => {
  window.customElements.define(
    'c-toast',
    class extends HTMLElement {
      set id(id) {
        this._id = id;
      }
      get id() {
        return this._id;
      }

      set data(data) {
        this._data = data;

        const {
          title,
          body,
          routerLink,
          externalLink,
          icon,
          type,
          sticky,
          showOptOut,
          dismissable = !sticky,
          duration = 5000,
          translateTitle = true,
          translateBody = true,
        } = data;

        if (title) {
          if (translateTitle) {
            this.nodes.title.setAttribute('key', title);
          } else {
            this.nodes.title.textContent = title;
          }
        }

        if (body) {
          if (translateBody) {
            this.nodes.body.setAttribute('key', body);
          } else {
            this.nodes.body.textContent = body;
          }
        }

        icon && this.nodes.icon.setAttribute('icon', icon);

        this.classList.add(type);

        if (routerLink) {
          const linkNode = document.createElement('c-router-link');
          linkNode.setAttribute('path', routerLink.path);
          linkNode.classList.add('fake-link');

          const textNode = document.createElement('c-translate');
          textNode.setAttribute('key', routerLink.text);

          linkNode.appendChild(textNode);
          this.nodes.link.appendChild(linkNode);
        }

        if (externalLink) {
          const linkNode = document.createElement('a');
          linkNode.setAttribute('href', externalLink.path);
          linkNode.classList.add('fake-link');

          const textNode = document.createElement('c-translate');
          textNode.setAttribute('key', externalLink.text);

          linkNode.appendChild(textNode);
          this.nodes.link.appendChild(linkNode);
        }

        if (!dismissable) {
          hide(this.actions.close);
        }

        if (showOptOut) {
          const settingToggle = document.createElement('c-setting-toggle');
          settingToggle.setAttribute('data-settings-key', showOptOut);
          settingToggle.setAttribute('inverted', '');
          this.nodes.settingToggle.insertAdjacentElement('afterbegin', settingToggle);
          show(this.nodes.settingToggle);
        }

        if (!sticky) {
          clearTimeout(this.durationTimeout);
          this.durationTimeout = setTimeout(() => this.close(), duration);
          this.addEventListener('mouseover', this);
          this.addEventListener('mouseleave', this);
        }
      }
      get data() {
        return this._data;
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      handleEvent(e) {
        switch (e.type) {
          case 'click': {
            this.close();
            break;
          }
          case 'mouseover': {
            clearTimeout(this.durationTimeout);
            break;
          }
          case 'mouseleave': {
            const { duration = 5000 } = this.data;
            this.durationTimeout = setTimeout(() => this.close(), duration);
            break;
          }
        }
      }

      close() {
        closeToast(this.id);
        clearTimeout(this.durationTimeout);
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.actions.close.addEventListener('click', this);
      }

      disconnectedCallback() {
        this.actions.close.removeEventListener('click', this);
        this.removeEventListener('mouseover', this);
        this.removeEventListener('mouseleave', this);
      }
    },
  );
});
