import { type ResCollection } from '@spindle/resclient';

import { initialized } from '@/lib/resgate.ts';
import { getUserFromStorage } from '@/lib/user.mjs';

/**
 * Get the user devices.
 *
 * This endpoint returns UserDevice model that holds initial data and can be used to
 * listen for changes per user.
 */
export async function getAuthenticatedUserDevices() {
  const user = await getUserFromStorage();

  if (user) {
    const resgate = await initialized();
    const response = await resgate.get(`availability.client.${user.clientUuid}.user.${user.uuid}.device`);
    const collection = response as ResCollection;

    return collection;
  }

  return null;
}
