import { setDarkMode, updateColorScheme } from '@/lib/colorScheme.mjs';
import { translateNodes } from '@/lib/i18n.mjs';
import * as segment from '@/lib/segment.mjs';
import * as settings from '@/lib/settings/remote.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import showSettingSaved from '@/utils/settingSaved.ts';

// since all modules are bundled and thus loaded when initialising the app this is run always
// so we initialise darkMode here to keep the logic in one place
window.setTimeout(() => {
  updateColorScheme();
}, 10);

loadTemplate('c-dark-mode-support').then(({ content }) => {
  window.customElements.define(
    'c-dark-mode-support',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      async handleEvent({ type, currentTarget: { checked } }) {
        if ('change' === type) {
          segment.track.darkModeToggle();
          await settings.set('isAdheringToColorScheme', checked);
          setDarkMode(checked);
          showSettingSaved(this.nodes.darkModeSaved);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        translateNodes(this);

        this.nodes.darkModeSupport.addEventListener('change', this);

        const checked = await settings.get('isAdheringToColorScheme');
        this.nodes.darkModeSupport.checked = checked;
      }

      disconnectedCallback() {
        this.nodes.darkModeSupport.removeEventListener('change', this);
      }
    },
  );
});
