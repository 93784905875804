import { translateNodes } from '@/lib/i18n.mjs';
import * as settings from '@/lib/settings/remote.mjs';

import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';

// Check for support of all necessary features.
const allFeaturesAreSupported = (function () {
  try {
    const mediaDevices = 'mediaDevices' in window.navigator;
    const setSinkId = new Audio().setSinkId;
    const cachedFeatures = {
      mediaDevices: mediaDevices,
      getUserMedia: mediaDevices && 'getUserMedia' in window.navigator.mediaDevices,
      setSinkId: setSinkId !== undefined,
      writeText: 'clipboard' in window.navigator && window.navigator.clipboard.writeText !== undefined,
      promise: 'Promise' in window,
      serviceWorker: 'serviceWorker' in window.navigator,
      notification: 'Notification' in window,
    };
    return Object.values(cachedFeatures).reduce((prev, value) => prev && value, true);
  } catch (err) {
    return false;
  }
})();

loadTemplate('c-unsupported-browser').then(({ content }) => {
  window.customElements.define(
    'c-unsupported-browser',
    class extends HTMLElement {
      handleEvent({ currentTarget }) {
        if (currentTarget === this.actions.acceptButton) {
          hide(this);
        }
      }

      constructor() {
        super();
        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        translateNodes(this);

        this.actions.acceptButton.addEventListener('click', this);

        if (!allFeaturesAreSupported && (await settings.get('showUnsupportedBrowserModal'))) {
          show(this);
        }
      }

      disconnectedCallback() {
        this.actions.acceptButton.removeEventListener('click', this);
      }
    },
  );
});
