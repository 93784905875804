import { invite, isAbleToMakeCall } from '@/lib/calling.mjs';
import * as segment from '@/lib/segment.mjs';
import * as localSettings from '@/lib/settings/local.mjs';

import { disable, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents, dataEvents } from '@/utils/eventTarget.ts';

loadTemplate('c-queue').then(({ content }) => {
  window.customElements.define(
    'c-queue',
    class extends HTMLElement {
      set data(queue) {
        this._data = queue;
        this.populate();
      }

      get data() {
        return this._data;
      }

      set numberOfWaitingCallers(waitingCallers) {
        this._waitingCallers = waitingCallers;
        this.nodes.avatar.textContent = waitingCallers;
        if (this.status) {
          this.nodes.avatar.classList.remove(this.status);
        }
        this.status = this.getStatus(waitingCallers);
        this.nodes.avatar.classList.add(waitingCallers);
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      async handleEvent({ type, currentTarget }) {
        if ('click' === type) {
          switch (currentTarget) {
            case 'clientStatusUpdated':
              this.sortCallButtonVisiblity();
              break;
            case this.actions.watch:
              {
                const selectedQueues = await localSettings.get('selectedQueues');
                if (selectedQueues.includes(this.data.id)) {
                  const newSelectedQueues = selectedQueues.filter((queueId) => queueId !== this.data.id);
                  await localSettings.set('selectedQueues', newSelectedQueues);
                  this.data.off('change', this.onchange);
                  this.numberOfWaitingCallers = 0;
                } else {
                  selectedQueues.push(this.data.id);
                  await localSettings.set('selectedQueues', selectedQueues);
                  this.numberOfWaitingCallers = this.data.waiting_callers;
                  this.data.on('change', this.onchange);
                }
                segment.track.queueActiveToggle();
                this.updateIsWatchedStatus(this.data.id);
                dataEvents.dispatchEvent(
                  new CustomEvent('queuesUpdated', {
                    detail: { queueId: this.data.id, waitingCallers: this.data.waiting_callers },
                  }),
                );
              }
              break;

            case this.actions.call:
              invite(this.data.phoneNumber);
              break;
          }
        }
      }

      onchange = (_, model) => {
        this.numberOfWaitingCallers = model?.waiting_callers;
        dataEvents.dispatchEvent(
          new CustomEvent('queuesUpdated', { detail: { queueId: model.id, waitingCallers: model?.waiting_callers } }),
        );
      };

      async updateIsWatchedStatus(id) {
        const selectedQueues = await localSettings.get('selectedQueues');
        if (selectedQueues.includes(id)) {
          this.actions.watch.setAttribute('active', '');
          show(this.nodes.watching);
        } else {
          this.actions.watch.removeAttribute('active');
          hide(this.nodes.watching);
        }
      }

      sortCallButtonVisiblity() {
        if (isAbleToMakeCall) {
          enable(this.actions.call);
        } else {
          disable(this.actions.call);
        }
      }

      getStatus(waitingCallers) {
        let status;
        if (waitingCallers > 10) {
          status = 'hectic';
        } else if (waitingCallers > 5) {
          status = 'busy';
        } else if (waitingCallers > 2) {
          status = 'moderate';
        } else {
          status = 'quiet';
        }
        return status;
      }

      async populate() {
        if (this.data) {
          const { callgroup_description, callgroup_internal_number, waiting_callers } = this.data;

          this.nodes.name.textContent = callgroup_description;
          this.nodes.number.textContent = callgroup_internal_number;

          this.updateIsWatchedStatus(this.data.id);

          this.numberOfWaitingCallers = waiting_callers;

          const selectedQueues = await localSettings.get('selectedQueues');
          if (selectedQueues.includes(this.data.id)) {
            this.data.on('change', this.onchange);
          }
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.actions.call.addEventListener('click', this);
        this.actions.watch.addEventListener('click', this);

        this.sortCallButtonVisiblity();

        callingEvents.addEventListener('clientStatusUpdated', this);
      }

      disconnectedCallback() {
        this.actions.call.removeEventListener('click', this);
        this.actions.watch.removeEventListener('click', this);

        callingEvents.removeEventListener('clientStatusUpdated', this);
      }
    },
  );
});
