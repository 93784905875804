import '@/components/c-account.mjs';
import '@/components/c-colleague.mjs';
import '@/components/c-contact.mjs';
import '@/components/c-keypad.mjs';
import '@/components/c-no-account.mjs';
import '@/components/c-no-audio-devices.mjs';
import '@/components/c-no-connection.mjs';
import { createColltactCard, createColltactCards } from '@/components/shared.mjs';

import { getSessions, invite, isAbleToMakeCall, isConnected, isConnecting, voipAccount } from '@/lib/calling.mjs';
import * as colleagues from '@/lib/colleagues.mjs';
import * as contacts from '@/lib/contacts.mjs';
import * as favorites from '@/lib/favorites.mjs';
import * as media from '@/lib/media.mjs';
import * as segment from '@/lib/segment.mjs';
import * as settings from '@/lib/settings/remote.mjs';
import * as voipaccounts from '@/lib/voipaccounts.mjs';

import cleanPhoneNumber from '@/utils/cleanPhoneNumber.mjs';
import { hide, isHidden, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents, colleagueAvailabilityEvents, mediaEvents, navigationEvents } from '@/utils/eventTarget.ts';
import getDialerBlockingInterfaceKey from '@/utils/getDialerBlockingInterfaceKey.mjs';
import { notTelephoneNumberRegExp } from '@/utils/regexp.ts';
import { getUrlParams, updateUrl } from '@/utils/url.ts';

const CONTACT_CARD_NODE_NAMES = ['C-ACCOUNT', 'C-CONTACT', 'C-COLLEAGUE'];

const inputFocusKeyBlacklist = [
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Shift',
  'Control',
  'Alt',
  'Meta',
];

const events = new EventTarget();

let contactCardObserver;

loadTemplate('c-dialer').then(({ content }) => {
  window.customElements.define(
    'c-dialer',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['session-id'];
      }

      get firstVisibleContactSuggestion() {
        return this.allVisibleContactSuggestions[0];
      }

      get focusedContactSuggestion() {
        const { activeElement } = document;
        if (activeElement && activeElement.nodeName && CONTACT_CARD_NODE_NAMES.includes(activeElement.nodeName)) {
          return activeElement;
        }
        return undefined;
      }

      get nextVisibleContactSuggestion() {
        let index = this.allVisibleContactSuggestions.indexOf(this.focusedContactSuggestion);
        return this.allVisibleContactSuggestions[index + 1];
      }
      get previousVisibleContactSuggestion() {
        let index = this.allVisibleContactSuggestions.indexOf(this.focusedContactSuggestion);
        return this.allVisibleContactSuggestions[index - 1];
      }

      set isDisabled(isDisabled) {
        this._isDisabled = isDisabled;

        [this.nodes.dialpad, this.nodes.dialerHeader, this.nodes.suggestionsContainer].forEach((n) => {
          isDisabled ? hide(n) : show(n);
        });

        if (!isDisabled) {
          this.sortDialpadVisibility();
        }
      }
      get isDisabled() {
        return this._isDisabled;
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);

        // since we handle keydown events for different conatainers for this custom element,
        // instead of using the instance with a handleEvent function to bind to in the addEventListener's
        // we bind the handleInputKeyDownEvent method to the instance to be able to use this as a reference to the instance itself,
        // and to reason about keydown events from the input in a seperate function for clarity
        this.handleInputKeyDownEvent = this.handleInputKeyDownEvent.bind(this);

        this.allContactSuggestions = {
          favorites: [],
          voipaccounts: [],
          colleagues: [],
          contacts: [],
        };
      }

      handleInputKeyDownEvent(e) {
        switch (e.key) {
          case 'ArrowDown':
            {
              e.preventDefault();
              const node = this.nextVisibleContactSuggestion || this.firstVisibleContactSuggestion;
              if (node) {
                node.focus();
              }
            }
            break;

          case 'Enter':
            {
              e.preventDefault();
              const { value } = this.nodes.input;
              updateUrl(`/dialer/${value}`);

              const firstVisibleContactSuggestion = this.firstVisibleContactSuggestion;
              if (notTelephoneNumberRegExp.test(value)) {
                if (firstVisibleContactSuggestion) {
                  const { phoneNumber } = firstVisibleContactSuggestion;
                  phoneNumber && this.initiatePhoneCall(phoneNumber);
                }
              } else {
                this.initiatePhoneCall();
              }
            }
            break;
        }
      }

      handleEvent(e) {
        const { type, currentTarget, target, key, detail } = e;
        const { dataset } = target;

        if (dataset && 'action' in dataset && target === currentTarget) {
          const { action } = dataset;

          // Execute the button's action when clicking or when it is
          // focussed and pressing enter.
          if (('keydown' === type && 'Enter' === key) || 'click' === type) {
            switch (action) {
              case 'backspace':
                {
                  const { value } = this.nodes.input;
                  if (value) {
                    this.nodes.input.value = value.substring(0, value.length - 1);
                    this.updateSuggestions();
                  }
                }
                break;

              case 'toggleDialpad':
                this.toggleDialpad();
                break;

              case 'call':
              case 'dialpadCall':
                this.initiatePhoneCall();
                break;

              case 'toggleVoipaccounts':
                this.toggleVoipaccounts();
                break;

              case 'toggleColleagues':
                this.toggleColleagues();
                break;

              case 'toggleContacts':
                this.toggleContacts();
                break;

              case 'toggleFavorites':
                this.toggleFavorites();
                break;
            }
          }
          return;
        }

        switch (type) {
          case 'keydown':
            {
              const {
                activeElement,
                activeElement: { dataset },
              } = document;
              if (activeElement && 'inputOverride' in dataset) {
                return;
              }
            }

            switch (key) {
              case 'Enter':
                {
                  const node = this.focusedContactSuggestion;
                  if (node) {
                    e.preventDefault();
                    segment.track.callContact();
                    const { phoneNumber } = node;
                    phoneNumber && this.initiatePhoneCall(phoneNumber);
                  }
                }
                break;

              case 'ArrowRight':
                {
                  const node = this.nextVisibleContactSuggestion || this.firstVisibleContactSuggestion;
                  if (node) {
                    e.preventDefault();
                    node.focus();
                  }
                }
                break;

              case 'ArrowLeft':
                {
                  const node = this.previousVisibleContactSuggestion;
                  if (node) {
                    e.preventDefault();
                    node.focus();
                  }
                }
                break;

              default:
                if (!inputFocusKeyBlacklist.includes(key)) {
                  this.nodes.input.focus();
                }
            }
            break;

          case 'click':
            switch (currentTarget) {
              case this.nodes.keypad:
                if (target.dataset.key) {
                  const { activeElement } = document;
                  this.nodes.input.value += target.dataset.key;
                  const { length } = this.nodes.input.value;
                  // setSelectionRange only works if the element is focussed.
                  this.nodes.input.focus();
                  this.nodes.input.setSelectionRange(length, length);
                  // When the selection has been set, go back to the original
                  // selected keypad element.
                  activeElement && activeElement.focus();
                  this.updateSuggestions();
                }
                break;

              case this.nodes.suggestions:
                if (target.data) {
                  segment.track.callContact();
                  const { phoneNumber } = target;
                  phoneNumber && this.initiatePhoneCall(phoneNumber);
                }
                break;
            }
            break;

          case 'input':
            this.updateSuggestions();
            break;
          case 'colleagueNowOnline':
            {
              // The showOnlyOnlineContacts skips making the cards, that's why we don't need to do this if the setting is false.
              if (this.showOnlyOnlineContacts) {
                const onlineColleagueUuid = detail.colleagueUuid;

                colleagues.onlyOnline().then((colleagueList) => {
                  const index = colleagueList.findIndex((colleague) => colleague.colleagueUuid === onlineColleagueUuid);

                  const afterNode = this.allContactSuggestions.colleagues[index - 1];
                  const colleagueCard = createColltactCard(
                    colleagueList[index],
                    afterNode,
                    this.showOnlyOnlineContacts,
                  );

                  this.allContactSuggestions.colleagues.splice(index, 0, colleagueCard);

                  this.updateSuggestions();
                });
              }
            }
            break;

          case 'sessionStarted':
          case 'sessionEnded':
            this.sortShowKeypadInformation();
            break;

          case 'clientStatusUpdated':
          case 'microphonePermissionUpdated':
          case 'blockDialerAllowedChanged':
            this.sortDialerBlocked();
            break;

          case 'paste':
            {
              const pasteData = e.clipboardData.getData('text/plain');
              const cleanedNumber = cleanPhoneNumber(pasteData);
              if (cleanedNumber) {
                e.preventDefault();
                const { selectionStart, selectionEnd } = this.nodes.input;
                const newSelection = selectionStart + cleanedNumber.length;
                this.nodes.input.setRangeText(cleanedNumber, selectionStart, selectionEnd);
                this.nodes.input.setSelectionRange(newSelection, newSelection);
                this.updateSuggestions();
              }
            }
            break;

          case 'change':
            {
              const { value } = this.nodes.input;
              updateUrl(`/dialer/${value}`);

              this.updateSuggestions();
            }
            break;

          case 'updateInterface':
            this.fillSearchInput(detail);
            this.updateSuggestions();
            break;
        }
      }

      async toggleDialpad() {
        await settings.set('showDialpad', !(await settings.get('showDialpad')));
        this.sortDialpadVisibility();
        this.sortShowKeypadInformation();
      }

      async toggleVoipaccounts() {
        await settings.set('showVoipaccounts', !(await settings.get('showVoipaccounts')));
        this.updateSuggestions();
      }

      async toggleContacts() {
        await settings.set('showContacts', !(await settings.get('showContacts')));
        this.updateSuggestions();
      }

      async toggleColleagues() {
        await settings.set('showColleagues', !(await settings.get('showColleagues')));
        this.updateSuggestions();
      }

      async toggleFavorites() {
        await settings.set('showFavorites', !(await settings.get('showFavorites')));
        this.updateSuggestions();
      }

      async sortDialpadVisibility() {
        if (await settings.get('showDialpad')) {
          show(this.nodes.dialpad);
          this.actions.toggleDialpad.classList.add('active');
        } else {
          hide(this.nodes.dialpad);
          this.actions.toggleDialpad.classList.remove('active');
        }
      }

      async sortSuggestionsSectionVisibility(toggleNode, settingsKey, type) {
        let visible = !isHidden(toggleNode) ? await settings.get(settingsKey) : true;

        const { value } = this.nodes.input;

        this.showMatchesFor(this.allContactSuggestions[type], value, visible);

        this.nodes.numberOfFavorites.textContent = `(${this.allContactSuggestions.favorites.length})`;
        this.nodes.numberOfVoipaccounts.textContent = `(${this.allContactSuggestions.voipaccounts.length})`;
        this.nodes.numberOfContacts.textContent = `(${this.allContactSuggestions.contacts.length})`;

        if (visible) {
          toggleNode.classList.remove('closed');
        } else {
          toggleNode.classList.add('closed');
        }
      }

      sortDialerBlocked() {
        const key = getDialerBlockingInterfaceKey({
          microphonePermissionGranted: media.microphonePermissionGranted,
          isConnecting: isConnecting(),
          isConnected: isConnected(),
          voipAccount,
        });

        let newBlockingInterface;
        if (key) {
          newBlockingInterface = this.nodes[key];
        }

        if (newBlockingInterface) {
          if (newBlockingInterface === this.blockingInterface) {
            return;
          }

          this.isDisabled = true;
          this.blockingInterface && hide(this.blockingInterface);
          show(newBlockingInterface);
          this.blockingInterface = newBlockingInterface;
        } else {
          this.isDisabled = false;
          this.blockingInterface && hide(this.blockingInterface);
          this.blockingInterface = undefined;
        }
      }

      fillSearchInput({ query = '' }) {
        this.nodes.input.value = query;
      }

      updateSuggestions() {
        this.allVisibleContactSuggestions = [];
        this.sortSuggestionsSectionVisibility(this.actions.toggleFavorites, 'showFavorites', 'favorites');
        this.sortSuggestionsSectionVisibility(this.actions.toggleVoipaccounts, 'showVoipaccounts', 'voipaccounts');
        this.sortSuggestionsSectionVisibility(this.actions.toggleColleagues, 'showColleagues', 'colleagues');
        this.sortSuggestionsSectionVisibility(this.actions.toggleContacts, 'showContacts', 'contacts');
      }

      async sortShowKeypadInformation() {
        if (getSessions().length > 0) {
          show(this.nodes.keypadInformation);
        } else {
          hide(this.nodes.keypadInformation);
        }
      }

      showMatchesFor(nodes, value, extraCheck = true) {
        for (const node of nodes) {
          if (node.doesMatchSearchString(value) && extraCheck && node.shouldShow()) {
            show(node);
            this.allVisibleContactSuggestions.push(node);
          } else {
            hide(node);
          }
        }
      }

      async initiatePhoneCall(value = this.nodes.input.value) {
        if (!value) {
          const latestDialedNumber = await settings.get('latestDialedNumber');
          if (latestDialedNumber) {
            segment.track.redial();
            this.nodes.input.value = latestDialedNumber;
            this.updateSuggestions();
          }
        } else if (!notTelephoneNumberRegExp.test(value) && isAbleToMakeCall) {
          const number = cleanPhoneNumber(value);
          settings.set('latestDialedNumber', number);
          history.pushState(undefined, '', '/dialer');
          invite(number);
        }
      }

      contactCardAddOrRemovalCallback() {
        // Removing the colleague card properly from the contacts suggestions.
        this.allContactSuggestions.colleagues = this.allContactSuggestions.colleagues.filter((node) =>
          this.nodes.suggestionsContainer.contains(node),
        );

        // The favorites section can have colleagues, so the number needs to be updated accordingly.
        this.allContactSuggestions.favorites = this.allContactSuggestions.favorites.filter((node) =>
          this.nodes.suggestionsContainer.contains(node),
        );

        // Firefox triggers the mutation observer when adjusting the textContent
        // while Google Chrome does not. That's why we do a specific check to
        // see if the number of colleagues or favorites has actually changed.
        const colleaguesText = `(${this.allContactSuggestions.colleagues.length})`;
        if (this.nodes.numberOfColleagues.textContent !== colleaguesText) {
          this.nodes.numberOfColleagues.textContent = colleaguesText;
        }

        const favoritesText = `(${this.allContactSuggestions.favorites.length})`;
        if (this.nodes.numberOfFavorites.textContent !== favoritesText) {
          this.nodes.numberOfFavorites.textContent = favoritesText;
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.sortDialerBlocked();

        // TODO remove this once the date has passed, and remove all the code related to it.
        const noMoreFavoritesExplanationNeededDate = new Date('2023-04-09');
        const currentDate = new Date(Date.now());
        if (noMoreFavoritesExplanationNeededDate < currentDate) {
          hide(this.nodes.favoritesExplanation);
        }

        // Add the event listeners as soon as possible, because it could
        // happen that an event is triggered before the dialer is done with
        // data fetching.
        callingEvents.addEventListener('clientStatusUpdated', this);
        callingEvents.addEventListener('sessionStarted', this);
        callingEvents.addEventListener('sessionEnded', this);
        callingEvents.addEventListener('attendedTransferStatusUpdated', this);

        colleagueAvailabilityEvents.addEventListener('colleagueNowOnline', this);

        mediaEvents.addEventListener('microphonePermissionUpdated', this);
        navigationEvents.addEventListener('updateInterface', this);
        events.addEventListener('blockDialerAllowedChanged', this);
        window.addEventListener('keydown', this);

        this.nodes.suggestionsContainer.classList.add('loading');

        const [voipaccountsList, colleaguesList, contactsList, favoritesList, showOnlyOnlineContacts] =
          await Promise.all([
            voipaccounts.all(),
            colleagues.all(),
            contacts.all(),
            favorites.all(),
            settings.get('showOnlineContacts'),
          ]).then(([voipaccountsList, colleaguesList, contactsList, favoritesList, showOnlyOnlineContacts]) => {
            return [
              voipaccountsList.filter((colltact) => colltact.phoneNumber || colltact.hasPhoneNumber),
              colleaguesList,
              contactsList.filter((colltact) => colltact.phoneNumber || colltact.hasPhoneNumber),
              favoritesList.filter(
                (favorite) => favorite.phoneNumber || favorite.hasPhoneNumber || favorite.emailAddress,
              ), // Colleagues will not have a phoneNumber until the Websocket is done sending messages. The email gets them into the favorites in an easy way.
              showOnlyOnlineContacts,
            ];
          });

        this.showOnlyOnlineContacts = showOnlyOnlineContacts;

        const colleagueCards = document.querySelector("[data-selector='suggestionsContainer']");

        contactCardObserver = new MutationObserver(this.contactCardAddOrRemovalCallback.bind(this));

        // childList and subtree will observe the addition and removal of new child
        // elements in the suggestionsContainer.
        contactCardObserver.observe(colleagueCards, {
          childList: true,
          subtree: true,
        });

        this.allContactSuggestions = {
          favorites: createColltactCards(favoritesList, this.actions.toggleFavorites, showOnlyOnlineContacts),
          voipaccounts: createColltactCards(voipaccountsList, this.actions.toggleVoipaccounts, showOnlyOnlineContacts),
          colleagues: createColltactCards(colleaguesList, this.actions.toggleColleagues, showOnlyOnlineContacts),
          contacts: createColltactCards(contactsList, this.actions.toggleContacts, showOnlyOnlineContacts),
        };

        this.allVisibleContactSuggestions = [];

        if (favoritesList.length === 0) {
          hide(this.actions.toggleFavorites);
        }

        const urlParams = getUrlParams();
        if ('query' in urlParams) {
          this.fillSearchInput(urlParams);
        }

        this.updateSuggestions();

        this.nodes.suggestionsContainer.classList.remove('loading');

        this.sortShowKeypadInformation();

        [
          this.actions.call,
          this.actions.dialpadCall,
          this.actions.toggleVoipaccounts,
          this.actions.toggleColleagues,
          this.actions.toggleContacts,
          this.actions.toggleFavorites,
          this.actions.toggleDialpad,
          this.actions.backspace,
          this.nodes.keypad,
          this.nodes.suggestions,
        ].forEach((n) => n.addEventListener('click', this));

        this.nodes.input.addEventListener('input', this);
        this.nodes.input.addEventListener('change', this);
        this.nodes.input.addEventListener('paste', this);
        this.nodes.input.addEventListener('keydown', this.handleInputKeyDownEvent, true);

        this.nodes.input.focus();
      }

      disconnectedCallback() {
        callingEvents.removeEventListener('clientStatusUpdated', this);
        callingEvents.removeEventListener('sessionStarted', this);
        callingEvents.removeEventListener('sessionEnded', this);
        callingEvents.removeEventListener('attendedTransferStatusUpdated', this);

        colleagueAvailabilityEvents.removeEventListener('colleagueNowOnline', this);

        mediaEvents.removeEventListener('microphonePermissionUpdated', this);
        navigationEvents.removeEventListener('updateInterface', this);
        events.removeEventListener('blockDialerAllowedChanged', this);
        window.removeEventListener('keydown', this);

        contactCardObserver?.disconnect();

        [
          this.actions.call,
          this.actions.dialpadCall,
          this.actions.toggleVoipaccounts,
          this.actions.toggleColleagues,
          this.actions.toggleContacts,
          this.actions.toggleFavorites,
          this.actions.toggleDialpad,
          this.actions.backspace,
          this.nodes.keypad,
          this.nodes.suggestions,
        ].forEach((n) => n.removeEventListener('click', this));

        this.nodes.input.removeEventListener('input', this);
        this.nodes.input.removeEventListener('change', this);
        this.nodes.input.removeEventListener('paste', this);
        this.nodes.input.removeEventListener('keydown', this.handleInputKeyDownEvent, true);
      }
    },
  );
});
