// Small common base needed to prevent circular dependencies between `calling.mjs`
// and `media.mjs`.
import * as WebCallingReal from 'webphone-lib';
import * as WebCallingStub from 'webphone-lib-stub';

import isRunningInTestRuntime from '@/utils/isRunningInTestRuntime.ts';

export const webCalling = isRunningInTestRuntime ? WebCallingStub : WebCallingReal;

export let client;

// Cache the currently used voipAccount, is undefined when no webphone account
// is set in the portal by the user. Set to an initial state that is updated
// once a SIP connection is attempted.
export let voipAccount = 'unknown';

export function newClient(options) {
  client = new webCalling.Client(options);
}

export function isConnected() {
  return client && client.isConnected();
}

export function getDefaultMedia() {
  if (client) {
    return client.defaultMedia;
  }
  return undefined;
}

export function getSessions() {
  if (isConnected()) {
    return client.getSessions();
  }
  return [];
}

export function getSession(id) {
  if (isConnected()) {
    return client.getSession(id);
  }
  return undefined;
}

export function setVoipAccount(account) {
  voipAccount = account;
}
