import { Logger } from '@/lib/log.mjs';

import { settingsEvents } from '@/utils/eventTarget.ts';

export const blfLogger = new Logger('blf');
export const sentryLogger = new Logger('sentry');
export const serviceWorkerLogger = new Logger('service-worker');
export const mediaLogger = new Logger('media');
export const soundLogger = new Logger('sound');
export const remoteSettingsLogger = new Logger('settings-remote');
export const localSettingsLogger = new Logger('settings-local');
export const routerLogger = new Logger('router');
export const webCallingLogger = new Logger('webphonelib-calling');
export const callingLogger = new Logger('calling');
export const userRatingLogger = new Logger('user-rating');
export const headsetLogger = new Logger('headset');
export const reconnectingWebsocketLogger = new Logger('reconnectingWebsocket');
export const resgateLogger = new Logger('resgate');

// We are using remote settings in the log.mjs lib so to prevent this circular dependency which
// causes the build to fail, we throw events in the settings/remote.mjs lib where the loggers.mjs lib will listen to.
settingsEvents.addEventListener('remoteSettingsInitialized', ({ detail }) => {
  remoteSettingsLogger.info('remote settings initialized', { settings: detail });
});

settingsEvents.addEventListener('remoteSettingChange', ({ detail }) => {
  remoteSettingsLogger.info(`changed remote setting "${detail.key}" to: ${detail.value}`);
});
