import * as user from '@/lib/user.mjs';

import { applicationEvents, userEvents } from '@/utils/eventTarget.ts';

export const READY_TO_ROCK_APPLICATION_STATUS = 'readyToRock';
export const INITIAL_APPLICATION_STATUS = 'initial';
export let status = INITIAL_APPLICATION_STATUS;

function updateStatus(_status) {
  if (status === _status) {
    return;
  }

  status = _status;
  applicationEvents.dispatchEvent(new CustomEvent(`applicationStatusUpdated`, { detail: status }));
}

function hasAlreadyFinishedWelcomeWizard() {
  // https://stackoverflow.com/questions/784929/what-is-the-not-not-operator-in-javascript#answer-784946
  return !!localStorage.getItem('wizard_completed');
}

function checkLoggedInStatus() {
  user
    .get()
    .then((user) => (user ? true : false))
    .catch(() => false)
    .then((isLoggedIn) => {
      if (isLoggedIn && hasAlreadyFinishedWelcomeWizard()) {
        updateStatus(READY_TO_ROCK_APPLICATION_STATUS);
      }
    });
}

export function isReadyToRock() {
  return new Promise((resolve) => {
    const onReadyToRock = () => {
      if (status === READY_TO_ROCK_APPLICATION_STATUS) {
        applicationEvents.removeEventListener('applicationStatusUpdated', onReadyToRock);
        resolve();
      }
    };
    applicationEvents.addEventListener('applicationStatusUpdated', onReadyToRock);

    if (status === READY_TO_ROCK_APPLICATION_STATUS) {
      applicationEvents.removeEventListener('applicationStatusUpdated', onReadyToRock);
      resolve();
    }
  });
}

export function justFinishedWelcomeWizard() {
  updateStatus(READY_TO_ROCK_APPLICATION_STATUS);
}

userEvents.addEventListener('loggedIn', () => {
  checkLoggedInStatus();
});

userEvents.addEventListener('loggedOut', () => {
  updateStatus(INITIAL_APPLICATION_STATUS);
});

userEvents.addEventListener('changePassword', () => {
  updateStatus(INITIAL_APPLICATION_STATUS);
});

userEvents.addEventListener('changedPassword', () => {
  if (hasAlreadyFinishedWelcomeWizard()) {
    updateStatus(READY_TO_ROCK_APPLICATION_STATUS);
  }
});

// also check right away if we might be already logged in
checkLoggedInStatus();
