export default {
  keypad: {
    inactive: {
      icon: 'dialpad',
      translationKey: 'keypad_inactive',
    },
    active: {
      icon: 'dialpad-alt',
      translationKey: 'keypad_active',
    },
  },
  mute: {
    inactive: {
      icon: 'mute',
      translationKey: 'mute_inactive',
    },
    active: {
      icon: 'microphone',
      translationKey: 'mute_active',
    },
  },
  transfer: {
    inactive: {
      icon: 'transfer',
      translationKey: 'transfer_inactive',
    },
    active: {
      icon: 'transfer-alt',
      translationKey: 'transfer_active',
    },
  },
  hold: {
    inactive: {
      icon: 'on-hold',
      translationKey: 'hold_inactive',
    },
    active: {
      icon: 'play',
      translationKey: 'hold_active',
    },
  },
};
