import getColleagues from '@/lib/data/getColleagues.mjs';

import sortColltacts from '@/utils/sortColltacts.ts';

export function all(forceRefresh = false) {
  return getColleagues(forceRefresh).then((colleagues) => colleagues.sort(sortColltacts));
}

export function onlyOnline(forceRefresh = false) {
  return getColleagues(forceRefresh).then((colleaguesList) => {
    return colleaguesList.filter((colleague) => colleague.availability !== 'offline').sort(sortColltacts);
  });
}
