import { userEvents } from '@/utils/eventTarget.ts';
import * as time from '@/utils/time.ts';

/**
 * This caching library is meant to be used for the request lib.
 * To cache something in memory use the temporary storage (/lib/temp.mjs).
 */

const cache = new Map();

export function get(key) {
  if (cache.has(key)) {
    const { promise } = cache.get(key);
    return promise;
  } else {
    return false;
  }
}

export function set(key, cacheObject) {
  cache.set(key, cacheObject);
}

export function remove(keyStart) {
  for (const key of cache.keys()) {
    if (key.startsWith(keyStart)) {
      cache.delete(key);
    }
  }
}

export function purge() {
  cache.clear();
}

// clearing items that lived past their TTL
window.setInterval(() => {
  const now = new Date().getTime();
  for (const [key, { devalidateAt }] of cache.entries()) {
    if (devalidateAt < now) {
      cache.delete(key);
    }
  }
}, time.second * 2);

userEvents.addEventListener('loggedOut', () => {
  purge();
});
