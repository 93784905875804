import { INITIAL_APPLICATION_STATUS, READY_TO_ROCK_APPLICATION_STATUS, status } from '@/lib/applicationStatus.mjs';

import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { applicationEvents } from '@/utils/eventTarget.ts';

loadTemplate('c-toasts').then(({ content }) => {
  window.customElements.define(
    'c-toasts',
    class extends HTMLElement {
      handleEvent({ detail }) {
        switch (detail) {
          case READY_TO_ROCK_APPLICATION_STATUS:
            show(this);
            break;

          case INITIAL_APPLICATION_STATUS:
            hide(this);
            break;
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));
        this.handleEvent({ detail: status });

        applicationEvents.addEventListener('applicationStatusUpdated', this);
      }

      disconnectedCallback() {
        applicationEvents.removeEventListener('applicationStatusUpdated', this);
      }
    },
  );
});
