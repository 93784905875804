import { clientStatus, getSession, invite, isAbleToMakeCall } from '@/lib/calling.mjs';
import { getUserAvailability } from '@/lib/data/getUserAvailability.ts';
import * as favorites from '@/lib/favorites.mjs';
import { translate } from '@/lib/i18n.mjs';
import { blfLogger } from '@/lib/loggers.mjs';
import * as segment from '@/lib/segment.mjs';

import doesMatchSearchString from '@/utils/doesMatchSearchString.ts';
import { disable, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents, mediaEvents } from '@/utils/eventTarget.ts';
import getSearchValuesFromModel from '@/utils/getSearchValuesFromModel.mjs';
import { camelToKebab } from '@/utils/string.ts';

const SEARCH_THESE_PROPERTIES = ['description', 'phoneNumber'];

// since we do not know the actual status of other accounts if we have no voipaccount or are connecting or recovering
// (without SIP subscription) we show other accounts as unknown, and only show them with a specific status when we're
// sure we know what status they have based on subscription data from SIP. So until we have subscription data we just
// indicate we do not know the actual status of accounts
const UNKNOWN_CLIENT_STATUSES = ['connecting', 'recovering', 'disconnecting', 'disconnected', undefined];

const indicatorAttributesMap = {
  available: {
    icon: 'user-connected',
  },
  internal: {
    icon: 'available-internal',
  },
  offline: {
    icon: 'user-disconnected',
  },
  busy: {
    icon: 'phone-volume',
  },
  ringing: {
    icon: 'bell',
  },
  dnd: {
    icon: 'bell-alt',
  },
  unknown: {
    icon: 'none',
  },
};

loadTemplate('c-colleague').then(({ content }) => {
  window.customElements.define(
    'c-colleague',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['session-id'];
      }

      set showOnlyOnlineContacts(showOnlyOnlineContacts) {
        this._showOnlyOnlineContacts = showOnlyOnlineContacts;
      }

      get showOnlyOnlineContacts() {
        return this._showOnlyOnlineContacts;
      }

      set data(data) {
        this._data = data;
        this.populate();
        this.searchValues = getSearchValuesFromModel(data, SEARCH_THESE_PROPERTIES);
      }

      get data() {
        return this._data;
      }

      get phoneNumber() {
        return this.data.phoneNumber.toString();
      }

      set presenceIndicator(presence) {
        this._presenceIndicator = presence;
      }

      get presenceIndicator() {
        return camelToKebab(this._presenceIndicator);
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);

        this._presenceIndicator = '';
      }

      async handleEvent({ type, currentTarget }) {
        switch (type) {
          case 'clientStatusUpdated':
            this.updateColleaguePresence();
            this.sortCallButtonVisibility();
            break;

          case 'microphonePermissionUpdated':
            this.sortCallButtonVisibility();
            break;

          case 'click':
            switch (currentTarget) {
              case this.actions.call:
                {
                  segment.track.callContact();
                  const path = document.location.pathname.split('/')[1];
                  history.pushState(undefined, '', `/${path}`);
                  invite(this.data.phoneNumber);
                }
                break;

              case this.actions.favorite:
                segment.track.favoriteToggle();
                await favorites.toggle(this.data.colleagueUuid);
                this.sortIsFavorite();
                break;
            }
        }
      }

      doesMatchSearchString(query = '') {
        return doesMatchSearchString(this.searchValues, query);
      }

      async populate(model) {
        if (this.data && this.isConnected) {
          const { description } = this.data;

          this.nodes.contactName.textContent = description;

          if (model) {
            const { internal_number, context, availability } = model;

            this._data.status = availability;

            const availabilityContext = context.length ? context[0].type : undefined;
            // e.g. The status ringing is given in the extra context, but the ringing state is still a status on the icon.

            if (availabilityContext) {
              this.nodes.availabilityContext.textContent = `${translate(availabilityContext)}`;

              //Override availability, since this gives the better icon
              if (indicatorAttributesMap[availabilityContext]) {
                this._data.status = availabilityContext;
              }
            } else {
              this.nodes.availabilityContext.textContent = `${translate(availability)}`;
            }

            this._data.phoneNumber = internal_number;

            this.nodes.contactNumber.textContent = `${internal_number} - `;

            this.updateColleaguePresence();
          }
        }
      }

      shouldShow() {
        if (this.showOnlyOnlineContacts && this.data.status === 'offline') {
          return false;
        } else {
          return true;
        }
      }

      sortCallButtonVisibility() {
        if (!isAbleToMakeCall) {
          disable(this.actions.call);
        } else {
          enable(this.actions.call);
        }
      }

      sortIsFavorite() {
        if (this.hasAttribute('favoritable') && favorites.contains(this.data.colleagueUuid)) {
          show(this.nodes.favoriteIndicator);
        } else {
          hide(this.nodes.favoriteIndicator);
        }

        if (this.hasAttribute('favoritable')) {
          show(this.actions.favorite);
        } else {
          hide(this.actions.favorite);
        }
      }

      getPresenceIndicator() {
        if ('offline' === this.data.status) {
          return 'offline';
        }
        if ('available_for_colleagues' === this.data.status) {
          return 'internal';
        }
        // We need to translate do_not_disturb to dnd. We get dnd from sip server and build all css classes surrounding that.
        if ('do_not_disturb' === this.data.status) {
          return 'dnd';
        }

        if (!this.data.status && UNKNOWN_CLIENT_STATUSES.includes(clientStatus)) {
          return 'unknown';
        }

        return this.data.status || 'unknown';
      }

      updateColleaguePresence() {
        const presenceIndicator = this.getPresenceIndicator();

        // If the indicator is the same, no need to change it.
        if (presenceIndicator === this._presenceIndicator) {
          return;
        }

        this.presenceIndicator = presenceIndicator;

        if (this.nodes.presenceIndicator) {
          this.nodes.presenceIndicator.className = `${this.initialPresenceIndicatorClassName} ${this.presenceIndicator}`;
        }
        if (this.nodes.presenceLabel) {
          this.nodes.presenceLabel.className = `${this.initialPresenceLabelClassName} ${this.presenceIndicator}`;
        }

        // icon cannot be looked up using kebab notation.
        const { icon } = indicatorAttributesMap[this._presenceIndicator] || {};

        if (icon) {
          this.nodes.presenceIcon.setAttribute('icon', icon);
          blfLogger.info(`c-colleague card icon changed to ${icon}`, {
            colleagueUuid: this.data.colleagueUuid,
            description: this.data.description,
          });
        }

        // Since the c-dialer component loads faster than the websocket, an extra way to hide the element.
        if (this.showOnlyOnlineContacts && this.data.status === 'offline') {
          hide(this);
        } else {
          show(this);
        }
      }

      colleagueModelChanged(_, model) {
        this.populate(model);
      }

      async updateColleagueStatus() {
        this.colleagueModel = await getUserAvailability(this.data.colleagueUuid);

        if (this.colleagueModel) {
          this.populate(this.colleagueModel);
          // We're using a bound function here to be able to remove the event listener later.
          this.boundColleagueModelChanged = this.colleagueModelChanged.bind(this);
          this.colleagueModel.on('change', this.boundColleagueModelChanged);
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.initialPresenceIndicatorClassName = this.nodes.presenceIndicator.className;
        this.initialPresenceLabelClassName = this.nodes.presenceLabel.className;

        this.actions.call.addEventListener('click', this);
        this.actions.favorite.addEventListener('click', this);

        this.updateColleagueStatus();
        this.sortCallButtonVisibility();
        this.sortIsFavorite();

        if (this.session) {
          this.nodes.callButtonIcon.setAttribute('icon', 'transfer');
        } else {
          this.nodes.callButtonIcon.setAttribute('icon', 'phone');
        }

        callingEvents.addEventListener('clientStatusUpdated', this);
        mediaEvents.addEventListener('microphonePermissionUpdated', this);
      }

      disconnectedCallback() {
        this.actions.call.removeEventListener('click', this);
        this.actions.favorite.removeEventListener('click', this);
        this.colleagueModel.off('change', this.boundColleagueModelChanged);

        callingEvents.removeEventListener('clientStatusUpdated', this);
        mediaEvents.removeEventListener('microphonePermissionUpdated', this);
      }

      attributeChangedCallback(name, oldValue, newValue) {
        // If the contact is part of a session it means we are
        // in transfer mode.
        this.session = getSession(newValue);
      }
    },
  );
});
