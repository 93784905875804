import replaceSpecialCharacters from './replaceSpecialCharacters.mjs';

export default function getSearchValuesFromModel(model, searchTheseProperties) {
  return searchTheseProperties.reduce((prev, property) => {
    if (property in model) {
      prev.push(`${model[property]}`.toLowerCase());
      prev.push(replaceSpecialCharacters(model[property]).toLowerCase());
    }
    return prev;
  }, []);
}
