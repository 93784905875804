import deleteTemporaryRedirect from '@/lib/data/deleteTemporaryRedirect.mjs';
import getTemporaryRedirect from '@/lib/data/getTemporaryRedirect.mjs';
import getVoicemail from '@/lib/data/getVoicemail.mjs';
import setTemporaryRedirect from '@/lib/data/setTemporaryRedirect.mjs';
import { translate } from '@/lib/i18n.mjs';
import { Logger } from '@/lib/logging.mjs';
import * as segment from '@/lib/segment.mjs';
import * as user from '@/lib/user.mjs';

import { disable, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';

const logger = new Logger('temporary-redirect-setup');

loadTemplate('c-temporary-redirect-wrapper').then(({ content }) => {
  window.customElements.define(
    'c-temporary-redirect-wrapper',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
        this.combinedDateTime = null;
      }

      async handleEvent({ currentTarget }) {
        const selectedVoicemail = this.nodes.voicemailSelect.options[this.nodes.voicemailSelect.selectedIndex];

        switch (currentTarget) {
          case this.actions.setupTemporaryRedirect:
            show(this.nodes.temporaryRedirectSetup);
            hide(this.nodes.temporaryRedirectInitial);

            segment.track.setupTemporaryRedirect();
            break;

          case this.actions.enableTemporaryRedirect:
            {
              hide(this.nodes.temporaryRedirectSetupError);
              disable(this.actions.enableTemporaryRedirect);
              this.actions.enableTemporaryRedirect.textContent = translate('starting_redirect');

              await setTemporaryRedirect(null, selectedVoicemail.value, this.userData.clientUuid, this.combinedDateTime)
                .then(async ({ id, destination, end }) => {
                  this.temporaryRedirectUuid = id;
                  const { data } = await getVoicemail(this.userData.clientId, destination.id);
                  this.activeVoicemail = data;
                  hide(this.nodes.temporaryRedirectSetup);
                  show(this.nodes.temporaryRedirectActive);
                  this.nodes.temporaryRedirectActive.setDescriptionText(this.activeVoicemail.name, end);
                  // After the request has completed, we can restore the button to
                  // the initial state
                  this.actions.enableTemporaryRedirect.textContent = translate('start_redirect');
                  enable(this.actions.enableTemporaryRedirect);
                  show(this.nodes.temporaryRedirectChangeActions);
                  hide(this.nodes.temporaryRedirectSetupActions);
                  window.dispatchEvent(new CustomEvent('tr_redirectStarted'));
                })
                .catch(async (err) => {
                  enable(this.actions.enableTemporaryRedirect);
                  this.actions.enableTemporaryRedirect.textContent = translate('start_redirect');
                  show(this.nodes.temporaryRedirectSetupError);
                  logger.error(err);
                });
            }
            break;

          case this.actions.disableTemporaryRedirect:
            {
              hide(this.nodes.temporaryRedirectStopError);
              disable(this.actions.disableTemporaryRedirect);
              this.actions.disableTemporaryRedirect.textContent = translate('stopping_redirect');

              await deleteTemporaryRedirect(this.temporaryRedirectUuid, this.userData.clientUuid)
                .then(async () => {
                  // Return to the initial state of the component
                  show(this.nodes.temporaryRedirectInitial);
                  hide(this.nodes.temporaryRedirectActive);
                  // After the request has completed, we can restore the button to
                  // the initial state
                  this.actions.disableTemporaryRedirect.textContent = translate('stop_redirect');
                  enable(this.actions.disableTemporaryRedirect);
                  hide(this.nodes.temporaryRedirectChangeActions);
                  show(this.nodes.temporaryRedirectSetupActions);
                  this.temporaryRedirectUuid = null;
                  window.dispatchEvent(new CustomEvent('tr_redirectEnded'));
                })
                .catch(async (err) => {
                  this.actions.disableTemporaryRedirect.textContent = translate('stop_redirect');
                  enable(this.actions.disableTemporaryRedirect);
                  show(this.nodes.temporaryRedirectStopError);
                  logger.error(err);
                });
            }
            break;

          case this.actions.changeTemporaryRedirect:
            this.nodes.temporaryRedirectSetup.setVoicemailDescriptionText(this.activeVoicemail.name);
            this.nodes.temporaryRedirectSetup.selectVoicemail(this.activeVoicemail.id);
            show(this.nodes.temporaryRedirectSetup);
            hide(this.nodes.temporaryRedirectActive);
            segment.track.changeTemporaryRedirect();
            break;

          case this.actions.changeTemporaryRedirectConfirm:
            {
              hide(this.nodes.temporaryRedirectSetupError);
              disable(this.actions.changeTemporaryRedirectConfirm);
              this.actions.changeTemporaryRedirectConfirm.textContent = translate('changing_redirect');

              await setTemporaryRedirect(
                this.temporaryRedirectUuid,
                selectedVoicemail.value,
                this.userData.clientUuid,
                this.combinedDateTime,
              )
                .then(async ({ destination, end }) => {
                  hide(this.nodes.temporaryRedirectSetup);
                  show(this.nodes.temporaryRedirectActive);
                  const { data } = await getVoicemail(this.userData.clientId, destination.id);
                  this.activeVoicemail = data;
                  this.nodes.temporaryRedirectActive.setDescriptionText(this.activeVoicemail.name, end);
                  // After the request has completed, we can restore the button to
                  // the initial state
                  this.actions.changeTemporaryRedirectConfirm.textContent = translate('change_redirect');
                  enable(this.actions.changeTemporaryRedirectConfirm);
                  show(this.nodes.temporaryRedirectChangeActions);
                  hide(this.nodes.temporaryRedirectSetup);
                  window.dispatchEvent(new CustomEvent('tr_redirectChanged'));
                })
                .catch(async (err) => {
                  enable(this.actions.changeTemporaryRedirectConfirm);
                  this.actions.changeTemporaryRedirectConfirm.textContent = translate('change_redirect');
                  show(this.nodes.temporaryRedirectSetupError);
                  logger.error(err);
                });
            }
            break;

          case this.actions.cancelChangeTemporaryRedirect:
            hide(this.nodes.temporaryRedirectSetup);
            show(this.nodes.temporaryRedirectActive);
            hide(this.nodes.temporaryRedirectSetupError);
            break;
        }
      }

      async getTemporaryRedirectState() {
        await getTemporaryRedirect(this.userData.clientUuid)
          .then(async (response) => {
            if (response && !response.available) {
              // We already know the below information, so we don't need to refetch this on every repoll
              if (!this.temporaryRedirectUuid) {
                const { data } = await getVoicemail(this.userData.clientId, response.destination.id);
                this.activeVoicemail = data;
                this.temporaryRedirectUuid = response.id;
                this.nodes.temporaryRedirectActive.setDescriptionText(this.activeVoicemail.name, response.end);
                this.nodes.temporaryRedirectSetup.setVoicemailDescriptionText(this.activeVoicemail.name);
                this.nodes.temporaryRedirectSetup.selectVoicemail(this.activeVoicemail.id);

                hide(this.nodes.temporaryRedirectInitial);
                show(this.nodes.temporaryRedirectActive);
                show(this.nodes.temporaryRedirectChangeActions);
              }
            } else {
              hide(this.nodes.temporaryRedirectActive);
              show(this.nodes.temporaryRedirectInitial);
              show(this.nodes.temporaryRedirectSetupActions);
              this.temporaryRedirectUuid = null;
            }
          })
          .catch((err) => {
            hide(this.nodes.temporaryRedirectActive);
            show(this.nodes.temporaryRedirectInitial);
            show(this.nodes.temporaryRedirectSetupActions);
            logger.error(err);
            this.temporaryRedirectActive = null;
          });
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));
        this.userData = await user.get();
        this.activeVoicemail = null;
        this.temporaryRedirectUuid = null;
        this.toggleInterval = null;

        if (this.userData) {
          this.getTemporaryRedirectState();

          this.toggleInterval = window.setInterval(() => {
            this.getTemporaryRedirectState();
          }, 120000);
        }

        this.actions.setupTemporaryRedirect.addEventListener('click', this);
        this.actions.enableTemporaryRedirect.addEventListener('click', this);
        this.actions.disableTemporaryRedirect.addEventListener('click', this);
        this.actions.changeTemporaryRedirect.addEventListener('click', this);
        this.actions.cancelChangeTemporaryRedirect.addEventListener('click', this);
        this.actions.changeTemporaryRedirectConfirm.addEventListener('click', this);
        this.nodes.temporaryRedirectSetup.addEventListener('combined-date-time-changed', (event) => {
          this.combinedDateTime = event.detail.newValue;
        });
      }

      disconnectedCallback() {
        this.actions.setupTemporaryRedirect.removeEventListener('click', this);
        this.actions.enableTemporaryRedirect.removeEventListener('click', this);
        this.actions.disableTemporaryRedirect.removeEventListener('click', this);
        this.actions.changeTemporaryRedirect.removeEventListener('click', this);
        this.actions.cancelChangeTemporaryRedirect.removeEventListener('click', this);
        this.actions.changeTemporaryRedirectConfirm.removeEventListener('click', this);
        clearInterval(this.toggleInterval);
        this.nodes.temporaryRedirectSetup.removeEventListener('combined-date-time-changed', () => {});
      }
    },
  );
});
