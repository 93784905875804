import * as settings from '@/lib/settings/remote.mjs';

import { settingsEvents } from '@/utils/eventTarget.ts';

export const isSupported = 'wakeLock' in navigator;

let wakeLock;

export async function request() {
  if (isSupported) {
    try {
      wakeLock = await navigator.wakeLock.request('screen');

      wakeLock.addEventListener('release', async () => {
        wakeLock = null;
      });
    } catch (err) {
      console.error(err);
    }
  }
}

export function release() {
  if (wakeLock) {
    wakeLock.release();
  }
}

document.addEventListener('visibilitychange', async () => {
  if (isSupported && (await settings.get('wakeLock')) && wakeLock === null && document.visibilityState === 'visible') {
    request();
  }
});

settings.get('wakeLock').then((useWakeLock) => {
  if (isSupported && useWakeLock) {
    request();
  }
});

settingsEvents.addEventListener('wakeLock', ({ detail: useWakeLock }) => {
  if (isSupported && useWakeLock) {
    request();
  }
});
