import { VALIDATION_MESSAGES_MAPPING } from './constants.mjs';

import { camelToSnake } from '@/utils/string.ts';

// recursive function to retrieve nested properties from the validation messages object
function get(object, properties) {
  switch (typeof object) {
    case 'undefined':
      return false;

    case 'object': {
      const [property, ...rest] = properties;

      if (property in object) {
        const suffix = get(object[property], rest);
        return `${camelToSnake(property)}${suffix ? `_${suffix}` : ''}`;
      } else {
        return false;
      }
    }

    default:
      return false;
  }
}

export default function getValidationMessage(...properties) {
  return get(VALIDATION_MESSAGES_MAPPING, ['validation', ...properties]);
}
