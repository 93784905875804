import '@/components/c-contact-create-edit-form.mjs';

import { loadTemplate } from '@/utils/dom.ts';

loadTemplate('p-contact-create').then(({ content }) => {
  window.customElements.define(
    'p-contact-create',
    class extends HTMLElement {
      connectedCallback() {
        this.appendChild(content.cloneNode(true));
      }
    },
  );
});
