import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-platform-link').then(({ content }) => {
  window.customElements.define(
    'c-platform-link',

    class extends HTMLElement {
      static get observedAttributes() {
        return ['user-permission'];
      }

      constructor() {
        super();
        this.actions = new ActionsProxy(this);
        this.nodes = new NodesProxy(this);
      }

      set data(data) {
        this._data = data;
        this.populate();
      }

      get data() {
        return this._data;
      }

      populate() {
        const { href, translation } = this.data;
        this.actions.platformLink.href = href;
        this.actions.platformLink.setAttribute('data-translation-key', translation);
      }

      handleEvent(event) {
        event.preventDefault();
        window.open(this.actions.platformLink.href, '_blank');
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));
        this.nodes.linkRow.addEventListener('click', this);
      }

      disconnectedCallback() {
        this.nodes.linkRow.removeEventListener('click', this);
      }
      attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
          case 'user-permission':
            if (newValue === 'true') {
              show(this);
            } else {
              hide(this);
            }
            break;
        }
      }
    },
  );
});
