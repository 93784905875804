import { connect, disconnect } from '@/lib/calling.mjs';
import getAccount from '@/lib/data/getAccount.mjs';
import getAccounts from '@/lib/data/getAccounts.mjs';
import setAccount from '@/lib/data/setAccount.mjs';
import setDestination from '@/lib/data/setDestination.mjs';
import { translate } from '@/lib/i18n.mjs';
import { Logger } from '@/lib/logging.mjs';
import { getPlatformClientUrl } from '@/lib/platform.mjs';
import * as segment from '@/lib/segment.mjs';
import * as user from '@/lib/user.mjs';

import { disable, empty, enable, hide, loadTemplate, select } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import showSettingSaved from '@/utils/settingSaved.ts';

const logger = new Logger('account-picker');

loadTemplate('c-account-picker').then(({ content }) => {
  window.customElements.define(
    'c-account-picker',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      set isDisabled(disabled) {
        if (disabled) {
          disable(this.nodes.accountSelect);
        } else {
          enable(this.nodes.accountSelect);
        }
      }

      async updateAccounts() {
        this.accounts = [];

        const [selectedAccount, accountsFromApi] = await Promise.all([getAccount(), getAccounts(true)]);

        this.fallbackAccount = selectedAccount !== undefined ? selectedAccount.id : 'none';
        // Add the option to not select an account.
        this.accounts.push({ description: translate('no_account'), id: 'none' }, ...accountsFromApi);

        empty(this.nodes.accountSelect);

        this.accounts.forEach((account) => {
          const option = document.createElement('option');
          option.id = account.id;
          option.value = account.id;
          option.textContent = account.description;

          if (selectedAccount && selectedAccount.id === account.id) {
            select(option);
          }

          this.nodes.accountSelect.appendChild(option);
        });
      }

      handleAccountAlreadyInUse() {
        this.nodes.accountInUseError.setText('account_in_use_error');
        this.nodes.accountInUseError.show();

        // falling back to previous account
        this.selectFallbackAccount();
      }

      selectFallbackAccount() {
        // setAttribute doesn't update the list.
        this.nodes.accountSelect.options.namedItem(this.fallbackAccount).selected = 'selected';
        this.isDisabled = false;
      }

      async handleEvent({ type, currentTarget, target: { value } }) {
        if ('change' === type && currentTarget === this.nodes.accountSelect) {
          this.isDisabled = true;

          this.nodes.accountInUseError.hide();
          try {
            if (value === 'none') {
              await setAccount(undefined);
              await disconnect();
            } else {
              const account = this.accounts.find((account) => account.id === Number(value));
              await setAccount(account.id);
              // Temporary fix to ensure people can switch Webphone accounts
              await setDestination(account);

              await disconnect();
              await connect();
            }

            this.isDisabled = false;
            showSettingSaved(this.nodes.accountSelectSaved);
            this.fallbackAccount = value;

            logger.debug(`account selected: ${value}`);
            segment.track.softphoneAccountUpdate();
          } catch (error) {
            const code = error.body.account[0].code;

            if (code === 'unsuitable_account_for_plugin' || code === 'account_in_use') {
              this.handleAccountAlreadyInUse();
              this.updateAccounts();
            }
          }
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.nodes.accountSelect.addEventListener('change', this);

        const loadingOption = document.createElement('option');
        loadingOption.textContent = translate('loading');
        disable(loadingOption);
        select(loadingOption);
        hide(loadingOption);
        this.nodes.accountSelect.appendChild(loadingOption);

        this.isDisabled = true;

        await this.updateAccounts();

        this.isDisabled = false;

        // Initialize the quick links before accounts are being fetched.
        const userData = await user.get();
        this.nodes.quickLinks.settingsKey = 'accountsQuickLinksVisible';
        this.nodes.quickLinks.links = [
          {
            text: translate('quick_links_voip_accounts'),
            link: await getPlatformClientUrl('phoneaccount'),
          },
          {
            text: translate('quick_links_user_preferences'),
            link: await getPlatformClientUrl(`user/${userData.id}/change/#tc0=user-tab-2`),
          },
        ];
      }

      disconnectedCallback() {
        this.nodes.accountSelect.removeEventListener('change', this);
      }
    },
  );
});
