import { Workbox } from 'workbox-window';

import { getSession, getSessions } from '@/lib/calling-base.mjs';
import { connect, disconnect } from '@/lib/calling.mjs';
import { serviceWorkerLogger } from '@/lib/loggers.mjs';

import { removeAll } from '@/utils/dom.ts';
import { serviceWorkerEvents } from '@/utils/eventTarget.ts';
import isRunningInTestRuntime from '@/utils/isRunningInTestRuntime.ts';

import { BUSY_HERE } from '@/constants/sessionRejectionCodes.mjs';
import { getRegistration } from '@/sw-registration.mjs';

export let wb;
export let isThisWindowChosen = false;

const checkForUpdateInterval = Number(import.meta.env.VITE_SERVICE_WORKER_UPDATE_INTERVAL);

export async function windowChosen() {
  if (!wb) {
    return;
  }

  isThisWindowChosen = true;
  wb.messageSW('windowChosen');
}

function showMultipleWindowsNode() {
  const hasSessions = getSessions().length !== 0;

  if (!hasSessions && !isRunningInTestRuntime) {
    const mainContent = document.querySelector('[data-selector="main-content"]');

    // to prevent screw ups we remove all the multiple windows messages first
    removeAll('c-multiple-windows', mainContent);

    mainContent.appendChild(document.createElement('c-multiple-windows'));
  }
}

if ('serviceWorker' in navigator) {
  wb = new Workbox('/service_worker.js');

  // Send a message when a window is opened to check if multiple windows are open.
  wb.messageSW('hello');

  wb.addEventListener('message', async ({ data: { type, tag, action, data } }) => {
    // Handle 'receiving call' notification.
    if ('notification' === type && tag === 'invite' && data) {
      const { sessionId } = data;
      const session = getSession(sessionId);

      if (session) {
        switch (action) {
          case 'accept':
            session
              .accept()
              .then(() => serviceWorkerLogger.info('accepted session using the OS notification'))
              .catch(serviceWorkerLogger.error);
            break;

          case 'reject':
            session
              .reject({ statusCode: BUSY_HERE })
              .then(() => serviceWorkerLogger.info('rejected session using the OS notification'))
              .catch(serviceWorkerLogger.error);
            break;
        }
      }
    }

    if ('windowChosenComplete' === type) {
      // Whenever a window is chosen, make sure all the other windows show
      // their 'multiple windows' popup again, except for the chosen window.
      if (isThisWindowChosen) {
        isThisWindowChosen = false;
        return;
      }

      serviceWorkerLogger.info('showing the multiple-windows-open modal for the other not-chosen windows');
      showMultipleWindowsNode();
    }

    if ('multipleWindowsOpen' === type) {
      serviceWorkerLogger.info('showing the multiple-windows-open modal because multiple windows have been opened');
      showMultipleWindowsNode();
    }

    // Disconnect all the windows and reconnect the chosen one. Reason for
    // always reconnecting is to make sure this window has the latest sip
    // registration, to make sure it has priority over the others.
    if ('windowChosen' === type) {
      const hasSession = getSessions().length !== 0;

      if (!hasSession) {
        serviceWorkerLogger.info(
          'disconnecting this window because there are multiple windows open and this window does not have active sessions',
        );
        await disconnect();
      }

      if (isThisWindowChosen) {
        if (!hasSession) {
          serviceWorkerLogger.info('reconnecting this chosen window');
          await connect();
        }

        // To make sure that all the 'other' windows are showing their popup
        // again.
        wb.messageSW('windowChosenComplete');
      }
    }
  });

  // Add an event listener to detect when the registered
  // service worker has installed but is waiting to activate.
  wb.addEventListener('waiting', () => {
    serviceWorkerLogger.info('new service worker found and is waiting to take control');

    // Install the new service worker in the background.
    // But it has not taken control yet, we need to refresh the page to do that.
    wb.messageSW('skipWaiting');

    // While the webphone is in use, show the update button. Clicking the update button
    // will simply refresh the page allowing the new service worker to take control.
    serviceWorkerLogger.info('dispatching updateAvailable event for the update button');
    serviceWorkerEvents.dispatchEvent(new CustomEvent('updateAvailable'));
  });

  // Check manually for updates at the given interval.
  setInterval(() => {
    getRegistration()
      .then((reg) => reg.update())
      .catch(serviceWorkerLogger.error);
  }, checkForUpdateInterval);

  // Register the service worker after event listeners have been added.
  wb.register();
} else {
  serviceWorkerLogger.error('service workers are not supported in this browser', {
    userAgent: navigator.userAgent,
  });
}
