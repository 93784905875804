import isRunningInTestRuntime from '@/utils/isRunningInTestRuntime.ts';
import _shouldPreventUnload from '@/utils/shouldPreventUnload/index.mjs';

export default function shouldPreventUnload(e, isPrevented) {
  // We prevent an unload by default. You can however overwrite this
  // by setting window.DO_NOT_PREVENT_UNLOAD to true
  if (!e.currentTarget.DO_NOT_PREVENT_UNLOAD) {
    _shouldPreventUnload(e, isPrevented, isRunningInTestRuntime);
  }
}
