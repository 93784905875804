import register from '@/lib/directives.mjs';

register((node) => {
  if (node.nodeName !== 'svg') {
    Array.from(node.querySelectorAll('input[type=password]:not([data-inserted-visualiser])')).forEach((input) => {
      const thingy = document.createElement('c-password-visualiser');
      input.dataset.insertedVisualiser = true;
      input.insertAdjacentElement('afterend', thingy);
    });
  }
});
