import { getAudioProcessing, setAudioProcessing } from '@/lib/media.mjs';
import * as segment from '@/lib/segment.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import showSettingSaved from '@/utils/settingSaved.ts';

loadTemplate('c-audio-processing-toggle').then(({ content }) => {
  window.customElements.define(
    'c-audio-processing-toggle',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      async handleEvent({ type, currentTarget: { checked } }) {
        if ('change' === type) {
          await setAudioProcessing(checked);
          segment.track.audioProcessingUpdate();
          showSettingSaved(this.nodes.audioProcessingSaved);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.nodes.audioProcessing.addEventListener('change', this);

        this.nodes.audioProcessing.checked = await getAudioProcessing();
      }

      disconnectedCallback() {
        this.nodes.audioProcessing.removeEventListener('change', this);
      }
    },
  );
});
