import { loadTemplate } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';

const errorTranslationMap = {
  duplicate: 'contact_api_error_duplicate',
  empty: 'contact_api_error_empty',
  invalid: 'contact_api_error_invalid',
  max_items: 'contact_api_error_max_items',
  required: 'contact_api_error_required',
  unique: 'contact_api_error_unique',
};

loadTemplate('c-contact-api-error').then(({ content }) => {
  window.customElements.define(
    'c-contact-api-error',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      set error(error) {
        this._error = error;
      }

      get error() {
        return this._error;
      }

      fillTextContent() {
        const translationKey = errorTranslationMap[this.error[0]['code']];
        this.nodes.errorNode.setAttribute('data-translation-key', translationKey);
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));
        this.fillTextContent();
      }

      disconnectedCallback() {}
    },
  );
});
