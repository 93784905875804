export default function (items) {
  return items.map(({ sipreginfo, description, internal_number, account_id }) => {
    const status = sipreginfo ? 'available' : 'offline';
    return {
      description,
      status,
      phoneNumber: internal_number.toString(),
      accountId: account_id,
    };
  });
}
