export const applicationEvents = new EventTarget();
export const callingEvents = new EventTarget();
export const dataEvents = new EventTarget();
export const dtmfEvents = new EventTarget();
export const mediaEvents = new EventTarget();
export const navigationEvents = new EventTarget();
export const requestEvents = new EventTarget();
export const serviceWorkerEvents = new EventTarget();
export const settingsEvents = new EventTarget();
export const subscriptionEvents = new EventTarget();
export const userEvents = new EventTarget();
export const colleagueAvailabilityEvents = new EventTarget();
export const popoutEvents = new EventTarget();
export const wakeLockEvents = new EventTarget();
