import { serviceWorkerLogger } from '@/lib/loggers.mjs';

import { getRegistration } from '@/sw-registration.mjs';

const supported = 'Notification' in window && 'serviceWorker' in navigator;

export function requestPermission() {
  if (supported) {
    return Notification.requestPermission();
  } else {
    return Promise.reject('OS notifications are not supported in the current browser');
  }
}

export function closeNotifications() {
  if (supported) {
    serviceWorkerLogger.info(`trying to close all OS notifications`);

    return getRegistration()
      .then((reg) => reg.getNotifications())
      .then((notifications) => {
        if (notifications.length === 0) {
          serviceWorkerLogger.info(`no OS notifications to close`);
        }

        notifications.forEach((notification) => {
          serviceWorkerLogger.info(`closing OS notification: ${notification.title}`);
          notification.close();
        });
      })
      .catch(serviceWorkerLogger.error);
  }
}

export function showNotification(options) {
  if (supported) {
    serviceWorkerLogger.info(`trying to showing OS notification: ${options.title}`);

    return getRegistration()
      .then(async (reg) => {
        const show = () => {
          serviceWorkerLogger.info(`showing OS notification: ${options.title}`);
          reg.showNotification(options.title, options);
        };

        await closeNotifications();

        if (Notification.permission === 'granted') {
          show();
        } else {
          await requestPermission()
            .then((status) => {
              if (status === 'granted') {
                show();
              } else {
                throw new Error('OS notifications permission has not been granted');
              }
            })
            .catch((e) => {
              serviceWorkerLogger.error(e, {});
            });
        }
      })
      .catch((e) => {
        serviceWorkerLogger.error(e, {});
      });
  }
}
