import getCallerInfo from '@/lib/calling/getCallerInfo.mjs';
import { translate } from '@/lib/i18n.mjs';
import { Logger } from '@/lib/logging.mjs';

import { disable, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents } from '@/utils/eventTarget.ts';

const logger = new Logger('outgoing-call');

loadTemplate('c-outgoing-call').then(({ content }) => {
  window.customElements.define(
    'c-outgoing-call',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      async handleEvent({ detail, type }) {
        switch (type) {
          case 'inviteSent':
            this.setupOutgoingCall(detail);
            break;

          case 'click':
            this.session.cancel().catch(logger.error);
            break;

          case 'sessionTerminatedSoundEnded':
            {
              if (!this.session || 'undefined' === typeof detail) {
                return;
              }
              if (detail.id === this.session.id) {
                this.reset();
              }
            }
            break;
        }
      }

      async setupOutgoingCall(session) {
        this.reset();

        this.session = session;

        this.nodes.callerName.textContent = translate('loading');
        this.nodes.callerNumber.textContent = this.session.phoneNumber;

        show(this);

        getCallerInfo(this.session).then(({ displayName, phoneNumber }) => {
          this.nodes.callerName.textContent = displayName;
          this.nodes.callerNumber.textContent = phoneNumber;
        });

        this.nodes.callStatusMessage.textContent = translate('calling');

        if (typeof this.session === 'undefined') {
          this.showError();
          return;
        }

        this.session
          .accepted()
          .then(({ accepted, rejectCause }) => {
            if (!accepted) {
              this.showError(rejectCause);
              return;
            }
            this.reset();
          })
          .catch(() => this.showError());
      }

      async showError(cause) {
        disable(this.nodes.callHeader);
        disable(this.actions.hangup);

        this.nodes.callStatusMessage.textContent = translate('call_failed');
        switch (cause) {
          case 'request_terminated':
            this.nodes.callErrorMessage.textContent = '';
            break;

          case 'busy':
            this.nodes.callErrorMessage.textContent = translate('call_failed_busy');
            this.nodes.callError.classList.add('small');
            break;

          case 'address_incomplete':
            this.nodes.callErrorMessage.textContent = translate('call_failed_address_incomplete');
            this.nodes.callError.classList.add('small');
            break;

          default:
            this.nodes.callErrorMessage.textContent = translate('call_failed_unavailable');
            this.nodes.callError.classList.add('small');
            break;
        }

        show(this.nodes.callError);
      }

      reset() {
        [this.actions.hangup, this.nodes.callHeader, this.actions.hangup].forEach(enable);

        [
          this.nodes.callStatusMessage,
          this.nodes.callErrorMessage,
          this.nodes.callerName,
          this.nodes.callerNumber,
        ].forEach((n) => (n.textContent = ''));

        this.nodes.callError.classList.remove('small');
        hide(this.nodes.callError);

        delete this.session;
        hide(this);
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.actions.hangup.addEventListener('click', this);
        callingEvents.addEventListener('inviteSent', this);
        callingEvents.addEventListener('sessionTerminatedSoundEnded', this);
      }

      disconnectedCallback() {
        this.actions.hangup.removeEventListener('click', this);
        callingEvents.removeEventListener('inviteSent', this);
        callingEvents.removeEventListener('sessionTerminatedSoundEnded', this);
      }
    },
  );
});
