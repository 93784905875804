import { getAuthenticatedUserAvailability } from '@/lib/data/getAuthenticatedUserAvailability.ts';
import { getAuthenticatedUserDevices } from '@/lib/data/getAuthenticatedUserDevices.ts';
import getUserDestinations from '@/lib/data/getUserDestinations.mjs';
import setDestination from '@/lib/data/setDestination.mjs';
import { showToast } from '@/lib/toasts.mjs';

import { disable, empty, enable, hide, loadTemplate, select, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { userEvents } from '@/utils/eventTarget.ts';

const SINGLE_DEVICE = ['webphone', 'app'];

loadTemplate('c-user-destinations').then(({ content }) => {
  window.customElements.define(
    'c-user-destinations',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      async handleEvent({ type, currentTarget }) {
        switch (type) {
          case 'click':
          case 'change':
            {
              // add loading state
              this.nodes.selectContainer.classList.add('loading');

              if (currentTarget.classList.contains('fake-disabled')) {
                this.nodes.selectContainer.classList.remove('loading');
                showToast({
                  id: 'deviceOfflineWarning',
                  body: 'device_offline_toast',
                  icon: 'warning',
                  type: 'danger',
                  sticky: false,
                });
                return;
              }

              if (currentTarget.classList.contains('selected')) {
                this.nodes.selectContainer.classList.remove('loading');
                return;
              }

              switch (currentTarget) {
                case this.nodes.deviceDropdown:
                  {
                    const destination = [...this.destinations.deskphones, ...this.destinations.fixedDestinations].find(
                      (device) => device.id === this.nodes.deviceDropdown.value,
                    );
                    await setDestination(destination);
                  }
                  break;

                // All these actions will trigger an update from the WS service, which will trigger set availability,
                // which will use populate to go to the latest setting.
                case this.actions.app:
                case this.actions.webphone:
                  {
                    const destinationType = currentTarget.dataset.action;
                    await setDestination(this.destinations[destinationType]);
                  }
                  break;

                case this.actions.fixedDestinations:
                case this.actions.deskphones:
                  {
                    const destinationType = currentTarget.dataset.action;
                    if (destinationType === 'fixedDestinations') {
                      await setDestination(this.destinations[destinationType][0]);
                    } else if (destinationType === 'deskphones') {
                      const firstOnlineDevice = this.destinations.deskphones.find((deskphone) =>
                        this.deviceCollection
                          .toArray()
                          .find((device) => device.is_online && device.account_id.toString() === deskphone.account_id),
                      );

                      if (firstOnlineDevice) {
                        await setDestination(firstOnlineDevice);
                      }
                    }
                  }
                  break;
              }

              this.nodes.selectContainer.classList.remove('loading');
            }
            break;

          case 'loggedIn':
            this.setUserAvailability();
            break;
        }
      }

      disableButtonsAndOptionOnOffline() {
        this.deviceNodes.forEach((deviceButton) => {
          enable(deviceButton);
          deviceButton.classList.remove('fake-disabled');
        });

        this.deviceCollection.toArray().forEach((device) => {
          const deviceId = device.account_id.toString();

          if (this.destinations.app?.account_id === deviceId) {
            this.destinations.app.isOnline = device.is_online;
          }

          if (this.destinations.webphone?.account_id === deviceId) {
            this.destinations.webphone.isOnline = device.is_online;
          }

          const deskphone = this.destinations.deskphones?.find((deskphone) => deskphone.account_id === deviceId);

          if (deskphone) {
            deskphone.isOnline = device.is_online;

            // disable the deskphones in the dropdown if they are there.
            const deviceInDropdown = document.getElementById(deviceId);
            if (deviceInDropdown && !device.is_online) {
              disable(deviceInDropdown);
            }
          }
        });

        if (this.destinations.deskphones?.every((device) => device.isOnline === false)) {
          this.actions.deskphones.classList.add('fake-disabled');
        }
        if (!this.destinations.app?.isOnline) {
          this.actions.app.classList.add('fake-disabled');
        }
        if (!this.destinations.webphone?.isOnline) {
          this.actions.webphone.classList.add('fake-disabled');
        }
      }

      createOption(device) {
        const option = document.createElement('option');
        // For offline status we can simply return an empty option since the
        // select will be hidden.
        if (!device) return option;
        option.id = device.account_id;
        option.value = device.id;
        const deviceDescription = device.description ? device.description : device.phone_number;
        if (device.internal_number) {
          option.textContent = `${device.internal_number} / ${deviceDescription}`;
        } else {
          option.textContent = deviceDescription;
        }
        return option;
      }

      populate() {
        empty(this.nodes.deviceDropdown);
        show(this.nodes.selectContainer);

        // hide the device button if the user doesn't have that account. E.g. no deskphones or no app.
        this.deviceNodes.forEach((node) => {
          const actionsDestination = this.destinations[node.dataset.action];
          if (actionsDestination === null || actionsDestination?.length === 0) {
            hide(node);
          } else {
            show(node);
          }
        });

        let destinationType = this.destinations.selectedDestinationType;
        if (
          SINGLE_DEVICE.includes(destinationType) ||
          this.destinations[destinationType]?.length === 1 ||
          destinationType === 'noDestination'
        ) {
          const device = this.destinations.selectedDestination;
          const option = this.createOption(device);
          this.nodes.deviceDropdown.appendChild(option);

          // Only one option, so no need to have dropdown functionality here.
          this.nodes.deviceDropdown.classList.add('one-option');
          hide(this.nodes.selectToggle);
          select(option);
        } else {
          this.nodes.deviceDropdown.classList.remove('one-option');
          show(this.nodes.selectToggle);

          const devices = this.destinations[destinationType];

          devices.forEach((device) => {
            const option = this.createOption(device);
            if (this.destinations.selectedDestination?.id === device.id) {
              select(option);
            }
            this.nodes.deviceDropdown.appendChild(option);
          });
        }
        this.updateSelectedUserDestination(this.actions[destinationType]);
      }

      updateSelectedUserDestination(currentTarget) {
        this.deviceNodes.forEach((node) => {
          node.classList.remove('selected');
        });

        if (currentTarget) {
          currentTarget.classList.add('selected');
        }
      }

      // The below event handle functions are arrow functions so we don't have to bind these.
      // If not we would need to create a bound version of this function before using it as an event handler.

      handleAvailabilityChanged = async () => {
        this.deviceNodes.forEach((node) => disable(node));
        this.destinations = await getUserDestinations();
        this.populate();
        this.disableButtonsAndOptionOnOffline();
      };

      handleDeviceAddedToCollection = ({ item }) => {
        // Also trigger this to use initial data.
        this.handleAvailabilityChanged();
        item.on('change', this.handleAvailabilityChanged);
      };

      handleDeviceRemovedFromCollection = ({ item }) => {
        item.off('change', this.handleAvailabilityChanged);
      };

      async setUserAvailability() {
        // In case we had to login make sure to remove any handlers from previous-logged-in users
        // before adding new event listeners.
        if (this.userModel) {
          this.userModel.off('change', this.handleAvailabilityChanged);
        }

        if (this.deviceCollection) {
          this.deviceCollection.toArray().forEach((model) => model.off('change', this.handleAvailabilityChanged));
          this.deviceCollection.off('add', this.handleDeviceAddedToCollection);
          this.deviceCollection.off('remove', this.handleDeviceRemovedFromCollection);
        }

        this.userModel = await getAuthenticatedUserAvailability();
        this.deviceCollection = await getAuthenticatedUserDevices();

        if (this.userModel) {
          // Also trigger this to use initial data.
          this.handleAvailabilityChanged();
          this.userModel.on('change', this.handleAvailabilityChanged);
        }

        if (this.deviceCollection) {
          this.deviceCollection.toArray().forEach((model) => model.on('change', this.handleAvailabilityChanged));
          this.deviceCollection.on('add', this.handleDeviceAddedToCollection);
          this.deviceCollection.on('remove', this.handleDeviceRemovedFromCollection);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.deviceNodes = [
          this.actions.app,
          this.actions.webphone,
          this.actions.fixedDestinations,
          this.actions.deskphones,
        ];

        this.setUserAvailability();

        this.deviceNodes.forEach((node) => node.addEventListener('click', this));
        this.nodes.deviceDropdown.addEventListener('change', this);
        userEvents.addEventListener('loggedIn', this);
      }

      disconnectedCallback() {
        if (this.userModel) {
          this.userModel.off('change', this.handleAvailabilityChanged);
        }

        if (this.deviceCollection) {
          this.deviceCollection.toArray().forEach((model) => model.off('change', this.handleAvailabilityChanged));
          this.deviceCollection.off('add', this.handleDeviceAddedToCollection);
          this.deviceCollection.off('remove', this.handleDeviceRemovedFromCollection);
        }

        this.deviceNodes.forEach((node) => node.removeEventListener('click', this));
        this.nodes.deviceDropdown.removeEventListener('change', this);
        userEvents.removeEventListener('loggedIn', this);
      }
    },
  );
});
