import { invite, isAbleToMakeCall } from '@/lib/calling.mjs';
import { translate, translateNodes } from '@/lib/i18n.mjs';
import * as segment from '@/lib/segment.mjs';

import { disable, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents, mediaEvents } from '@/utils/eventTarget.ts';
import { navigate } from '@/utils/history.ts';
import * as selection from '@/utils/selection.mjs';
import * as time from '@/utils/time.ts';

const eventCheckTimeout = 150;

function performClickActionIfNeeded() {
  if (selection.hadSelection) {
    return;
  }
  if (isAbleToMakeCall) {
    segment.track.callRecent();
    invite(this.data.phoneNumber);
  }
}

const statusToIconMapping = {
  'incoming': 'incoming-call',
  'missed': 'missed-call',
  'outgoing': 'outgoing-call',
  'answered-elsewhere': 'answered-elsewhere',
};

loadTemplate('c-recent-call').then(({ content }) => {
  window.customElements.define(
    'c-recent-call',
    class extends HTMLElement {
      set data(data) {
        this._data = data;
        this.populate();
      }

      get data() {
        return this._data;
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);

        // bind the performClickActionIfNeeded function to the instance so we can easilly call it via setTimeout later
        // and not make a new function each time we need to execute it
        this.performClickActionIfNeeded = performClickActionIfNeeded.bind(this);
      }

      handleEvent(e) {
        const { type, currentTarget } = e;
        switch (type) {
          case 'dblclick':
            clearTimeout(this.eventCheckTimer);
            break;

          case 'click':
            {
              switch (currentTarget) {
                case this.actions.call:
                  if (isAbleToMakeCall) {
                    segment.track.callRecent();
                    invite(this.data.phoneNumber);
                  }
                  break;

                case this.actions.copy:
                  // Stop the propagation otherwise you will click through
                  // the copy button and it will also trigger the click
                  // of the row.
                  e.stopPropagation();
                  navigator.clipboard.writeText(this.data.phoneNumber);
                  hide(this.nodes.copyLabel);
                  show(this.nodes.copiedLabel);
                  segment.track.copyRecentPhoneNumber();
                  break;

                case this.actions.createContact:
                  e.stopPropagation();
                  navigate(`/contact/create/${this.data.phoneNumber}`);
                  break;

                default:
                  clearTimeout(this.eventCheckTimer);
                  this.eventCheckTimer = setTimeout(this.performClickActionIfNeeded, eventCheckTimeout);
              }
            }
            break;

          case 'mouseout':
            show(this.nodes.copyLabel);
            hide(this.nodes.copiedLabel);
            break;

          case 'clientStatusUpdated':
            this.sortCallActionsAvailability();
            break;

          case 'microphonePermissionUpdated':
            this.sortCallActionsAvailability();
            break;
        }
      }

      populate() {
        if (this.data && this.isConnected) {
          const { status, callerName, phoneNumber, callDate, duration, answeredElsewhereName } = this.data;

          const icon = statusToIconMapping[status];
          this.nodes.avatar.classList.add(status);
          this.nodes.icon.setAttribute('icon', icon);

          this.nodes.callerName.textContent = callerName ? callerName : phoneNumber;
          this.nodes.callerNumber.textContent = phoneNumber;
          this.nodes.callDate.textContent = callDate;

          const { hours, minutes, seconds } = time.getHoursMinutesAndSeconds(duration * time.second);

          let timeString = '';
          if (seconds > 0 || duration === 0) {
            timeString = `${seconds}s`;
          }
          if (minutes > 0) {
            timeString = `${minutes}m ` + timeString;
          }
          if (hours > 0) {
            timeString = `${hours}h ` + timeString;
          }
          this.nodes.callDuration.textContent = timeString;

          if ('answered-elsewhere' === status) {
            this.nodes.callerNumber.textContent += ` ${translate('answered_by')} ${answeredElsewhereName}`;
          }
        }
      }

      sortCallActionsAvailability() {
        if (isAbleToMakeCall) {
          enable(this.actions.call);
          this.classList.add('active');
        } else {
          disable(this.actions.call);
          this.classList.remove('active');
        }
      }

      sortActionButtonsVisibility() {
        if (isAbleToMakeCall) {
          this.classList.add('active');
        } else {
          this.classList.remove('active');
        }
      }

      sortCreatContactButtonVisibility() {
        if (this.data.callerName) {
          hide(this.actions.createContact);
        } else {
          show(this.actions.createContact);
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        translateNodes(this);

        this.addEventListener('click', this);
        this.addEventListener('dblclick', this);

        this.actions.call.addEventListener('click', this);
        this.actions.copy.addEventListener('click', this);
        this.actions.createContact.addEventListener('click', this);
        this.actions.copy.addEventListener('mouseout', this);

        this.populate();
        this.sortCallActionsAvailability();
        this.sortCreatContactButtonVisibility();

        callingEvents.addEventListener('clientStatusUpdated', this);
        mediaEvents.addEventListener('microphonePermissionUpdated', this);
      }

      disconnectedCallback() {
        this.actions.call.removeEventListener('click', this);
        this.actions.copy.removeEventListener('click', this);
        this.actions.createContact.removeEventListener('click', this);
        this.actions.copy.removeEventListener('mouseout', this);

        this.removeEventListener('click', this);
        this.removeEventListener('dblclick', this);

        callingEvents.removeEventListener('clientStatusUpdated', this);
        mediaEvents.removeEventListener('microphonePermissionUpdated', this);
      }
    },
  );
});
