export default class Group {
  get name() {
    return this._name;
  }

  constructor({ name }) {
    this._name = name;
  }

  serialize() {
    return {
      name: this.name,
    };
  }
}
