import getAccount from '@/lib/data/getAccount.mjs';
import getCallerIdNumbers from '@/lib/data/getCallerIdNumbers.ts';
import getVoipAccount from '@/lib/data/getVoipAccount.ts';
import updateVoipAccount, { UpdateVoipAccountBody } from '@/lib/data/updateVoipAccount.ts';
import validateIfUserHasAllPermissions from '@/lib/data/validateIfUserHasAllPermissions.mjs';
import { translate } from '@/lib/i18n.mjs';
import { getPlatformClientUrl } from '@/lib/platform.mjs';
import * as user from '@/lib/user.mjs';

import { disable, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.ts';
import { callingEvents } from '@/utils/eventTarget.ts';
import showSettingSaved from '@/utils/settingSaved.ts';

interface UserData {
  clientId: string;
  clientUuid: string;
  id: string;
}

interface WebphoneAccount {
  id: string;
}

interface SettingErrorComponent extends HTMLElement {
  setText(text: string): void;
}

interface QuickLinksPopupComponent extends HTMLElement {
  links: Array<{ text: string; link: string }>;
}

interface Nodes {
  changeOutgoingNumber: HTMLElement;
  noAccount: HTMLElement;
  outgoingNumberSelectContainer: HTMLElement;
  outgoingNumberSelect: HTMLSelectElement;
  outgoingNumberError: SettingErrorComponent;
  outgoingNumberSaved: HTMLElement;
  quickLinks: QuickLinksPopupComponent;
}

loadTemplate('c-change-outgoing-number').then(({ content }) => {
  window.customElements.define(
    'c-change-outgoing-number',
    class extends HTMLElement {
      nodes: Nodes;
      userData: UserData | null;
      webphoneAccount: WebphoneAccount | null | undefined;
      hasVoipAccountPermissions = false;

      constructor() {
        super();
        this.nodes = NodesProxy<Nodes>(this);
        this.userData = null;
        this.webphoneAccount = null;
      }

      async handleEvent(event: CustomEvent) {
        switch (event.type) {
          case 'change':
            this.updateOutgoingNumber((event.target as HTMLSelectElement).value);
            break;

          case 'clientStatusUpdated':
            // When the client is connected, we can hide the c-no-account
            // and fetch the initial data
            if (event.detail.status === 'connected' && !this.webphoneAccount) {
              this.webphoneAccount = await getAccount();

              if (this.nodes.changeOutgoingNumber) {
                show(this.nodes.changeOutgoingNumber);
              }

              if (this.nodes.noAccount) {
                hide(this.nodes.noAccount);
              }

              this.fetchInitialData();
            }
            break;
        }
      }

      async updateOutgoingNumber(outgoingNumber: string) {
        // Hide any errors, so we can change this while the previous error is still visible
        hide(this.nodes.outgoingNumberError);

        // To prevent someone changing the outgoingNumber while we are still updating
        disable(this.nodes.outgoingNumberSelect);

        let response;

        if (this.userData && this.webphoneAccount) {
          const body: UpdateVoipAccountBody = {
            outgoing_caller_identification: {
              phone_number: outgoingNumber,
            },
          };

          response = await updateVoipAccount(this.userData.clientId, this.webphoneAccount.id, body);
        }

        // If the response returns undefined, it means that the request failed
        // and we'll show an error
        if (!response) {
          this.nodes.outgoingNumberError.setText('error_occured');
          show(this.nodes.outgoingNumberError);
        } else {
          showSettingSaved(this.nodes.outgoingNumberSaved);
        }

        // When all is set and done, re-enable the select
        enable(this.nodes.outgoingNumberSelect);
      }

      async fetchInitialData() {
        // If we have no account we can hide the current screen
        // and show the (custom styled) no account screen
        if (!this.webphoneAccount) {
          hide(this.nodes.changeOutgoingNumber);
          show(this.nodes.noAccount);
          return;
        }

        const outgoingNumbers = [];

        if (this.userData) {
          let response = await getCallerIdNumbers(this.userData.clientUuid, null);
          outgoingNumbers.push(...response.items);

          while (response.next) {
            // We could implement rate limiting here, but it seems like it's not needed
            // so far. If we do need it, we can use the following code:
            // await new Promise(r => setTimeout(r, 1000)); // wait for 1 second
            response = await getCallerIdNumbers(this.userData.clientUuid, response.next);
            outgoingNumbers.push(...response.items);
          }
        }

        // Fill the select with the outgoing numbers
        outgoingNumbers.forEach((outgoingNumber) => {
          const option = document.createElement('option');
          option.value = outgoingNumber.number;
          option.textContent = `${outgoingNumber.number} ${
            outgoingNumber.description ? `(${outgoingNumber.description})` : ''
          }`;

          this.nodes.outgoingNumberSelect.appendChild(option);
        });

        // After the select is filled, we can take a look at which
        // outgoing number is currently selected

        let currentVoipAccount;

        if (this.userData && this.webphoneAccount) {
          currentVoipAccount = await getVoipAccount(this.userData.clientId, this.webphoneAccount.id);
        }

        // currentVoipAccount can return undefined or {} (defaultResponse)
        // so we need to use chaining operators to make sure we actually have
        // a phone number
        if (currentVoipAccount?.outgoing_caller_identification?.phone_number) {
          this.nodes.outgoingNumberSelect.value = currentVoipAccount?.outgoing_caller_identification?.phone_number;
        }
        // Remove loading state
        if (this.nodes.outgoingNumberSelectContainer) {
          this.nodes.outgoingNumberSelectContainer.classList.remove('loading');
        }
      }

      async createQuickLinks() {
        if (this.nodes.quickLinks && this.userData) {
          this.nodes.quickLinks.links = [
            {
              text: translate('quick_links_phonenumber'),
              link: await getPlatformClientUrl('phonenumber'),
            },
            {
              text: translate('quick_links_user_preferences'),
              link: await getPlatformClientUrl(`user/${this.userData.id}/change/#tc0=user-tab-2`),
            },
          ];
        }
      }

      async connectedCallback() {
        this.hasVoipAccountPermissions = await validateIfUserHasAllPermissions(['phoneaccount.view_phoneaccount']);

        if (this.hasVoipAccountPermissions) {
          const settingsOutgoingNumberElement = document.querySelector('[data-selector="settingsOutgoingNumber"]');
          if (settingsOutgoingNumberElement) {
            show(settingsOutgoingNumberElement);
          }
          this.appendChild(content.cloneNode(true));
          this.userData = await user.getUserFromStorage();
          this.webphoneAccount = await getAccount();
          await this.createQuickLinks();
          await this.fetchInitialData();

          this.nodes.outgoingNumberSelect.addEventListener('change', this);
          callingEvents.addEventListener('clientStatusUpdated', this);
        }
      }

      disconnectedCallback() {
        if (this.hasVoipAccountPermissions) {
          this.nodes.outgoingNumberSelect.removeEventListener('change', this);
        }
        callingEvents.removeEventListener('clientStatusUpdated', this);
      }
    },
  );
});
