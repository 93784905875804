/**
 * Pads a number  with a leading zero if the number is less than 10.
 * @param num - the number to left pad.
 * @returns {string} - the left padded string representation of the number.
 */
export default function leftPad(num) {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
}
