import request from '@/lib/request.mjs';

export default function (temporaryRedirectUuid, voicemailId, clientUuid, combinedDateTime) {
  if (voicemailId) {
    const body = {};
    body.end = combinedDateTime;

    body.destination = {
      type: 'VOICEMAIL',
      id: parseInt(voicemailId),
    };

    if (temporaryRedirectUuid) {
      return request('updateTemporaryRedirect', {
        client_uuid: clientUuid,
        temporary_redirect_uuid: temporaryRedirectUuid,
        body: body,
      });
    } else {
      return request('setTemporaryRedirect', { client_uuid: clientUuid, body: body });
    }
  }
}
