export default function soundCheck(audioContext) {
  return new Promise((resolve, reject) => {
    const timeout = window.setTimeout(reject, 100);

    audioContext.addEventListener('statechange', () => {
      window.clearTimeout(timeout);
      if (audioContext.state === 'running') {
        resolve();
      } else {
        reject();
      }
    });

    if (audioContext.state === 'running') {
      window.clearTimeout(timeout);
      resolve();
    }
  });
}
