import isValidatedBefore from './isValidatedBefore.mjs';

export default function ({ target }) {
  if (!target.validity.valid) {
    if (isValidatedBefore(target)) {
      // don't ask me why this fixes Chrome to be teribly wonky as to what input an error is shown under...
      // but it solves the issue at least... /shrug
      window.setTimeout(() => {
        target.reportValidity();
      }, 0);
    }
  }
}
