import { disable, enable, hide } from '@/utils/dom.ts';
import getInstanceName from '@/utils/getInstanceName.mjs';

import upcomingStatesForButtons from '@/constants/upcomingStatesForButtons.mjs';
import Contact from '@/models/Contact.mjs';

/* ROUTER LINK SHARED FUNCTIONS */
export function routerLinkAttributeChanged(name, oldValue, newValue) {
  switch (name) {
    case 'icon':
      this.iconNode.setAttribute('icon', newValue);
      break;

    case 'path':
      this._path = newValue;
      break;

    case 'disabled':
      // make sure the <a>'s in the nav-link or router-link are also disabled when they need to be
      Array.from(this.querySelectorAll('a')).map(null === newValue ? enable : disable);
      break;
  }
}

export function routerLinkConnect() {
  this.addEventListener('click', this);
  window.addEventListener('popstate', this);
  window.addEventListener('updateview', this);
}

export function routerLinkDisconnect() {
  this.removeEventListener('click', this);
  window.removeEventListener('popstate', this);
  window.removeEventListener('updateview', this);
}

export function routerLinkSetActive() {
  if (this._path === document.location.pathname) {
    this.setAttribute('active', '');
  } else {
    this.removeAttribute('active');
  }
}

export function routerLinkHandleEvent(e) {
  const { type, target, currentTarget } = e;

  switch (type) {
    case 'click': {
      if (currentTarget.hasAttribute('disabled') || target.hasAttribute('disabled')) {
        e.preventDefault();
        return;
      }

      if (target.nodeName === 'A') {
        e.preventDefault();
      }
      if (document.location.pathname !== this._path) {
        history.pushState(undefined, '', this._path);
        window.dispatchEvent(new CustomEvent('updateview'));
      }
      break;
    }

    case 'popstate':
    case 'updateview':
      routerLinkSetActive.call(this);
      break;
  }
}

export function determineElementName(colltact) {
  if (getInstanceName(colltact) === Contact.name.toLowerCase()) {
    return 'C-CONTACT';
  } else if (colltact.emailAddress) {
    return 'C-COLLEAGUE';
  } else {
    return 'C-ACCOUNT';
  }
}

export function createColltactCard(colltact, afterNode, showOnlyOnlineContacts, phoneNumberInstance) {
  const elementName = determineElementName(colltact);

  const colltactNode = document.createElement(elementName);
  if (phoneNumberInstance) {
    colltactNode.phoneNumber = phoneNumberInstance;
  }
  colltactNode.setAttribute('tabindex', '0');
  colltactNode.classList.add('click-to-call');

  colltactNode.showOnlyOnlineContacts = showOnlyOnlineContacts;

  colltactNode.data = colltact;
  hide(colltactNode);
  afterNode.insertAdjacentElement('afterend', colltactNode);
  return colltactNode;
}

export function createColltactCards(colltacts, node, showOnlyOnlineContacts) {
  let afterNode = node;

  const colltactNodes = [];

  for (const colltact of colltacts) {
    if (showOnlyOnlineContacts && colltact.status === 'offline') {
      continue;
    }

    if (colltact.phoneNumbers) {
      for (const phoneNumberInstance of colltact.phoneNumbers) {
        const colltactNode = createColltactCard(colltact, afterNode, showOnlyOnlineContacts, phoneNumberInstance);
        afterNode = colltactNode;
        colltactNodes.push(colltactNode);
      }
    } else {
      const colltactNode = createColltactCard(colltact, afterNode, showOnlyOnlineContacts);
      afterNode = colltactNode;
      colltactNodes.push(colltactNode);
    }
  }
  return colltactNodes;
}

export function setVisualStateOfButton({ buttonKey, state, buttonNode }) {
  const { icon, translationKey } = upcomingStatesForButtons[buttonKey][state];
  const iconNode = buttonNode.querySelector('c-icon');
  if (iconNode) {
    iconNode.setAttribute('icon', icon);
  }
  buttonNode.querySelector('c-translate').setAttribute('key', translationKey);
}
