import { loadTemplate } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { getDateTimeString } from '@/utils/formatDate.ts';

loadTemplate('c-temporary-redirect-active').then(({ content }) => {
  window.customElements.define(
    'c-temporary-redirect-active',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      async setDescriptionText(selectedVoicemailName, dateTime) {
        this.nodes.temporaryRedirectVoicemail.textContent = selectedVoicemailName;

        const formattedDateTimeString = await getDateTimeString(dateTime);
        this.nodes.redirectCallsDurationDateTime.textContent = formattedDateTimeString;
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));
      }
    },
  );
});
