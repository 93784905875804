import '@/components/c-account.mjs';
import '@/components/c-colleague.mjs';
import '@/components/c-contact.mjs';
import '@/components/c-keypad.mjs';
import { createColltactCards } from '@/components/shared.mjs';

import { attendedTransfer, blindTransfer, getSession, invite, isAbleToMakeCall } from '@/lib/calling.mjs';
import * as colleagues from '@/lib/colleagues.mjs';
import * as contacts from '@/lib/contacts.mjs';
import * as favorites from '@/lib/favorites.mjs';
import * as segment from '@/lib/segment.mjs';
import * as settings from '@/lib/settings/remote.mjs';
import * as voipaccounts from '@/lib/voipaccounts.mjs';

import cleanPhoneNumber from '@/utils/cleanPhoneNumber.mjs';
import { disable, enable, hide, isHidden, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { callingEvents, mediaEvents } from '@/utils/eventTarget.ts';
import { notTelephoneNumberRegExp } from '@/utils/regexp.ts';

const CONTACT_CARD_NODE_NAMES = ['C-ACCOUNT', 'C-CONTACT', 'C-COLLEAGUE'];

loadTemplate('c-transfer').then(({ content }) => {
  window.customElements.define(
    'c-transfer',
    class extends HTMLElement {
      static get observedAttributes() {
        return ['session-id'];
      }

      get firstVisibleContactSuggestion() {
        return this.allVisibleContactSuggestions[0];
      }

      get focusedContactSuggestion() {
        const { activeElement } = document;
        if (activeElement && activeElement.nodeName && CONTACT_CARD_NODE_NAMES.includes(activeElement.nodeName)) {
          return activeElement;
        }
        return undefined;
      }

      get nextVisibleContactSuggestion() {
        let index = this.allVisibleContactSuggestions.indexOf(this.focusedContactSuggestion);
        return this.allVisibleContactSuggestions[index + 1];
      }
      get previousVisibleContactSuggestion() {
        let index = this.allVisibleContactSuggestions.indexOf(this.focusedContactSuggestion);
        return this.allVisibleContactSuggestions[index - 1];
      }

      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);

        // since we handle keydown events for different containers for this custom element,
        // instead of using the instance with a handleEvent function to bind to in the addEventListener's
        // we bind the handleInputKeyDownEvent method to the instance to be able to use this as a reference to the instance itself,
        // and to reason about keydown events from the input in a seperate function for clarity
        this.handleInputKeyDownEvent = this.handleInputKeyDownEvent.bind(this);

        this.allContactSuggestions = {
          favorites: [],
          voipaccounts: [],
          colleagues: [],
          contacts: [],
        };
      }

      handleInputKeyDownEvent(e) {
        switch (e.key) {
          case 'ArrowDown':
            {
              e.preventDefault();
              const node = this.nextVisibleContactSuggestion || this.firstVisibleContactSuggestion;
              if (node) {
                node.focus();
              }
            }
            break;

          case 'Enter':
            {
              e.preventDefault();
              const { value } = this.nodes.input;
              const firstVisibleContactSuggestion = this.firstVisibleContactSuggestion;
              if (notTelephoneNumberRegExp.test(value)) {
                if (firstVisibleContactSuggestion) {
                  const { phoneNumber } = firstVisibleContactSuggestion;
                  phoneNumber && this.transferCall(phoneNumber);
                }
              } else {
                this.transferCall();
              }
            }
            break;
        }
      }

      handleEvent(e) {
        const { type, currentTarget, target, key } = e;
        const { dataset } = target;

        if (dataset && 'action' in dataset && target === currentTarget) {
          const { action } = dataset;
          switch (action) {
            case 'backspace':
              {
                const { value } = this.nodes.input;
                if (value) {
                  this.nodes.input.value = value.substring(0, value.length - 1);
                  this.updateSuggestions();
                }
              }
              break;

            case 'toggleDialpad':
              this.toggleDialpad();
              break;

            case 'transferCall':
              this.transferCall();
              break;

            case 'completeTransfer':
              if (this.transferToSession) {
                attendedTransfer(this.session, this.transferToSession);
              }
              break;

            case 'toggleVoipaccounts':
              this.toggleVoipaccounts();
              break;

            case 'toggleColleagues':
              this.toggleColleagues();
              break;

            case 'toggleContacts':
              this.toggleContacts();
              break;

            case 'toggleFavorites':
              this.toggleFavorites();
              break;
          }
          return;
        }

        switch (type) {
          case 'keydown':
            switch (key) {
              case 'Backspace':
                e.preventDefault();
                break;

              case 'Enter':
                {
                  e.preventDefault();
                  const node = this.focusedContactSuggestion;
                  if (node) {
                    const { phoneNumber } = node;
                    phoneNumber && this.transferCall(phoneNumber);
                  }
                }
                break;

              case 'ArrowRight':
                {
                  e.preventDefault();
                  const node = this.nextVisibleContactSuggestion || this.firstVisibleContactSuggestion;
                  if (node) {
                    node.focus();
                  }
                }
                break;

              case 'ArrowLeft':
                {
                  e.preventDefault();
                  const node = this.previousVisibleContactSuggestion;
                  if (node) {
                    node.focus();
                  }
                }
                break;
            }
            break;

          case 'click':
            switch (currentTarget) {
              case this.nodes.keypad:
                if (target.dataset.key) {
                  this.nodes.input.value += target.dataset.key;
                  this.updateSuggestions();
                }
                break;

              case this.nodes.suggestions:
                if (CONTACT_CARD_NODE_NAMES.includes(target.nodeName)) {
                  const { phoneNumber } = target;
                  phoneNumber && this.transferCall(phoneNumber);
                }
                break;
            }
            break;

          case 'input':
            this.updateSuggestions();
            break;

          case 'change':
            this.setTransferMethod(currentTarget.value);
            break;

          case 'paste':
            {
              const pasteData = e.clipboardData.getData('text/plain');
              const cleanedNumber = cleanPhoneNumber(pasteData);
              if (cleanedNumber) {
                e.preventDefault();
                const { selectionStart, selectionEnd } = this.nodes.input;
                const newSelection = selectionStart + cleanedNumber.length;
                this.nodes.input.setRangeText(cleanedNumber, selectionStart, selectionEnd);
                this.nodes.input.setSelectionRange(newSelection, newSelection);
                this.updateSuggestions();
              }
            }
            break;
          case 'clientStatusUpdated':
            this.sortTransferButtonVisiblity();
            break;

          case 'microphonePermissionUpdated':
            this.sortTransferButtonVisiblity();
            break;
        }
      }

      async toggleDialpad() {
        await settings.set('showDialpad', !(await settings.get('showDialpad')));
        this.sortDialpadVisibility();
      }

      async toggleVoipaccounts() {
        await settings.set('showVoipaccounts', !(await settings.get('showVoipaccounts')));
        this.updateSuggestions();
      }

      async toggleContacts() {
        await settings.set('showContacts', !(await settings.get('showContacts')));
        this.updateSuggestions();
      }

      async toggleColleagues() {
        await settings.set('showColleagues', !(await settings.get('showColleagues')));
        this.updateSuggestions();
      }

      async toggleFavorites() {
        await settings.set('showFavorites', !(await settings.get('showFavorites')));
        this.updateSuggestions();
      }

      async sortDialpadVisibility() {
        if (await settings.get('showDialpad')) {
          show(this.nodes.dialpad);
          this.actions.toggleDialpad.classList.add('active');
        } else {
          hide(this.nodes.dialpad);
          this.actions.toggleDialpad.classList.remove('active');
        }
      }

      sortTransferButtonVisiblity() {
        if (!isAbleToMakeCall) {
          disable(this.actions.transferCall);
        } else {
          enable(this.actions.transferCall);
        }
      }

      async sortSuggestionsSectionVisibility(toggleNode, settingsKey, type) {
        let visible = !isHidden(toggleNode) ? await settings.get(settingsKey) : true;

        const { value } = this.nodes.input;

        this.showMatchesFor(this.allContactSuggestions[type], value, visible);

        if (visible) {
          toggleNode.classList.remove('closed');
        } else {
          toggleNode.classList.add('closed');
        }
      }

      async updateSuggestions() {
        this.allVisibleContactSuggestions = [];
        this.sortSuggestionsSectionVisibility(this.actions.toggleFavorites, 'showFavorites', 'favorites');
        this.sortSuggestionsSectionVisibility(this.actions.toggleVoipaccounts, 'showVoipaccounts', 'voipaccounts');
        this.sortSuggestionsSectionVisibility(this.actions.toggleColleagues, 'showColleagues', 'colleagues');
        this.sortSuggestionsSectionVisibility(this.actions.toggleContacts, 'showContacts', 'contacts');
      }

      transferCall(value = this.nodes.input.value) {
        if (!value) {
          return;
        }
        const number = cleanPhoneNumber(value);

        switch (this.transferMethod) {
          case 'attended':
            {
              segment.track.attendedTransfer();
              invite(number, {
                isAttendedTransfer: true,
                session: this.session,
              });
            }
            break;

          case 'blind':
            {
              segment.track.blindTransfer();
              blindTransfer(this.session, number);
            }
            break;
        }
      }

      setTransferMethod(transferMethod) {
        this.transferMethod = transferMethod;
        settings.set('latestTransferMethod', transferMethod);

        switch (this.transferMethod) {
          case 'attended':
            hide(this.nodes.blindTransferIndicator);
            show(this.nodes.attendedTransferIndicator);
            break;

          case 'blind':
            show(this.nodes.blindTransferIndicator);
            hide(this.nodes.attendedTransferIndicator);
            break;
        }
      }

      showMatchesFor(nodes, value, extraCheck = true) {
        for (const node of nodes) {
          if (node.doesMatchSearchString(value) && extraCheck && node.shouldShow()) {
            show(node);
            this.allVisibleContactSuggestions.push(node);
          } else {
            hide(node);
          }
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.nodes.suggestionsContainer.classList.add('loading');

        const [voipaccountsList, colleaguesList, contactsList, favoritesList, showOnlyOnlineContacts] =
          await Promise.all([
            voipaccounts.all(),
            colleagues.all(),
            contacts.all(),
            favorites.all(),
            settings.get('showOnlineContacts'),
          ]).then(([voipaccountsList, colleaguesList, contactsList, favoritesList, showOnlyOnlineContacts]) => {
            return [
              voipaccountsList.filter((colltact) => colltact.phoneNumber || colltact.hasPhoneNumber),
              colleaguesList,
              contactsList.filter((colltact) => colltact.phoneNumber || colltact.hasPhoneNumber),
              favoritesList.filter((favorite) => favorite.phoneNumber || favorite.hasPhoneNumber),
              showOnlyOnlineContacts,
            ];
          });

        this.allContactSuggestions = {
          favorites: createColltactCards(favoritesList, this.actions.toggleFavorites, showOnlyOnlineContacts),
          voipaccounts: createColltactCards(voipaccountsList, this.actions.toggleVoipaccounts, showOnlyOnlineContacts),
          colleagues: createColltactCards(colleaguesList, this.actions.toggleColleagues, showOnlyOnlineContacts),
          contacts: createColltactCards(contactsList, this.actions.toggleContacts, showOnlyOnlineContacts),
        };

        this.allVisibleContactSuggestions = [];

        if (favoritesList.length === 0) {
          hide(this.actions.toggleFavorites);
          hide(this.actions.toggleContacts);
        }

        this.sortDialpadVisibility();
        this.updateSuggestions();

        this.nodes.suggestionsContainer.classList.remove('loading');

        [
          this.actions.toggleDialpad,
          this.actions.toggleVoipaccounts,
          this.actions.toggleColleagues,
          this.actions.toggleContacts,
          this.actions.toggleFavorites,
          this.actions.backspace,
          this.actions.transferCall,
          this.nodes.keypad,
          this.nodes.suggestions,
        ].forEach((n) => n.addEventListener('click', this));

        this.nodes.suggestions.addEventListener('keydown', this, true);

        this.nodes.input.addEventListener('input', this);
        this.nodes.input.addEventListener('paste', this);
        this.nodes.input.addEventListener('keydown', this.handleInputKeyDownEvent, true);

        this.nodes.selectTransferMethod.addEventListener('change', this);

        let latestTransferMethod = await settings.get('latestTransferMethod');

        // When settings were not loaded the selected item in the dropdown was
        // empty and was then sent to the settings API.
        // Perhaps it's a good idea to introduce choices for certain settings
        // and otherwise get the default value.
        if (latestTransferMethod === '') {
          latestTransferMethod = 'attended';
        }

        this.nodes.selectTransferMethod.value = latestTransferMethod;

        this.setTransferMethod(this.nodes.selectTransferMethod.value);

        callingEvents.addEventListener('attendedTransferStatusUpdated', this);
        callingEvents.addEventListener('clientStatusUpdated', this);
        mediaEvents.addEventListener('microphonePermissionUpdated', this);

        this.nodes.input.focus();
      }

      disconnectedCallback() {
        [
          this.actions.toggleDialpad,
          this.actions.toggleVoipaccounts,
          this.actions.toggleColleagues,
          this.actions.toggleContacts,
          this.actions.toggleFavorites,
          this.actions.backspace,
          this.actions.transferCall,
          this.nodes.keypad,
          this.nodes.suggestions,
        ].forEach((n) => n.removeEventListener('click', this));

        this.nodes.suggestions.removeEventListener('keydown', this, true);

        this.nodes.input.removeEventListener('input', this);
        this.nodes.input.removeEventListener('paste', this);
        this.nodes.input.removeEventListener('keydown', this.handleInputKeyDownEvent, true);

        this.nodes.selectTransferMethod.removeEventListener('change', this);

        callingEvents.removeEventListener('attendedTransferStatusUpdated', this);
        callingEvents.removeEventListener('clientStatusUpdated', this);
        mediaEvents.removeEventListener('microphonePermissionUpdated', this);
      }

      attributeChangedCallback(name, oldValue, newValue) {
        this.session = getSession(newValue);
      }
    },
  );
});
