// Matches any character that is not a digit, plus sign, space, asterisk, or hash
// OR a plus sign not followed by a digit
// OR a string that is exactly one character long and contains only the allowed characters
export const notTelephoneNumberRegExp = /[^\d+ *#]|\+(?!\d)|^[\d+ *#]{1}$/;

// Matches either a plus sign or "00" followed by exactly two digits
export const removeCountryCodeRegExp = /(\+|00)\d{2}/;

// Matches any of the following characters: (, ), -, =, #, ., ,, |, {, }, [, ]
export const removeTheseFromPhoneNumbersRegExp = /[()\-=#.,|{}[\]]/g;

// Matches any character in the Unicode range U+2000 to U+206F, which includes various punctuation and special symbols
export const generalPunctuationCharacterBlockRegExp = /[\u2000-\u206F]/gu;
