import * as settings from '@/lib/settings/remote.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';
import showSettingSaved from '@/utils/settingSaved.ts';

loadTemplate('c-show-online-contacts-toggle').then(({ content }) => {
  window.customElements.define(
    'c-show-online-contacts-toggle',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      async handleEvent({ type, currentTarget: { checked } }) {
        if ('change' === type) {
          await settings.set('showOnlineContacts', checked);
          showSettingSaved(this.nodes.showOnlineContactsSaved);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.nodes.showOnlineContacts.addEventListener('change', this);

        this.nodes.showOnlineContacts.checked = await settings.get('showOnlineContacts');
      }

      disconnectedCallback() {
        this.nodes.showOnlineContacts.removeEventListener('change', this);
      }
    },
  );
});
