import { getAuthenticatedUserAvailability } from '@/lib/data/getAuthenticatedUserAvailability.ts';
import * as userStatusService from '@/lib/data/userStatus.mjs';
import { translate } from '@/lib/i18n.mjs';
import * as localSettings from '@/lib/settings/local.mjs';

import { disable, enable, hide, loadTemplate, show } from '@/utils/dom.ts';
import { ActionsProxy, NodesProxy } from '@/utils/elementProxies.mjs';
import { userEvents } from '@/utils/eventTarget.ts';

loadTemplate('c-user-statuses').then(({ content }) => {
  window.customElements.define(
    'c-user-statuses',
    class extends HTMLElement {
      constructor() {
        super();

        this.nodes = new NodesProxy(this);
        this.actions = new ActionsProxy(this);
      }

      // the popout knows of the availability and the status of the user. (note: these can be two different things e.g. available !== offline)
      // if these are not the same warnings are shown!
      set selectedDeviceIsOffline(isSelectedDeviceOffline) {
        if (isSelectedDeviceOffline) {
          this.actions.available.querySelector('span').textContent = translate('available_ringing_device_offline');
          hide(this.nodes.checkIconAvailable);
          this.statusNodes.forEach((statusButton) => disable(statusButton));
        } else {
          this.actions.available.querySelector('span').textContent = translate('available');
          show(this.nodes.checkIconAvailable);
          this.statusNodes.forEach((statusButton) => enable(statusButton));
        }
      }

      async handleEvent({ type, currentTarget }) {
        switch (type) {
          case 'click':
            this.selectCurrentStatus(currentTarget);
            this.updateSelectedAvailability(currentTarget);
            break;

          case 'loggedIn':
            this.setUserAvailability();
            break;
        }
      }

      updateSelectedAvailability(statusNode) {
        userStatusService.set(statusNode.dataset.action);
      }

      selectCurrentStatus(statusNode) {
        this.statusNodes.forEach((node) => {
          node.classList.remove('selected');
        });
        statusNode.classList.add('selected');
      }

      // The below event handle function is an arrow functions so we don't have to bind it.
      // If not we would need to create a bound version of this function before using it as an event handler.
      handleAvailabilityChanged = () => {
        localSettings.set('userStatus', this.userModel.user_status);
        this.selectCurrentStatus(this.actions[this.userModel.user_status]);
      };

      async setUserAvailability() {
        // In case we had to login make sure to remove any handlers from previous-logged-in users.
        if (this.userModel) {
          this.userModel.off('change', this.handleAvailabilityChanged);
        }

        this.userModel = await getAuthenticatedUserAvailability();

        if (this.userModel) {
          // Also trigger this to use initial data.
          this.handleAvailabilityChanged();
          this.userModel.on('change', this.handleAvailabilityChanged);
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.statusNodes = [
          this.actions.available,
          this.actions.available_for_colleagues,
          this.actions.do_not_disturb,
          this.actions.offline,
        ];

        this.setUserAvailability();

        this.statusNodes.forEach((node) => node.addEventListener('click', this));
        userEvents.addEventListener('loggedIn', this);
      }

      disconnectedCallback() {
        if (this.userModel) {
          this.userModel.off('change', this.handleAvailabilityChanged);
        }

        this.statusNodes.forEach((node) => node.removeEventListener('click', this));
        userEvents.removeEventListener('loggedIn', this);
      }
    },
  );
});
