import getUserPermissions from '@/lib/data/getUserPermissions.mjs';

import arrayContainsAllValues from '@/utils/arrayContainsAllValues.mjs';

export default async function (permissions) {
  const userPermissions = await getUserPermissions();

  const hasAllPermissions = arrayContainsAllValues({ valuesAsArray: permissions, array: userPermissions });

  return hasAllPermissions;
}
