import isSetup from './isSetup.mjs';
import markAsAsyncIsInvalid from './markAsAsyncIsInvalid.mjs';
import markAsAsyncIsValid from './markAsAsyncIsValid.mjs';
import markAsAsyncValidationEnded from './markAsAsyncValidationEnded.mjs';
import markAsAsyncValidationStarted from './markAsAsyncValidationStarted.mjs';
import markAsSetup from './markAsSetup.mjs';
import markAsValidatedBefore from './markAsValidatedBefore.mjs';
import reportValidityIfNeeded from './reportValidityIfNeeded.mjs';
import resetCustomErrorMessage from './resetCustomErrorMessage.mjs';

export default function (validationRules, validationFunction) {
  return function setup(node) {
    if (isSetup(node)) {
      return;
    }

    node.addEventListener('change', resetCustomErrorMessage, { passive: true });
    node.addEventListener('focusin', resetCustomErrorMessage, { passive: true });
    node.addEventListener('focusin', validationFunction);
    node.addEventListener('focusin', reportValidityIfNeeded, { passive: true });
    node.addEventListener('blur', markAsValidatedBefore, { once: true, passive: true });
    node.addEventListener('focusout', validationFunction);
    node.addEventListener('validate', resetCustomErrorMessage, { passive: true });
    node.addEventListener('validate', validationFunction);

    // for async validation, not used by standard fields
    node.addEventListener('startedAsyncValidation', markAsAsyncValidationStarted);
    node.addEventListener('finishedAsyncValidation', markAsAsyncValidationEnded);
    node.addEventListener('isValid', markAsAsyncIsValid);
    node.addEventListener('isInvalid', markAsAsyncIsInvalid);

    validationRules.forEach(([attr, value]) => {
      node.setAttribute(attr, value);
    });

    markAsSetup(node);

    return () => {
      node.removeEventListener('change', resetCustomErrorMessage, { passive: true });
      node.removeEventListener('focusin', resetCustomErrorMessage, { passive: true });
      node.removeEventListener('focusin', validationFunction);
      node.removeEventListener('focusin', reportValidityIfNeeded, { passive: true });
      node.removeEventListener('blur', markAsValidatedBefore, { once: true, passive: true });
      node.removeEventListener('focusout', validationFunction);
      node.removeEventListener('validate', resetCustomErrorMessage, { passive: true });
      node.removeEventListener('validate', validationFunction);

      // for async validation, not used by standard fields
      node.removeEventListener('startedAsyncValidation', markAsAsyncValidationStarted);
      node.removeEventListener('finishedAsyncValidation', markAsAsyncValidationEnded);
      node.removeEventListener('isValid', markAsAsyncIsValid);
      node.removeEventListener('isInvalid', markAsAsyncIsInvalid);
    };
  };
}
