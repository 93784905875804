import { hide, loadTemplate, show } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('c-setting-error').then(({ content }) => {
  window.customElements.define(
    'c-setting-error',
    class extends HTMLElement {
      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }
      show() {
        show(this);
        this.hideTimeout = setTimeout(() => this.hide(), 5000);
      }

      hide() {
        hide(this);
        clearTimeout(this.hideTimeout);
      }

      setText(text) {
        this.nodes.errorTranslationKey.setAttribute('key', text);
      }

      connectedCallback() {
        hide(this);
        this.appendChild(content.cloneNode(true));
      }

      disconnectedCallback() {
        clearTimeout(this.hideTimeout);
      }
    },
  );
});
