import '@/components/c-queue.mjs';

import { getClientQueues } from '@/lib/data/getQueues.ts';

import { empty, loadTemplate } from '@/utils/dom.ts';
import { NodesProxy } from '@/utils/elementProxies.mjs';

loadTemplate('p-queues').then(({ content }) => {
  window.customElements.define(
    'p-queues',
    class extends HTMLElement {
      set queues(queues) {
        this._queues = queues;
        this.populate();
      }

      get queues() {
        return this._queues;
      }

      constructor() {
        super();
        this.nodes = new NodesProxy(this);
      }

      populate() {
        empty(this.nodes.queuesList);

        for (const queue of this.queues) {
          const queueNode = document.createElement('c-queue');
          this.nodes.queuesList.appendChild(queueNode);
          queueNode.data = queue;
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.queues = await getClientQueues();
      }
    },
  );
});
