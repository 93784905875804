import { userEvents } from '@/utils/eventTarget.ts';

const tempStorage = new Map();

export function set(key, value) {
  tempStorage.set(key, value);
}

export function get(key) {
  return tempStorage.get(key);
}

export function remove(key) {
  tempStorage.delete(key);
}

userEvents.addEventListener('loggedOut', () => {
  tempStorage.clear();
});
