import { justFinishedWelcomeWizard } from '@/lib/applicationStatus.mjs';
import * as segment from '@/lib/segment.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { navigate } from '@/utils/history.ts';

import { requestPermission } from '@/sw-notifications.mjs';

loadTemplate('p-audio-settings').then(({ content }) => {
  window.customElements.define(
    'p-audio-settings',
    class extends HTMLElement {
      handleEvent({ type }) {
        if ('click' === type) {
          localStorage.setItem('wizard_completed', true);

          justFinishedWelcomeWizard();
          segment.track.wizardCompleted();

          navigate('/dialer');
        }
      }

      connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.completeButton = this.querySelector('[data-selector=complete-button]');
        this.completeButton.addEventListener('click', this);
        requestPermission();
      }

      disconnectedCallback() {
        this.completeButton.removeEventListener('click', this);
        segment.track.wizardDevicesStepCompleted();
      }
    },
  );
});
