import * as localSettings from '@/lib/settings/local.mjs';

import { loadTemplate } from '@/utils/dom.ts';
import { dataEvents } from '@/utils/eventTarget.ts';

loadTemplate('c-queue-size').then(({ content }) => {
  window.customElements.define(
    'c-queue-size',
    class extends HTMLElement {
      async handleEvent({ detail: { queueId, waitingCallers } }) {
        const selectedQueues = await localSettings.get('selectedQueues');
        if (selectedQueues.length > 0) {
          this.badge.classList.remove('no-queue');
          this.allQueuesSize[queueId] = waitingCallers;
          // Object.values will give an array of the waiting callers. Reduce calls a function on each element of the array,
          // and passes the result back to the next value. So here: summing up all the waiting callers of all queues a user is watching.
          const sumValues = Object.values(this.allQueuesSize).reduce((a, b) => a + b, 0);

          this.badge.textContent = sumValues;
        } else {
          this.badge.classList.add('no-queue');
          this.badge.textContent = '0';
        }
      }

      async connectedCallback() {
        this.appendChild(content.cloneNode(true));

        this.badge = this.querySelector('[data-selector=badge]');
        this.allQueuesSize = {};

        dataEvents.addEventListener('queuesUpdated', this);

        const selectedQueues = await localSettings.get('selectedQueues');
        if (selectedQueues.length > 0) {
          this.badge.classList.remove('no-queue');
        }
      }

      disconnectedCallback() {
        dataEvents.removeEventListener('queuesUpdated', this);
      }
    },
  );
});
